import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgChartsModule} from "ng2-charts";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import {MatCardModule} from "@angular/material/card";
import {MatStepperModule} from "@angular/material/stepper";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MaterialFileInputModule} from "ngx-material-file-input";
import {MatNativeDateModule, MatOptionModule} from "@angular/material/core";
import {MatMenuModule} from "@angular/material/menu";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatTabsModule} from "@angular/material/tabs";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {MatDialogModule} from "@angular/material/dialog";
import {MatTableModule} from "@angular/material/table";
import {MatInputModule} from "@angular/material/input";
// import {initializeApp, provideFirebaseApp} from "@angular/fire/app";
// import {environment} from "../../../environments/environment";
// import {getAnalytics, provideAnalytics} from "@angular/fire/analytics";
// import {connectAuthEmulator, getAuth, provideAuth} from "@angular/fire/auth";
// import {connectDatabaseEmulator, getDatabase, provideDatabase} from "@angular/fire/database";
// import {connectFirestoreEmulator, getFirestore, provideFirestore} from "@angular/fire/firestore";
import {MatSelectModule} from "@angular/material/select";
import {MatSortModule} from "@angular/material/sort";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatRadioModule} from "@angular/material/radio";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatTooltipModule} from "@angular/material/tooltip";
import {FullCalendarModule} from "@fullcalendar/angular";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatTimepickerModule} from "mat-timepicker";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {FileUploadComponent} from "../../upload/file-upload/file-upload.component";
import {FileUploadDialogComponent} from "../../upload/file-upload-dialog/file-upload-dialog.component";
import {ImageUploadComponent} from "../../upload/image-upload/image-upload.component";
import {UploadFileDetailComponent} from "../../upload/image-upload/upload-file-detail/upload-file-detail.component";
import {ProgressComponent} from "../../progress/progress.component";
import {UploadComponent} from "../../upload/upload.component";
import {UploadListComponent} from "../../upload/image-upload/upload-list/upload-list.component";
import {CdkMenuModule} from "@angular/cdk/menu";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {IndianPostalCodesModule} from "indian-postal-codes";
import {getFunctions, provideFunctions} from "@angular/fire/functions";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FeatherIconsModule} from "./components/feather-icons/feather-icons.module";
import {NgApexchartsModule} from "ng-apexcharts";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
// import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
// import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
// import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
// import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';

@NgModule({
    declarations: [
        FileUploadComponent,
        FileUploadDialogComponent,
        ImageUploadComponent,
        UploadFileDetailComponent,
        ProgressComponent,
        UploadComponent,
        UploadListComponent,
        FileUploadComponent,
        // ConditionalRequiredValidatorDirective,

    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,

        MatTableModule,
        MatInputModule,
        MatButtonModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatSortModule,
        MatPaginatorModule,
        MatRadioModule,
        MatExpansionModule,
        MatTooltipModule,
        FullCalendarModule,
        ScrollingModule,
        MatButtonToggleModule,
        MatTimepickerModule,
        FormsModule,
        MatAutocompleteModule,
        MatProgressBarModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatSidenavModule,
        MatListModule,
        MatCardModule,
        MatStepperModule,
        MatSnackBarModule,
        MaterialFileInputModule,
        MatNativeDateModule,
        MatMenuModule,
        MatDatepickerModule,
        NgChartsModule,
        MatPaginatorModule,
        MatAutocompleteModule,
        MatTabsModule,
        MatBottomSheetModule,
        MatDialogModule,
        IndianPostalCodesModule,
        FeatherIconsModule,
        CKEditorModule,


    ],
    exports: [
        MatTableModule,
        MatInputModule,
        MatButtonModule,
        MatOptionModule,
        MatSelectModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatRadioModule,
        MatExpansionModule,
        MatTooltipModule,
        FullCalendarModule,
        ScrollingModule,
        MatButtonToggleModule,
        MatTimepickerModule,
        FormsModule,
        MatAutocompleteModule,
        MatProgressBarModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatListModule,
        MatCardModule,
        MatStepperModule,
        MatSnackBarModule,
        MaterialFileInputModule,
        MatNativeDateModule,
        MatMenuModule,
        MatDatepickerModule,
        NgChartsModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatTabsModule,
        MatBottomSheetModule,
        MatDialogModule,
        UploadComponent,
        FileUploadComponent,
        FileUploadDialogComponent,
        UploadFileDetailComponent,
        UploadListComponent,
        ScrollingModule,
        CdkMenuModule,
        IndianPostalCodesModule,
        ImageUploadComponent,
        FeatherIconsModule,
        // IconsModule,
        IndianPostalCodesModule,
        NgApexchartsModule,
        CKEditorModule,
        // ConditionalRequiredValidatorDirective,

    ]
})
export class SharedModule {
}
