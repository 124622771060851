import {Component, isDevMode, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../../auth.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {FullChild} from "../../interfaces/FullChild";
import firebase from "firebase/compat";
import {Centre} from "../../interfaces/Centres";
import {environment} from "../../../environments/environment";
import {UserRole} from "../../components/rename/add-user-role/add-user-role.component";

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
    env = isDevMode() ? environment.testMode : environment.productionMode

    admissions: UserRole[] = (localStorage.getItem('permissions') != null) ? JSON.parse(localStorage.getItem('permissions')!) as UserRole[] : [
        {
            functionName: "Child Admission",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Child Prescreening",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Counselling",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Education",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Meal Mate",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Unit Sponsorship",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "General Donation",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "CMS",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Child Report",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Counseling Report",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Education Report",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Donation Report",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Weekly Report",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Centres",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Employees",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Hospital",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Unit",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Types",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Donor",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Event",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Audit Logs",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Dashboard",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        }
    ];

    dashboardPermission = this.admissions.filter((x) => x.functionName == 'Dashboard')
    counsellingPermission = this.admissions.filter((x) => x.functionName == 'Counselling')
    educationPermission = this.admissions.filter((x) => x.functionName == 'Education')
    childAdmissionPermission = this.admissions.filter((x) => x.functionName == 'Child Admission')
    donationReport = this.admissions.filter((x) => x.functionName == 'Donation Report')
    childReport = this.admissions.filter((x) => x.functionName == 'Child Report')
    weeklyReport = this.admissions.filter((x) => x.functionName == 'Weekly Report')
    mealMate = this.admissions.filter((x) => x.functionName == 'Meal Mate')
    unitSponsership = this.admissions.filter((x) => x.functionName == 'Unit Sponsorship')
    CMS = this.admissions.filter((x) => x.functionName == 'CMS')
    centres = this.admissions.filter((x) => x.functionName == 'Centres')
    Employees = this.admissions.filter((x) => x.functionName == 'Employees')
    Hospital = this.admissions.filter((x) => x.functionName == 'Hospital')
    Unit = this.admissions.filter((x) => x.functionName == 'Unit')
    Types = this.admissions.filter((x) => x.functionName == 'Types')
    Donor = this.admissions.filter((x) => x.functionName == 'Donor')
    Events = this.admissions.filter((x) => x.functionName == 'Event')
    AuditLogs = this.admissions.filter((x) => x.functionName == 'Audit Logs')
    roleAs: string
    panelOpenState = true

    constructor(private router: Router,
                private mDatabase: AngularFireDatabase,
                private mFirestore: AngularFirestore,
                private mAuth: AuthService) {
        console.log(this.admissions)
        this.roleAs = mAuth.getRole()
        console.log('Role as', this.roleAs)
        // this.mDatabase.list('users').snapshotChanges().subscribe(res=>{
        //  res.forEach(it=>{
        //    console.log('x',it.payload.val())
        //    console.log('y',it.payload.key)
        //  this.mDatabase.object(`users/${it.payload.key}`).update({'uid':it.payload.key})
        //  })
        // })

    }

    ngOnInit(): void {
        // this.addExpiredChildrens()
        // this.moveChildData()
        // this.getWrongGenders()
        // this.backUpALChild()
        // this.setcurrentUnitToSelectUnit()
//this.router.navigateByUrl('dashboard')
//     this.setBulkAge()
//    this.RenameHospitals()
//     this.changeCenterName()
this.setSponsorshipStatus()

        // this.setUnitsBulk(newAlloted)
        //this.setisAllotedToFalseForAll()

        // * For setting status by isAlloted Value
        //   this.setStatus()
      // this.changeExpiredStatus()
    }
changeExpiredStatus (){
      this.mFirestore.collection<FullChild>(this.env.child,ref => ref.where('lifeStatus','==',"EXP")).valueChanges({idField:'id'}).subscribe(res=>{
      // alert(res.length)
        res.forEach(it=>{
        if(it.expired==true){
          // this.mFirestore.collection(this.env.child).doc(it.id).update({"status":"OM SHANTI"}).then(()=>{
          //   console.log("changed status of"+it.id)
          // })
        }
      })
      })
}
    setUnitsBulk(childArray: string[]) {
        childArray.forEach(it => {
            this.mFirestore.collection<FullChild>('ALChild').doc(it).valueChanges().subscribe(child => {
                console.log(it, child?.childCode)
                this.mDatabase.object(`ALUnits/${child?.centreCode}/${child?.selectUnit}`).update(
                    {
                        allotedTo: child?.childCode,
                        centreCode: child?.centreCode,
                        name: child?.firstName + ' ' + child?.lastName,
                        status: 'Alloted'
                    }
                ).then(() => {
                    console.log('Updated', 'alloted' + child?.selectUnit)
                })
            })

        })

    }

    setBulkAge() {
        this.mFirestore.collection<FullChild>(this.env.child).get().subscribe(res => {
            alert(res.docs.length)

            res.forEach(it => {
                    let c = it.data()
                    console.log(c.dob)
                    if (c.dob) this.getCalculatedAge(c.dob, c.childCode)
                    else console.log('DOB NOT AVAILABLE IN ', c.childCode)
                }
            )
        })
    }

    getCalculatedAge(child: firebase.firestore.Timestamp, childCode: string) {
        let timeDiff = Math.abs(Date.now() - (child).toDate().getTime());
        let age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
        // * UPDATING AGE IN FIRESTORE ALCHILD
        this.mFirestore.collection(this.env.child).doc(childCode).update({age: age}).then(() => {
            console.log(`SET DATE TO ${childCode} as`, age)
        })
            .catch(() => {
                console.log('ERROR ADDING AGE IN', childCode)
            })
        console.log(age)
        return age
    }

    // setisAllotedToFalseForAll(){
    //   this.mFirestore.collection<FullChild>('ALChild').valueChanges({idField:'key'}).subscribe(child=>{
    //     child.forEach(res=>{
    //       this.mFirestore.collection<FullChild>('ALChild').doc(res.key).update({isAlloted:false})
    //       console.log('setisAllotedToFalseForAll',res.childCode)
    //     })
    //     this.mDatabase.object(`ALUnits/${child?.centreCode}/${child?.selectUnit}`).update(
    //         {
    //           allotedTo:child?.childCode,
    //           centreCode:child?.centreCode,
    //           name:child?.firstName +' ' +child?.lastName,
    //           status:'Alloted'
    //         }
    //       ).then(()=>{
    //       console.log('Updated','alloted'+ child?.selectUnit)
    //     })
    //   })
    //
    //
    // }
    //* To add selectUnit to every child where currentUnit exits and selectUnit ==null
    setcurrentUnitToSelectUnit() {
        this.mFirestore.collection<FullChild>('registeredChild').valueChanges({idField: 'id'}).subscribe(it => {
            it.forEach(child => {
                if (child.selectUnit == null && child.currentUnit != null) this.mFirestore.collection('registeredChild').doc(child.id).update({selectUnit: child.currentUnit})
                console.log('Child Updated', child.id)
            })
        })
    }

    private RenameHospitals() {
        this.mFirestore.collection<FullChild>('registeredChild', ref => ref.where('hospital', '==', 'Kastruba Hospital Manipal')).get()
            .subscribe(it => {

                it.forEach(c => {
                    this.mFirestore.collection('registeredChild').doc(c.data().id).update({hospital: 'Kasturba Hospital'}).then(() => {
                        console.log('RENAMED HOSPITAL IN ', c.data().id)
                    })
                })
            })
    }

    private changeCenterName() {
        this.mFirestore.collection<Centre>('ALCentres').doc('ALDiamond Garden').valueChanges({idField: 'id'}).subscribe(res => {
            let c = res as Centre
            this.mFirestore.collection('ALCentres').doc('ALDiamondGarden').set(c).then(r => {
                alert('DIAMOND GARDEN ADDED')
            })
            console.table(c)
        })
    }

    setSponsorshipStatus() {
alert('set sponsorship status')
        this.mFirestore.collection<FullChild>('ALChild',ref => ref.where('sponsored','==',undefined)).get()

            .subscribe(child => {
                child.docs.forEach(it=>{
                    console.log('TOTAL UNSPONSORED CHILDREN',child.docs.length)
                    this.mFirestore.collection('ALChild').doc(it.id).update({sponsored: false}).then(res => {
                        console.log('added sponsored status to '+it.id)
                })
            })
        })
    }

    private getWrongGenders() {
        this.mFirestore.collection<FullChild>('ALChild').valueChanges({idField: 'id'}).subscribe(res => {
            let missingGender: string[] = []
            res.forEach(c => {
                if (c.gender != "Male" && c.gender != "Female") {
                    missingGender.push(c.childCode + "=>" + c.gender)
                }

            })
            console.log('Missing Gender', missingGender)
        })
    }

    private backUpALChild() {
        this.mFirestore.collection<FullChild>("ALChild").valueChanges({idField: 'id'}).subscribe(res => {
            res.forEach(it => {
                this.mFirestore.collection('backupbeforefulladmin').doc(it.id).set(it).then(it1 => {
                    console.log(it.id)
                })
            })
        })
    }

    private moveChildData() {
// this.move86t085()
//     this.move87t086()
//     this.move88t087()
    }

    private move86t085() {
        this.mFirestore.collection<FullChild>('ALChild').doc('AL-2023-00886').get().subscribe(res => {
            let data = res.data()
            this.mFirestore.collection('ALChild').doc('AL-2023-00885').set(data).then(res => {
                alert('move 86 t0 85 Successfull')
            })
        })
    }


    private addExpiredChildrens() {


        const complaintIds: string[] = [
            "AL-2014-00008",
            "AL-2014-00010",
            "AL-2015-00011",
            "AL-2015-00013",
            "AL-2015-00018",
            "AL-2015-00019",
            "AL-2015-00023",
            "AL-2016-00028",
            "AL-2016-00029",
            "AL-2016-00030",
            "AL-2016-00033",
            "AL-2016-00043",
            "AL-2016-00045",
            "AL-2016-00049",
            "AL-2016-00059",
            "AL-2016-00062",
            "AL-2016-00063",
            "AL-2016-00066",
            "AL-2016-00071",
            "AL-2016-00074",
            "AL-2017-00076",
            "AL-2017-00081",
            "AL-2017-00082",
            "AL-2017-00083",
            "AL-2017-00086",
            "AL-2017-00088",
            "AL-2017-00093",
            "AL-2017-00094",
            "AL-2017-00095",
            "AL-2017-00099",
            "AL-2017-00101",
            "AL-2017-00102",
            "AL-2017-00104",
            "AL-2017-00105",
            "AL-2017-00106",
            "AL-2017-00109",
            "AL-2017-00117",
            "AL-2017-00120",
            "AL-2017-00122",
            "AL-2017-00123",
            "AL-2017-00124",
            "AL-2017-00125",
            "AL-2017-00132",
            "AL-2017-00133",
            "AL-2017-00135",
            "AL-2017-00137",
            "AL-2017-00145",
            "AL-2017-00149",
            "AL-2017-00153",
            "AL-2017-00155",
            "AL-2018-00157",
            "AL-2018-00159",
            "AL-2018-00161",
            "AL-2018-00166",
            "AL-2018-00169",
            "AL-2018-00171",
            "AL-2018-00177",
            "AL-2018-00179",
            "AL-2018-00181",
            "AL-2018-00184",
            "AL-2018-00186",
            "AL-2018-00187",
            "AL-2018-00192",
            "AL-2018-00195",
            "AL-2018-00199",
            "AL-2018-00201",
            "AL-2018-00204",
            "AL-2018-00209",
            "AL-2018-00210",
            "AL-2018-00211",
            "AL-2018-00212",
            "AL-2018-00214",
            "AL-2018-00219",
            "AL-2018-00221",
            "AL-2018-00222",
            "AL-2018-00223",
            "AL-2018-00224",
            "AL-2018-00231",
            "AL-2018-00232",
            "AL-2018-00233",
            "AL-2018-00245",
            "AL-2018-00246",
            "AL-2018-00250",
            "AL-2018-00252",
            "AL-2018-00255",
            "AL-2018-00257",
            "AL-2018-00259",
            "AL-2018-00261",
            "AL-2018-00266",
            "AL-2018-00268",
            "AL-2018-00279",
            "AL-2019-00315",
            "AL-2019-00326",
            "AL-2019-00333",
            "AL-2019-00335",
            "AL-2019-00337",
            "AL-2019-00338",
            "AL-2019-00339",
            "AL-2019-00349",
            "AL-2019-00352",
            "AL-2019-00357",
            "AL-2019-00367",
            "AL-2019-00370",
            "AL-2019-00371",
            "AL-2019-00375",
            "AL-2019-00377",
            "AL-2019-00378",
            "AL-2019-00392",
            "AL-2019-00393",
            "AL-2019-00398",
            "AL-2019-00407",
            "AL-2019-00408",
            "AL-2019-00440",
            "AL-2019-00450",
            "AL-2019-00455",
            "AL-2019-00459",
            "AL-2020-00476",
            "AL-2020-00482",
            "AL-2020-00488",
            "AL-2020-00493",
            "AL-2020-00503",
            "AL-2020-00510",
            "AL-2020-00512",
            "AL-2020-00513",
            "AL-2020-00517",
            "AL-2020-00528",
            "AL-2020-00533",
            "AL-2021-00547",
            "AL-2021-00557",
            "AL-2021-00558",
            "AL-2021-00579",
            "AL-2021-00582",
            "AL-2021-00586",
            "AL-2021-00592",
            "AL-2021-00595",
            "AL-2021-00604",
            "AL-2021-00611",
            "AL-2021-00616",
            "AL-2021-00628",
            "AL-2021-00631",
            "AL-2021-00639",
            "AL-2021-00641",
            "AL-2021-00643",
            "AL-2021-00645",
            "AL-2021-00647",
            "AL-2021-00650",
            "AL-2021-00656",
            "AL-2021-00664",
            "AL-2022-00666",
            "AL-2022-00669",
            "AL-2022-00678",
            "AL-2022-00682",
            "AL-2022-00689",
            "AL-2022-00703",
            "AL-2022-00724",
            "AL-2022-00730",
            "AL-2022-00746",
            "AL-2022-00772",
            "AL-2022-00782",
            "AL-2022-00816"
        ];
    complaintIds.forEach(it=>{
        this.mFirestore.collection('ALChild').doc(it).update({"status": "OM SHANTI", 'expired': true}).then(()=>{
            console.log('UPloaded',it)
        }).catch(res=>{
            console.log('CHILD NOT FOUND',it)
        })
    })
    }
}
