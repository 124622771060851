import {Component, OnInit} from '@angular/core';
import statesdata from "../../../../assets/json/states.json";
import {States} from "../../../interfaces/States";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {lowerFirst} from "lodash";
import {firstValueFrom, take} from "rxjs";
import {Database} from "@angular/fire/database";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {Timestamp} from "@angular/fire/firestore";
import {map} from "rxjs/operators";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {MatSnackBar} from "@angular/material/snack-bar";

export interface AttemptedDonationModel {
  date:Timestamp,
  donorMobile: string,
  donorName: string,
  amount: string,
  state: string,
  zipcode: string,
  donoraddress: string,
  donor_type: string,
  donorcity: string,
  donation_type: string,
  email: string,
  pan: string,
  city: string,


}

@Component({
  selector: 'app-manual-attempted-donation-reciept',
  templateUrl: './manual-attempted-donation-reciept.component.html',
  styleUrls: ['./manual-attempted-donation-reciept.component.scss']
})
export class ManualAttemptedDonationRecieptComponent implements OnInit {
  currentCounter: string = '';
  myForm!: any
  receiptDate: Date | undefined
  stateslist = statesdata as States[]
  private mId: string | undefined
  private mData: AttemptedDonationModel | undefined
  updateCounter = false;

  constructor(private readonly mFirestore: AngularFirestore,
              private router: Router,
              private mDatabase: Database,
              private db: AngularFireDatabase,
              private snackBar:MatSnackBar,
              private mRoute: ActivatedRoute) {
    // private setReceiptDate(date:number,month:number,year:number,hour:number,minutes:number,ss:number) {

    // this.setReceiptDate(23,3,2024,14,38,10)
    if (this.mId == null) this.myForm = new FormGroup({
      donated_by: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      donation_amount: new FormControl(0, Validators.required),
      donation_type: new FormControl({value: 'Online', disabled: true}, Validators.required),
      idproof: new FormControl({value: 'PAN', disabled: true}, Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      country: new FormControl({value: 'India', disabled: true}, Validators.required),
      nationality: new FormControl({value: 'Indian', disabled: true}, Validators.required),
      zipcode: new FormControl('', [Validators.required, Validators.maxLength(6)]),
      city: new FormControl('', Validators.required),
      pan: new FormControl('', Validators.compose([Validators.required, Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}')])),
      mobile: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.pattern('^[0-9]{10}$')]),
      details: new FormControl('details',Validators.required),
      receipt_id: new FormControl("",Validators.required),
      order_id: new FormControl('',Validators.required),
      payment_id: new FormControl('',Validators.required),
      groupKey: new FormControl("",Validators.required),
      description: new FormControl('General Donation',Validators.required),
      method: new FormControl('ONLINE',Validators.required),
      totalAmountWithoutTax: new FormControl(0,Validators.required),
      addtax: new FormControl(false),
      createdAt: new FormControl(null,Validators.required),
      date: new FormControl(null,Validators.required),
    });
    this.mRoute.params.subscribe(res => {
      this.mId = res['id']

      console.log('id is', this.mId)
      this.mFirestore.collection('attemptedDonations').doc(this.mId).get().subscribe(snap => {
        this.mData = snap.data() as AttemptedDonationModel
        console.log('id Data', this.mData)
        // alert(JSON.stringify(this.mData))
        if (this.mData != null) {
          // let details = JSON.parse(this.mData.details).error.metadata
          // alert(details)
          this.getReceiptName()

          this.myForm = new FormGroup({
            donated_by: new FormControl(this.mData.donorName, Validators.required),
            address: new FormControl(this.mData.donoraddress, Validators.required),
            state: new FormControl(this.mData.state, Validators.required),
            donation_amount: new FormControl(this.mData.amount, Validators.required),
            donation_type: new FormControl({value: 'Online', disabled: true}, Validators.required),
            idproof: new FormControl({value: 'PAN', disabled: true}, Validators.required),
            email: new FormControl(this.mData.email, [Validators.required, Validators.email]),
            country: new FormControl({value: 'India', disabled: true}, Validators.required),
            nationality: new FormControl({value: 'Indian', disabled: true}, Validators.required),
            zipcode: new FormControl(this.mData.zipcode, [Validators.required, Validators.maxLength(6)]),
            city: new FormControl(this.mData.donorcity, Validators.required),
            pan: new FormControl(this.mData.pan, Validators.compose([Validators.required, Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}')])),
            mobile: new FormControl(this.mData.donorMobile, [Validators.required, Validators.maxLength(10), Validators.pattern('^[0-9]{10}$')]),
            details: new FormControl('details',Validators.required),
            receipt_id: new FormControl('',Validators.required),
            order_id: new FormControl('',Validators.required),
            payment_id: new FormControl('',Validators.required),
            groupKey: new FormControl(this.mData.donation_type||'GF',Validators.required),
            description: new FormControl(this.mData.donation_type||'General Donation',Validators.required),
            method: new FormControl('ONLINE',Validators.required),
            totalAmountWithoutTax: new FormControl(this.mData.amount),
            addtax: new FormControl(false,Validators.required),
            createdAt: new FormControl(this.mData.date!!.toDate().getTime(),Validators.required),
            date: new FormControl((this.mData.date as Timestamp)!!.toDate(),Validators.required),
          });

        }

      })
    })


  }

  ngOnInit(): void {
  }

  getReceiptName(): void {
    console.log('getting receipt');

    let msg = `2024-2025-80G-ONL-`;

    // Select the path for production or test
    const documentRef = `receiptcounter/counter`;

    firstValueFrom(this.db.object<number>(documentRef).valueChanges())
      .then((snapshot) => {

        if (snapshot !== null) {
          if (snapshot.toString().length === 1) {
            msg += '00' + snapshot;
          } else if (snapshot.toString().length === 2) {
            msg += '0' + snapshot;
          } else {
            msg += snapshot;
          }

          console.log(msg);
          this.currentCounter = msg
     this.myForm.get('receipt_id').setValue(this.currentCounter);
        }

      })
  }


  submit() {

    if (this.myForm.valid) {
      this.myForm.get('totalAmountWithoutTax').patchValue(this.myForm.get('donation_amount')?.value)
      this.myForm.get('createdAt').setValue(this.myForm.get('date')?.value.getTime());
      this.mFirestore.collection('donations').add(this.myForm.value).then(res => {
        if(this.updateCounter){
this.incrementCounter()
        }
        this.router.navigateByUrl('/home/donation')
      })
    } else {
      console.log(this.findInvalidControls())
this.snackBar.open('Please enter all fields','',{
  panelClass:'snackbar-error',
  duration:3000

})
    }
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.myForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  // private setReceiptDate(date:number,month:number,year:number,hour:number,minutes:number,ss:number) {
  //   let d = new Date()
  //   d.setDate(date)
  //   d.setMonth(month)
  //   d.setFullYear(year)
  //   d.setHours(hour)
  //   d.setMinutes(minutes)
  //   d.setSeconds(ss)
  //   this.receiptDate =d
  //   return d
  // }
  // async incrementCounter(): Promise<void> {
  //   try {
  //     const counterRef = this.db.object('receiptcounter/counter');
  //     const counterSnapshot = await counterRef.snapshotChanges().toPromise();
  //
  //     let currentCount = 0;
  //     if (counterSnapshot!!.exists()) {
  //       currentCount = counterSnapshot!!.payload.val() as number;
  //     }
  //
  //     await counterRef.update({ count: currentCount + 1 });
  //     console.log('Counter incremented successfully!');
  //   } catch (error) {
  //     console.error('Error incrementing counter:', error);
  //   }
  // }
  async incrementCounter(): Promise<void> {
    try {
      const counterRef = this.db.object<number>('receiptcounter/counter')
      const counter$ = counterRef.valueChanges();

      counter$.pipe(
          take(1),
          map(count => ({ count: count ? count + 1 : 1 }))
      ).subscribe((updatedCount: any) => {

        this.db.object<number>('receiptcounter/counter').set(updatedCount.count);
        // counterRef.set(updatedCount.count);
      });

    } catch (error) {
      console.error('Error incrementing counter:', error);
    }
  }

  onCheckboxChange($event: MatCheckboxChange) {
    this.updateCounter=$event.checked
  }
}
