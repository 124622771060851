import {Injectable, isDevMode} from '@angular/core';
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {Centre, CentreOption} from 'src/app/interfaces/Centres'
import {Database, increment, ref, set} from "@angular/fire/database";
import {MatSnackBar} from "@angular/material/snack-bar";
import {UnitModel} from "../models/UnitModel";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {catchError, map, shareReplay, switchMap, tap} from "rxjs/operators";

import {environment} from "../../environments/environment";

import { FormControl } from '@angular/forms';
import {of, throwError} from "rxjs";




@Injectable({
    providedIn: 'root'
})

export class CentreService {
    id = ''
    centreRead = 0
    // unitArray: UnitModel[] = []
    env = isDevMode() ? environment.testMode : environment.productionMode
centreControl = new FormControl()
    constructor(private mDatabase: AngularFireDatabase,
                private mDatabaseRef: Database,
                private mFirestore: AngularFirestore,
                private _snackbar: MatSnackBar) {
        console.log(isDevMode())
    }

    addCenter(centre: Centre) {
        const mCounterRef = this.mDatabase.object(`${this.env.counters}/centreCounter`)
        let sub$ = mCounterRef.valueChanges().subscribe((res) => {
            console.log(res)
            /** This is the new Id by which center will be added*/
            const newId = 'AL0' + res
            this.id = 'ALDiamondGarden'
            console.log(newId)
            centre.sr = res as number
            /**  We are adding the centre with new generated Center ID  */
            centre.centreCode = 'ALDiamondGarden'
            this.mFirestore.doc(`${this.env.centres}/${newId}`).set(centre)
            this.mDatabase.object(`${this.env.centres}/${newId}`).set(centre).then(() => {
                sub$.unsubscribe()
                const documentRef = ref(this.mDatabaseRef, `${this.env.counters}/centreCounter`)
                /**After adding increasing the current center counter by 1 */
                set(documentRef, increment(1)).then(() => {
                    let map = new Map<string, UnitModel>()
                    for (let i = 0; i < Number(centre.totalUnits); i++) {
                        console.log(`repeated ${i} times`)
                        const a = new UnitModel()
                        a.order = i + 1
                        a.centreCode = 'Test'
                        map.set(`${newId}-${i + 1}`, a)
                        console.log(map.get(`${newId}-${i + 1}`))
                        // this.unitArray.set('AL',a)
                        console.log('unitarray: ', JSON.stringify(map))
                    }
                    console.log('after loop', map)

                    map.forEach((i, key) => {
                        i.key = key
                        this.mDatabase.object(`${this.env.units}/${this.id}/${key}`).set(i)
                    })
                    this.mDatabase.object(`${this.env.units}/test`).set(map).then(() => {
                        this._snackbar.open('Center Added Successfully!', '', {duration: 2000})

                    }).catch((reason) => {
                        console.log(reason)
                    })

                    //   console.log('counter updated by 1')
                    // })
                })


                // this.mDatabase.object('counters/centreCounter',{})
                // @ts-ignore
                //Getting Current Counter From Database
                // get(documentRef).then((snapshot)=>{
                //   console.log(snapshot.val())
                // })
                // return set(documentRef, increment(1)).then(() => {
                //   console.log('counter updated by 1')
                // })

            })
        })
    }

    addCenter2(centre: Centre, unitArr: Array<UnitModel>) {
        //* GETTING LATEST CENTER NUMBER FROM CENTRE COUNTER FROM FIREBASE DATABASE
        const mCounterRef = this.mDatabase.object(`${this.env.counters}/centreCounter`)
        let sub$ = mCounterRef.valueChanges().subscribe((res) => {
            console.log(res)

            //**  This is the new Id by which center will be added*/
            const newId = centre.centreName.replace(' ', '')
            this.id = newId
            console.log(newId)
            centre.sr = res as number
            //**  We are adding the centre with new generated Center ID  */
            centre.centreCode = newId
            this.mFirestore.doc(`${this.env.centres}/${newId}`).set(centre)
            this.mDatabase.object(`${this.env.centres}/${newId}`).set(centre).then(() => {
                sub$.unsubscribe()
                const documentRef = ref(this.mDatabaseRef, `${this.env.counters}/centreCounter`)
                /**After adding increasing the current center counter by 1 */
                set(documentRef, increment(1)).then(() => {
                    // Array to Unit Logic
                    //
                    let map = new Map<string, UnitModel>()
                    for (let i = 0; i < Number(unitArr.length); i++) {
                        // console.log(`repeated ${i} times`)
                        // const a = new UnitModel()
                        // @ts-ignore
                        unitArr.at(i).order = i + 1
                        // @ts-ignore
                        unitArr.at(i).centreName = centre.centreName

                        // @ts-ignore
                        unitArr.at(i).centreCode = newId
                        // @ts-ignore
                        map.set(`${newId}-${i + 1}`, unitArr.at(i) as UnitModel)
                        console.log(map.get(`${newId}-${i + 1}`))
                        // this.unitArray.set('AL',a)
                        console.log('unitarray: ', map)
                    }
                    console.log('after loop', map)

                    map.forEach((i, key) => {
                        i.key = key
                        this.mDatabase.object(`${this.env.units}/${this.id}/${key}`).update(i)
                        console.log('Key:' + key, 'value ' + i.key)
                    })
                    this.mDatabase.object(`${this.env.units}/test`).set(map).then(() => {
                        this._snackbar.open('Center Added Successfully!', '', {duration: 2000})
                    }).catch((reason) => {
                        console.log(reason)
                    })

                    //   console.log('counter updated by 1')
                    // })
                })


                // this.mDatabase.object('counters/centreCounter',{})
                // @ts-ignore
                //Getting Current Counter From Database
                // get(documentRef).then((snapshot)=>{
                //   console.log(snapshot.val())
                // })
                // return set(documentRef, increment(1)).then(() => {
                //   console.log('counter updated by 1')
                // })

            })
        })
    }

    updateCenter(key: String, centre: Centre, oldUnits: number, newUnits: number) {
        console.log('EDIT CENTRE', centre)

        this.allotNewUnits(centre, oldUnits, newUnits)
        // centre.availableUnits = centre.totalUnits-centre.occupiedUnits!
        return this.mFirestore.doc(`${this.env.centres}/${centre.centreCode}`).update(centre)

        // return this.mDatabase.object(`dummy/centrelist/${key}`).update(centre)
    }

    allotNewUnits(centre: Centre, oldUnits: number, newUnits: number) {
        if (newUnits > oldUnits) {
            const map = new Map<string, UnitModel>()
            for (let i = 0; i < Number(centre.totalUnits); i++) {
                //     console.log(`repeated ${i} times`)
                const a = new UnitModel()
                a.centreName = centre.centreName
                a.order = i + 1
                a.centreCode = centre.centreCode

                if (i >= oldUnits) map.set(`${a.centreCode}-${i + 1}`, a)
                //    console.log(map.get(`${a.centreCode}-${i + 1}`))
                // this.unitArray.set('AL',a)
                //   console.log('unitarray: ', JSON.stringify(map))
            }
            console.log('after loop', map)

            map.forEach((i, key) => {
                i.key = key
                // this.mDatabase.object(`UnitsTest/units/${centre.centreCode}/${key}`).update(i)
                this.mDatabase.object(`${this.env.units}/units/${centre.centreCode}/${key}`).update(i)
            })
        }
    }

    getAllCentresBackup() {
        let userData = JSON.parse(localStorage.getItem('userData')!)
        console.log('into getallcentres')
        let query = this.mFirestore.collection<Centre>(this.env.centres, ref1 => ref1.orderBy('isArchive','asc').where('isArchive','!=',true)).valueChanges({idField: 'key'})
        if (userData.role == 'Centre') query = this.getSortedCenterListByRole(userData.centreCode)
        return query
        // return this.mDatabase.list('dummy/centrelist').snapshotChanges()
    }
    getAllCentres() {
        let userData = JSON.parse(localStorage.getItem('userData')!)
        console.log('into getallcentres')
        let query = this.mFirestore.collection<Centre>(this.env.centres, ref1 => ref1.orderBy('isArchive','asc').where('isArchive','!=',true)).valueChanges({idField: 'key'})
        if (userData.centresAllowed) query = this.getCentresByArray(userData.centresAllowed)
        return query
        // return this.mDatabase.list('dummy/centrelist').snapshotChanges()
    }
  getAllCentresBorivali() {
    console.log('into getallcentres')
    let query = this.mFirestore.collection<Centre>(this.env.centres, ref1 => ref1.orderBy('isArchive','asc').where('centreCode','==','ALBorivali')).valueChanges({idField: 'key'})
    return query
    // return this.mDatabase.list('dummy/centrelist').snapshotChanges()
  }
    getAllCentresForMealmate() {
        let userData = JSON.parse(localStorage.getItem('userData')!)
        console.log('into getallcentres')
        let query = this.mFirestore.collection<Centre>(this.env.centres, ref1 => ref1.orderBy('isArchive','asc').where('isArchive','!=',true).where('mealMate','==',true)).valueChanges({idField: 'key'})
        // if (userData.centresAllowed) query = this.getCentresByArray(userData.centresAllowed)
        return query
        // return this.mDatabase.list('dummy/centrelist').snapshotChanges()
    }
    getAllCentresOptions() {
        let userData = JSON.parse(localStorage.getItem('userData')!)
        console.log('into getallcentres')
        let query = this.mFirestore.collection<CentreOption>(this.env.centres, ref1 => ref1.orderBy('isArchive','asc').where('isArchive','!=',true)).valueChanges({idField: 'key'})
        if (userData.centresAllowed) query = this.getCentresByArray(userData.centresAllowed)
        return query.pipe(
            map(centres => centres.map<CentreOption>(centre => ({ centreName: centre.centreName, centreCode: centre.centreCode }))) // Map to desired structure

        )
        // return this.mDatabase.list('dummy/centrelist').snapshotChanges()
    }
//* Used in unit sponsorship
  getAllCentresSorting(centreCode:string) {
    let userData = JSON.parse(localStorage.getItem('userData')!)
    console.log('into getallcentres')
    //By default for All Centres
    let query = this.mFirestore.collection<Centre>(this.env.centres, ref1 => ref1.orderBy('isArchive','asc').where('isArchive','!=',true)).valueChanges({idField: 'key'})
   if(centreCode !='All') query = this.mFirestore.collection<Centre>(this.env.centres, ref1 => ref1.orderBy('isArchive','asc').where('isArchive','!=',true).where('centreCode','==',centreCode)).valueChanges({idField: 'key'})

    if (userData.centresAllowed) query = this.getCentresByArray(userData.centresAllowed)
    return query
    // return this.mDatabase.list('dummy/centrelist').snapshotChanges()
  }

    getCentresByArray(centresAllowed: string[]) {
        const centerRef = this.mFirestore.collection<Centre>(this.env.centres, ref1 =>
            ref1
                .where('centreCode', 'in', centresAllowed)
                .where('isArchive', '!=', true)
                // .orderBy("sr", 'desc') // Use only if necessary
                // .limit(20) // Example: Limit results to 20
        );

        return centerRef.valueChanges({ idField: 'key' }).pipe(
            tap(arr => console.log(`centre: read (${this.centreRead += arr.length}) docs`)),
            shareReplay(1),
            catchError(error => {
                // Handle errors gracefully
                console.error('Error fetching centres:', error);
                return throwError(error);
            })
        );
    }
    getTotalAllCentres() {
        let userData = JSON.parse(localStorage.getItem('userData')!)
        console.log('into getallcentres')
        let query = this.mFirestore.collection<Centre>(this.env.centres, ref1 => ref1.orderBy("isArchive").orderBy('sr', 'desc').where('isArchive',"!=",true)).valueChanges()
        // if (userData.role == 'Centre') query = this.getSortedCenterListByRole(userData.centreCode)
        return query
        // return this.mDatabase.list('dummy/centrelist').snapshotChanges()
    }
    getCentreByCode(centreCode:string) {
        let userData = JSON.parse(localStorage.getItem('userData')!)
        console.log('into getCentreByCode')
        let query = this.mFirestore.collection<Centre>(this.env.centres, ref1 => ref1.where('centreCode','==',centreCode)).valueChanges({idField: 'key'})
        if (userData.role == 'Centre') query = this.getSortedCenterListByRole(userData.centreCode)
        return query
        // return this.mDatabase.list('dummy/centrelist').snapshotChanges()
    }
 //? FETCHING ALL ACTIVE AND INACTIVE CENTRES
    getCentreList() {
        console.log('into: getCenterList')
        const centreRef = this.mFirestore.collection<Centre>(this.env.centres)
        return centreRef.valueChanges().pipe(
            tap(arr => console.log(`centre: read (${this.centreRead += arr.length} )docs`), shareReplay(1)
            ))
    }

    getSortedCenterList() {
        console.log('into: getSortedCenterList')

        const centerRef = this.mFirestore.collection<Centre>(this.env.centres, ref1 => ref1.orderBy("sr", 'desc'))

        return centerRef.valueChanges({idField: 'key'}).pipe(
            tap(arr => console.log(`centre: read (${this.centreRead += arr.length} )docs`), shareReplay(1)
            ))
    }

    getSortedCenterListByRole(centreCode: string, ) {
        console.log('into: getSortedCenterListByRole')

        const centerRef = this.mFirestore.collection<Centre>(this.env.centres, ref1 => ref1.where('centreCode', '==', centreCode)
            // .orderBy("sr", 'desc')
        )

        return centerRef.valueChanges({idField: 'key'}).pipe(
            tap(arr => console.log(`centre: read (${this.centreRead += arr.length} )docs`), shareReplay(1)
            ))
    }

    getCentreListSnapshot() {
        const centreRef = this.mDatabase.list<Centre>(`${this.env.centres}/centrelist`)
        return centreRef.snapshotChanges()
    }

    archiveCenter(c: Centre) {
        let value = c.isArchive
        if (c.isArchive == true) {
            value = false
        } else value = true
        return this.mFirestore.doc(`${this.env.centres}/${c.centreCode}`).update({isArchive: value})

    }

    getAmmenities() {
        return this.mDatabase.list('types/Ammenities').valueChanges()
    }

    getCentresByRole() {

        const centerRef = this.mFirestore.collection<Centre>(this.env.centres, ref1 => ref1.orderBy("sr", 'desc'))

        return centerRef.valueChanges({idField: 'key'}).pipe(
            tap(arr => console.log(`centre: read (${this.centreRead += arr.length} )docs`), shareReplay(1)
            ))
    }
fetchCentreByCode(centreCode:string){
    console.log('centreSelected',centreCode)
       return  this.mFirestore.collection(this.env.centres).doc(centreCode).get().toPromise()
}

}
