import {Component, OnInit} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {UserRole} from "../../rename/add-user-role/add-user-role.component";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import _default from "chart.js/dist/plugins/plugin.legend";
import title = _default.defaults.title;
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AddRolePermissionsComponent} from "../add-role-permissions/add-role-permissions.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-edit-rol-permissions',
  templateUrl: './edit-role-permissions.component.html',
  styleUrls: ['./edit-role-permissions.component.scss']
})
export class EditRolePermissionsComponent implements OnInit {
  defaultPermissions = [
    'Child Admission',
    'Child Prescreening',
    'Counselling',

    'Education',
    'Meal Mate',
    'Unit Sponsorship',
    'General Donation',
    'CMS',
    'Education Report',
    'Donation Report',
    'Weekly Report',
    'Hospital',
    'Unit',
    'Types',
    'Donor',
    'Event',
    'Audit Logs',
    'Inventory',
    'Vehicles',
    // Add other function names as needed
    'Audit Logs',
    'Dashboard',
    'Child Admission',
    'Donation Report',
    'Child Report',
    'Unit Sponsorship',
    'Centres',
    'Employees',
    'Tasks',
    'Ops Reports',
    'Counseling Report'
  ];
  permissionsMap: { [key: string]: UserRole[] } = {};

  permissionsData: UserRole[] = []
  data: UserRole[] = []
  roleSelected: any;
  roles$: Observable<any[]>;
  selectedRole: string | undefined;

  constructor(private firestore: AngularFirestore,
              private fb: FormBuilder,
              private dialog: MatDialog,) {
    this.roles$ = this.firestore.collection<any>('user_permissions').valueChanges({idField: 'id'})
    this.roles$.subscribe(res => {
      console.log(res)
      console.log(this.roles$)
    })
    // this.roles$ = this.mDatabase.list('types/Employee Roles').valueChanges()
  }

  ngOnInit(): void {

    //Setting permissions for each role manually
    //   this.getCounsellingRules()
    //    let title ='Counselling'
    //    this.setPermissions(this.data, title)

  }


  onRoleSelect(event: any) {
    this.featchPermmisions(event.value)
  }


  private featchPermmisions(value: string) {
    this.selectedRole = value
    this.firestore.collection<UserRole>(`/user_permissions/${value}/permissions`, ref => ref.orderBy('functionName', 'asc')).valueChanges({idField: 'id'}).subscribe(res => {
      this.permissionsData = res
      console.log(res)
    })
  }

  // selectionChangeRoleCheckBox(checked:any, id:any,type:string){
  //   // this.firestore.doc(`/user_permissions/${add}/permission/${id}`).set({[type]:add}, { merge: true })
  //   this.firestore.doc(`/user_permissions/${this.selectedRole}/permissions/${id}`).set({[type]:checked}, { merge: true })
  //     .then(() => {
  //       console.log('Permissions updated successfully!');
  //       console.log(`Path: /user_permissions/${this.selectedRole}/permissions/${id}`);
  //       console.log(`Type: ${type}, ${type}: ${checked}`);
  //     })
  //     .catch((error) => {
  //       console.error('Error updating permissions: ', error);
  //     });
  // }
  selectionChangeRoleCheckBox(checked: boolean, id: any, type: string) {
    const permission = this.permissionsData.find(p => p.id === id);
    if (!permission) return;

    if (type === 'all') {
      // If "All" is checked, set all other permissions to true
      permission.add = checked;
      permission.edit = checked;
      permission.archive = checked;
      permission.view = checked;
      permission.excel = checked;
      permission.showMenu = checked;
    } else {
      // If any individual checkbox is unchecked, uncheck "All"
      if (!checked) {
        permission.all = false;
      } else {
        // If all permissions are checked, "All" should be checked
        if (
          permission.add &&
          permission.edit &&
          permission.archive &&
          permission.view &&
          permission.excel &&
          permission.showMenu
        ) {
          permission.all = true;
        }
      }
    }

    // Update Firestore with all changes
    this.firestore.doc(`/user_permissions/${this.selectedRole}/permissions/${id}`)
      .set({...permission}, {merge: true})
      .then(() => console.log('Permissions updated successfully!'))
      .catch(error => console.error('Error updating permissions:', error));
  }

//ADDED
  getSeniorCounselling() {
    const newData = [{
      "functionName": "Child Admission",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Child Prescreening",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Counselling",
      "all": false,
      "add": true,
      "edit": true,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Education",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Meal Mate",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Unit Sponsorship",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "General Donation",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "CMS",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Child Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Counseling Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Education Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Donation Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Weekly Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Centres",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Employees",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Hospital",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Unit",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Types",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Donor",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Event",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Audit Logs",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Dashboard",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
    ]

    this.data = newData


  }

  getCMRules() {
    const newData = [
      {
        functionName: "Child Admission",
        all: false,
        add: true,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Child Prescreening",
        all: true,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counselling",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Meal Mate",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: true
      },
      {
        functionName: "General Donation",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "CMS",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Child Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counseling Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Donation Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Weekly Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Centres",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Employees",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Hospital",
        all: false,
        add: true,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: true
      },
      {
        functionName: "Unit",
        all: false,
        add: true,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Types",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Donor",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Event",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Audit Logs",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Dashboard",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
    ];
    this.data = newData


  }

//ADDED
  getCentreCoordinator() {
    const newData = [
      {
        functionName: "Child Admission",
        all: false,
        add: true,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Child Prescreening",
        all: true,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counselling",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Meal Mate",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: true
      },
      {
        functionName: "General Donation",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "CMS",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Child Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counseling Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Donation Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Weekly Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Centres",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Employees",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Hospital",
        all: false,
        add: true,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: true
      },
      {
        functionName: "Unit",
        all: false,
        add: true,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Types",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Donor",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Event",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Audit Logs",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Dashboard",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
    ];
    this.data = newData


  }

  getCounsellingRules() {
    const newData = [{
      "functionName": "Child Admission",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Child Prescreening",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Counselling",
      "all": false,
      "add": true,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Education",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Meal Mate",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Unit Sponsorship",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "General Donation",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "CMS",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Child Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Counseling Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Education Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Donation Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Weekly Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Centres",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Employees",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Hospital",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Unit",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Types",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Donor",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Event",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Audit Logs",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Dashboard",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },]

    this.data = newData


  }

  getTeacher() {
    const newData = [
      {
        functionName: "Child Admission",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Child Prescreening",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Counselling",
        all: false,
        add: true,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education",
        all: false,
        add: true,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Meal Mate",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "General Donation",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "CMS",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Child Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counseling Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Education Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Donation Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Weekly Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Centres",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Employees",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Hospital",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Types",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Donor",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Event",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Audit Logs",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Dashboard",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
    ];
    this.data = newData


  }

  getSeniorTeacher() {
    const newData = [{
      "functionName": "Child Admission",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Child Prescreening",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Counselling",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Education",
      "all": false,
      "add": true,
      "edit": true,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Meal Mate",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Unit Sponsorship",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "General Donation",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "CMS",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Child Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Counseling Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Education Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Donation Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Weekly Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Centres",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Employees",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Hospital",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Unit",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Types",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Donor",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Event",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Audit Logs",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Dashboard",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },]
    this.data = newData
  }

  getManagement() {
    const newData = [{
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Child Admission",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Child Prescreening",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Counselling",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Education",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Meal Mate",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Unit Sponsorship",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "General Donation",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "CMS",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Child Report",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Counseling Report",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Education Report",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Donation Report",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Weekly Report",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Centres",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Employees",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Hospital",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Unit",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Types",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Donor",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Event",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Audit Logs",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Dashboard",
      "showMenu": true,
      "view": true
    }, {
      functionName: "Inventory",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },]
    this.data = newData

  }

//ADDED
  getOperationsManager() {
    const newData = [
      {
        functionName: "Child Admission",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Child Prescreening",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counselling",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Meal Mate",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "General Donation",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "CMS",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Child Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counseling Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Donation Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Weekly Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Centres",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Employees",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Hospital",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Types",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Donor",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Event",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: true,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Audit Logs",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Dashboard",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
    ];
    this.data = newData

  }

  getGMOperations() {
    const newData = [
      {
        functionName: "Child Admission",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Child Prescreening",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counselling",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Meal Mate",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "General Donation",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "CMS",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Child Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counseling Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Donation Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Weekly Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Centres",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Employees",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Hospital",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Types",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Donor",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Event",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Audit Logs",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Dashboard",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
    ];

    this.data = newData

  }

  getProjectManager() {
    const newData = [
      {
        functionName: "Child Admission",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Child Prescreening",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counselling",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Meal Mate",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "General Donation",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "CMS",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Child Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counseling Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Donation Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Weekly Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Centres",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Employees",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Hospital",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Unit",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Types",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Donor",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Event",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Audit Logs",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Dashboard",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
    ];
    this.data = newData

  }

  getFinancialAccounts() {
    const newData = [
      {
        functionName: "Child Admission",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Child Prescreening",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Counselling",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Education",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Meal Mate",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "General Donation",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "CMS",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Child Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counseling Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Education Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Donation Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Weekly Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Centres",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Employees",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Hospital",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Types",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Donor",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Event",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Audit Logs",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Dashboard",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
    ];
    this.data = newData
  }

  getProgramManager() {
    const newData = [
      {
        "add": true,
        "all": false,
        "archive": false,
        "edit": true,
        "excel": true,
        "functionName": "Child Admission",
        "showMenu": true,
        "view": true
      },
      {
        "add": true,
        "all": false,
        "archive": false,
        "edit": true,
        "excel": true,
        "functionName": "Child Prescreening",
        "showMenu": true,
        "view": true
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": true,
        "functionName": "Counselling",
        "showMenu": true,
        "view": true
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": true,
        "functionName": "Education",
        "showMenu": true,
        "view": true
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": false,
        "functionName": "Meal Mate",
        "showMenu": false,
        "view": false
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": false,
        "functionName": "Unit Sponsorship",
        "showMenu": false,
        "view": false
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": false,
        "functionName": "General Donation",
        "showMenu": false,
        "view": false
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": false,
        "functionName": "CMS",
        "showMenu": false,
        "view": false
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": true,
        "functionName": "Child Report",
        "showMenu": true,
        "view": true
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": true,
        "functionName": "Counseling Report",
        "showMenu": true,
        "view": true
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": true,
        "functionName": "Education Report",
        "showMenu": true,
        "view": true
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": false,
        "functionName": "Donation Report",
        "showMenu": false,
        "view": false
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": true,
        "functionName": "Weekly Report",
        "showMenu": true,
        "view": true
      },
      {
        "add": true,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": false,
        "functionName": "Centres",
        "showMenu": false,
        "view": false
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": false,
        "functionName": "Employees",
        "showMenu": false,
        "view": false
      },
      {
        "add": true,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": true,
        "functionName": "Hospital",
        "showMenu": true,
        "view": true
      },
      {
        "add": true,
        "all": false,
        "archive": false,
        "edit": true,
        "excel": true,
        "functionName": "Unit",
        "showMenu": true,
        "view": true
      },
      {
        "add": true,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": true,
        "functionName": "Types",
        "showMenu": true,
        "view": true
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": false,
        "functionName": "Donor",
        "showMenu": false,
        "view": false
      },
      {
        "add": true,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": true,
        "functionName": "Event",
        "showMenu": true,
        "view": true
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": false,
        "functionName": "Audit Logs",
        "showMenu": false,
        "view": false
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": true,
        "functionName": "Dashboard",
        "showMenu": true,
        "view": true
      },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
    ]
    this.data = newData

  }

//ADDED
  getDonors() {
    const newData = [{
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Child Admission",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Child Prescreening",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Counselling",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Education",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Meal Mate",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Unit Sponsorship",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "General Donation",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "CMS",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Child Report",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Counseling Report",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Education Report",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Donation Report",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Weekly Report",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Centres",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Employees",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Hospital",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Unit",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Types",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Donor",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Event",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Audit Logs",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Dashboard",
      "showMenu": true,
      "view": true
    },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },]
    this.data = newData


  }

  private setPermissions(data: UserRole[], title: string) {
    this.firestore.collection('user-permissions').doc(title).set({permissions: data}, {merge: true}).then(() => {

    })
  }

  openDialog() {
    const dialogRef = this.dialog.open(AddRolePermissionsComponent, {
      width: '500px',
      height: '300px',
      data: {selectedRole: this.selectedRole}
    });
  }

  deletePermission(id: any) {
    if (!confirm('Are you sure you want to delete this permission?')) {
      return;
    }

    // Ensure ID is valid before proceeding
    if (!id) {
      console.error("Invalid ID: Cannot delete");
      return;
    }

    this.firestore
      .collection('user_permissions')
      .doc(this.selectedRole)
      .collection('permissions')
      .doc(id)
      .delete()
      .then(() => {
        console.log('Permission deleted successfully');
        this.permissionsData = this.permissionsData.filter(d => d.id !== id);
      })
      .catch(error => {
        console.error('Error deleting permission:', error);
      });
  }

}
