import {Component, isDevMode, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {FullChild} from "../../../interfaces/FullChild";
import {FormControl} from "@angular/forms";
import {ChildService} from "../../child/child.service";
import {Subject} from "rxjs/internal/Subject";
import {takeUntil} from "rxjs/internal/operators/takeUntil";
import firebase from 'firebase/compat';
import {MatSelectChange} from "@angular/material/select";
import {CentreService} from "../../../services/centre.service";
import {Observable} from "rxjs";
import {Centre} from "../../../interfaces/Centres";
import {environment} from "../../../../environments/environment";

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}


export interface FinancialYearReport {
  centreName: string,
  discharge: number,
  new_admission: number,
  follow_up_admission: number,
  follow_up_discharge: number,
}

@Component({
  selector: 'app-financial-report-table',
  templateUrl: './financial-report-table.component.html',
  styleUrls: ['./financial-report-table.component.scss']
})
export class FinancialReportTableComponent implements OnInit, OnDestroy {
  financialData: any[] = []
  mYear = 2023
  destroyed$ = new Subject()
  heading = ''
  sortBy: "byDate" | 'byYear' | 'byCentre' = 'byYear'
  startDate: Date = new Date('2023-04-01')
  endDate: Date = new Date('2023-06-30')
  showInactiveCentres = false
  startRange = new Date('2024-04-01')

  selectCentre = new FormControl('All')
  sortBySelect = new FormControl(this.sortBy)
  centres$: Observable<Centre[]>
  selectedCentre!: Centre
  child: FullChild[] = []
  dataSource = new MatTableDataSource(this.financialData);
  select = new FormControl('Please Select')
  admissionsCountChildarray: FullChild[] = []
  dischargeCountChildArray: FullChild[] = []
  followUpCountChildArray: FullChild[] = []
  followUpOutCountChildArray: FullChild[] = []
  showLog = false


  constructor(private readonly mFirestore: AngularFirestore,
              private readonly mCentreService: CentreService,
              private readonly mChildServie: ChildService) {
    this.centres$ = this.mCentreService.getAllCentres()
    setTimeToMidnight(this.startDate)
    setTimeToMidnight(this.endDate)
  }

  ngOnInit(): void {
    // this.getData()
  }

  calculateData(child: FullChild[], centreName: string, centreCode: string, startDate: Date, endDate: Date) {
    console.log('FINANCIAL DATA', child.length)

    let admission = child.filter((c) => c.centreCode == centreCode && c.dateOfAdmission.toDate() > startDate && c.dateOfAdmission.toDate() < endDate)
    let discharge = child.filter((c) => c.centreCode == centreCode && c.dateOfAdmission.toDate() > startDate && c.dateOfAdmission.toDate() < endDate && c.admissionData.length == 2)
    let follow_up_admission = child.filter((c) => c.centreCode == centreCode && c.dateOfAdmission.toDate() > startDate && c.dateOfAdmission.toDate() < endDate && c.admissionData.length >= 3 && c.admissionData.length % 2 !== 0)
    let follow_up_discharge = child.filter((c) => c.centreCode == centreCode && c.dateOfAdmission.toDate() > startDate && c.dateOfAdmission.toDate() < endDate && c.admissionData.length >= 3 && c.admissionData.length % 2 === 0)

    console.log('Admission', admission.length)
    console.log('Discharge', discharge.length)
    console.log('follow_up_admission', follow_up_admission.length)
    console.log('follow_up_discharge', follow_up_discharge.length)
    console.log(admission)
    let pune: FinancialYearReport = {
      centreName: centreName,
      new_admission: admission.length,
      discharge: discharge.length,
      follow_up_admission: follow_up_admission.length,
      follow_up_discharge: follow_up_discharge.length,
    }
    this.financialData.push(pune)
  }

  calculateData2(child: FullChild[], centreName: string, centreCode: string, startDate: Date, endDate: Date) {
    let admission: FullChild[] = []
    let discharge: FullChild[] = []
    let firstAdmission = 0
    let firstDischarge = 0
    let admissionCount = 0
    let dischargeCount = 0
    let admittedChildTable: { centreName: string; childCode: string; doa: Date }[] = []
    child.forEach(c => {
      try {
        c.admissionData.length!
      } catch (e) {
        console.log(e, c.childCode)
      }
    })
    // console.log('FINANCIAL DATA', child.length)
    try {
      admission = child.filter((c) => c.centreCode == centreCode && c.dateOfAdmission.toDate() >= startDate && c.dateOfAdmission.toDate() <= endDate)

    } catch (reason) {
      console.log('====ERROR======', child)
    }
    let admissionDates = admission.map(m => m.childCode)
    // let discharge = child.filter((c) => c.centreCode == centreCode && c.dateOfAdmission.toDate() > startDate && c.dateOfAdmission.toDate() < endDate && c.admissionData.length >= 2)
    // discharge = child.filter((c) => c.centreCode == centreCode && c.admissionData.length >= 2 && c.admissionData[1].dateOfDischarge!.toDate() >= startDate && c.admissionData[1].dateOfDischarge!.toDate() <= endDate)
    // let follow_up_admission = child.filter((c) => c.centreCode == centreCode && c.dateOfAdmission.toDate() > startDate && c.dateOfAdmission.toDate() < endDate && c.admissionData.length >= 3 && c.admissionData.length % 2 !== 0)
    try {
      discharge = child.filter((c) => c.centreCode == centreCode && c.admissionData.length >= 2 && c.admissionData[1].dateOfDischarge!.toDate() >= startDate && c.admissionData[1].dateOfDischarge!.toDate() <= endDate)


    } catch (reason) {
      console.log('====ERROR======', child)
    }

    let follow_up_admission = child.filter((c) => c.centreCode == centreCode)


    follow_up_admission.forEach(c => {
      try {


        c.admissionData.forEach((ad, index) => {
          if (index == 0) {
            if (c.dateOfAdmission.toDate() >= startDate && c.dateOfAdmission.toDate() <= endDate) {
              firstAdmission++
              this.admissionsCountChildarray.push(c)
            }


            // if(  c.dateOfAdmission.toDate() >= startDate && c.dateOfAdmission.toDate() <= endDate)         admittedChildTable.push({centreName:centreName,childCode: c.childCode,doa: c.dateOfAdmission.toDate()})


          }
          if (index == 1) {
            // @ts-ignore
            if (ad.dateOfDischarge.toDate() >= startDate && ad.dateOfDischarge.toDate() <= endDate) {
              firstDischarge++
              this.dischargeCountChildArray.push(c)
            }

            // @ts-ignore
            // * To show Discharge Count Table
            // if (ad.dateOfDischarge?.toDate() >= startDate && ad.dateOfDischarge.toDate() <= endDate) admittedChildTable.push({
            //     centreName: centreName,
            //     childCode: c.childCode,
            //     doa: ad.dateOfDischarge!.toDate()
            // })

          }
          try {


            if (index >= 2 && index % 2 == 0) {
              // console.log(ad.dateOfAdmission?.toDate())
              let d = ad.dateOfAdmission?.toDate()!
              if (d > startDate && d < endDate) {
                admissionCount++
                this.followUpCountChildArray.push(c)
              }
            }
          } catch (e: any) {
            alert(c.childCode + " " + e)
          }
          if (index > 2 && index % 2 != 0) {
            let d = ad.dateOfDischarge?.toDate()!
            if (d > startDate && d < endDate) {
              dischargeCount++
              this.followUpOutCountChildArray.push(c)
            }
            // console.log(d)

          }
        })
      } catch (e: any) {
        alert(c.childCode)
      }
    })

    //  console.assessment-table(admittedChildTable)
    // alert(`START DATE = ${startDate} END DATE: ${endDate} Admission ${admission.length} Discharge: ${discharge.length} Follow Up Admission ${admissionCount} Followup Discharge ${dischargeCount}`)

    // let follow_up_discharge = child.filter((c) => c.centreCode == centreCode && c.dateOfAdmission.toDate() > startDate && c.dateOfAdmission.toDate() < endDate && c.admissionData.length >= 3 && c.admissionData.length % 2 === 0)
    // console.log('Admission', admission.length)
    // console.log('Admission', admissionDates)
    // console.log('Discharge', discharge.length)
    // console.log('follow_up_admission', follow_up_admission.length)
    // console.log('follow_up_discharge', follow_up_discharge.length)
    // console.log(admission)
    let pune: FinancialYearReport = {
      centreName: centreName,
      new_admission: firstAdmission,
      discharge: firstDischarge,
      follow_up_admission: admissionCount,
      follow_up_discharge: dischargeCount
    }

    this.financialData.push(pune)
  }

  displayedColumns: string[] = ['centreName', 'new_admission', 'discharge', 'follow_up_admission', 'follow_up_discharge'];


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  selectYear($event: any) {
    if ($event.value == '2024') {
      this.mYear = 2024
      this.startDate = new Date('2024-04-01')
      this.endDate = new Date('2025-03-31')
      setTimeToMidnight(this.startDate)
      setTimeToMidnight(this.endDate)

      // alert(2020)
      this.heading = 'April 2024 - March 2025'
      if (this.child.length == 0) {
        this.getData()

      } else {
        this.calculateCentreData(2024)
      }

    } else if ($event.value == '2023') {
      this.mYear = 2023
      this.startDate = new Date('2023-04-01')
      this.endDate = new Date('2024-03-31')
      setTimeToMidnight(this.startDate)
      setTimeToMidnight(this.endDate)

      // alert(2020)
      this.heading = 'April 2023 - March 2024'
      if (this.child.length == 0) {
        this.getData()

      } else {
        this.calculateCentreData(2023)
      }

    } else if ($event.value == '2021') {
      this.mYear = 2021

      this.startDate = new Date('2021-04-01')
      this.endDate = new Date('2022-03-31')
      setTimeToMidnight(this.startDate)
      setTimeToMidnight(this.endDate)

      if (this.child.length == 0) {
        this.getData()
      } else this.calculateCentreData(2021)
      // alert(2021)
      this.heading = 'April 2021 - March 2022'
    } else if ($event.value == '2020') {
      this.mYear = 2020

      this.startDate = new Date('2020-04-01')
      this.endDate = new Date('2021-03-31')
      setTimeToMidnight(this.startDate)
      setTimeToMidnight(this.endDate)

      // alert(2020)
      this.heading = 'April 2020 - March 2021'
      if (this.child.length == 0) {
        this.getData()
      } else this.calculateCentreData(2020)
    } else if ($event.value == '2022') {
      this.mYear = 2022
      // alert(2022)
      this.heading = 'April 2022 - March 2023'
      this.startDate = new Date('2022-04-01')
      this.endDate = new Date('2023-03-31')
      setTimeToMidnight(this.startDate)
      setTimeToMidnight(this.endDate)

      if (this.child.length == 0) {
        this.getData()
      } else this.calculateCentreData(2022)
    } else if ($event.value == '2019') {
      this.mYear = 2019

      this.startDate = new Date('2019-04-01')
      this.endDate = new Date('2020-03-31')
      setTimeToMidnight(this.startDate)
      setTimeToMidnight(this.endDate)

      // alert(2020)
      this.heading = 'April 2019 - March 2020'
      if (this.child.length == 0) {
        this.getData()
      } else this.calculateCentreData(2019)
    } else if ($event.value == '2018') {
      this.mYear = 2018

      this.startDate = new Date('2018-04-01')
      this.endDate = new Date('2019-03-31')
      setTimeToMidnight(this.startDate)
      setTimeToMidnight(this.endDate)

      // alert(2020)
      this.heading = 'April 2018 - March 2019'
      if (this.child.length == 0) {
        this.getData()
      } else this.calculateCentreData(2018)
    } else if ($event.value == '2017') {
      this.mYear = 2017

      this.startDate = new Date('2017-04-01')
      this.endDate = new Date('2018-03-31')
      setTimeToMidnight(this.startDate)
      setTimeToMidnight(this.endDate)

      // alert(2020)
      this.heading = 'April 2017 - March 2018'
      if (this.child.length == 0) {
        this.getData()
      } else this.calculateCentreData(2017)
    } else if ($event.value == '2016') {
      this.mYear = 2016

      this.startDate = new Date('2016-04-01')
      this.endDate = new Date('2017-03-31')
      setTimeToMidnight(this.startDate)
      setTimeToMidnight(this.endDate)

      // alert(2020)
      this.heading = 'April 2016 - March 2017'
      if (this.child.length == 0) {
        this.getData()
      } else this.calculateCentreData(2016)
    } else if ($event.value == '2015') {
      this.mYear = 2015

      this.startDate = new Date('2015-04-01')
      this.endDate = new Date('2016-03-31')
      setTimeToMidnight(this.startDate)
      setTimeToMidnight(this.endDate)

      // alert(2020)
      this.heading = 'April 2015 - March 2016'
      if (this.child.length == 0) {
        this.getData()
      } else this.calculateCentreData(2015)
    } else if ($event.value == '2014') {
      this.mYear = 2014

      this.startDate = new Date('2014-06-01')
      this.endDate = new Date('2015-03-31')
      setTimeToMidnight(this.startDate)
      setTimeToMidnight(this.endDate)

      // alert(2020)
      this.heading = 'April 2014 - March 2015'
      if (this.child.length == 0) {
        this.getData()
      } else this.calculateCentreData(2014)
    } else if ($event.value == '2015') {
      this.mYear = 2015

      this.startDate = new Date('2023-04-01')
      this.endDate = new Date('2024-03-31')
      setTimeToMidnight(this.startDate)
      setTimeToMidnight(this.endDate)

      // alert(2020)
      this.heading = `From ${this.startDate.toDateString()} To ${this.endDate.toDateString()} `
      // this.getData()

    } else if ($event.value == 'Please Select') {
      // this.startDate = new Date('2020-04-23')
      // this.endDate = new Date('2021-03-24')
      // alert(2020)
      this.heading = `From ${this.startDate.toDateString()} To ${this.endDate.toDateString()} `
      // this.getData()
    } else {
      this.heading = ''
    }
  }

  getData() {

//* GETTING ALL ALCHILD DATA
    this.mChildServie.getLatestData().pipe(takeUntil(this.destroyed$))
      .subscribe(c => {
        this.child = c.docs.map(c => c.data())
        this.calculateCentreData(this.mYear)
      })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null)
    this.destroyed$.complete()
  }

  showSelections($event: MatSelectChange) {
    //    console.log('SORT BY', $event.value)
    this.sortBy = $event.value

  }

  searchByDate() {
    if (this.child.length == 0) {
      this.getData()
    } else {

      this.financialData = []
      this.calculateData2(this.child, 'AL Gurunanak Niwas', 'ALGurunanak', this.startDate, this.endDate);
      this.calculateData2.call(this, this.child, 'AL Diamond Garden', 'ALDiamondGarden', this.startDate, this.endDate);
      this.calculateData2.call(this, this.child, 'AL Bandra', 'ALBandra', this.startDate, this.endDate);
      this.calculateData2.call(this, this.child, 'AL Borivali', 'ALBorivali', this.startDate, this.endDate);
      this.calculateData2.call(this, this.child, 'AL Pune', 'ALPune', this.startDate, this.endDate);
      this.calculateData2.call(this, this.child, 'AL Manipal', 'ALManipal', this.startDate, this.endDate);
      this.calculateData2(this.child, 'AL Chandigarh', 'ALChandigarh', this.startDate, this.endDate);
      this.calculateData2(this.child, 'AL Coimbatore', 'ALCoimbatore', this.startDate, this.endDate);
      this.calculateData2(this.child, 'AL Ahemdabad', 'ALAhmedabad', this.startDate, this.endDate);
      this.calculateData2(this.child, 'AL SanduGarden', 'ALSanduGarden', this.startDate, this.endDate);
      this.calculateData2(this.child, 'AL Bangalore', 'ALBangalore', this.startDate, this.endDate);
      if (this.startDate < new Date('2021-12-01') || this.showInactiveCentres) this.calculateData2(this.child, 'AL Ghatla', 'ALGhatla', this.startDate, this.endDate);
      if (this.startDate < new Date('2021-03-01') || this.showInactiveCentres) this.calculateData2(this.child, 'AL Thane', 'ALThane', this.startDate, this.endDate);
      if (this.startDate < new Date('2021-03-01') || this.showInactiveCentres) this.calculateData2(this.child, 'AL Andheri', 'ALAndheri', this.startDate, this.endDate);
      this.getTotalDataThenSetDataSource()


    }

  }

  calculateCentreData(year: number) {
    // alert(year)
    // this.startDate = new Date('2014-06-01')
    // this.endDate = new Date('2015-03-31')
    // setTimeToMidnight(this.startDate)
    // setTimeToMidnight(this.endDate)

    this.financialData = []
    if ((!(this.startDate < new Date('2023-06-10')) && (this.endDate < new Date('2024-04-31'))) || year >= 2023) this.calculateData2(this.child, 'AL Bangalore', 'ALBangalore', this.startDate, this.endDate);
    if ((!(this.startDate < new Date('2023-06-10')) && (this.endDate < new Date('2024-04-31'))) || year >= 2023) this.calculateData2(this.child, 'AL Ahemdabad', 'ALAhmedabad', this.startDate, this.endDate);
    if ((!(this.startDate < new Date('2023-06-10')) && (this.endDate < new Date('2024-04-31'))) || year >= 2023) this.calculateData2(this.child, 'AL Sandu Garden', 'ALSanduGarden', this.startDate, this.endDate);

    this.calculateData2(this.child, 'AL Gurunanak Niwas', 'ALGurunanak', this.startDate, this.endDate);

    this.calculateData2.call(this, this.child, 'AL Diamond Garden', 'ALDiamondGarden', this.startDate, this.endDate);
    this.calculateData2.call(this, this.child, 'AL Bandra', 'ALBandra', this.startDate, this.endDate);
    this.calculateData2.call(this, this.child, 'AL Borivali', 'ALBorivali', this.startDate, this.endDate);
    this.calculateData2.call(this, this.child, 'AL Pune', 'ALPune', this.startDate, this.endDate);
    this.calculateData2.call(this, this.child, 'AL Manipal', 'ALManipal', this.startDate, this.endDate);
    this.calculateData2(this.child, 'AL Chandigarh', 'ALChandigarh', this.startDate, this.endDate);
    //
    this.calculateData2(this.child, 'AL Coimbatore', 'ALCoimbatore', this.startDate, this.endDate);

    if (this.startDate < new Date('2021-12-01') || this.showInactiveCentres) this.calculateData2(this.child, 'AL Ghatla', 'ALGhatla', this.startDate, this.endDate);
    if (this.startDate < new Date('2021-03-01') || this.showInactiveCentres) this.calculateData2(this.child, 'AL Thane', 'ALThane', this.startDate, this.endDate);
    if (this.startDate < new Date('2021-03-01') || this.showInactiveCentres) this.calculateData2(this.child, 'AL Andheri', 'ALAndheri', this.startDate, this.endDate);

    this.getTotalDataThenSetDataSource()
  }

  private getTotalDataThenSetDataSource() {
    let totalAdmissions = 0
    let totalDischarge = 0
    let totalIn = 0
    let totalOut = 0
    this.financialData.forEach(data => {
      totalAdmissions = totalAdmissions += data.new_admission
      totalDischarge = totalDischarge += data.discharge
      totalIn = totalIn += data.follow_up_admission
      totalOut = totalOut += data.follow_up_discharge
    })
    let total: FinancialYearReport = {
      centreName: 'Total',
      new_admission: totalAdmissions,
      discharge: totalDischarge,
      follow_up_admission: totalIn,
      follow_up_discharge: totalOut,
    }
    this.financialData.push(total)
    // this.calculateData2(child, 'AL Thane', 'ALThane', this.startDate, this.endDate);
    // this.calculateData2(child, 'AL Ghatla', 'ALGhatla', this.startDate, this.endDate);
    // alert(this.financialData.length)
    this.financialData.sort((a, b) => a.centreName.localeCompare(b.centreName));

    this.dataSource = new MatTableDataSource(this.financialData);

  }

}

function setTimeToMidnight(date: Date): Date {
  date.setHours(0);     // Set hours to 0 (midnight)
  date.setMinutes(0);   // Set minutes to 0
  date.setSeconds(0);   // Set seconds to 0
  date.setMilliseconds(0);  // Set milliseconds to 0
  return date;
}
