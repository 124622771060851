import {Injectable, isDevMode} from "@angular/core";
import {AngularFireDatabase, AngularFireObject} from "@angular/fire/compat/database";
import {Hospitals} from "../interfaces/Hospitals";
import {environment} from "../../environments/environment";
import {filter, Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})

export class HospitalService {
    env = isDevMode() ? environment.testMode : environment.productionMode

    constructor(private mDatabase: AngularFireDatabase,
                private mFirestore: AngularFireDatabase) {

    }

    addHospital(hospital: any) {
        // const ref  = this.mDatabase.list('dummy/hospitals')
      console.log(hospital)
        return this.mDatabase.list(this.env.hospitals).push(hospital)
    }

    updatehospital(key: String, hospital: Hospitals) {

        return this.mDatabase.object(`${this.env.hospitals}/${key}`).update(hospital)
    }
  getHospitalById(key: string): Observable<Hospitals | null> {
    const hospitalRef: AngularFireObject<Hospitals> = this.mDatabase.object(`${this.env.hospitals}/${key}`);
    return hospitalRef.snapshotChanges().pipe(
      map(snapshot => {
        if (snapshot.payload.exists()) {
          const data = snapshot.payload.val();
          return { key: snapshot.payload.key, ...data } as Hospitals;
        } else {
          return null;
        }
      })
    );
  }

    getAllHosptitals() {
        return this.mDatabase.list(this.env.hospitals).snapshotChanges()
    }

    getHospitalList(): Observable<Hospitals[]> {
        const centreRef = this.mDatabase.list<Hospitals>(this.env.hospitals);
        return centreRef.valueChanges().pipe(
            map(hospitals => hospitals.sort((a, b) => a.hospitalName.localeCompare(b.hospitalName)))
        );
    }

    getHospitalListByCentre(centreCodes: string[]): Observable<Hospitals[]> {
        // Use a Set to store unique city names for efficiency
        const uniqueCities = new Set<string>();

        // Loop through centre codes and add corresponding cities to the set
        for (const centreCode of centreCodes) {
            if (centreCode === 'ALBandra' || centreCode === 'ALGurunanak' ||
                centreCode === 'ALSanduGarden' || centreCode === 'ALDiamondGarden') {
                uniqueCities.add('Mumbai');
            } else if (centreCode === 'ALBangalore') {
                uniqueCities.add('Bengaluru');
            } else if (centreCode === 'ALChandigarh') {
                uniqueCities.add('Chandigarh');
            } else if (centreCode === 'ALPune') {
                uniqueCities.add('Pune');
            } else if (centreCode === 'ALManipal') {
                uniqueCities.add('Manipal');
            }
            else if (centreCode === 'ALAhmedabad') {
                uniqueCities.add('Ahmedabad');
            }
            else {
                console.warn(`City not found for centre code: ${centreCode}`);
            }
        }

        const centreRef = this.mDatabase.list<Hospitals>(this.env.hospitals);
        return centreRef.valueChanges().pipe(
            map(hospitals => {
                if (uniqueCities.size > 0) {
                    // Filter hospitals where city matches any element in the set
                    return hospitals.filter(h => uniqueCities.has(h.city))
                        .sort((a, b) => a.hospitalName.localeCompare(b.hospitalName));
                } else {
                    // No cities provided, return all hospitals sorted (default behavior)
                    return hospitals.sort((a, b) => a.hospitalName.localeCompare(b.hospitalName));
                }
            })
        );
    }

    deleteHospital(id: string) {
        return this.mDatabase.object(this.env.hospitals + '/' + id).remove()
    }
}
