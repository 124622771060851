import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from "@angular/material/table";
import {Centre} from "../../../interfaces/Centres";
import {Observable, of, Subject, takeUntil} from "rxjs";
import {FullChild} from "../../../interfaces/FullChild";
import {MatPaginator} from "@angular/material/paginator";
import {FormControl} from "@angular/forms";
import {Hospitals} from "../../../interfaces/Hospitals";
import {MatDialog} from '@angular/material/dialog';
import {CentreService} from "../../../services/centre.service";
import {ChildService} from "../../child/child.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CounselingService} from "../../../modules/counseling/counseling.service";
import {EducationService} from "../../../modules/education/education.service";
import {HospitalService} from "../../../services/hospital.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {MatSelectChange} from "@angular/material/select";
import {RankingData} from "../../../modules/reports/occupancy/occupancy.component";

@Component({
  selector: 'app-mis-borivali',
  templateUrl: './mis-borivali.component.html',
  styleUrls: ['./mis-borivali.component.scss']
})
export class MisBorivaliComponent implements OnInit, OnDestroy, AfterViewInit {
  centreSelectionArray = [

    {
      centreName: 'AL Borivali',
      centreCode: 'ALBorivali'
    },

  ]

  @ViewChild(MatSort) sort!: MatSort;
  mStartDate?: Date | null
  mEndDate?: Date | null
  show = false  //For refetching tables and graphs
  dataSource: MatTableDataSource<Centre>;
  startRange = new Date('2024-04-01')
  dataTableLoaded = false;
  dataHospitalLoaded = false;
  showRecentDischarge = false;
  userData: any
  private _destroyed$ = new Subject()
  mCentres: Centre[] = []
  allCentres: any[] = [{centreName:'AL Borivalie',centreCode:'ALBorivali',rankings:0}];
  extraRowAdded = false
  mChildren: FullChild[] = []
  mAllChildren: FullChild[] = []
  mAdmissionChartCentres: string[] = []
  mAdmissionChartUniqueCount: number[] = []
  mAdmissionChartFollowUpCount: number[] = []
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) matSort!: MatSort;
  displayedColumns: String[] = ['sr', 'centername', 'totalUnits', 'occupiedUnits', 'availableUnits', 'centrePercentage'];
  //*********** For Occupancy card******************
  totalUnits = 0
  totalOccupiedUnits = 0
  totalAvailableUnits = 0
  centrePercentage = 0
  totalRanking = 0
//***********************
//************CHILD DATA************
  totalRegisteredChildren = 0
  totalRegisteredGirls = 0
  totalRegisteredBoys = 0
  freshBoys = 0
  freshGirls = 0
  followupBoys = 0
  followupGirls = 0
//*********************************
  ageArray: number[] = []
  mFollowUpAgeArray: number[] = []
  a = 0
  af = 0
  b = 0
  bf = 0
  c1 = 0
  cf = 0
  d = 0
  df = 0
  e = 0
  ef = 0
  HospitalLables: any[] = []
  HospitalCount: any[] = []
  mFollowUpHospitalCount: any[] = []
  hosptalLables$ = of(this.HospitalLables)
  hospitalCount$ = of(this.HospitalCount)
  mFollowUpHospitalCount$ = of(this.mFollowUpHospitalCount)
  hData: any[] = []
  sortBy: "byDate" | 'byYear' | 'byCentre' = 'byCentre'
  startDate: Date = new Date('2023-04-01')
  endDate: Date = new Date('2023-06-30')
  selectCentre = new FormControl('ALBorivali')

  sortBySelect = new FormControl(this.sortBy)
  loading = false
  currentCentreName = 'AL Borivali'
  selectedCentreOption: any;
  uniqueChildrenCount: any;
  followUpChildrenCount: any;
  totalAdmissionCount = 0;
  followUpAdmissionsCount = 0
  uniqueAdmissionsCount = 0;
  showAdmissionChart = false;
  hospitals$: Observable<Hospitals[]>
  mHospitals: Hospitals[] = []
  mTotalEducationIndividual = 0
  mTotalEducationGroup = 0
  mTotalCounsellingIndividual = 0
  mTotalCounsellingAssestment = 0
  mTotalCounsellingFAssestment = 0
  mTotalCounsellingGroup = 0

  constructor(private matdialog: MatDialog,
              private centreService: CentreService,
              private childService: ChildService,
              private mDatabase: AngularFireDatabase,
              private _snackBar: MatSnackBar,
              private mCounsellingService: CounselingService,
              private mEducationService: EducationService,
              private mHospitalService: HospitalService,
              private readonly mFirestore: AngularFirestore) {

    this.dataSource = new MatTableDataSource(this.allCentres);
    this.dataSource.sort = this.sort
    this.userData = JSON.parse(localStorage.getItem('userData')!)
    this.hospitals$ = this.mHospitalService.getHospitalList()
    this.hospitals$.subscribe(res => {
      this.mHospitals = res
      // console.log(this.mHospitals)
    })

  }

  ngOnInit(): void {

    //* TO GET ALL CENTRES DATA
    this.initAllCentresData('ALBorivali')
    // this.getAllCentresData('All');

    this.initChildData()
  }

  ngAfterViewInit() {
    //  this.matSort.sortChange.pipe(takeUntil(this._destroyed$)).subscribe(() => (this.paginator.pageIndex = 0));
    //Read
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort
  }

//* GET ALL CENTRES DATA


  //* On ngInit to get all centres data
  lastRow?: Centre;


  private async initAllCentresData(centreCode: string) {
    // console.log('getAllCentre Called with:', centreCode);


      // At startup when centreCode == "All"
      this.centreService.getAllCentresBorivali().pipe(takeUntil(this._destroyed$))
        .subscribe(centres => {
          this.allCentres = centres;
          this.mCentres = []
          // console.log('CENTRE LIST TABLE', centres);
          this.allCentres = centres.filter(x=>x.centreCode !='ALBandra')
          this.totalUnits = 0;
          this.totalAvailableUnits = 0;
          this.totalOccupiedUnits = 0;
          this.centrePercentage = 0
          this.allCentres.forEach(c => {
            this.totalUnits += +c.totalUnits!;
            this.totalAvailableUnits += +c.availableUnits!;
            this.totalOccupiedUnits += +c.occupiedUnits!;
            c.centrePercentage = parseFloat(this.getPercentage(c))

            // console.log(parseFloat(this.getPercentage(c)))
          });

          let totalName = 'Total';
          // console.log(this.allCentres)
          //@ts-ignore
          console.log(this.allCentres)
          this.dataSource =  new MatTableDataSource(this.allCentres);
          this.dataTableLoaded = true;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.addextraRowtoOccupancyTable(this.totalUnits, this.totalAvailableUnits, this.totalOccupiedUnits, totalName);

        });

      console.log('Into getAllCentresData with centreCode ==', centreCode);
      this.mCentres = this.allCentres.filter(c => c.centreCode === centreCode);
      console.log('CENTRE LIST TABLE', this.mCentres);

      this.totalUnits = 0;
      this.totalAvailableUnits = 0;
      this.totalOccupiedUnits = 0;
      this.centrePercentage = 0
      this.totalRanking = 0
      this.mCentres.forEach(c => {
        this.totalUnits += +c.totalUnits!;
        this.totalAvailableUnits += +c.availableUnits!;
        this.totalOccupiedUnits += +c.occupiedUnits!;
        this.centrePercentage = parseFloat(this.getPercentage(c))
// this.totalRanking   =this.getTotalRanking(c.centreCode)
      });
      let totalName = 'Total';
      this.addextraRowtoOccupancyTable(this.totalUnits, this.totalAvailableUnits, this.totalOccupiedUnits, totalName);
      this.dataSource = new MatTableDataSource(this.mCentres);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort

    }





  private fetchAllCentresData() {
    this.centreService.getAllCentres().pipe(takeUntil(this._destroyed$))
      .subscribe(centres => {
        this.allCentres = centres;
        this.mCentres = centres
        this.updateCentreData(this.allCentres);
        this.prepareDataSource(this.allCentres);
      });
  }


  private updateCentreData(centres: Centre[]) {
    this.totalUnits = centres.reduce((total, c) => total + (+c.totalUnits! || 0), 0);
    this.totalAvailableUnits = centres.reduce((total, c) => total + (+c.availableUnits! || 0), 0);
    this.totalOccupiedUnits = centres.reduce((total, c) => total + (+c.occupiedUnits! || 0), 0);
  }

  private prepareDataSource(centres: Centre[]) {
    let totalName = 'Total';
    if (centres.length > 0) {
      this.dataSource = new MatTableDataSource(centres);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort
      // console.log('')
      this.addextraRowtoOccupancyTable(this.totalUnits, this.totalAvailableUnits, this.totalOccupiedUnits, totalName);

    }
  }

  //* ON DATA INIT
  private initChildData() {
    this.totalAdmissionCount = 0
    this.totalRegisteredBoys = 0;
    this.totalRegisteredGirls = 0;
    this.totalRegisteredBoys = 0;
    this.totalRegisteredGirls = 0;
    this.followUpChildrenCount = 0;
    this.uniqueAdmissionsCount = 0;
    this.followUpAdmissionsCount = 0;
    this.totalAdmissionCount = 0;
    this.childService.getBorivaliData()
      .pipe(takeUntil(this._destroyed$))
      .subscribe(children => {
        this.totalRegisteredChildren = children.docs.length;
        this.mAllChildren = children.docs.map(c => c.data());
        this.showRecentDischarge = true
        this.mChildren = [...this.mAllChildren]; // Clone the array
        let filteredChildData = this.mAllChildren
        this.totalRegisteredChildren = this.mAllChildren.length;
        const followUpChildrens = filteredChildData.filter(child => child.admissionData.length > 2);
        this.totalRegisteredBoys = filteredChildData.filter(child => child.gender === 'Male').length;
        this.totalRegisteredGirls = filteredChildData.filter(child => child.gender === 'Female').length;
        this.uniqueChildrenCount = filteredChildData.filter(child => child.admissionData.length <= 2).length;
        this.followUpChildrenCount = filteredChildData.filter(child => child.admissionData.length > 2);
        this.freshBoys = filteredChildData.filter(child => child.gender === 'Male').length;
        this.freshGirls = filteredChildData.filter(child => child.gender === 'Female').length;
        this.followupBoys = followUpChildrens.filter(child => child.gender === 'Male' && child.admissionData.length >= 2).length;
        this.followupGirls = followUpChildrens.filter(child => child.gender === 'Female' && child.admissionData.length >= 2).length;
        let totalAdmissionsCount = 0;
        let followUpAdmissionsCount = 0;


        this.mChildren.forEach(it => {
            it.admissionData.forEach((y, i) => {
              if (i >= 2 && i % 2 == 0) {
                this.logInvalidCentre(it)
                this.followUpAdmissionsCount++;
                it.gender == 'Male' ? this.followupBoys++ : this.followupGirls++
                if (it.gender != "Male" && it.gender != 'Female') console.log('MISSING GENDER', it.childCode)
                // repeatedFollowUpsChlidren.push(it)

              }
            });

          }
        );


        filteredChildData.forEach(child => {
          totalAdmissionsCount += child.admissionData.length;
          followUpAdmissionsCount += (Math.max(0, child.admissionData.length - 2) / 2);
        });
        this.followUpAdmissionsCount = Math.floor(followUpAdmissionsCount);
        this.uniqueAdmissionsCount = this.freshBoys + this.freshGirls
        this.totalAdmissionCount = this.followUpAdmissionsCount + this.uniqueAdmissionsCount;
        this.fetchHospitalData(this.mChildren); // Data for hospital widget
        this.fetchAgeTableData(this.mChildren, followUpChildrens); // Data for age table widget
        this.fetchAdmissionChartData(this.mChildren); // Data for admission chart
      });
    this.getTotalEducationAndCounsellingSessions()
  }

  getTotalEducationAndCounsellingSessions() {
    // Regular counseling sessions from 2024
    this.mCounsellingService.fetchIndividualSessionsRegularFrom2024Borivali(this.startRange).subscribe(res => {
      this.mTotalCounsellingIndividual = res.length
    })
    this.mCounsellingService.fetchGroupSessionsfrom2024Borivali(this.startRange).subscribe(res => {
      console.log(this.startRange)
      this.mTotalCounsellingGroup = res.length
      console.log(this.mTotalCounsellingGroup)

    })
    this.mCounsellingService.fetchAssessmentsFrom2024Borivali(this.startRange).subscribe(res => {
      this.mTotalCounsellingAssestment = res.length
    })

    this.mCounsellingService.fetchfollowupAssessmentsFrom2024Borivali(this.startRange).subscribe(res => {
      this.mTotalCounsellingFAssestment = res.length
    })


    this.mEducationService.getIndividualEducationListPresentOnly2024Borivali(this.startRange).subscribe(res => {
      this.mTotalEducationIndividual = res.length
    })
    this.mEducationService.getGroupEducationSessions2024Borivali(this.startRange).subscribe(res => {
      this.mTotalEducationGroup = res.length
    })
  }

  onCentreSelection($event: MatSelectChange) {

    this.currentCentreName = this.allCentres.find(res => res.centreCode === $event.value)?.centreName || 'ALBorivali';
    // this.totalRegisteredBoys = 0;
    // this.totalRegisteredGirls = 0;
    // this.followUpChildrenCount = 0;
    // this.uniqueAdmissionsCount = 0;
    // this.followUpAdmissionsCount = 0;
    // this.totalAdmissionCount = 0;
    // this.show = false;
    let c = $event.value;
    // this.totalUnits = 0;
    // this.totalOccupiedUnits = 0;
    // this.totalAvailableUnits = 0;

    // if (this.mStartDate == null && this.mEndDate == null) {
    //     // this._snackBar.open('dates are null running Get All Child Data by Centre')._dismissAfter(3000)
    //     this.getAllCentresData(c);
    //     this.getAllChildDataByCentre(c);
    // }
    //
    // // if (this.mStartDate != null && this.mEndDate != null) {
    // //     this.getAllCentresDataRange(c);
    //     this.getAllChildDataByCentreRange(c, this.mStartDate!, this.mEndDate!);
    // // }
  }

  private getAllCentresData(centreCode: string) {

    // console.log('getAllCentre Called with:', centreCode)
    // this.centreService.getCentreByCode(centreCode)
    if (centreCode == 'All') {
//? At startup when centreCode == "All"

      // this.allCentres = []
      // console.log('CENTRE LIST TABLE', centres)
      // this.allCentres = centres
      this.mCentres = this.allCentres
      this.totalUnits = 0
      this.totalAvailableUnits = 0
      this.totalOccupiedUnits = 0
      this.mCentres.forEach(c => {
        this.totalUnits = this.totalUnits += +c.totalUnits!
        this.totalAvailableUnits = this.totalAvailableUnits += +c.availableUnits!
        this.totalOccupiedUnits = this.totalOccupiedUnits += +c.occupiedUnits!
      })

      let totalName = 'Total'
      // this.addextraRowtoOccupancyTable(this.totalUnits, this.totalAvailableUnits, this.totalOccupiedUnits, totalName);
      this.dataSource = new MatTableDataSource(this.allCentres);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      // this.getTotalUnitsCount(this.allCentres)


      // console.log('allCentres if', this.allCentres.length)
    } else if (centreCode != 'All') {
      {
        // console.log('Into getAllCentresData with centreCode ==', centreCode)
        this.mCentres = this.allCentres.filter(c => c.centreCode == centreCode)
        // console.log('CENTRE LIST TABLE', this.mCentres)
        let totalCentres = 0
        this.totalUnits = 0
        this.totalAvailableUnits = 0
        this.totalOccupiedUnits = 0
        this.allCentres.forEach(c => {
          this.totalUnits = this.totalUnits += +c.totalUnits!
          this.totalAvailableUnits = this.totalAvailableUnits += +c.availableUnits!
          this.totalOccupiedUnits = this.totalOccupiedUnits += +c.occupiedUnits!
        })
        let totalName = 'Total'
        this.addextraRowtoOccupancyTable(this.totalUnits, this.totalAvailableUnits, this.totalOccupiedUnits, totalName);
        this.dataSource = new MatTableDataSource(this.mCentres);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        //    this.getTotalUnitsCount(this.allCentres)


        // console.log('allCentres else', this.allCentres.length)
      }
    }


  }


  private addextraRowtoOccupancyTable(totalUnits: number, totalAvailableUnits: number, totalOccupiedUnits: number, totalCentres: string) {
    totalUnits = 0
    totalAvailableUnits = 0
    totalOccupiedUnits = 0
    // console.log(this.mCentres)
    this.allCentres.forEach(c => {
      totalUnits = totalUnits += c.totalUnits!
      totalAvailableUnits = totalAvailableUnits += c.availableUnits!
      totalOccupiedUnits = totalOccupiedUnits += c.occupiedUnits!

    })
    let c = {
      centreName: totalCentres,
      availableUnits: totalAvailableUnits,
      occupiedUnits: totalOccupiedUnits,
      totalUnits: totalUnits
    }
    this.lastRow = c as unknown as Centre
    // if(!this.extraRowAdded)     this.mCentres.push(this.lastRow)
    this.extraRowAdded = true

  }


  ngOnDestroy(): void {
    // @ts-ignore
    this._destroyed$.next()
    this._destroyed$.complete()
  }


  private fetchAgeTableDataOLD(fullChildren: FullChild[], FollowUpChildrens: FullChild[]) {
    // * console.log('into age calculation***')
    this.ageArray = []
    this.mFollowUpAgeArray = []
    this.a = 0
    this.af = 0
    this.b = 0
    this.bf = 0
    this.c1 = 0
    this.cf = 0
    this.d = 0
    this.df = 0
    this.e = 0
    this.ef = 0
    fullChildren.forEach(c => {
      if (c.age == null) {
        //    console.log('INVALID DOB', c.firstName + ' ' + c.lastName)
      }
      if (c.age != null) {
        if (c.age <= 3 && c.age > 0) {
          this.a++
        } else if (c.age >= 4 && c.age <= 7) {
          this.b++
        } else if (c.age >= 8 && c.age <= 10) {
          this.c1++
        } else if (c.age >= 11 && c.age <= 14) {
          this.d++
        } else this.e++
      }
    })
    // this._snackBar.open(fullChildren.length.toString(),'')
    alert('Followup in age ' + FollowUpChildrens.length)
    FollowUpChildrens.forEach(c => {
      if (c.age == null) {
        //    console.log('INVALID DOB', c.firstName + ' ' + c.lastName)
      }
      if (c.age != null) {
        if (c.age <= 3 && c.age > 0) {
          this.af++
        } else if (c.age >= 4 && c.age <= 7) {
          this.bf++
        } else if (c.age >= 8 && c.age <= 10) {
          this.cf++
        } else if (c.age >= 11 && c.age <= 14) {
          this.df++
        } else this.ef++
      }
    })
    //* console.log('a', this.a)
    //* console.log('b', this.b)
    //* console.log('c1', this.c1)
    //* console.log('d', this.d)
    //* console.log('e', this.e)
    if (this.a != undefined) this.ageArray.push(this.a)
    if (this.b != undefined) this.ageArray.push(this.b)
    if (this.c1 != undefined) this.ageArray.push(this.c1)
    if (this.d != undefined) this.ageArray.push(this.d)
    if (this.e != undefined) this.ageArray.push(this.e)

    if (this.af != undefined) this.mFollowUpAgeArray.push(this.af)
    if (this.bf != undefined) this.mFollowUpAgeArray.push(this.bf)
    if (this.cf != undefined) this.mFollowUpAgeArray.push(this.cf)
    if (this.df != undefined) this.mFollowUpAgeArray.push(this.df)
    if (this.ef != undefined) this.mFollowUpAgeArray.push(this.ef)
    this.show = true
    this.dataHospitalLoaded = true
  }

  private fetchAgeTableData(fullChildren: FullChild[], FollowUpChildrens: FullChild[]) {
    this.ageArray = this.calculateAgeArray(fullChildren);
    this.mFollowUpAgeArray = this.calculateAgeArray(FollowUpChildrens);
    // this._snackBar.open('Follow Up Children in Age Graph:'+FollowUpChildrens.length,'',{
    //   duration:3000,
    //   panelClass:'info'
    // })
    this.show = true;
  }

  private calculateAgeArray(children: FullChild[]): number[] {
    const ageCounts = [0, 0, 0, 0, 0];

    children.forEach(c => {
      if (c.age !== null) {
        const ageGroup = this.getAgeGroup(c.age);
        ageCounts[ageGroup]++;
      }
    });

    return ageCounts;
  }

  private getAgeGroup(age: number): number {
    if (age <= 3 && age > 0) {
      return 0;
    } else if (age >= 4 && age <= 7) {
      return 1;
    } else if (age >= 8 && age <= 10) {
      return 2;
    } else if (age >= 11 && age <= 14) {
      return 3;
    } else {
      return 4;
    }
  }

  private fetchHospitalData(children: FullChild[]) {
    this.resetHospitalData();
    this.show = false;
    let uniqueChildren: FullChild[] = []
    let followUpChildren: FullChild[] = []

    const hospitals = this.mHospitals.map(x => ({name: x.hospitalName, label: x.shortName}));

    hospitals.forEach(hospital => {

      if (hospital.name) {
        try {

          uniqueChildren = children.filter(child => child.hospital != null && child.hospital.includes(hospital.name) && child.admissionData.length <= 2);
          followUpChildren = children.filter(child => child.hospital != null && child.hospital.includes(hospital.name) && child.admissionData.length > 2);

        } catch (e: any) {
          console.log(`${e.message} }`)
        }


        if (uniqueChildren.length > 0) {

          this.HospitalLables.push(hospital.label);
          this.HospitalCount.push(uniqueChildren.length);
          this.mFollowUpHospitalCount.push(followUpChildren.length)
          // console.log(hospital.name, followUpChildren.length)
          this.hData.push({data: [uniqueChildren.length, followUpChildren.length], label: hospital.label});

        }
      }

    });
    this.show = true;
    this.dataHospitalLoaded = true

  }


  private resetHospitalData() {
    this.show = false;
    this.HospitalLables = [];
    this.HospitalCount = [];
    this.mFollowUpHospitalCount = []
    this.hData = [];
  }


//? On centre selection


//* ON CENTRE CHANGE ONLY

  private getAllChildDataByCentre(centreCode: string) {
    // this._snackBar.open(`${centreCode} Selected `,'',{duration:3000,
    // panelClass:'info'})
    let filteredChildData = this.mAllChildren;
    if (centreCode != 'All') {
      filteredChildData = this.mAllChildren.filter(child => child.centreCode === centreCode);
    }
    this.totalRegisteredChildren = filteredChildData.length;
    const followUpChildrens = filteredChildData.filter(child => child.admissionData.length > 2);

    this.totalRegisteredBoys = filteredChildData.filter(child => child.gender === 'Male').length;
    this.totalRegisteredGirls = filteredChildData.filter(child => child.gender === 'Female').length;

    this.uniqueChildrenCount = filteredChildData.filter(child => child.admissionData.length <= 2).length;
    this.followUpChildrenCount = filteredChildData.filter(child => child.admissionData.length > 2).length;

    this.freshBoys = filteredChildData.filter(child => child.admissionData.length <= 2 && child.gender === 'Male').length;
    this.freshGirls = filteredChildData.filter(child => child.admissionData.length <= 2 && child.gender === 'Female').length;
    this.followupBoys = followUpChildrens.filter(child => child.gender === 'Male').length;
    this.followupGirls = followUpChildrens.filter(child => child.gender === 'Female').length;

    let totalAdmissionsCount = 0;
    let followUpAdmissionsCount = 0;

    filteredChildData.forEach(child => {
      totalAdmissionsCount += child.admissionData.length;
      followUpAdmissionsCount += Math.max(0, child.admissionData.length - 2);
    });

    this.followUpAdmissionsCount = followUpAdmissionsCount;
    this.uniqueAdmissionsCount = this.freshBoys + this.freshGirls
    this.totalAdmissionCount = this.followUpAdmissionsCount + this.uniqueAdmissionsCount;
    setTimeout(() => {

      console.log(`${filteredChildData.length} ${followUpChildrens.length}`)

      this.fetchHospitalData(filteredChildData);
      this.fetchAgeTableData(filteredChildData, followUpChildrens);
    }, 100);


    this.mCounsellingService.fetchIndividualSessionsByCentre(centreCode).subscribe(res => {
      this.mTotalCounsellingIndividual = res.length
    })
    this.mCounsellingService.fetchGroupSessionsByCentre(centreCode, this.currentCentreName).subscribe(res => {
      // const filteredArray = res.filter(obj=>obj.centres.filter)
      // const filteredArray = myArray.filter(obj => obj.age > 25);

      this.mTotalCounsellingGroup = res.length

    })
    this.mEducationService.getIndividualEducationListByCenter(centreCode).subscribe(res => {
      this.mTotalEducationIndividual = res.length
    })
    let obj = {
      centreName: this.currentCentreName,
      centreCode: centreCode
    }
    this.mEducationService.getGroupEducationSessionsByCenterCode(obj).subscribe(res => {
      this.mTotalEducationGroup = res.length
    })

  }


//*OLD

//* Calculating by startdate and enddate in centre
  private getAllChildDataByCentreRange(centreCode: string, startDate: Date, endDate: Date) {
    this.totalRegisteredBoys = 0;
    this.totalRegisteredGirls = 0;
    this.followUpChildrenCount = 0;
    this.uniqueAdmissionsCount = 0;
    this.followUpAdmissionsCount = 0;
    this.totalAdmissionCount = 0;
    let uniqueArray: string[] = []
    let childData: FullChild[] = []
    if (centreCode == 'All') {
      childData = this.mAllChildren
      // query = this.childService.getRegisteredChildListByDateRange(this.mStartDate!, this.mEndDate!);
    }
    //* calculating data for particular centre
    else {
      childData = this.mAllChildren.filter((x) => x.centreCode == centreCode)
      // query = this.childService.getRegisteredChildListByDateRangeAndCentre(centreCode, this.mStartDate!, this.mEndDate!);
      this._snackBar.open('Calculating by centre and date : ' + centreCode)._dismissAfter(5000)
    }

    let arr = childData.filter((x, i) => x.admissionData[0].dateOfAdmission!.toDate() >= startDate! && x.admissionData[0].dateOfAdmission!.toDate() <= endDate!)
    arr.forEach(it => {
      uniqueArray.push(`${it.firstName} ${it.lastName}`)
    })
    this.uniqueAdmissionsCount = childData.filter((x, i) => x.admissionData[0].dateOfAdmission!.toDate() >= startDate! && x.admissionData[0].dateOfAdmission!.toDate() <= endDate!).length
    this.freshBoys = childData.filter((x, i) => x.admissionData[0].dateOfAdmission!.toDate() >= startDate! && x.admissionData[0].dateOfAdmission!.toDate() <= endDate! && x.gender === 'Male').length
    this.freshGirls = childData.filter((x, i) => x.admissionData[0].dateOfAdmission!.toDate() >= startDate! && x.admissionData[0].dateOfAdmission!.toDate() <= endDate! && x.gender === 'Female').length
    this.followupBoys = 0
    this.followupGirls = 0

//* Calculating followup boys and girls
    let repeatedFollowUpsChlidren: FullChild[] = []
    childData.forEach(it => {
        it.admissionData.forEach((y, i) => {
          if (i >= 2 && i % 2 == 0) {

            if (y.dateOfAdmission!.toDate() >= startDate! && y.dateOfAdmission!.toDate() <= endDate!) {
              this.logInvalidCentre(it)
              this.followUpAdmissionsCount++;
              it.gender == 'Male' ? this.followupBoys++ : this.followupGirls++
              if (it.gender != "Male" && it.gender != 'Female') console.log('MISSING GENDER', it.childCode)
              repeatedFollowUpsChlidren.push(it)
            }
          }
        });

      }
    );

    this.totalAdmissionCount = this.uniqueAdmissionsCount + this.followUpAdmissionsCount
    console.log('uniqueChildrenCount', this.uniqueChildrenCount)
    console.log('followUpAdmissionsCount', this.followUpAdmissionsCount)
    console.log('totalAdmissionCount', this.totalAdmissionCount)
    let sortchildbyadmissionsDate = childData.filter((x, i) => childData.filter((x) => x.admissionData[0].dateOfAdmission!.toDate() >= startDate! && x.admissionData[0].dateOfAdmission!.toDate() <= endDate!))
    this.totalRegisteredChildren = childData.length;
    this.totalRegisteredChildren = childData.filter((x, i) => x.admissionData[0].dateOfAdmission!.toDate() >= startDate! && x.admissionData[0].dateOfAdmission!.toDate() <= endDate!).length
    this.totalRegisteredBoys = sortchildbyadmissionsDate.filter(x => x.admissionData[0].dateOfAdmission!.toDate() >= startDate! && x.admissionData[0].dateOfAdmission!.toDate() <= endDate! && x.gender === 'Male').length;
    this.totalRegisteredGirls = sortchildbyadmissionsDate.filter(x => x.admissionData[0].dateOfAdmission!.toDate() >= startDate! && x.admissionData[0].dateOfAdmission!.toDate() <= endDate! && x.gender === 'Female').length;
    setTimeout(() => {
      const FollowUpChildrens: FullChild[] = []
      if (childData) {
        let childF = childData.filter(child => child.admissionData.length > 2)
        childF.forEach(child => {
          child.admissionData.forEach((admission, i: number) => {
            if (i > 2 && admission.dateOfAdmission?.toDate()! >= this.startDate! && admission.dateOfAdmission?.toDate()! <= this.endDate) {
              FollowUpChildrens.push(child)
            }
          })
        })
        // alert('loading chart')
        this.getChildAccordingtoAdmissionDate(childData, startDate, endDate)
        this.fetchHospitalData(this.getChildAccordingtoAdmissionDate(childData, startDate, endDate));
        this.fetchAgeTableData(this.getChildAccordingtoAdmissionDate(childData, startDate, endDate), repeatedFollowUpsChlidren);
      }
    }, 100);
    // this.mCounsellingService.fetchIndividualSessionsByCentreByDate(centreCode,startDate,endDate).subscribe(res => {
    //     this.mTotalCounsellingIndividual = res.length
    // })
    // this.mCounsellingService.fetchGroupSessionsByCentreByDate(centreCode,startDate,endDate).subscribe(res => {
    //     this.mTotalCounsellingGroup = res.length
    // })
    // this.mEducationService.getIndividualEducationListByCenterByDate(centreCode,startDate,endDate).subscribe(res => {
    //     this.mTotalEducationIndividual = res.length
    // })
    // this.mEducationService.getGroupEducationSessionsByCenterByDate(centreCode,startDate,endDate).subscribe(res => {
    //     this.mTotalEducationGroup = res.length
    // })
  }


  getPercentage(element: Centre | any) {

    try {
      if (element.totalUnits != null && element.occupiedUnits != null) {
        let total = element.totalUnits
        let available = element.occupiedUnits
        let percentage = available / total * 100
        // console.log(` ${total} ${available} ${percentage}`)
        return percentage.toFixed(2)
      }
    } catch (e) {
      console.log(e)
    }
    return 'error'
  }

  resetDate() {
    this.mStartDate = null
    this.mEndDate = null
    this.selectedCentreOption = 'ALBorivali'
  }


  private getChildAccordingtoAdmissionDate(childData: FullChild[], startDate: Date, endDate: Date) {
    return childData.filter((x) => x.admissionData[0].dateOfAdmission!.toDate() >= startDate && x.admissionData[0].dateOfAdmission!.toDate() <= endDate)
  }


  private fetchAdmissionChartData(mChildren: FullChild[]) {

    if (this.mCentres.length == 0) {
      this.mCentres = this.allCentres
    }
    this.showAdmissionChart = false
    let data = of(mChildren)
    let centrescodes: string[] = this.mCentres.map(x => x.centreCode).reverse()
    let centreNames: string[] = this.mCentres.map(x => x.centreName).reverse()
    // centrescodes.reverse()
    // centres.reverse()
    // this.mCentres.forEach(it=>{
    //     centres.push(it.centreName)
    // })
    data.subscribe((children) => {
      setTimeout(() => {

        this.mAdmissionChartCentres = centreNames
        // this._snackBar.open('CENTRES :',centres.length.toString())
        centrescodes.forEach(it => {
          let c = children.filter((x) => x.centreCode == it)
          let f = children.filter((x) => x.centreCode == it && x.admissionData.length >= 2)
          this.mAdmissionChartUniqueCount.push(c.length)
          this.mAdmissionChartFollowUpCount.push(f.length)

        })
        console.log(`Admission Chat Unique ${this.mAdmissionChartUniqueCount.length}`)
        console.log(`Admission Chat Follow Up ${this.mAdmissionChartFollowUpCount.length}`)
        this.showAdmissionChart = true
      }, 2000)
    })

  }

  private logInvalidCentre(child: FullChild) {
    if (

      child.centreCode == 'ALAhmedabad' ||
      child.centreCode == 'ALBangalore' ||
      child.centreCode == 'ALBorivali' ||
      child.centreCode == 'ALChandigarh' ||
      child.centreCode == 'ALCoimbatore' ||
      child.centreCode == 'ALDiamondGarden' ||
      child.centreCode == 'ALGurunanak' ||
      child.centreCode == 'ALManipal' ||
      child.centreCode == 'ALPune' ||
      child.centreCode == 'ALSanduGarden') {

    } else {
      // console.log(`INVALID CENTRE ${child.centreCode} ${child.childCode}`)
    }
  }

  // * Generated using chatGPT

  search() {
    this.totalRegisteredBoys = 0;
    this.totalRegisteredGirls = 0;
    this.followUpChildrenCount = 0;
    this.uniqueAdmissionsCount = 0;
    this.followUpAdmissionsCount = 0;
    this.totalAdmissionCount = 0;
    this.show = false;
    let c = this.selectCentre.value;
    // this.totalUnits = 0;
    // this.totalOccupiedUnits = 0;
    // this.totalAvailableUnits = 0;

    if (this.mStartDate == null && this.mEndDate == null) {
      // this._snackBar.open('dates are null running Get All Child Data by Centre')._dismissAfter(3000)
      // this.getAllCentresData(c!!);
      this.getAllChildDataByCentre(c!!);
    }

    if (this.mStartDate != null && this.mEndDate != null) {
      // this.getAllCentresDataRange(c!!);
      this.getAllChildDataByCentreRange(c!!, this.mStartDate!, this.mEndDate!);
    }
  }



}

function getDateBeforeMonth(date: Date) {
  // Create a copy of the date object to avoid modifying the original
  const newDate = new Date(date.getTime());

  // Set the date to the first day of the current month
  newDate.setDate(1);

  // Subtract one day to get the date before the current month
  newDate.setDate(newDate.getDate() - 1);

  // Return the modified date object
  return newDate;
}
