import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Vehicle} from "./add-vehicles/add-vehicles.component";
import {Driverdata} from "./add-info/add-info.component";

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  constructor(private firestore: AngularFirestore) {
  }

  addVehicle(vehicle: Vehicle): Promise<any> {
    return this.firestore.collection('ALVehicles').add(vehicle);
  }

  getVehicles(): Observable<any> {
    return this.firestore.collection('ALVehicles').valueChanges({idField: 'id'});
  }

  updateVehicle(id: string, data: Vehicle): Promise<void> {
    return this.firestore.collection('ALVehicles').doc(id).update(data);
  }


  deleteVehicle(id: string): Promise<void> {
    return this.firestore.collection('ALVehicles').doc(id).delete();
  }

  addDriverData(driverData: Driverdata) {
    return this.firestore.collection('ALDrivers').add(driverData);
  }
  getDriver() {
    return this.firestore.collection('ALDrivers').valueChanges({idField:'id'})
  }

  updateDriverData(driverData: Driverdata, id: string) {
    return this.firestore.collection('ALDrivers').doc(id).update(driverData);
  }

  deleteDriverData(id: string): Promise<void> {
    return this.firestore.collection('ALDrivers').doc(id).delete();
  }

  carLogDelete(id:string):Promise<void> {
    return this.firestore.collection('ALTrips').doc(id).delete()
  }


  addDCarFuelsData(carData: any) {
    return this.firestore.collection('prachipetrolfilling').add(carData);
  }
  updateDCarFuelsData(carId: string, carData: any) {
    return this.firestore.collection('prachipetrolfilling').doc(carId).update(carData);
  }
  getCarFuelsData() {
    return this.firestore.collection('prachipetrolfilling').valueChanges({idField:'id'})
  }
  updateFuelsData(id: string, data: Vehicle){
    return this.firestore.collection('prachipetrolfilling').doc(id).update(data);
  }

  deleteCarFuelsData(id:any) {
    return this.firestore.collection('prachipetrolfilling').doc(id).delete()
  }

  addServicingData(carData: any) {
    return this.firestore.collection('VehicleServicing').add(carData);
  }
  getServicingData() {
    return this.firestore.collection('VehicleServicing').valueChanges({idField:'id'})
  }
  updateServicingData(id: string, data: Vehicle){
    return this.firestore.collection('VehicleServicing').doc(id).update(data);
  }

  deleteServicingData(id:any) {
    return this.firestore.collection('VehicleServicing').doc(id).delete()
  }

}
