<h1 class="bg-warning text-center mb-4">Trip Report</h1>
<div class="row">

  <mat-form-field appearance="outline" class="col-md-3 ">

    <mat-label>Select a date range</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate [(ngModel)]="mStartDate" placeholder="Start date">
      <input matEndDate [(ngModel)]="mEndDate" placeholder="End date">
    </mat-date-range-input>
    <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-3">
    <mat-label>Driver Name:</mat-label>
    <mat-select [(ngModel)]="drivers_name" (selectionChange)="onDriverSelect($event)">
      <mat-option *ngFor="let driver of driverNames" [value]="driver">
        {{ driver }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-3">
    <mat-label>Vehicle Number</mat-label>
    <mat-select placeholder="Select Number" [(ngModel)]="vehicles_no" (selectionChange)="OnVehicleSelect($event)">
      <mat-option *ngFor="let vehicle of vehicleNumbers" [value]="vehicle">{{ vehicle }}</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="justify-content-start gap-2 d-flex col-md-3">
    <button mat-mini-fab (click)="searchData()">
      <mat-icon>search</mat-icon>
    </button>
    <button mat-mini-fab (click)="clearAll()" color="accent">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

</div>


<div class="card p-2">
  <div class="ngxTableHeader">
    <ul class="header-buttons-left ms-0 mb-0">
      <li class="dropdown">
        <h2>Trip Report</h2>
      </li>
      <li class="dropdown m-l-20">
        <label for="search-input"><i class="material-icons search-icon">search</i></label>
        <input placeholder="Search" id="search-input" type="text" class="browser-default search-field"
               (keyup)='applyFilter($event)' aria-label="Search box" #input>
      </li>
    </ul>
    <ul class="header-buttons mr-2">
      <li>
        <button mat-mini-fab color="primary" (click)="openAddCarLog()">
          <mat-icon class="col-white">add</mat-icon>
        </button>
      </li>
    </ul>
  </div>

  <table mat-table [dataSource]="dataSource" class=" " matSort>

    <!-- Display Column -->
    <ng-container matColumnDef="tripDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Trip Date</th>
      <td mat-cell *matCellDef="let element" class="truncate-text"
          matTooltip="{{displayDate2(element.trip_started_at)}}"
          matTooltipPosition="above"> {{ displayDate2(element.trip_started_at) }}
      </td>
    </ng-container>

    <!-- Location Column -->
    <ng-container matColumnDef="tripFrom">
      <th mat-header-cell *matHeaderCellDef> Trip From</th>
      <td mat-cell *matCellDef="let element" class="From-trip" matTooltip="{{element.pick_up_location}}"
          matTooltipPosition="above"> {{ element.pick_up_location }}
      </td>
    </ng-container>

    <ng-container matColumnDef="tripTo">
      <th mat-header-cell *matHeaderCellDef> Trip To</th>
      <td mat-cell *matCellDef="let element" class="to-trip" matTooltip="{{element.drop_location}}"
          matTooltipPosition="above">{{ element.drop_location }}
      </td>
    </ng-container>

    <!-- Time Reached Column -->
    <ng-container matColumnDef="vehicleNo">
      <th mat-header-cell *matHeaderCellDef> Vehicle Number</th>
      <td mat-cell *matCellDef="let element" class="vehicleNo" matTooltip="{{element.vehicle_info.vehicleNumber}}"
          matTooltipPosition="above"> {{ element.vehicle_info.vehicleNumber }}
      </td>
    </ng-container>

    <ng-container matColumnDef="driverName">
      <th mat-header-cell *matHeaderCellDef> Driver Name</th>
      <td mat-cell *matCellDef="let element" class="truncate-text" matTooltip="{{element.vehicle_info.driverName}}"
          matTooltipPosition="above"> {{ element.vehicle_info.driverName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="startTripDate">
      <th mat-header-cell *matHeaderCellDef> Start Date and Time</th>
      <td mat-cell *matCellDef="let element" class="Start-Date-and-Time"
          matTooltip="{{displayDate(element.trip_started_at)}},'Start Meter Reading':{{element?.pick_up_meter_reading}}"
          matTooltipPosition="above">{{ displayDate(element.trip_started_at) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="endTripDate">
      <th mat-header-cell *matHeaderCellDef> End Date and Time</th>
      <td mat-cell *matCellDef="let element" class="End-Date-and-Time"
          matTooltip="{{displayDate(element.trip_ended_at)}},'End Meter Reading':{{element?.drop_meter_reading}}"
          matTooltipPosition="above">{{ displayDate(element.trip_ended_at) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="tripType">
      <th mat-header-cell *matHeaderCellDef> Trip Type</th>
      <td mat-cell *matCellDef="let element" class="truncate-text" matTooltip="{{element.triptype}}"
          matTooltipPosition="above">{{ element.triptype }}
      </td>
    </ng-container>

    <ng-container matColumnDef="totalKms">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Total KM</th>
      <td mat-cell *matCellDef="let element" class="totalKms" matTooltip="{{calculateTotalKms(element)}}"
          matTooltipPosition="above"> {{ calculateTotalKms(element) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Action</th>
      <td mat-cell *matCellDef="let element" class="actions-width">
        <button mat-icon-button color="accent" matTooltip="View" (click)="openView(element)">
          <mat-icon>visibility</mat-icon>
        </button>
        <button mat-icon-button color="primary" matTooltip="Edit" (click)="edit(element)">
          <mat-icon>edit</mat-icon>
        </button>
        <button *ngIf="getEmail === 'test1@gmail.com'" mat-icon-button color="warn" matTooltip="delete"
                (click)="confirmDelete(element)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Add more columns as needed -->

    <!-- Table Rows -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [length]="200" [pageSizeOptions]="[25, 50, 100, 200, 500]" aria-label="Select page">
  </mat-paginator>
</div>
