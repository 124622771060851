<section class="">
  <div class="">
    <!-- Your content goes here  -->
    <div class="row clearfix">
      <div class="col-lg-4 col-md-12">
        <div class="card">
          <div class="m-b-20">
            <div class="contact-grid">
              <div class="profile-header bg-dark">
                <div
                  class="user-name">{{driverData.firstName}} {{driverData.middleName}} {{driverData.lastName}}{{driverData.vehicleName}}</div>
                <div class="text-warning my-1">Contact Number: {{driverData.contactPhone}}</div>
              </div>
              <img [src]="driverData.kycData.passportPhoto" class="user-img" alt="Driver Photo">
              <p>
                <strong>Date of Birth : </strong><span
                class="text-muted">{{(driverData.dob.seconds * 1000) | date:'dd/MM/yyyy'}}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="card">
          <!-- Add other tabs or content here -->
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="card">
          <mat-tab-group>
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon msr-2">face</mat-icon>
                Driver
              </ng-template>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="project_widget">
                  <div class="header">
                    <h2><strong>Driver Details</strong></h2>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-md-4  ">
                        <strong>Driver Name</strong>
                        <p
                          class="text-muted">{{driverData.firstName}} {{driverData.middleName}} {{driverData.lastName}}</p>
                      </div>
                      <div class="col-md-4  ">
                        <strong>Address</strong>
                        <p class="text-muted mb-0">{{driverData.currentAddress}}</p>
                      </div>
                      <div class="col-md-4  ">
                        <strong>State</strong>
                        <p class="text-muted mb-0">{{driverData.state}}</p>
                      </div>
                      <div class="col-md-4  ">
                        <strong>Date of Joining</strong>
                        <p class="text-muted mb-0">{{(driverData.dateOfJoining).toDate() | date:'dd/MM/yyyy'}}</p>
                      </div>
                      <div class="col-md-4  ">
                        <strong>Expiration Date</strong>
                        <p class="text-muted mb-0">{{(driverData.expirationDate).toDate() | date:'dd/MM/yyyy'}}</p>
                      </div>
                      <div class="col-md-4  ">
                        <strong>License</strong>
                        <p class="text-muted mb-0">{{driverData.license}}</p>
                      </div>
                      <div class="col-md-4  ">
                        <strong>Type of Equipment</strong>
                        <p class="text-muted mb-0">{{driverData.typeOfEquipment}}</p>
                      </div>
                      <!-- Add other fields as needed -->
                    </div>
                    <br>
                  </div>
                </div>
              </div>
              <hr>
              <div class="text-center">
                    <button mat-raised-button color="accent" (click)="onCancel()">Cancel</button>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon msr-2">face</mat-icon>
                Drivers Doc\Photos
              </ng-template>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="project_widget">
                  <div class="header">
                    <h2><strong>Drivers Doc\Photos</strong></h2>
                  </div>
                  <div class="body">
                    <div class="row row-gap-2">
                      <div class="col-md-6 ">
                        <img [src]="driverData.kycData.passportPhoto" alt="Drivers Doc\Photos" height="200px"
                             class="childphoto card">
                      </div>
                      <!-- Add other image fields as needed -->
                    </div>
                  </div>
                  <hr>
              <div class="text-center">
                <button mat-raised-button color="accent" (click)="onCancel()">Cancel</button>
              </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</section>
