import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {VehicleService} from "../vehicle.service";
import {MatDialog} from "@angular/material/dialog";
import {AddCarFuelsDetailsComponent} from "../add-car-fuels-details/add-car-fuels-details.component";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {ViewCarFuelsDetailsComponent} from "../view-car-fuels-details/view-car-fuels-details.component";
import {LogService} from "../../../services/log.service";
import { Timestamp } from '@angular/fire/firestore';
import {DatePipe} from "@angular/common";
import {LoadingService} from "../../../services/loading.service";

@Component({
  selector: 'app-all-car-fuels-details-new',
  templateUrl: './all-car-fuels-details-new.component.html',
  styleUrls: ['./all-car-fuels-details-new.component.scss']
})
export class AllCarFuelsDetailsNewComponent implements OnInit {
  displayedColumns: string[] = ['driverName', 'entryDateTime', 'createdDateTime', 'totalQuantityField', 'meterReading', 'vehicleNumber', 'action'];
  dataSource: MatTableDataSource<any>
  drivers: any[] = [];
  driver_name=  '';
  vehicles_no = '';
  vehicles: any[] = [];
  filteredData: any[] = []; // Data after filtering
  driverName: any;
  selectedVehicle: any;
  selectedDriver: string = '';
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private mDialog: MatDialog,
              private mLogService: LogService,
              private datePipe: DatePipe,
              private loadingService: LoadingService,
              private mVehicleService: VehicleService) {
    this.dataSource = new MatTableDataSource<any>()

  }


  ngOnInit(): void {
    this.getAllCarfuleData();
    this.mVehicleService.getDriver().subscribe(res => {
      this.drivers = res
      console.log(this.drivers)
    })

    this.mVehicleService.getVehicles().subscribe(res => {
      console.log(res)
      this.vehicles = res
    })
  }

  getAllCarfuleData() {
    this.loadingService.setLoading(true);
    this.mVehicleService.getCarFuelsData().subscribe(res => {
      this.dataSource = new MatTableDataSource(res);
      this.loadingService.setLoading(false);

    })
  }

  displayDate(timestamp: Timestamp): string {
    // Check if timestamp exists and is a Firestore Timestamp
    if (timestamp && timestamp.toDate) {
      const date = timestamp.toDate(); // Convert to JavaScript Date
      return this.datePipe.transform(date, 'dd/MM/yyyy') || '';
    }
    return '';
  }


  filterAllCarfuleData() {debugger
    // Set custom filter predicate
    this.dataSource.filterPredicate = (data: any, filter: string) => {
    console.log(data)
      const filterValues = JSON.parse(filter); // Parse filter string
      console.log(filterValues)
      const matchDriver = filterValues.driverName ? data.driverName.toLowerCase().includes(filterValues.driverName.toLowerCase()) : true;
      const matchVehicle = filterValues.vehicleNumber ? data.vehicleNumber.toLowerCase().includes(filterValues.vehicleNumber.toLowerCase()) : true;
      return matchDriver && matchVehicle;
    };

    const filterObject = {
      driverName: this.selectedDriver,
      vehicleNumber: this.selectedVehicle
    };
    // Set the filter to the dataSource
    this.dataSource.filter = JSON.stringify(filterObject); // Apply filter
  }

  clearAll() {
    this.driver_name = ''; // Clear driver selection
    this.vehicles_no = ''; // Clear vehicle selection
    this.getAllCarfuleData(); // Re-fetch all data to reset the table
  }
  OpenDialog() {
    this.mDialog.open(AddCarFuelsDetailsComponent, {
      maxHeight: '60vh',
    });

  }
  openView(element:any){
    this.mDialog.open(ViewCarFuelsDetailsComponent, {
      data:element,
      width: '700px'
    })

    let activity = {
      date: new Date().getTime(),
      section: 'Vehicle',
      action: 'View',
      description: `Vehicles view View Vehicle by user`,
      currentIp: localStorage.getItem('currentip')!
    }
    this.mLogService.addLog(activity)
  }

  editInfo(element:any) {
    this.mDialog.open(AddCarFuelsDetailsComponent, {
      data:element,
    })

  }

  deleteInfo(element:any) {
    this.mVehicleService.deleteCarFuelsData(element.id).then(res => {
      console.log("Deleted Successfully")
    })

  }

  onDriverSelect($event:any){
    this.driverName = $event.value
  }

  OnVehicleSelect($event:any){
    this.selectedVehicle = $event.value

  }

  // applyFilter($event: KeyboardEvent) {debugger
  //   const filterValue = ($event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  // }
  applyFilter(event: KeyboardEvent) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

    // Use the built-in filter function of MatTableDataSource
    this.dataSource.filter = filterValue; // This will filter based on all columns
  }
  }

