import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {VehicleService} from "../vehicle.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Employee} from "../../../interfaces/Employee";
import {LogService} from "../../../services/log.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-add-car-fuels-details',
  templateUrl: './add-car-fuels-details.component.html',
  styleUrls: ['./add-car-fuels-details.component.scss']
})
export class AddCarFuelsDetailsComponent implements OnInit {
  carForm: FormGroup;
  vehicles:any[] =[]
   drivers: any[] =[];
  isUpdateMode :boolean = false;
  userName:any
  constructor(private fb: FormBuilder,
              private mVehicleService: VehicleService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private readonly mDialogRef:MatDialogRef<AddCarFuelsDetailsComponent>,
              private readonly mSnackbar:MatSnackBar,
              private mLogService: LogService
              ) {
    this.isUpdateMode = !!data;
    const userData = JSON.parse(localStorage.getItem('userData')!)
    this.userName = userData?.userName
    // Initialize the form
    this.carForm = this.fb.group({
      date: ['', Validators.required],
      totalQuantityField: ['', [Validators.required, Validators.pattern(/^\d+$/)]],  // Only digits allowed
      meterReading: ['', [Validators.required, Validators.pattern(/^\d+$/)]],  // Only digits allowed
      vehicleNumber: ['', [Validators.required, Validators.pattern(/^[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}$/)]],  // Example pattern for vehicle number (e.g., AB12CD1234)
      driverName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]+$/)]],  // Only letters and spaces allowed
      userName: [this.userName]
    });

  }

  ngOnInit(): void {
    console.log(this.data)

    this.mVehicleService.getVehicles().subscribe(res => {
      console.log(res)
      this.vehicles = res
    })


    this.mVehicleService.getDriver().subscribe(res =>{
      this.drivers = res
    })

   // let email =await localStorage.getItem('userEmail')
    if(this.isUpdateMode){
      this.carForm.patchValue({
        date: this.getFormattedDate(this.data.date),
        totalQuantityField: this.data.totalQuantityField,
        meterReading: this.data.meterReading,
        vehicleNumber: this.data.vehicleNumber,
        driverName: this.data.driverName
      });
    }
  }
  cancel(){
  this.mDialogRef.close()
  }

  onSubmit() {
    console.log('Form Value:', this.carForm.value);
    console.log('Is Form Valid:', this.carForm.valid);

    if (this.carForm.valid) {
      const formData = {
        ...this.carForm.value,
        date: this.carForm.value.date ? Date.now() : null
      };

      // Check if there's an existing data object with an ID for updating
      if (this.data && this.data.id) {
        this.mVehicleService.updateDCarFuelsData(this.data.id, formData).then(() => {
          this.mSnackbar.open('Form Updated Successfully', '', {
            panelClass: 'success',
            duration: 3000
          });
          this.mDialogRef.close();
        }).catch(error => {
          console.error('Update Error:', error);
          this.mSnackbar.open('Failed to update the form. Please try again.', '', {
            panelClass: 'error',
            duration: 3000
          });
        });
      } else {
        // No existing data, proceed with add
        this.mVehicleService.addDCarFuelsData(formData).then(() => {
          this.mSnackbar.open('Form Submitted Successfully', '', {
            panelClass: 'success',
            duration: 3000
          });
          this.mDialogRef.close();
        }).catch(error => {
          console.error('Submission Error:', error);
          this.mSnackbar.open('Failed to submit the form. Please try again.', '', {
            panelClass: 'error',
            duration: 3000
          });
        });
      }
    } else {
      console.error('Form is invalid:', this.carForm.errors);
      this.mSnackbar.open('Please fill in all required fields correctly.', '', {
        panelClass: 'error',
        duration: 3000
      });
    }
  }




  private getFormattedDate(timestamp: any): Date {
    if (timestamp && timestamp.seconds) {
      return new Date(timestamp.seconds * 1000);
    } else {
      return new Date(); // Set a default date or handle accordingly
    }
  }
}
