<div class="container">
<!--  <prettyjson [obj]="addressForm.value"></prettyjson>-->
  <form [formGroup]="addressForm">
    <h1 class="text-center">{{ data ? 'Edit Hospital Information' : 'Add Hospital Information' }}</h1>
    <mat-card class="m-2 p-2">
      <div class="row">
        <div class="col-md-8">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Hospital Name</mat-label>
            <input matInput formControlName="hospitalName">
            <mat-error *ngIf="addressForm.get('hospitalName')?.invalid">
              <span *ngIf="addressForm.get('hospitalName')?.errors?.required">*Hospital Name is required.</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Short Name</mat-label>
            <input matInput formControlName="shortName">
            <mat-error *ngIf="addressForm.get('shortName')?.invalid">
              <span *ngIf="addressForm.get('shortName')?.errors?.required">*Short Name is required.</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
<hr>
      <div class="row">
        <ng-container formArrayName="drDetails">
          <div *ngFor="let dr of drDetail.controls; let i = index" [formGroupName]="i">
            <p>Doctor Details {{ i + 1 }}</p>
            <div class="row">
              <div class="col-md-4">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Doctor's Name</mat-label>
                  <input matInput formControlName="doctorsName">
                  <mat-error *ngIf="dr.get('doctorsName')?.invalid">
                    <span *ngIf="dr.get('doctorsName')?.errors?.required">*It is required.</span>
                    <span *ngIf="dr.get('doctorsName')?.errors?.pattern">*Enter only characters.</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Designation</mat-label>
                  <mat-select formControlName="designation">
                    <mat-option *ngFor="let d of drDesignation$ | async" [value]="d">{{ d }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="dr.get('designation')?.errors?.required">At least one designation is required.</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Dr. Contact Number</mat-label>
                  <input type="number" maxlength="10" matInput formControlName="phoneNo"/>
                  <mat-error *ngIf="dr.get('phoneNo')?.invalid">
                    <span *ngIf="dr.get('phoneNo')?.errors?.required">*It is required.</span>
                    <span *ngIf="dr.get('phoneNo')?.errors?.pattern">*Enter a valid 10-digit number.</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Email</mat-label>
                  <input matInput formControlName="email" type="email" required />
                  <mat-error *ngIf="dr.get('email')?.errors?.required">Email is required</mat-error>
                  <mat-error *ngIf="dr.get('email')?.errors?.required">Enter a valid email</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4 d-flex gap-2 mb-4">
                <button mat-raised-button color="warn" (click)="removeDrDetail(i)" *ngIf="drDetail.length > 1">Remove</button>
                <button mat-raised-button color="primary" (click)="addDrDetail()">Add Dr</button>
              </div>
            </div>
          </div>
        </ng-container>
        <hr>
      </div>
      <div class="row">
        <ng-container formArrayName="socialWorkerDetails">
          <div *ngFor="let w of socialWorkerDetail.controls; let i = index" [formGroupName]="i">
            <p>MSW Details {{ i + 1 }}</p>
            <div class="row">
              <!-- Social Worker Name -->
              <div class="col-md-4">
                <mat-form-field class="full-width" appearance="outline">
                  <mat-label>Medical MSW Name</mat-label>
                  <input matInput  formControlName="socialWorkerName">
                  <mat-error *ngIf="w.get('socialWorkerName')?.invalid">
                    <span *ngIf="w.get('socialWorkerName')?.errors?.required">*It is required.</span>
                    <span *ngIf="w.get('socialWorkerName')?.errors?.pattern">*Enter only characters.</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Contact Number -->
              <div class="col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>MSW Contact Number</mat-label>
                  <input matInput type="number" maxlength="10" formControlName="phoneNo"/>
                  <mat-error *ngIf="w.get('phoneNo')?.invalid">
                    <span *ngIf="w.get('phoneNo')?.errors?.required">*It is required.</span>
                    <span *ngIf="w.get('phoneNo')?.errors?.pattern">*Enter a valid 10-digit number.</span>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>Email</mat-label>
                  <input matInput formControlName="email" type="email" required />
                  <mat-error *ngIf="w.get('email')?.errors?.required">Email is required</mat-error>
                  <mat-error *ngIf="w.get('email')?.errors?.required">Enter a valid email</mat-error>
                </mat-form-field>
              </div>
              <!-- Buttons -->
              <div class="d-flex gap-2 mb-4 col-md-4">
                <button mat-raised-button color="warn" (click)="removeSocialWorkerDetail(i)"
                        *ngIf="socialWorkerDetail.length > 1">Remove
                </button>
                <button mat-raised-button color="primary" (click)="addSocialWorkerDetail()">Add MSW</button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <hr>
      <div class="row">
        <mat-label>Hospital Address</mat-label>
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Hospital Address</mat-label>
          <textarea matInput   placeholder="Hospital Address" formControlName="address"></textarea>
        </mat-form-field>
        <span *ngIf="addressForm.get('address')?.errors?.required">Address is required.</span>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>City</mat-label>
            <input matInput formControlName="city">
            <mat-error *ngIf="addressForm.get('city')?.invalid">
              <span *ngIf="addressForm.get('city')?.errors?.required">*City is required.</span>
              <span *ngIf="addressForm.get('city')?.errors?.pattern">*Enter only characters.</span>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-6">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>State</mat-label>
            <mat-select formControlName="state">
              <mat-option *ngFor="let state of mState" [value]="state.name">{{ state.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="addressForm.get('state')?.invalid">
              <span *ngIf="addressForm.get('state')?.errors?.required">*State is required.</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Pin Code</mat-label>
            <input matInput #postalCode maxlength="6" type="text" formControlName="zipcode">
            <mat-hint align="end">{{ postalCode.value.length }} / 6</mat-hint>
            <mat-error *ngIf="addressForm.get('zipcode')?.invalid">
              <span *ngIf="addressForm.get('zipcode')?.errors?.required">*Pin Code is required.</span>
              <span *ngIf="addressForm.get('zipcode')?.errors?.minlength">*Must be 6 digits.</span>
              <span *ngIf="addressForm.get('zipcode')?.errors?.pattern">*Only numbers allowed.</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="justify-content-center d-flex gap-2">
        <button mat-raised-button color="accent" type="button" (click)="onCancel()">Cancel</button>
        <button mat-raised-button color="primary" type="submit" (click)="onSubmit()" [disabled]="addressForm.invalid">{{ data ? 'Update' : 'Submit' }}</button>
      </div>
    </mat-card>
  </form>
</div>
