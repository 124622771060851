

<!--<app-mis ></app-mis>-->
<ng-container *ngIf="!isExternal">
  <app-mis2></app-mis2>


</ng-container>
<ng-container *ngIf="isExternal">
  <app-mis-borivali></app-mis-borivali>

</ng-container>
<!--<app-financial-report-assessment-table></app-financial-report-assessment-table>-->


<!---->
