<h1 class="bg-warning text-center rounded">
  {{ isUpdateMode ? 'Update Servicing Details Form' : 'Add Servicing Details Form' }}
</h1>
<form [formGroup]="servicingForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <mat-form-field appearance="outline" class="col-md-4">
      <mat-label>Choose a date</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="dates">
      <mat-hint>dd/MM/yyyy</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="servicingForm.get('dates')?.invalid && servicingForm.get('dates')?.touched">
        Date is required.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-md-4">
      <mat-label>Paid Amount:</mat-label>
      <input matInput type="text" formControlName="paidAmount" />
      <mat-error *ngIf="servicingForm.get('paidAmount')?.invalid && servicingForm.get('paidAmount')?.touched">
        <ng-container *ngIf="servicingForm.get('paidAmount')?.errors?.required">Amount is required.</ng-container>
        <ng-container *ngIf="servicingForm.get('paidAmount')?.errors?.pattern">Invalid amount format.</ng-container>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-md-4">
      <mat-label>Current KM of Meter:</mat-label>
      <input matInput type="text" formControlName="meters" />
      <mat-error *ngIf="servicingForm.get('meters')?.invalid && servicingForm.get('meters')?.touched">
        KM is required and must be a number.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-md-4">
      <mat-label>Vehicle Number:</mat-label>
      <mat-select formControlName="vehicleNumber">
        <mat-option *ngFor="let model of vehicles" [value]="model.vehicleNumber">
          {{ model.vehicleNumber }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="servicingForm.get('vehicleNumber')?.invalid && servicingForm.get('vehicleNumber')?.touched">
        Vehicle Number is required.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-md-4">
      <mat-label>Driver Name:</mat-label>
      <mat-select formControlName="driverName">
        <mat-option *ngFor="let model of drivers" [value]="model.firstName + ' ' + model.lastName">
          {{ model.firstName }} {{ model.lastName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="servicingForm.get('driverName')?.invalid && servicingForm.get('driverName')?.touched">
        Driver Name is required.
      </mat-error>
    </mat-form-field>
  </div>
  <div class="d-flex justify-content-center gap-3">
    <button mat-raised-button color="accent" class="mb-4" type="button" (click)="onCancel()">Cancel</button>
    <button mat-raised-button color="primary" class="mb-4" type="submit" [disabled]="servicingForm.invalid">
      {{ isUpdateMode ? 'Update' : 'Submit' }}
    </button>
  </div>
</form>
