import {Component, OnInit, ViewChild} from '@angular/core';
import {AddVehiclesComponent, Vehicle} from "../add-vehicles/add-vehicles.component";
import {VehicleService} from "../vehicle.service";
import {MatDialog} from "@angular/material/dialog";
import {UpdateVehiclesComponent} from "../update-vehicles/update-vehicles.component";
import {UserRole} from "../../rename/add-user-role/add-user-role.component";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {ViewCarLogBookComponent} from "../view-car-log-book/view-car-log-book.component";
import {TripData} from "../car-log-book/car-log-book.component";
import {ViewVehicleComponent} from "../view-vehicle/view-vehicle.component";
import {MatTableDataSource} from "@angular/material/table";
import {LoadingService} from "../../../services/loading.service";

@Component({
  selector: 'app-vehicle-table',
  templateUrl: './vehicle-table.component.html',
  styleUrls: ['./vehicle-table.component.scss']
})
export class VehicleTableComponent implements OnInit {
  dataSource: MatTableDataSource<Vehicle>
  displayedColumns: string[] = ['vehicleName', 'vehicleNumber','tripStatus', 'vehicleBrand','location', 'action'];
  permissions = JSON.parse(localStorage.getItem('permissions')!) as UserRole[]
  vehicle=this.permissions.filter((x)=>x.functionName=='Vehicles')
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(private mService:VehicleService,
              private loadingService: LoadingService,
              private mDialog: MatDialog) {
this.dataSource =new MatTableDataSource<Vehicle>()
  }

  ngOnInit(): void {
    this.loadingService.setLoading(true);
    this.mService.getVehicles().subscribe(res =>{
      this.dataSource =new MatTableDataSource<Vehicle>(res)
      this.dataSource.paginator =this.paginator
      this.dataSource.sort =this.sort
      this.loadingService.setLoading(false);

    })
  }
  editVehicle(element:any) {
    const dialogRef = this.mDialog.open(UpdateVehiclesComponent, {
      width: '1000px',
      maxHeight: '90vh',
      data: element
    });

  }
  openVehicle() {
    const dialogRef = this.mDialog.open(AddVehiclesComponent, {
      width: '1000px',
      maxHeight: '90vh', // Adjust the value based on your requirements
      panelClass: 'custom-dialog-container'
    });


  }
  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    // @ts-ignore
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  deleteVehicle(element:any) {
    this.mService.deleteVehicle(element.id).then(res => {
      // alert("data deleted successfully")
    })

  }

  openView(element:any) {
    this.mDialog.open(ViewVehicleComponent, {
      data:element,
      maxHeight: '100vh',
      width: '100%'
    })

  }
}
