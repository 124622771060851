import {Injectable, isDevMode} from '@angular/core';
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {environment} from "../../environments/environment";
import {Employee} from "../interfaces/Employee";
import {AngularFireFunctions} from "@angular/fire/compat/functions";
import {TaskEmployeeModel} from "../modules/task/models/taskemployee.model";
import {map} from "rxjs/operators";
import {filter} from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class EmployeeService {
  env= isDevMode()? environment.testMode :environment.productionMode
  constructor(private mDatabase: AngularFireDatabase,
              private functions: AngularFireFunctions) { }
  createUser(email: string, password: string): Promise<any> {
    const callable = this.functions.httpsCallable('creatUserFunctionEmail');
    return callable({ email: email, password: password }).toPromise();
  }

  addEmployee(Employeedata: any, uid:string) {
  return   this.mDatabase.object(this.env.users+`/${uid}`).set(Employeedata)
  // return   this.mDatabase.object(`testingEmails/${uid}`).set(Employeedata)
  }

  getEmployee() {
    return this.mDatabase.list<Employee>(this.env.employee).snapshotChanges()
  }
  getUsers(){
    return this.mDatabase.list<Employee>('users').snapshotChanges()
  }
  getTestUserData() {
    return this.mDatabase.list<Employee>('TestUsers').snapshotChanges()
  }
getCounsellers(){
return  this.mDatabase.list<Employee>(this.env.users, ref =>
    ref.orderByChild('role').equalTo('Counselling'))
    .valueChanges()
    .pipe(
      map(employees => employees.filter(employee => employee.disabled !== true))
    );

  }
  getTeachers(){
    return this.mDatabase.list<Employee>(this.env.users,ref => ref.orderByChild('jobtitle').equalTo('Teacher')).valueChanges().pipe(
      map(employees => employees.filter(employee => employee.disabled !== true))
    )
  }

  getCounsellersFromUsers(){
    return this.mDatabase.list<Employee>('users',ref => ref.orderByChild('role').equalTo('Psychologist')).valueChanges()
  }
  getEmployeeList() {
    const employeeRef = this.mDatabase.list<Employee>(this.env.employee)
    return employeeRef.valueChanges()
  }
  getTaskEmployee() {
    const employeeRef = this.mDatabase.list<TaskEmployeeModel>(this.env.users)
    return employeeRef.valueChanges()
  }

}
