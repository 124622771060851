import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ChildService} from "../child.service";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {Centre} from "../../../interfaces/Centres";
import {CentreService} from "../../../services/centre.service";
import {MAT_DATE_FORMATS} from "@angular/material/core";
import {MY_FORMATS} from "../../../utils/formats";
import {MatSelectChange} from "@angular/material/select";
import {FullChild} from "../../../interfaces/FullChild";

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
// tslint:disable-next-line:no-duplicate-imports
import firebase from "firebase/compat";
import Timestamp = firebase.firestore.Timestamp;
import {Hospitals} from "../../../interfaces/Hospitals";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {Observable} from "rxjs";
import {needConfirmation} from "../../../confirm-dialog/confirm-dialog.decorator";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ListType} from "../../types/types.component";
import {invalid} from "moment";
import {TitleCasePipe} from "@angular/common";
import {LogService} from "../../../services/log.service";
import {cloneDeep, isEqual} from "lodash";

@Component({
  selector: 'app-child-edit',
  templateUrl: './child-edit.component.html',
  styleUrls: ['./child-edit.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})

export class ChildEditComponent implements OnInit, AfterViewInit {
  childForm: any;
  submitted = false;
  iscentreselected = false
  centerSelected: string | undefined
  centrelist: Centre[] = []

    hospitals$: Observable<Hospitals[]>
   _diagnosis$: Observable<ListType[]>;
    prescreeningStatus$: Observable<ListType[]>;
  initialFormState: any; //?add this
  reasonOfClosure$: Observable<string[]>;
  userData = JSON.parse(localStorage.getItem('userData')!)
    constructor(public dialogRef: MatDialogRef<ChildEditComponent>,
                private childService: ChildService,
                private _snackBar: MatSnackBar,
                private centreService: CentreService,
                private readonly mDatabase: AngularFireDatabase,
                private mLogService:LogService,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private titlecasePipe: TitleCasePipe,
                @Inject(MAT_DIALOG_DATA) public index: FullChild,) {
        this.hospitals$ = this.mDatabase.list<Hospitals>('Hospitals').valueChanges()
      this._diagnosis$ = this.mDatabase.list<ListType>('types/Diagnosis').valueChanges()
      this.prescreeningStatus$ = this.mDatabase.list<ListType>('types/PrescreeningStatus').valueChanges()
      this.reasonOfClosure$ = this.mDatabase.list<string>('types/Reason Of Closure').valueChanges()

// alert(JSON.stringify(this.data))
    }


    ngOnInit(): void {

        this.centreService.getAllCentres().subscribe((res) => {
            this.centrelist = res as Centre[]
            // console.log('centredata', this.centrelist)
        })

        this.childForm = new FormGroup({
            // "centrecode": new FormControl('', [Validators.required, Validators.pattern('[0-9 ]*')]),
            // "childcode": new FormControl('', [Validators.required, Validators.pattern('[0-9 ]*')]),
            // "centreno": new FormControl('', [Validators.required, Validators.pattern('[0-9 ]*')]),
            // "regis": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
            "id": new FormControl(this.data.id, Validators.required),
            "firstName": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
            "lastName": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
            "city": new FormControl('', [Validators.required]),
            "middleName": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
            "age": new FormControl('', [Validators.required]),
            "mobile": new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(10), Validators.maxLength(10)]),
            "hospitalname": new FormControl('', [Validators.required]),
            "state": new FormControl('', [Validators.required]),
            "remark": new FormControl('', [Validators.required]),
            "diagnosis": new FormControl('', [Validators.required,]),
            "status": new FormControl('', [Validators.required,]),
            "gender": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
            "district": new FormControl('',),
            "dateOfClosure": new FormControl(null),
            "reasonOfClosure": new FormControl(''),
            "createdBy": new FormControl(this.data.createdBy ),
            "updatedBy": new FormControl(this.userData.userName ),
            q1: new FormControl(this.data.q1),
            q2: new FormControl(this.data.q2),
            q3: new FormControl(this.data.q3),
            q4: new FormControl(this.data.q4),
            q5: new FormControl(this.data.q5),
            q6: new FormControl(this.data.q6),
            q7: new FormControl(this.data.q7),
            q8: new FormControl(this.data.q8),
            q9: new FormControl(this.data.q9),
        })
      this.firstname.setValue(this.data.firstName)
    this.middleName.setValue(this.data.middleName)
    this.lastName.setValue(this.data.lastName)
    this.age.setValue(this.data.age)
    this.mobile.setValue(this.data.mobile)
    this.hospitalname.setValue(this.data.hospitalname)
    this.state.setValue(this.data.state)
    this.city.setValue(this.data.city)
    this.remark.setValue(this.data.remark)
    this.diagnosis.setValue(this.data.diagnosis)
    this.gender.setValue(this.data.gender)
    this.district.setValue(this.data.district)
    this.status.setValue(this.data.status)

      this.initialFormState = cloneDeep(this.childForm); //?add this


    }


    get firstname() {
        return this.childForm.get('firstName');
    }

    get status() {
        return this.childForm.get('status');
    }

    get gender() {
    return this.childForm.get('gender');
  }
  get hospitalname() {
    return this.childForm.get('hospitalname');
  }
  get age() {
    return this.childForm.get('age');
  }

    get lastName() {
        return this.childForm.get('lastName');
    }

    get middleName() {
        return this.childForm.get('middleName');
    }

  get district() {
    return this.childForm.get('district');
  }


    get mobile() {
        return this.childForm.get('mobile');
    }

    get city() {
        return this.childForm.get('city');
    }

    get remark() {
        return this.childForm.get('remark');
    }

    get country() {
        return this.childForm.get('country');
    }

    get state() {
        return this.childForm.get('state');
    }

    get pincode() {
        return this.childForm.get('pincode');
    }

    get diagnosis() {
        return this.childForm.get('diagnosis');
    }

    get District() {
        return this.childForm.get('district');
    }

    get Hobbies() {
        return this.childForm.get('hobbies');
    }


    get Allergies() {
        return this.childForm.get('allergies');
    }

    get Aspirations() {
        return this.childForm.get('aspirations');
    }

    get centres() {
        return this.childForm.get('centrelist');
    }

    ngAfterViewInit(): void {
        // this.childService.fetchPreScreeningChild().subscribe((snapshot) => {
        //   // @ts-ignore
        //   console.log('fetchChild: ', this.index)
        //
        // })

    }
  onBlur(formControl: any) {
    if(formControl.value !== null) {
      formControl.setValue(this.titlecasePipe.transform(formControl.value.toString().trim().toUpperCase()))
    }
  }
    @needConfirmation()
    Update() {
//first sumbit data
        this.submitted = true;
      const submittedFormState: any = this.childForm; //?add this
        //Ideal Code Should be like this
        if (this.childForm.valid) {
            console.log(this.childForm.value)
          // alert('FORM IS VALID')
            // then submit data to service
            this.childService.updatePrescreening(this.childForm.value)
            // then close dialog
          const differences = this.getFormDifference(this.initialFormState, submittedFormState)
            let activity={
                date:new Date().getTime(),
                section: 'Prescreening',
                action: 'Edit',
                changes: differences,  //?add this
                description: `Child Edited ${this.data.childName} ${this.data.id} by  `,
                currentIp: localStorage.getItem('currentip')!
            }
            this.mLogService.addLog(activity)
            this.dialogRef.close()
        } else if (!this.childForm.valid) {
            this.submitted = false;
            this._snackBar.open("Form is Invalid , Please fill  form properly!", '', {duration: 3000})
          console.log(  this.findInvalidControls())
        }
    }
  getFormDifference(initialFormState: any, submittedFormState: any) {
    const differences: any = {};

    for (const key in this.initialFormState.controls) {
      const initialControl = this.initialFormState.get(key);
      const submittedControl = submittedFormState.get(key);
      console.log(`Initial Value: ${initialControl.value}`);
      console.log(`Submitted Value: ${submittedControl.value}`);
      if (initialControl instanceof FormArray && submittedControl instanceof FormArray) {

        // Handle FormArray fields
        for (let i = 0; i < initialControl.length; i++) {
          if (!isEqual(initialControl.at(i).value, submittedControl.at(i).value)) {

            if (!differences[key]) {

              differences[key] = [];
            }
            alert('differnce found')

            differences[key].push({
              index: i,
              initialValue: initialControl.at(i).value,
              submittedValue: submittedControl.at(i).value,
            });
          }
        }
      } else if (!isEqual(initialControl.value, submittedControl.value)) {
        // Handle regular FormControl fields
        alert('differnce found')
        alert(`${initialControl.value}=> ${submittedControl.value}`)
        differences[key] = {
          initialValue: initialControl.value,
          submittedValue: submittedControl.value,
        };
      }

    }
    return differences
  }

    cancel() {
        this.dialogRef.close()
        this.submitted = false;
        console.log(this.childForm.reset())
    }

    // @ts-ignore
    changeWebsite($event: MatSelectChange) {
        console.log('change event called')
        console.log('dsd', $event.value)
        this.iscentreselected = true
        this.centerSelected = $event.value

    }

    get Pincode() {
        return this.childForm.get('pincode');
    }

    public findInvalidControls() {
        const invalid = [];
        const controls = this.childForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        return invalid;
    }

  protected readonly invalid = invalid;

  get reasonOfClosure() {
    return this.childForm.get('reasonOfClosure');
  }
  get dateOfClosure() {
    return this.childForm.get('dateOfClosure');
  }
}
