
import {Injectable, isDevMode} from '@angular/core';
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {Centre} from "../interfaces/Centres";
import {FormGroup, ɵFormGroupValue, ɵTypedOrUntyped} from "@angular/forms";
import {AngularFirestore, AngularFirestoreDocument} from "@angular/fire/compat/firestore";
import {doc, docData, Firestore, increment, updateDoc} from "@angular/fire/firestore"
import {FullChild} from "../interfaces/FullChild";
import {MatSnackBar} from "@angular/material/snack-bar";
import firebase from "firebase/compat/app";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class UnitService {
    env = isDevMode() ? environment.testMode : environment.productionMode

    constructor(private mDatabase: AngularFireDatabase,
                private mFirestore: AngularFirestore,
                private firestore: Firestore,
                private _snackBar: MatSnackBar) {


    }


    //? To Get Units
    getUnit(centre: string) {
        console.log('getUni', this.env.units)
        return this.mDatabase.list(`${this.env.units}/${centre}`, ref => ref.orderByChild('order')).valueChanges()
    }

//? For Unit Allotement
    allotUnit(childCode: string, centreCode: string, unitCode: string, childname: string) {
        console.log('IN Unit Service', `${childCode},${centreCode},${unitCode}`)
        console.log('IN Unit Service', `${this.env.units}`)
        return this.mDatabase.object(`${this.env.units}/${centreCode}/${unitCode}`).update({
            'allotedTo': childCode,
            'name': childname,
            'status': "Alloted"
        })
    }

//? To allot floor to unit
    allotFloor(selectedCentre: Centre, unit: string, floorselected: string | undefined) {
        console.log('Allot Floor', `${selectedCentre?.centreCode} ${unit} ${floorselected}`)
        return this.mDatabase.object(`${this.env.units}/${selectedCentre.centreCode}/${unit}`).update({
            'floorNo': floorselected
            // 'name': childname,
            // 'status': "Alloted"
        })
    }

    dischargeProcess(dischargeForm: any, child: FullChild, setReminder: boolean) {
        // change unit status
        //change child status
        // change center available and occupied status
        // set followup reminder

        // return this.freeUnit(selectedCentreCode, unit, childCode,dischargeForm)
        return this.dischargeBatch(dischargeForm, child, setReminder)
    }


    private freeUnit(selectedCentreCode: string, unit: string, childCode: string, data: FullChild, child: FullChild) {
        // ? Updating Unit Details
        return this.mDatabase.object(`${this.env.units}/${selectedCentreCode}/${unit}`).update({
            allotedTo: 'NA',
            allotedBefore: childCode,
            name: '',
            status: 'Available'
        })
            .then(() => {
                // ? Updating Child Details
                this.mFirestore.collection(this.env.child).doc(childCode).update({
                    status: 'Discharged',
                    selectUnit: `Discharged From ${unit}`,
                    dischargeDate: data?.dischargeDate,
                    isAlloted: false,
                    followUpDate: data?.followUpDate,
                    reason: data?.reason,
                    remark: data?.remark
                })
            }).then(async () => {
                // ? Updating Centre
                const ref = doc(this.firestore, `${this.env.centres}/${selectedCentreCode}`)
                await updateDoc(ref, {
                    availableUnits: increment(1),
                    occupiedUnits: increment(-1),
                })
            })
            .then(() => {
                const map = new Map<String, any>()
                map.set(data.childCode, {
                    followUpDate: data?.followUpDate,
                    childCode: data?.childCode,
                    centre: data?.centreCode,
                    firstName: data?.firstName,
                    lastName: data?.lastName
                })
                const _childCode = data?.childCode
                const _centreCode = data?.centreCode
                const _firstName = data?.firstName
                const _lastName = data?.lastName
                const _followUpDate = data?.followUpDate
                const _remark = data?.remark
                const _updateData = {
                    _childCode: {
                        'centreCode': _centreCode,
                        'firstName': _firstName,
                        'lastName': _lastName,
                        'childCode': _childCode,
                        'followUpDate': _followUpDate,
                        weight: data?.weight,
                        height: data?.height,
                        'remark': _remark
                    }
                }
                // this.mFirestore.firestore.collection('ffddfs').doc('fdfd').update(map)
                this.mFirestore.firestore.collection(this.env.reminders).doc('followUpChildren').update(
                    _updateData
                )
                    .then(r => {
                        this._snackBar.open('Followup Added to Reminder', '', {duration: 4000})
                    })
            });

    }

    private dischargeBatch(dischargeForm: any, child: FullChild, setReminder: boolean) {
        const batch = this.mFirestore.firestore.batch()
        console.log('CHILD', child)
        // ? Updating Unit Details
        // ? Updating Child Details
        const updateChildDetailsRef = this.mFirestore.firestore.doc(`${this.env.child}/${child.childCode}`)
        const dischargeArrayObject = {
            centreCode: child?.centreCode,
            currentUnit: child?.selectUnit,
            dateOfDischarge: dischargeForm?.dischargeDate,
            weight: dischargeForm?.weight,
            height: dischargeForm?.height,
            type: 'Discharge',
            reason: dischargeForm?.reason,

            //centerName: data?.centreName
        }

        const updateChildData = {
            status: 'Discharged',
            selectUnit: `Discharged From ${child.selectUnit}`,
            dischargeDate: dischargeForm?.dischargeDate,
            isAlloted: false,
            followUpDate: dischargeForm?.followUpDate,
            reason: dischargeForm?.reason,
            remark: dischargeForm?.remark,
            centreCode: child?.centreCode,
            centreName: child?.centreName,
            admissionData: firebase.firestore.FieldValue.arrayUnion(dischargeArrayObject)
        }

        // ?  To Update Centre
        const updateCentreCount = this.mFirestore.firestore.doc(`${this.env.centres}/${child.centreCode}`)

        const _childCode = child?.childCode
        const _centreCode = child?.centreCode
        const _firstName = child?.firstName
        const _lastName = child?.lastName
        const _followUpDate = dischargeForm?.followUpDate
        const _remark = dischargeForm?.remark
        console.log('data', dischargeForm)
        console.log('_childCode', _childCode)
        console.log('_centreCode', _centreCode)
        console.log('_firstName', _firstName)
        console.log('_lastName', _lastName)
        console.log('_followUpDate', _followUpDate)
        console.log('_remark', _remark)

        const _updateDataReminderData = {
            'childName': _firstName + ' ' + _lastName,
            'centreName': child?.centreName,
            'centreCode': _centreCode,
            'childCode': _childCode,
            'followUpDate': _followUpDate,
            'remark': _remark,

        }
        const _updateReminderNode = `remindersdata.` + _childCode
        // this.mFirestore.firestore.collection('ffddfs').doc('fdfd').update(map)
// ?Reminder Reference
        const setReminderRef = this.mFirestore.firestore.collection(this.env.reminders).doc('followUpChildren')

        //? Loge before operation

        console.log('dischargeArrayObject', dischargeArrayObject)
        console.log('updateCentreCount', updateCentreCount)
        console.log('updateChildData', updateChildData)

        // ? To update child data
        batch.update(updateChildDetailsRef, updateChildData)

        //? update chilren count in centre
        batch.update(updateCentreCount, {
            availableUnits: increment(1),
            occupiedUnits: increment(-1),
        })

        if (setReminder) {
            batch.update(setReminderRef, {
                [_updateReminderNode]: _updateDataReminderData
            })
        }

        // console.log('updateChildData',updateChildData)
        return batch.commit().then(() => {
            this.mDatabase.object(`${this.env.units}/${child?.centreCode}/${child?.selectUnit}`).update({
                allotedTo: 'NA',
                // allotedBefore: childCode,
                name: '',
                status: 'Available'

            }).then(() => console.log('Unit discharged!')).catch((reason) => console.log(reason))

        })

    }
//? To update reminder in folllow up reminder section
    updateReminder(value: any) {
      const _updateReminderNode = `remindersdata.` +value.childCode
      return  this.mFirestore.firestore.collection(this.env.reminders).doc('followUpChildren')
          .update({ [_updateReminderNode]:value})

    }
    deleteReminder(key: string) {
        const _updateReminderNode = `remindersdata.` +key

        return  this.mFirestore.firestore.collection(this.env.reminders).doc('followUpChildren')
            .update({

                [_updateReminderNode]: firebase.firestore.FieldValue.delete()
            })


    }

    reAdmissionProcess(selectedCentre: Centre | undefined | null, selectUnit: string, childCode: string, data: ɵTypedOrUntyped<any, ɵFormGroupValue<any>, any>, child: FullChild) {
        console.log(child)
        console.log('selectUni in readmission', selectUnit)
        const batch = this.mFirestore.firestore.batch()
        console.log('Selected Centre During ReAdmission', selectedCentre)
        // ? Updating Unit Details
        // ? Updating Child Details
        const updateChildDetailsRef = this.mFirestore.firestore.doc(`${this.env.child}/${childCode}`)
        const admissionData = {
            centreCode: selectedCentre?.centreCode,
            currentUnit: selectUnit,
            dateOfAdmission: data?.dateOfAdmission,
            height: data?.height,
            weight: data?.weight,
            tentativeDischargeDate: data?.tentativeDischargeDate,
            type: 'FollowUp In',
            //centerName: data?.centreName
        }
        const updateChildData = {
            status: 'Alloted',
            isAlloted: true,
            selectUnit: selectUnit,
            centreCode: selectedCentre?.centreCode,
            centreName: selectedCentre?.centreName,
            // dischargeDate: data?.dischargeDate,
            // followUpDate: data?.followUpDate,
            // reason: data?.reason,
            // remark: data?.remark,
            admissionData: firebase.firestore.FieldValue.arrayUnion(admissionData)
        }

        // ? Updating Centre
        const updateCentreCount = this.mFirestore.firestore.doc(`${this.env.centres}/${selectedCentre?.centreCode}`)
        const _childCode = child?.childCode
        const _centreCode = child?.centreCode
        const _firstName = child?.firstName
        const _lastName = child?.lastName
        // const _followUpDate = data?.followUpDate
        const _remark = data?.remark
        console.log('data', data)
        console.log('_childCode', _childCode)
        console.log('_centreCode', _centreCode)
        console.log('_firstName', _firstName)
        console.log('_lastName', _lastName)
        // console.log('_followUpDate',_followUpDate )
        console.log('_remark', _remark)


        // this.mFirestore.firestore.collection('ffddfs').doc('fdfd').update(map)
// ?Reminder Reference (No reminder on admission)
        const setReminder = this.mFirestore.firestore.collection(this.env.reminders).doc('followUpChildren')

        // ? To update child data
        batch.update(updateChildDetailsRef, updateChildData)
        batch.update(updateCentreCount, {
            availableUnits: increment(-1),
            occupiedUnits: increment(1),
        })
        // batch.update(setReminder, {
        //   [_updateReminderNode]: _updateDataReminder
        // })

        return batch.commit().then(() => {
            this.mDatabase.object(`${this.env.units}/${selectedCentre?.centreCode}/${selectUnit}`).update({
                allotedTo: childCode,
                // allotedBefore: childCode,
                name: `${child.firstName} ${child.lastName}`,
                status: 'Alloted'

            })

        })


    }
//? Testing fieldvalue delete operation in firesotre
    testDelete(){
        let key= 'map.person2'
        this.mFirestore.collection('MAPTEST').doc('test').update({
[key]:firebase.firestore.FieldValue.delete()
        })
}

    swapUnits1(selectedCentre: Centre, fromChild: any, toChild: any, mChildAData: FullChild, mChildBData: FullChild) {
     return    this.mDatabase.database.ref('/').transaction(ref => {
            const a = {
                allotedTo:fromChild.allotedTo,
                name:fromChild.name
            }
            const b = {
                allotedTo:toChild.allotedTo,
                name:toChild.name
            }
         console.log('a',a)
         console.log('b',b)
         console.log('fromChild',fromChild.key)
         console.log('toChild',toChild.key)
         console.log('ref',this.env.units+`/${selectedCentre.centreCode}/${toChild.key}`)
         console.log('ref',this.env.units+`/${selectedCentre.centreCode}/${fromChild.key}`)
            ref.child(this.env.units+`/${selectedCentre.centreCode}/${toChild.key}`).update(a);
            ref.child(this.env.units+`/${selectedCentre.centreCode}/${fromChild.key}`).update(b);

            // ref.child(this.env.units).set('New value for childBData');
            // ... Perform further updates here
        }).then(res=>{
     return   this.swapCentres(mChildBData,mChildAData)

     })


    }
    swapUnits(selectedCentre: Centre, fromChild: any, toChild: any, mChildAData: FullChild, mChildBData: FullChild) {
        // Create objects to hold the data for updates
        console.log(`child A1 ${mChildAData.childCode} `,mChildAData.admissionData)
        console.log(`child B1 ${mChildBData.childCode}`,mChildBData.admissionData)

        const updates: any = {};

        // Define the data to be updated for fromChild and toChild
        const fromChildUpdate = {
            allotedTo: fromChild.allotedTo,
            name: fromChild.name
        };

        const toChildUpdate = {
            allotedTo: toChild.allotedTo,
            name: toChild.name
        };

        // Construct the paths for the database updates
        const fromChildPathAllotedTo = `${this.env.units}/${selectedCentre.centreCode}/${fromChild.key}/allotedTo`;
        const fromChildPathName = `${this.env.units}/${selectedCentre.centreCode}/${fromChild.key}/name`;
        const toChildPathAllotedTo = `${this.env.units}/${selectedCentre.centreCode}/${toChild.key}/allotedTo`;
        const toChildPathName = `${this.env.units}/${selectedCentre.centreCode}/${toChild.key}/name`;

        // Set the updates for each child in the updates object
        updates[fromChildPathAllotedTo] = toChildUpdate.allotedTo;
        updates[fromChildPathName] = toChildUpdate.name;
        updates[toChildPathAllotedTo] = fromChildUpdate.allotedTo;
        updates[toChildPathName] = fromChildUpdate.name;


        // Perform the multi-location update
        return this.mDatabase.database.ref().update(updates)
            .then(() => {
                // Multi-location update succeeded
                console.log('Simultaneous updates completed successfully');
                // Now you can call the swapCentres method
                return this.swapCentres(mChildAData, mChildBData);
            })
            .catch(error => {
                // Multi-location update failed
                console.error('Simultaneous updates failed:', error);
                throw error; // Rethrow the error to propagate it further
            });
    }

    private swapCentres1(mChildAData: FullChild, mChildBData: FullChild) {
     const childALastAdmission= mChildAData.admissionData?.[mChildAData.admissionData.length-1]
     const childBLastAdmission= mChildBData.admissionData?.[mChildBData.admissionData.length-1]
        const childAIndex = mChildAData.admissionData?.length-1
        const childBIndex = mChildBData.admissionData?.length-1
        const childACurrenUnit =mChildAData.admissionData[childAIndex].currentUnit
        const childBCurrenUnit =mChildBData.admissionData[childBIndex].currentUnit
        const childAAdmissionData =`admissionData.${childAIndex}`
        const childBAdmissionData =`admissionData.${childBIndex}`
        // const admiA =child
        // data.admissionData[data.admissionData.length - 1][fieldToUpdate] = newValue;

        this.mFirestore.collection(this.env.child).doc(mChildAData.childCode).update({selectUnit:mChildBData.selectUnit})
        this.mFirestore.collection(this.env.child).doc(mChildBData.childCode).update({selectUnit:mChildAData.selectUnit})
        this.mFirestore.collection(this.env.child).doc(mChildAData.childCode).update({[childAAdmissionData]:childBCurrenUnit})
        this.mFirestore.collection(this.env.child).doc(mChildBData.childCode).update({[childBAdmissionData]:childACurrenUnit})

    }
    private async swapCentres2(mChildAData: FullChild, mChildBData: FullChild) {
        const batch = this.mFirestore.firestore.batch();

        console.log('INTO SWAP CENTRES')
        // 1. Extract data safely and concisely using optional chaining:
     try {
            // const childAData = mChildAData?.admissionData?.[mChildAData.admissionData.length - 1];
            // const childBData = mChildBData?.admissionData?.[mChildBData.admissionData.length - 1];
const childA=mChildAData
const childB=mChildBData
            // 2. Handle missing data gracefully:
            // if (!childAData || !childBData) {
            //     console.error('Error: Missing data for swapping centers.');
            //     return; // Or handle the missing data scenario as needed
            // }

            // 3. Prepare updates using destructuring and template literals:
            const childAIndex = mChildAData.admissionData.length - 1;
            const childBIndex = mChildBData.admissionData.length - 1;
         console.log(`child A2 ${childA.childCode} `,childA.admissionData)
         console.log(`child B2 ${childB.childCode}`,childB.admissionData)



            const updatesForA = childB
            updatesForA.selectUnit = childB.selectUnit
            updatesForA.admissionData[childAIndex].currentUnit = childB.admissionData[childBIndex].currentUnit
         console.log('Updates for A ',updatesForA.admissionData)



            const updatesForB = childA
            updatesForB.selectUnit = childA.selectUnit
            updatesForB.admissionData[childBIndex].currentUnit = childA.admissionData[childAIndex].currentUnit
         //
         console.log('Updates for B ',updatesForB.admissionData)

            // 4. Optionally use a batch write for efficiency (if available):
            if (batch) {

                console.log('into if')
              try {
                  // console.log(updatesForA)
                  // console.log(updatesForB)

                  await this.mFirestore.collection(this.env.child).doc(mChildAData.childCode).update(updatesForA);
                  await this.mFirestore.collection(this.env.child).doc(mChildBData.childCode).update(updatesForB);
              }
              catch (e:any){
                  console.log(e)

              }

                // batch.set(this.mFirestore.firestore.collection(this.env.child).doc(mChildAData.childCode), updatesForA,{merge:true});
                // batch.set(this.mFirestore.firestore.collection(this.env.child).doc(mChildBData.childCode), updatesForB,{merge:true});
                await batch.commit()

                    .then(res=>{
                    console.log('operation successful')
                })
                    .catch(e=>{
                        console.log('error swapping',e)
                    }); // Handle potential errors here
            } else {
                console.log('into else')
                // 5. Fallback to individual updates:
                // await this.mFirestore.collection(this.env.child).doc(mChildAData.childCode).update(updatesForA);
                // await this.mFirestore.collection(this.env.child).doc(mChildBData.childCode).update(updatesForB);
            }
        }catch (e:any){
         console.log(e)
     }
    }
    private async swapCentres(mChildAData: FullChild, mChildBData: FullChild) {
        const batch = this.mFirestore.firestore.batch();
        console.log('swapCentres Called')

        console.log('Swapping centers...');

        try {
            // Safely access child data
            const childA = mChildAData
            const childB = mChildBData

            if (!childA || !childB) {
                console.error('Error: Missing data for swapping centers.');
                throw new Error('Missing child data'); // Or handle missing data as needed
            }

            // Prepare updates
            const childAIndex = mChildAData.admissionData.length - 1;
            const childBIndex = mChildBData.admissionData.length - 1;
            console.log('childAIndex', childAIndex)
            console.log('childBIndex', childBIndex)

            const updatesForA = {
                selectUnit: childB.selectUnit,
                admissionData:
                    [
                        ...mChildAData.admissionData.slice(0, childAIndex),

                      {
                            ...
                                childA.admissionData[childAIndex],
                            currentUnit: childB.admissionData[childBIndex].currentUnit
                        }
                    ]
        }
            console.log('updatesForA', updatesForA)
            const updatesForB = {
                selectUnit: childA.selectUnit,
                admissionData: [
                    ...mChildBData.admissionData.slice(0, childAIndex),

                    {
                        ...childB.admissionData[childBIndex],
                        currentUnit: childA.admissionData[childAIndex].currentUnit
                    }
                ]
            }
            console.log('updatesForB', updatesForB)

            // Use batch write for efficiency (if available)
            if (batch) {
                console.log('into batch operation')
                // const a = this.mFirestore.collection(this.env.child).doc(childA.childCode).ref
                await batch.set(this.mFirestore.collection(this.env.child).doc(childA.childCode).ref, updatesForA, {merge: true});

                await batch.set(this.mFirestore.collection(this.env.child).doc(childB.childCode).ref, updatesForB, {merge: true});
                try {
                    await batch.commit();
                } catch (e) {
                    console.log('ERROR IN BATCH', e)
                }
                console.log('Swapping centers successful:', childA.childCode, childB.childCode);

            } else {
                console.log('Batch write unavailable, using individual updates');
                // await this.mFirestore.collection(this.env.child).doc(childA.childCode).update(updatesForA);
                // await this.mFirestore.collection(this.env.child).doc(childB.childCode).update(updatesForB);
            }
        } catch (error) {
            console.error('Error swapping centers:', error);
        }
    }

    transferToEmptyUnit(fromChild: any, toChild: any, mChildDataA: FullChild) {
        console.log('in transferToEmptyUnit')
        console.log('from Child',fromChild)
        console.log('to Child',toChild )
        console.log('mChildDataA',mChildDataA)
        console.log(`child A1 ${mChildDataA.childCode} `,mChildDataA.admissionData)
        // console.log(`child B1 ${mChildBData.childCode}`,mChildBData.admissionData)

        const updates: any = {};

        // Define the data to be updated for fromChild and toChild
        const fromChildUpdate = {
            allotedTo: fromChild.allotedTo,
            name: fromChild.name,
            status:fromChild.status
        };

        const toChildUpdate = {
            allotedTo: toChild.allotedTo,
            name: toChild.name,
            status:toChild.status

        };

        // Construct the paths for the database updates
        const fromChildPathAllotedTo = `${this.env.units}/${fromChild.centreCode}/${fromChild.key}/allotedTo`;
        const fromChildPathName = `${this.env.units}/${fromChild.centreCode}/${fromChild.key}/name`;
        const fromChildPathStatus = `${this.env.units}/${fromChild.centreCode}/${fromChild.key}/status`;
        const toChildPathAllotedTo = `${this.env.units}/${fromChild.centreCode}/${toChild.key}/allotedTo`;
        const toChildPathName = `${this.env.units}/${fromChild.centreCode}/${toChild.key}/name`;
        const toChildPathStatus = `${this.env.units}/${fromChild.centreCode}/${toChild.key}/status`;

        // Set the updates for each child in the updates object
        updates[fromChildPathAllotedTo] = toChildUpdate.allotedTo;
        updates[fromChildPathName] = toChildUpdate.name;
        updates[fromChildPathStatus] = toChildUpdate.status;
        updates[toChildPathAllotedTo] = fromChildUpdate.allotedTo;
        updates[toChildPathName] = fromChildUpdate.name;
        updates[toChildPathStatus] = fromChildUpdate.status;



        // Perform the multi-location update





        return this.mDatabase.database.ref().update(updates)
            .then(() => {
                // Multi-location update succeeded
                console.log('Simultaneous updates completed successfully');
                // Now you can call the swapCentres method
                // return this.swapCentres(mChildAData, mChildBData);

                const batch = this.mFirestore.firestore.batch();
                const childAIndex = mChildDataA.admissionData.length - 1;

                const updatesForA = {
                    selectUnit: toChild.key,
                    admissionData: [
                        ...mChildDataA.admissionData.slice(0, childAIndex),
                        {
                            ...mChildDataA.admissionData[childAIndex],
                            currentUnit: toChild.key
                        },
                        ...mChildDataA.admissionData.slice(childAIndex + 1)
                    ]
                };
                batch.set(this.mFirestore.collection(this.env.child).doc(mChildDataA.childCode).ref, updatesForA, {merge: true});
              return  batch.commit()
            })
            .catch(error => {
                // Multi-location update failed
                console.error('Simultaneous updates failed:', error);
                throw error; // Rethrow the error to propagate it further
            });



    }

    centreTransfer(fromChild: any, toChild: any, mChildAData: FullChild, mChildBData: FullChild) {
        console.log('childTransferCalled')
        // Create objects to hold the data for updates
        console.log(`child A1 ${mChildAData.childCode} `,mChildAData.admissionData)
        console.log(`child B1 ${mChildBData.childCode}`,mChildBData.admissionData)

        const updates: any = {};
const centreCodeA=fromChild.centreCode;
const centreCodeB=toChild.centreCode;
        // Define the data to be updated for fromChild and toChild
        const fromChildUpdate = {
            allotedTo: fromChild.allotedTo,
            name: fromChild.name
        };

        const toChildUpdate = {
            allotedTo: toChild.allotedTo,
            name: toChild.name
        };
        console.log('FROMCHILD',fromChildUpdate)
        console.log('TOCHILD',toChildUpdate)
        // Construct the paths for the database updates
        const fromChildPathAllotedTo = `${this.env.units}/${fromChild.centreCode}/${fromChild.key}/allotedTo`;
        const fromChildPathName = `${this.env.units}/${fromChild.centreCode}/${fromChild.key}/name`;

        const toChildPathAllotedTo = `${this.env.units}/${toChild.centreCode}/${toChild.key}/allotedTo`;
        const toChildPathName = `${this.env.units}/${toChild.centreCode}/${toChild.key}/name`;

        // Set the updates for each child in the updates object
        updates[fromChildPathAllotedTo] = toChildUpdate.allotedTo;
        updates[fromChildPathName] = toChildUpdate.name;
        // Set the updates for each child in the updates object
        updates[toChildPathAllotedTo] = fromChildUpdate.allotedTo;
        updates[toChildPathName] = fromChildUpdate.name;


        // Perform the multi-location update
        return this.mDatabase.database.ref().update(updates)
            .then(() => {
                // Multi-location update succeeded
                console.log('Simultaneous updates completed successfully');
                // Now you can call the swapCentres method
                 return this.swapInterCentres(mChildAData, mChildBData);
            })
            .catch(error => {
                // Multi-location update failed
                console.error('Simultaneous updates failed:', error);
                throw error; // Rethrow the error to propagate it further
            });
    }
    private async swapInterCentres(mChildAData: FullChild, mChildBData: FullChild) {
        const batch = this.mFirestore.firestore.batch();

        console.log('Swapping centers...');

        try {
            // Safely access child data
            const childA = mChildAData
            const childB = mChildBData

            if (!childA || !childB) {
                console.error('Error: Missing data for swapping centers.');
                throw new Error('Missing child data'); // Or handle missing data as needed
            }
            // Prepare updates
            const childAIndex = mChildAData.admissionData.length - 1;
            const childBIndex = mChildBData.admissionData.length - 1;
            console.log('childAIndex', childAIndex)
            console.log('childBIndex', childBIndex)

            const updatesForA = {
                 selectUnit: childB.selectUnit,
                centreCode: childB.centreCode,
                centreName: childB.centreName,
                admissionData:
                    [
                        ...mChildAData.admissionData.slice(0, childAIndex),

                        {
                            ...
                                childA.admissionData[childAIndex],
                            currentUnit: childB.selectUnit,
                            centreCode: childB.centreCode,
                            centreName: childB.centreName,
                        }
                    ]
            }
            console.log('updatesForA', updatesForA)
            const updatesForB = {
                 selectUnit: childA.selectUnit,
                centreCode: childA.centreCode,
                centreName: childA.centreName,
                admissionData: [
                    ...mChildBData.admissionData.slice(0, childAIndex),
                    {
                        ...childB.admissionData[childBIndex],
                         currentUnit:childA.selectUnit,
                        centreCode: childA.centreCode,
                        centreName: childA.centreName,
                    }
                    ]
            }
            console.log('updatesForB', updatesForB)

            // Use batch write for efficiency (if available)
            if (batch) {
                console.log('into batch operation')
                // const a = this.mFirestore.collection(this.env.child).doc(childA.childCode).ref

                try {
                    await batch.set(this.mFirestore.collection(this.env.child).doc(childA.childCode).ref, updatesForA, {merge: true});

                    await batch.set(this.mFirestore.collection(this.env.child).doc(childB.childCode).ref, updatesForB, {merge: true});

                    await batch.commit();
                    console.log('Swapping centers successful:', childA.childCode, childB.childCode);

                } catch (e) {
                    console.log('ERROR IN BATCH', e)
                }

            } else {
                console.log('Batch write unavailable, using individual updates');
                // await this.mFirestore.collection(this.env.child).doc(childA.childCode).update(updatesForA);
                // await this.mFirestore.collection(this.env.child).doc(childB.childCode).update(updatesForB);
            }
        } catch (error) {
            console.error('Error swapping centers:', error);
        }
    }
}
