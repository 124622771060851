<!-- trip-view.component.html -->

<div class="container mt-4 w-100 border-1">
  <h1 class="bg-warning text-center mb-4 mt-2">Car Servicing Details</h1>

  <table class="table w-100">
    <tbody>

    <tr>
      <th>Driver Name</th>
      <td>{{ Data.driverName}}</td>
    </tr>
    <!-- Vehicle Number -->
    <tr>
      <th>Vehicle Number</th>
      <td>{{ Data.vehicleNumber }}</td>
    </tr>

    <tr>
      <th>Date Of Filling</th>
      <td>{{ Data.dates}}</td>
    </tr>
    <tr>
      <th>Date Of Entry</th>
      <td>{{ displayDate(Data.createdAt) }}</td>
    </tr>
    <!-- Purpose of Trip -->
    <tr>
      <th>Paid Ammount</th>
      <td>{{ Data.paidAmount }}</td>
    </tr>
    <tr>
      <th>Meter reading</th>
      <td>{{ Data.meters }}</td>
    </tr>
    <tr>
      <th>Remark</th>
      <td>{{ Data.remarks }}</td>
    </tr>


    </tbody>
  </table>
  <!-- Image Row -->
  <div class="col-4 text-center">
    <strong>Vehicle Meter Photo</strong>
    <div>
      <img *ngIf="servicepic; else servicepicFill" [src]="servicepic" alt="Vehicle Meter Photo" class="img-fluid" style="max-height: 10rem;">
      <ng-template #servicepicFill>
        <img src="/assets/images/dummyImage.jpg" alt="Dummy Vehicle Meter Image" class="img-fluid" style="max-height: 10rem;">
      </ng-template>
    </div>
  </div>
  <div class="text-center">
    <!--    <button mat-raised-button color="accent" (click)="onCancel()" class="m-4">Cancel</button>-->
  </div>
</div>
