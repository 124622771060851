import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {LogService} from "../../../services/log.service";

@Component({
  selector: 'app-view-driver',
  templateUrl: './view-driver.component.html',
  styleUrls: ['./view-driver.component.scss']
})
export class ViewDriverComponent implements OnInit {
   driverData: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data:any,
              private mLogService:LogService,
              private mDialog:MatDialog) { }

  ngOnInit(): void {
    this.driverData = this.data
    console.log(this.driverData)

    let activity = {
      date: new Date().getTime(),
      section: 'Driver',
      action: 'view',
      description: `Vehicle view by user`,
      currentIp: localStorage.getItem('currentip')!
    }
    this.mLogService.addLog(activity)
  }
  onCancel() {
    this.mDialog.closeAll();
  }


}
