import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Employee} from "../../../interfaces/Employee";
import {MatSelectChange} from "@angular/material/select";
import {CentreService} from "../../../services/centre.service";
import {Subject, takeUntil} from "rxjs";
import {map} from "rxjs/operators";
import {LogService} from "../../../services/log.service";

export interface UserRole {
  id?:string
  functionName: string
  all: boolean
  add: boolean
  edit: boolean
  archive: boolean
  view: boolean
  excel: boolean
  showMenu: boolean
}

@Component({
  selector: 'app-add-user-role',
  templateUrl: './add-user-role.component.html',
  styleUrls: ['./add-user-role.component.scss']
})
export class AddUserRoleComponent implements OnInit, AfterViewInit, OnDestroy {
  roleSelected?: string
  // tempData: UserRole[]
  roles = [
    'Centre Manager',
    'Centre Coordinator',
    'Financial Accounts',
    'Donors',
    'Program Manager',
    'Counselling',
    'Sr Counselling',
    'Teacher',
    'Sr Teacher',
    'Project Manager',
    'Management',
    'GM Operations',
    'Operation Manager',
  ]
  data: UserRole[] = []
  cmrules: UserRole[] = []
  selectedCentres: string[] = []
  centres: any = []
  _destroyed = new Subject()

  constructor(private mDatabase: AngularFireDatabase,
              private _snackBar: MatSnackBar,
              private dialogRef: MatDialogRef<AddUserRoleComponent>,
              private centreService: CentreService,
              private mLogService:LogService,
              @Inject(MAT_DIALOG_DATA) public md: Employee,) {
    this.data.push({
      functionName: "Child Admission",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Child Prescreening",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Counselling",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Education",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Meal Mate",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Unit Sponsorship",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "General Donation",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "CMS",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Child Report",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Counseling Report",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Education Report",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Donation Report",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Weekly Report",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Centres",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Employees",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Hospital",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Unit",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Types",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Donor",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Event",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Audit Logs",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Dashboard",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Inventory",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Vehicles",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    this.data.push({
      functionName: "Unit Sponsorship",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    })
    // this.tempData = this.md.permissions!
    console.log(md)
    console.log(this.data)

  }

  ngOnInit(): void {
    if (this.md != null && this.md.permissions != null) {

      this.data = []
      if (this.md.centresAllowed != null) this.selectedCentres = Object.values(this.md?.centresAllowed);
      this.roleSelected = this.md.role
      let p: UserRole[] = this.md.permissions  !
      const keys = Object.keys(this.md.permissions!);
// 0 , 1,2,3
      // Iterate over the array
      keys.forEach(key => {
        console.log('KEYS', key)
        // @ts-ignore
        const item = this.md.permissions?.[key]!;
        // Perform any operations with the item
        this.data.push(item)
        console.log(item);
      });
      console.log()
      // Logging the values
      // console.log(this.md.permissions);
      // console.log(this.data);
    }

  }

  submit() {
    let id = this.md.id
    // this.mDatabase.object(`TestUsers/${id}/permissions`).set(this.data)
    this.mDatabase.object(`users/${id}/permissions`).update(this.data).then(res => {
      this.mDatabase.object(`users/${id}/centresAllowed`).set(this.selectedCentres).then(() => {
        if (this.roleSelected != null) this.mDatabase.object(`users/${id}/role`).set(this.roleSelected)

      })
      this._snackBar.open('Updated Successfully!')._dismissAfter(3000)
      this.dialogRef.close()

        let activity = {
        date: new Date().getTime(),
        section: 'Employee',
        action: 'Edit',
        description: `Employee ${this.md.userName} edited by`,
        currentIp: localStorage.getItem('currentip')!
      }
      this.mLogService.addLog(activity)
    })
    // this.mDatabase.object(`TestUsers/${id}/permissions`).set(this.data )
    // this.mDatabase.object(`TestUsers/pavan/lastName`).set( 'Malviya' )
    // this.mDatabase.object('TestUsers/id').update({permissions:this.md})
  }

  ngAfterViewInit(): void {

// this.md.permissions!.forEach((it)=>{
//     console.log(it)
// })

    this.centreService.getTotalAllCentres().pipe(takeUntil(this._destroyed),
      map(x => x.map(c => c.centreCode))
    ).subscribe(res =>
      this.centres = res
    )

  }

  blank() {
    const newData = [
      {
        functionName: "Child Admission",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Child Prescreening",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Counselling",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Education",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Meal Mate",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "General Donation",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "CMS",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Child Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Counseling Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Education Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Donation Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Weekly Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Centres",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Employees",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Hospital",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Types",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Donor",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Event",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Audit Logs",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Dashboard",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
    ];
    this.data = newData
  }

  getSeniorCounselling() {
    const newData = [{
      "functionName": "Child Admission",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Child Prescreening",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Counselling",
      "all": false,
      "add": true,
      "edit": true,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Education",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Meal Mate",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Unit Sponsorship",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "General Donation",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "CMS",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Child Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Counseling Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Education Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Donation Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Weekly Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Centres",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Employees",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Hospital",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Unit",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Types",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Donor",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Event",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Audit Logs",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Dashboard",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
    ]

    this.data = newData


  }

  getCMRules() {
    const newData = [
      {
        functionName: "Child Admission",
        all: false,
        add: true,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Child Prescreening",
        all: true,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counselling",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Meal Mate",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: true
      },
      {
        functionName: "General Donation",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "CMS",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Child Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counseling Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Donation Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Weekly Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Centres",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Employees",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Hospital",
        all: false,
        add: true,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: true
      },
      {
        functionName: "Unit",
        all: false,
        add: true,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Types",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Donor",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Event",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Audit Logs",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Dashboard",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
    ];
    this.data = newData


  }

  getCentreCoordinator() {
    const newData = [
      {
        functionName: "Child Admission",
        all: false,
        add: true,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Child Prescreening",
        all: true,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counselling",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Meal Mate",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: true
      },
      {
        functionName: "General Donation",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "CMS",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Child Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counseling Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Donation Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Weekly Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Centres",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Employees",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Hospital",
        all: false,
        add: true,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: true
      },
      {
        functionName: "Unit",
        all: false,
        add: true,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Types",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Donor",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Event",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Audit Logs",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Dashboard",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
    ];
    this.data = newData


  }

  getCounsellingRules() {
    const newData = [{
      "functionName": "Child Admission",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Child Prescreening",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Counselling",
      "all": false,
      "add": true,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Education",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Meal Mate",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Unit Sponsorship",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "General Donation",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "CMS",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Child Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Counseling Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Education Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Donation Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Weekly Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Centres",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Employees",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Hospital",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Unit",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Types",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Donor",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Event",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Audit Logs",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Dashboard",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },]

    this.data = newData


  }

  getTeacher() {
    const newData = [
      {
        functionName: "Child Admission",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Child Prescreening",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Counselling",
        all: false,
        add: true,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education",
        all: false,
        add: true,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Meal Mate",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "General Donation",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "CMS",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Child Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counseling Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Education Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Donation Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Weekly Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Centres",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Employees",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Hospital",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Types",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Donor",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Event",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Audit Logs",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Dashboard",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
    ];
    this.data = newData


  }

  getSeniorTeacher() {
    const newData = [{
      "functionName": "Child Admission",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Child Prescreening",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Counselling",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Education",
      "all": false,
      "add": true,
      "edit": true,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Meal Mate",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Unit Sponsorship",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "General Donation",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "CMS",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Child Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Counseling Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Education Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Donation Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Weekly Report",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Centres",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Employees",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Hospital",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Unit",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Types",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Donor",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Event",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": false,
      "excel": false,
      "showMenu": false
    }, {
      "functionName": "Audit Logs",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    }, {
      "functionName": "Dashboard",
      "all": false,
      "add": false,
      "edit": false,
      "archive": false,
      "view": true,
      "excel": true,
      "showMenu": true
    },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },]
    this.data = newData
  }

  getManagement() {
    const newData = [{
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Child Admission",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Child Prescreening",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Counselling",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Education",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Meal Mate",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Unit Sponsorship",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "General Donation",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "CMS",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Child Report",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Counseling Report",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Education Report",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Donation Report",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Weekly Report",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Centres",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Employees",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Hospital",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Unit",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Types",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Donor",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Event",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Audit Logs",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": true,
      "functionName": "Dashboard",
      "showMenu": true,
      "view": true
    },          {
      functionName: "Inventory",
      all: false,
      add: false,
      edit: false,
      archive: false,
      view: false,
      excel: false,
      showMenu: false
    },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },]
    this.data = newData
  }

  getOperationsManager() {
    const newData = [
      {
        functionName: "Child Admission",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Child Prescreening",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counselling",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Meal Mate",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "General Donation",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "CMS",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Child Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counseling Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Donation Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Weekly Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Centres",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Employees",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Hospital",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Types",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Donor",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Event",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: true,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Audit Logs",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Dashboard",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
    ];
    this.data = newData
  }

  getGMOperations() {
    const newData = [
      {
        functionName: "Child Admission",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Child Prescreening",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counselling",
        all: false,
        add: true,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Meal Mate",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "General Donation",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "CMS",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Child Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counseling Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Donation Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Weekly Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Centres",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Employees",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Hospital",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Types",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Donor",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Event",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Audit Logs",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Dashboard",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
    ];
    this.data = newData

  }

  getProjectManager() {
    const newData = [
      {
        functionName: "Child Admission",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Child Prescreening",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counselling",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Meal Mate",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: true,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "General Donation",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "CMS",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Child Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counseling Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Education Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Donation Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Weekly Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Centres",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Employees",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Hospital",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Unit",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Types",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Donor",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Event",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Audit Logs",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Dashboard",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
    ];
    this.data = newData

  }

  getFinancialAccounts() {
    const newData = [
      {
        functionName: "Child Admission",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Child Prescreening",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Counselling",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Education",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Meal Mate",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "General Donation",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "CMS",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Child Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Counseling Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Education Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Donation Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Weekly Report",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Centres",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Employees",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Hospital",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Types",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Donor",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Event",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Audit Logs",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Dashboard",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: true,
        excel: true,
        showMenu: true
      },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
    ];
    this.data = newData
  }

  getProgramManager() {
    const newData = [
      {
        "add": true,
        "all": false,
        "archive": false,
        "edit": true,
        "excel": true,
        "functionName": "Child Admission",
        "showMenu": true,
        "view": true
      },
      {
        "add": true,
        "all": false,
        "archive": false,
        "edit": true,
        "excel": true,
        "functionName": "Child Prescreening",
        "showMenu": true,
        "view": true
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": true,
        "functionName": "Counselling",
        "showMenu": true,
        "view": true
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": true,
        "functionName": "Education",
        "showMenu": true,
        "view": true
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": false,
        "functionName": "Meal Mate",
        "showMenu": false,
        "view": false
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": false,
        "functionName": "Unit Sponsorship",
        "showMenu": false,
        "view": false
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": false,
        "functionName": "General Donation",
        "showMenu": false,
        "view": false
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": false,
        "functionName": "CMS",
        "showMenu": false,
        "view": false
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": true,
        "functionName": "Child Report",
        "showMenu": true,
        "view": true
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": true,
        "functionName": "Counseling Report",
        "showMenu": true,
        "view": true
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": true,
        "functionName": "Education Report",
        "showMenu": true,
        "view": true
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": false,
        "functionName": "Donation Report",
        "showMenu": false,
        "view": false
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": true,
        "functionName": "Weekly Report",
        "showMenu": true,
        "view": true
      },
      {
        "add": true,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": false,
        "functionName": "Centres",
        "showMenu": false,
        "view": false
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": false,
        "functionName": "Employees",
        "showMenu": false,
        "view": false
      },
      {
        "add": true,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": true,
        "functionName": "Hospital",
        "showMenu": true,
        "view": true
      },
      {
        "add": true,
        "all": false,
        "archive": false,
        "edit": true,
        "excel": true,
        "functionName": "Unit",
        "showMenu": true,
        "view": true
      },
      {
        "add": true,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": true,
        "functionName": "Types",
        "showMenu": true,
        "view": true
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": false,
        "functionName": "Donor",
        "showMenu": false,
        "view": false
      },
      {
        "add": true,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": true,
        "functionName": "Event",
        "showMenu": true,
        "view": true
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": false,
        "functionName": "Audit Logs",
        "showMenu": false,
        "view": false
      },
      {
        "add": false,
        "all": false,
        "archive": false,
        "edit": false,
        "excel": true,
        "functionName": "Dashboard",
        "showMenu": true,
        "view": true
      },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
    ]
    this.data = newData

  }

  getDonors() {
    const newData = [{
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Child Admission",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Child Prescreening",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Counselling",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Education",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Meal Mate",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Unit Sponsorship",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "General Donation",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "CMS",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Child Report",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Counseling Report",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Education Report",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Donation Report",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Weekly Report",
      "showMenu": true,
      "view": true
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Centres",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Employees",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Hospital",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Unit",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Types",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Donor",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Event",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Audit Logs",
      "showMenu": false,
      "view": false
    }, {
      "add": false,
      "all": false,
      "archive": false,
      "edit": false,
      "excel": false,
      "functionName": "Dashboard",
      "showMenu": true,
      "view": true
    },
      {
        functionName: "Inventory",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Vehicles",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },
      {
        functionName: "Unit Sponsorship",
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      },]
    this.data = newData

  }

  onRoleSelect($event: MatSelectChange) {
    let role = $event.value
    this.roleSelected = $event.value
    if (role == 'Centre Manager') {
      this.getCMRules()
    }
    if (role == 'Centre Coordinator') {
      this.getCentreCoordinator()
    }
    if (role == 'Financial Accounts') {
      this.getFinancialAccounts()
    }
    if (role == 'Donors') {
      this.getDonors()
    }
    if (role == 'Program Manager') {
      this.getProgramManager()
    }
    if (role == 'Counselling') {
      this.getCounsellingRules()
    }
    if (role == 'Sr Counselling') {
      this.getSeniorCounselling()
    }
    if (role == 'Teacher') {
      this.getTeacher()
    }
    if (role == 'Sr Teacher') {
      this.getSeniorTeacher()
    }
    if (role == 'Project Manager') {
      this.getProjectManager()
    }
    if (role == 'Management') {
      this.getManagement()
    }
    if (role == 'GM Operations') {
      this.getGMOperations()
    }
    if (role == 'Operation Manager') {
      this.getOperationsManager()
    }

  }

  ngOnDestroy() {
    this._destroyed.next('')
    this._destroyed.complete()
  }

  selectAllCheckbox() {
    // for (const d of this.data) {
    //   // Set the state of other checkboxes based on the state of "Select All" checkbox
    //
    //   d.add = d.all;
    //   d.edit = d.all;
    //   d.archive = d.all;
    //   d.view = d.all;
    //   d.excel = d.all;
    // }

  }
}
