import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {VehicleService} from "../vehicle.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Employee} from "../../../interfaces/Employee";
import {LogService} from "../../../services/log.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-add-servicing-details',
  templateUrl: './add-servicing-details.component.html',
  styleUrls: ['./add-servicing-details.component.scss']
})
export class AddServicingDetailsComponent implements OnInit {
  servicingForm: FormGroup;
  vehicles:any[] =[]
   drivers: any[] =[];
  userName: any
  isUpdateMode: boolean = false; // Set a default value
  constructor(private fb: FormBuilder,
              private mVehicleService: VehicleService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private readonly mDialogRef:MatDialogRef<AddServicingDetailsComponent>,
              private readonly mSnackbar:MatSnackBar,
              private mLogService: LogService
              ) {
    this.isUpdateMode = !!data;
    const userData = JSON.parse(localStorage.getItem('userData')!)
    this.userName = userData?.userName
    this.servicingForm = this.fb.group({
      dates: ['', Validators.required],
      paidAmount: ['', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
      meters: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
      vehicleNumber: ['', [Validators.required, Validators.pattern(/^[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}$/)]],
      driverName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]+$/)]],
      userName: [this.userName]
    });

  }

  ngOnInit(): void {
    console.log(this.data)

    this.mVehicleService.getVehicles().subscribe(res => {
      console.log(res)
      this.vehicles = res
    })


    this.mVehicleService.getDriver().subscribe(res =>{
      this.drivers = res
    })

    if (this.isUpdateMode) {
      // Populate form with existing data if updating
      this.servicingForm.patchValue({
        dates: this.getFormattedDate(this.data.dates),
        paidAmount: this.data.paidAmount,
        meters: this.data.meters,
        vehicleNumber: this.data.vehicleNumber,
        driverName: this.data.driverName,
        createdAt: this.data.createdAt || new Date(),
        userName: this.userName
      });
    }
  }

  // onSubmit() {
  //   if (this.servicingForm.valid) {
  //     this.mVehicleService.addServicingData(this.servicingForm.value).then(r => {
  //       console.log("Servicing data added successfully")
  //       let activity={
  //         date:new Date().getTime(),
  //         section: 'Transport',
  //         action: 'Add',
  //
  //         description: `Servicing details added by`,
  //         currentIp: localStorage.getItem('currentip')!
  //       }
  //       this.mLogService.addLog(activity)
  //     })
  //     console.log(this.servicingForm.value);
  //   }
  //   // this.carForm.reset()
  //   this.mSnackbar.open('Form Submiited Successfully','',{
  //     panelClass:'success',
  //     duration:3000
  //   })
  //   this.mDialogRef.close()
  // }
  onSubmit() {
    if (this.servicingForm.valid) {
      const formData = {
        ...this.servicingForm.value,
        dates: this.servicingForm.value.dates ? Date.now() : null,
        createdAt: this.servicingForm.value.createdAt || new Date()
      };

      if (this.isUpdateMode) {
        // Update existing servicing data
        this.mVehicleService.updateServicingData(this.data.id, formData).then(() => {
          console.log("Servicing data updated successfully");
          let activity = {
            date: new Date().getTime(),
            section: 'Vehicle',
            action: 'Update',
            description: `Servicing details updated by ${this.userName}`,
            currentIp: localStorage.getItem('currentip')!
          };
          this.mLogService.addLog(activity);
        });
        this.mSnackbar.open('Servicing data updated successfully', '', {
          panelClass: 'success',
          duration: 3000
        });
      } else {
        // Add new servicing data
        this.mVehicleService.addServicingData(formData).then(() => {
          console.log("Servicing data added successfully");
          let activity = {
            date: new Date().getTime(),
            section: 'Vehicle',
            action: 'Submit',
            description: `Servicing details added by ${this.userName}`,
            currentIp: localStorage.getItem('currentip')!
          };
          this.mLogService.addLog(activity);
        });
        this.mSnackbar.open('Servicing data added successfully', '', {
          panelClass: 'success',
          duration: 3000
        });
      }

      this.mDialogRef.close();
    }
  }


  onCancel(){
    this.mDialogRef.close()
  }

  private getFormattedDate(timestamp: any): Date {
    if (timestamp && timestamp.seconds) {
      return new Date(timestamp.seconds * 1000);
    } else {
      return new Date(); // Set a default date or handle accordingly
    }
  }
}
