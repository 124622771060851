<!-- trip-view.component.html -->

<div class="container mt-4 w-100 border-1">
  <h1 class="bg-warning text-center mb-4 mt-2">Car fuel Details</h1>

  <table class="table w-100">
    <tbody>

    <tr>
      <th>Driver Name</th>
      <td>{{ Data.driverName}}</td>
    </tr>
    <tr>
      <th>Date Of Filling</th>
      <td>{{ Data.date}}</td>
    </tr>
    <tr>
      <th>Date Of Entry</th>
      <td>{{ displayDate(Data.createdAt) }}</td>
    </tr>


    <!-- Vehicle Number -->
    <tr>
      <th>Vehicle Number</th>
      <td>{{ Data.vehicleNumber }}</td>
    </tr>

    <!-- Purpose of Trip -->
    <tr>
      <th>Fuel Quantity</th>
      <td>{{ Data.totalQuantityField }}</td>
    </tr>


    </tbody>
  </table>
  <!-- Image Row -->
  <!-- Image Row -->
  <div class="row">
    <!-- Vehicle Meter Photo -->
    <div class="col-4 text-center">
      <strong>Vehicle Meter Photo</strong>
      <div>
        <img *ngIf="petrolfill; else placeholderFill" [src]="petrolfill" alt="Vehicle Meter Photo" class="img-fluid" style="max-height: 10rem;">
        <ng-template #placeholderFill>
          <img src="/assets/images/dummyImage.jpg" alt="Dummy Vehicle Meter Image" class="img-fluid" style="max-height: 10rem;">
        </ng-template>
      </div>
    </div>

    <!-- Meter Photo (Pump) -->
    <div class="col-4 text-center">
      <strong>Meter Photo (Pump)</strong>
      <div>
        <img *ngIf="petrolmeter; else placeholderMeter" [src]="petrolmeter" alt="Pump Meter Image" class="img-fluid" style="max-height: 10rem;">
        <ng-template #placeholderMeter>
          <img src="/assets/images/dummyImage.jpg" alt="Dummy Pump Meter Image" class="img-fluid" style="max-height: 10rem;">
        </ng-template>
      </div>
    </div>
  </div>


  <div class="text-center">
<!--    <button mat-raised-button color="accent" (click)="onCancel()" class="m-4">Cancel</button>-->
  </div>
</div>
