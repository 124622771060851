import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Reciept} from "../DonationModel";
import {AttemptedDonationModel} from "../manual-attempted-donation-reciept/manual-attempted-donation-reciept.component";

@Component({
  selector: 'app-view-attempted-donation-reciept',
  templateUrl: './view-attempted-donation-reciept.component.html',
  styleUrls: ['./view-attempted-donation-reciept.component.scss']
})
export class ViewAttemptedDonationRecieptComponent  implements OnInit {
  myForm!: FormGroup;
  heading = 'Payment';
  description = 'Payment';
  calculatedAmount = 0;
  totalAmountWithoutTax = 500;
  minimumamount = ''

  constructor(public dialogRef: MatDialogRef<ViewAttemptedDonationRecieptComponent>,
              @Inject(MAT_DIALOG_DATA) public receipt: AttemptedDonationModel) {
  }

  ngOnInit(): void {
    // console.table(this.receipt)
    this.myForm = new FormGroup({
      donated_by: new FormControl(this.receipt.donorName, Validators.required),
      address: new FormControl(this.receipt.donoraddress, Validators.required),
      state: new FormControl(this.receipt.state, Validators.required),
      donation_amount: new FormControl(this.receipt.amount, Validators.required),
      groupkey: new FormControl({value:this.receipt.donation_type||'GF', disabled: true}, Validators.required),
      description: new FormControl({value:this.receipt.donation_type||'General Donation', disabled: true}, Validators.required),
      // idproof: new FormControl({value: 'PAN', disabled: true}, Validators.required),
      email: new FormControl(this.receipt.email, [Validators.required, Validators.email]),
      // country: new FormControl({value: 'India', disabled: true}, Validators.required),
      // nationality: new FormControl({value: 'Indian', disabled: true}, Validators.required),
      zipcode: new FormControl(this.receipt.zipcode, [Validators.required, Validators.maxLength(6)]),
      donorCity: new FormControl(this.receipt.donorcity, Validators.required),
      pan: new FormControl(this.receipt.pan, Validators.compose([Validators.required, Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}')])),
      mobile: new FormControl(this.receipt.donorMobile, [Validators.required, Validators.maxLength(12), Validators.minLength(10)]),
      // details: new FormControl(''),
      // addtax: new FormControl(this.istransactionIncluded)

    });
    // this.myForm.setValue(this.receipt)
  }

}
