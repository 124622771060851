<div class="container-fluid">
    <h1>Payments</h1>
<div class="row">
    <mat-form-field  appearance="outline" class="col-md-4">
        <mat-label>Filter by Status</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
            <input matStartDate [(ngModel)]="mStartDate" placeholder="Start date">
            <input matEndDate [(ngModel)]="mEndDate" placeholder="End date">
        </mat-date-range-input>
        <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <button mat-mini-fab color="primary" (click)="search()" class="col-md-2">
        <mat-icon>search</mat-icon>
    </button>

</div>

<table mat-table [dataSource]="dataSource" matSort>


    <!--Email Column -->
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let payment">{{ payment.notes.email }}</td>
    </ng-container>
    <!--Contact Column -->
    <ng-container matColumnDef="contact">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact</th>
        <td mat-cell *matCellDef="let payment">{{ payment.contact }}</td>
    </ng-container>



    <!-- Amount Column -->
    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
        <td mat-cell *matCellDef="let payment">{{ payment.amount/100 }}</td>
    </ng-container>
  <!-- OrderId Column -->
  <!-- ID Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Id</th>
    <td mat-cell *matCellDef="let payment">{{ payment.id }}</td>
  </ng-container>
  <ng-container matColumnDef="order_id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Id</th>
    <td mat-cell *matCellDef="let payment">{{ payment.order_id }}</td>
  </ng-container>
  <ng-container matColumnDef="group">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Donation Type </th>
    <td mat-cell *matCellDef="let payment">{{ payment.notes.group }}</td>
  </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let payment">{{ payment.status }}</td>
    </ng-container>

    <!-- Method Column -->
    <ng-container matColumnDef="method">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Method</th>
        <td mat-cell *matCellDef="let payment">{{ payment.method }}</td>
    </ng-container>

    <!-- Created At Column -->
    <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
        <td mat-cell *matCellDef="let payment">{{ getDate( payment.created_at) }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
    <mat-paginator [pageSizeOptions]="[25, 50, 100,1000]" aria-label="Select page of users"></mat-paginator>

</div>
