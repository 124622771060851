import {DOCUMENT} from '@angular/common';
import {
    Component,
    Inject,
    ElementRef,
    OnInit,
    Renderer2,
    AfterViewInit,
} from '@angular/core';
import {Router} from '@angular/router';
import {InConfiguration} from "../../models/config.interface";
import {AuthService} from "../../auth.service";
import {ConfigService} from "../../services/config.service";
import {UnsubscribeOnDestroyAdapter} from "../../shared/UnsubscribeOnDestroyAdapter";
import {LoadingService} from "../../services/loading.service";

interface Notifications {
    message: string;
    time: string;
    icon: string;
    color: string;
    status: string;
}

@Component({
    selector: 'app-header2',
    templateUrl: './header2.component.html',
    styleUrls: ['./header2.component.scss']
})
export class Header2Component
    extends UnsubscribeOnDestroyAdapter

    implements OnInit, AfterViewInit {
    public config!: InConfiguration;
    isLoading :boolean =true;
    isNavbarCollapsed = false;
    isNavbarShow = true;
    flagvalue: string | string[] | undefined;
    countryName: string | string[] = [];
    langStoreValue?: string;
    defaultFlag?: string;
    isOpenSidebar?: boolean;
    docElement: HTMLElement | undefined;
    isFullScreen = false;
    navbarColor = 'white'

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        public elementRef: ElementRef,
        // private rightSidebarService: RightSidebarService,
        private configService: ConfigService,
        private authService: AuthService,
        private router: Router,
        private loadingService: LoadingService
        // public languageService: LanguageService
    ) {
        super();
    }

    listLang = [
        {text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en'},
        {text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es'},
        {text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de'},
    ];
    notifications: Notifications[] = [
        {
            message: 'Please check your mail',
            time: '14 mins ago',
            icon: 'mail',
            color: 'nfc-green',
            status: 'msg-unread',
        },
        {
            message: 'New Patient Added..',
            time: '22 mins ago',
            icon: 'person_add',
            color: 'nfc-blue',
            status: 'msg-read',
        },
        {
            message: 'Your leave is approved!! ',
            time: '3 hours ago',
            icon: 'event_available',
            color: 'nfc-orange',
            status: 'msg-read',
        },
        {
            message: 'Lets break for lunch...',
            time: '5 hours ago',
            icon: 'lunch_dining',
            color: 'nfc-blue',
            status: 'msg-read',
        },
        {
            message: 'Patient report generated',
            time: '14 mins ago',
            icon: 'description',
            color: 'nfc-green',
            status: 'msg-read',
        },
        {
            message: 'Please check your mail',
            time: '22 mins ago',
            icon: 'mail',
            color: 'nfc-red',
            status: 'msg-read',
        },
        {
            message: 'Salary credited...',
            time: '3 hours ago',
            icon: 'paid',
            color: 'nfc-purple',
            status: 'msg-read',
        },
    ];

    ngOnInit(): void {
        this.config = this.configService.configData;
        this.configService.navbarColor = this.navbarColor
        this.langStoreValue = localStorage.getItem('lang') as string;
        const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
        this.countryName = val.map((element) => element.text);
        if (val.length === 0) {
            if (this.flagvalue === undefined) {
                this.defaultFlag = 'assets/images/flags/us.jpg';
            }
        } else {
            this.flagvalue = val.map((element) => element.flag);
        }
      // Subscribe to the loading state from the service
      this.loadingService.loading$.subscribe((isLoading) => {
        this.isLoading = isLoading;
      });
    }

    ngAfterViewInit(): void {
        // set theme on startup
        if (localStorage.getItem('theme')) {
            this.renderer.removeClass(this.document.body, this.config.layout.variant);
            this.renderer.addClass(
                this.document.body,
                localStorage.getItem('theme') as string
            );
        } else {
            this.renderer.addClass(this.document.body, this.config.layout.variant);
        }

        if (localStorage.getItem('menuOption')) {
            this.renderer.addClass(
                this.document.body,
                localStorage.getItem('menuOption') as string
            );
        } else {
            this.renderer.addClass(
                this.document.body,
                'menu_' + this.config.layout.sidebar.backgroundColor
            );
        }

        if (localStorage.getItem('choose_logoheader')) {
            this.renderer.addClass(
                this.document.body,
                localStorage.getItem('choose_logoheader') as string
            );
        } else {
            this.renderer.addClass(
                this.document.body,
                'logo-' + this.config.layout.logo_bg_color
            );
        }

        if (localStorage.getItem('sidebar_status')) {
            if (localStorage.getItem('sidebar_status') === 'close') {
                this.renderer.addClass(this.document.body, 'side-closed');
                this.renderer.addClass(this.document.body, 'submenu-closed');
            } else {
                this.renderer.removeClass(this.document.body, 'side-closed');
                this.renderer.removeClass(this.document.body, 'submenu-closed');
            }
        } else {
            if (this.config.layout.sidebar.collapsed === true) {
                this.renderer.addClass(this.document.body, 'side-closed');
                this.renderer.addClass(this.document.body, 'submenu-closed');
            }
        }
    }

    callFullscreen() {
        if (!this.isFullScreen) {
            this.docElement?.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
        this.isFullScreen = !this.isFullScreen;
    }

    mobileMenuSidebarOpen(event: Event, className: string) {
        const hasClass = (event.target as HTMLInputElement).classList.contains(
            className
        );
        if (hasClass) {
            this.renderer.removeClass(this.document.body, className);
        } else {
            this.renderer.addClass(this.document.body, className);
        }
    }

    callSidemenuCollapse() {
        const hasClass = this.document.body.classList.contains('side-closed');
        if (hasClass) {
            this.renderer.removeClass(this.document.body, 'side-closed');
            this.renderer.removeClass(this.document.body, 'submenu-closed');
        } else {
            this.renderer.addClass(this.document.body, 'side-closed');
            this.renderer.addClass(this.document.body, 'submenu-closed');
        }
    }

    logout() {
        this.authService.SignOut()
            .then(res => {
                localStorage.removeItem('user');
                this.authService.roleAs = '';

                this.router.navigate(['login']);
                console.log('Logged out!');
            })


    }
}
