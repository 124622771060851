
<div class="container">
<br>
<!--  <mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4">-->
<!--    <mat-tab label=" Child Form (Full) "><app-fullcontentchild></app-fullcontentchild></mat-tab>-->
<!--    <mat-tab label="Child Admission"><app-childadmission></app-childadmission></mat-tab>-->
<!--    <mat-tab label="Guardian"><app-childguardian></app-childguardian>  </mat-tab>-->
<!--    <mat-tab label="Child Health Information"> <app-child-health></app-child-health> </mat-tab>-->
<!--    <mat-tab label="Child Documents"><app-childdocument></app-childdocument>  </mat-tab>-->
<!--  </mat-tab-group>-->

<div class="d-flex justify-content-between ">
<div>
    <button color="accent" *ngIf="prescreeningPermissions[0].all || prescreeningPermissions[0].add"  mat-raised-button id="create-center"  (click)="openpopup()"><mat-icon>group_add</mat-icon>&nbsp;Child Pre-screening</button>&nbsp;
    <button color="accent" *ngIf="registraionPermissions[0].all || registraionPermissions[0].add" mat-raised-button id="direct-admission"  (click)="DirectAllot()"><mat-icon>person_add</mat-icon>&nbsp;Direct Allotment</button>&nbsp;
</div>
    <div>
        <mat-form-field appearance="outline" class="mx-2">
             <mat-label> Enter Child Code</mat-label>
            <input matInput [(ngModel)]="childcodeEntered" />

        </mat-form-field>
        <button mat-mini-fab (click)="searchChildByCode()" ><mat-icon>search</mat-icon></button>
    </div>
</div>
  <br>
  <mat-tab-group mat-stretch-tabs class="example-stretched-tabs card">
      <mat-tab label="Registered">
          <app-child-registration-table></app-child-registration-table>
      </mat-tab>
    <mat-tab label="PreScreen">
<div class="d-flex justify-content-center mt-2">
    <mat-form-field  class=" rounded-outline m-r-10" appearance="outline">
      <mat-label>Select Status</mat-label>

      <mat-select  [(ngModel)]="statusSelected"   matTooltip="Select Status"  >
        <mat-option value="active">Active</mat-option>
        <mat-option value="close">Closed</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field  style='width:350px;' class="  m-r-10" appearance="outline">
      <mat-label>Select Hospital</mat-label>

      <mat-select  [(ngModel)]="hospitalSelected"  matTooltip="Select Hospital"  >
        <mat-option *ngFor="let h of mHospitals$|async" [value]="h.hospitalName">{{h.hospitalName}}</mat-option>

      </mat-select>
    </mat-form-field>

    <button   mat-mini-fab color="accent" (click)="search()" >
      <mat-icon >search</mat-icon>
    </button>&nbsp;

  <button   mat-mini-fab color="warn" (click)="clearAll()" >
    <mat-icon  >clear</mat-icon>
  </button>
</div>


  <section class="example-container " tabindex="0">
    <div class="ngxTableHeader">
      <ul class="header-buttons-left ms-0 mb-0">
        <li class="dropdown">
          <!--            <h2>Unit & Floor Report</h2>-->
        </li>
        <li class="dropdown m-l-20">
          <label for="search-input"><i class="material-icons search-icon">search</i></label>
          <input placeholder="Search" id="search-input" type="text" class="browser-default search-field"
                 (keyup)='applyFilter($event)' aria-label="Search box" #input>
        </li>

      </ul>
      <ul class="header-buttons mr-2">

      </ul>
    </div>

    <table mat-table [dataSource]="dataSource" matSort class="child-table" matSortDisableClear matSortDirection="desc"  >
<!--  POSITION Column-->
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="#">Sr.</th>
        <td mat-cell *matCellDef="let i = index">
          {{this.paginator1.pageIndex == 0 ? i + 1 : 1 + i + this.paginator1.pageIndex * this.paginator1.pageSize}}
        </td>
      </ng-container>
      <!-- Center Column -->
      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> State </th>
        <td mat-cell *matCellDef="let element"> {{element.state  }} </td>
      </ng-container>

        <!-- Center Column -->
        <ng-container matColumnDef="mobile">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile </th>
            <td mat-cell *matCellDef="let element"> {{element.mobile  }} </td>
        </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="gender">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Gender </th>
        <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
      </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status </th>
            <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>
      <!--      Child Name Column-->
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
        <td mat-cell *matCellDef="let element"> {{element.firstName   }} </td>
      </ng-container>

      <!--      Last Name Column-->
      <ng-container matColumnDef="lastName" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
        <td mat-cell *matCellDef="let element"> {{element.lastName  }} </td>
      </ng-container>
        <ng-container matColumnDef="hospitalname" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Hospital Name </th>
            <td mat-cell *matCellDef="let element"> {{element.hospitalname  }} </td>
        </ng-container>
      <!-- City Column -->
      <ng-container matColumnDef="city" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header> City </th>
        <td mat-cell *matCellDef="let element">&nbsp; {{element.city}} </td>
      </ng-container>
        <ng-container matColumnDef="age" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Age </th>
            <td mat-cell *matCellDef="let element"> {{element.age}} </td>
        </ng-container>
      <ng-container matColumnDef="createdAt" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
        <td mat-cell *matCellDef="let element"> {{element.createdAt.toDate()|date:'dd-MM-yyyy'}} </td>
      </ng-container>
      <ng-container matColumnDef="createdBy" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
        <td mat-cell *matCellDef="let element"> {{element.createdBy}} </td>
      </ng-container>
      <!-- Symbol Column -->
      <!--    <ng-container matColumnDef="symbol">-->
      <!--      <th mat-header-cell *matHeaderCellDef> Symbol </th>-->
      <!--      <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>-->
      <!--    </ng-container>-->

      <!--     Action-->
      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef  > Action </th>
        <td mat-cell *matCellDef="let element;let i = index">
<!--          <button mat-icon-button>-->
<!--            <mat-icon>archive</mat-icon>Archive-->

<!--          </button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-->
        <div class="d-flex">
          <button color="accent" *ngIf="prescreeningPermissions[0].view || prescreeningPermissions[0].all" mat-icon-button  (click)="datashow(element)" matTooltip="View" ><mat-icon>remove_red_eye</mat-icon>&nbsp;</button>&nbsp;
          <button color="primary" *ngIf="prescreeningPermissions[0].edit || prescreeningPermissions[0].all" mat-icon-button   (click)="showEdit(element)" matTooltip="Edit" ><mat-icon>edit</mat-icon>&nbsp;</button>&nbsp;
          <button color="warn" *ngIf="prescreeningPermissions[0].archive || prescreeningPermissions[0].all" mat-icon-button   (click)="delete(element)" matTooltip="Delete" ><mat-icon>delete</mat-icon>&nbsp;</button>&nbsp;
          <button  mat-icon-button *ngIf="prescreeningPermissions[0].add || prescreeningPermissions[0].all" (click)="GoToAllotment(element)" matTooltip="Allot Child">
            <mat-icon >assignment</mat-icon>
          </button>
<!--           <button color="accent" *ngIf="(prescreeningPermissions[0].add || prescreeningPermissions[0].all) && element.status!='Active'" mat-stroked-button   (click)="Active(element)" matTooltip="Active" >Set Active</button>&nbsp;-->
<!--           <button color="primary" *ngIf="(prescreeningPermissions[0].add || prescreeningPermissions[0].all)&& element.status!='Closed'" mat-stroked-button    (click)="Closed(element)" matTooltip="Closed" >Set Closed</button>&nbsp;-->
<!--    (click)="cfullform"      -->

<!--          <button   mat-icon-button >-->
<!--            <mat-icon>remove_red_eye</mat-icon>-->
<!--          </button>View-->

        </div>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr *matNoDataRow class="mat-row">
          <!--        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>-->
      </tr>
    </table>
  </section>
      <mat-paginator [pageSizeOptions]="[20, 50, 100,500]"
                     showFirstLastButtons
                     aria-label="Select page of periodic elements">
      </mat-paginator>
    </mat-tab>



<!---------------------------------------------------Registered Tab Content-------------------------->


  </mat-tab-group>
<!----------------------------------------------------Registered Tab End--------------->
</div>
