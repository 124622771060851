<div>
  <h2>Select Centre:</h2>
  <mat-form-field appearance="outline">
    <mat-label>Centre</mat-label>
    <mat-select [(ngModel)]="selectedCentre" name="selectedCentre" (selectionChange)="onCentreSelect($event)">
      <mat-option *ngFor="let centre of centres$ | async" [value]="centre.centreCode">{{ centre.centreName }}</mat-option>
      <mat-option  value="All">All Centres</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="card p-2">
    <div class="col-lg-12 col-md-12 table">
      <div class="ngxTableHeader">
        <ul class="header-buttons-left ms-0 mb-0">
          <li class="dropdown">
            <h2>Non Sponsored Children</h2>
          </li>
          <!--      <li class="dropdown m-l-20">-->
          <!--        <label for="search-input"><i class="material-icons search-icon">search</i></label>-->
          <!--        <input placeholder="Search" type="text" class="browser-default search-field"-->
          <!--               (keyup)='applyFilter($event)' aria-label="Search box" #input>-->
          <!--      </li>-->
        </ul>
        <ul class="header-buttons mr-2">

          <!--        <li>-->
          <!--          <div  class="export-button m-l-10" matTooltip="XLSX" (click)="exportexcel()">-->
          <!--            <img src="assets/images/icons/xlsx.png" alt="" />-->
          <!--          </div>-->
          <!--        </li>-->
        </ul>
      </div>

      <table mat-table [dataSource]="datasource" class="" matSort>
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
          <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header>Last Name</th>
          <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
        </ng-container>
<!--        <ng-container matColumnDef="firstName" >-->
<!--          <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>-->
<!--          <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>-->
<!--        </ng-container>-->
        <ng-container matColumnDef="state" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
          <td mat-cell *matCellDef="let element">{{ element.state }}</td>
        </ng-container>

        <ng-container matColumnDef="centreName" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Center Name</th>
          <td mat-cell *matCellDef="let element" class="TextWidth">{{ element.centreName }}</td>
        </ng-container>
        <ng-container matColumnDef="gender" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Gender</th>
          <td mat-cell *matCellDef="let element">{{ element.gender }}</td>
        </ng-container>
        <ng-container matColumnDef="age" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Age</th>
          <td mat-cell *matCellDef="let element">{{ element.age }}</td>
        </ng-container>
        <ng-container matColumnDef="city" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
          <td mat-cell *matCellDef="let element">{{ element.city }}</td>
        </ng-container>
        <ng-container matColumnDef="cancer" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Cancer</th>
          <td mat-cell *matCellDef="let element"> <ng-container *ngFor="let diagnosis of element.diagnosisSelected">
            <p *ngIf="isString(diagnosis)" matTooltip="diagnosis" class="truncate-text" matTooltipPosition="above">{{ diagnosis }}</p>
            <p *ngIf="isObject(diagnosis)"  matTooltip="diagnosis" class="truncate-text" matTooltipPosition="above">{{ element.diagnosis }}</p>
          </ng-container></td>
        </ng-container>

        <ng-container matColumnDef="dob" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>DOB</th>
          <td mat-cell *matCellDef="let element" class="TextWidth">{{ element.dob.toDate()| date:'dd-MMM-yyyy' }}</td>
        </ng-container>
        <ng-container matColumnDef="state" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
          <td mat-cell *matCellDef="let element" class="TextWidth">{{ element.state}}</td>
        </ng-container>

        <ng-container matColumnDef="hospital" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Hospital</th>
          <td mat-cell *matCellDef="let element" class="truncate-text" [matTooltip]="element.hospital" matTooltipPosition="above">{{ element.hospital }}</td>
        </ng-container>

<!--        <ng-container matColumnDef="action">-->
<!--          <th mat-header-cell *matHeaderCellDef>Action</th>-->
<!--          <td mat-cell  *matCellDef="let element" ><button mat-raised-button  [disabled]="element.remainingAmount==0" (click)="showChild(element)">Allot</button> </td>-->
<!--        </ng-container>-->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
      <mat-paginator [pageSizeOptions]="[25, 50, 100,1000]"
                     showFirstLastButtons
                     aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
</div>
