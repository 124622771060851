import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable(); // Observable to subscribe to

  // Method to set the loading state
  setLoading(isLoading: boolean): void {
    this.loadingSubject.next(isLoading);
  }
}
