import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import statesdata from '../../../../assets/json/states.json';
import {States} from "../../../interfaces/States";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {ActivatedRoute, Route, Router} from "@angular/router";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {firstValueFrom} from "rxjs";
import {Timestamp} from "@angular/fire/firestore";
import {Database, increment, ref, set} from "@angular/fire/database";
import {LogService} from "../../../services/log.service";


@Component({
  selector: 'app-manual-donation',
  templateUrl: './manual-donation.component.html',
  styleUrls: ['./manual-donation.component.scss']
})
export class ManualDonationComponent implements OnInit {
  currentCounter: string = '';
  payment_id = '';
  order_id = '';
  myForm!: any
  receiptDate: Date | undefined
  stateslist = statesdata as States[]
  private mId: string | undefined
  protected mData: any | null
  updateCounter = false;

  constructor(private readonly mFirestore: AngularFirestore,
              private router: Router,
              private mDatabase: Database,
              private db: AngularFireDatabase,
              private mLogService:LogService,
              private mRoute: ActivatedRoute) {
    // private setReceiptDate(date:number,month:number,year:number,hour:number,minutes:number,ss:number) {

    // this.setReceiptDate(23, 3, 2024, 14, 38, 10)
    if (this.mId == null) {
      this.myForm = new FormGroup({
        donated_by: new FormControl('', Validators.required),
        address: new FormControl('', Validators.required),
        state: new FormControl('', Validators.required),
        donation_amount: new FormControl(0, Validators.required),
        donation_type: new FormControl({value: 'Online', disabled: true}, Validators.required),
        idproof: new FormControl({value: 'PAN', disabled: true}, Validators.required),
        email: new FormControl('', [Validators.required, Validators.email]),
        country: new FormControl({value: 'India', disabled: true}, Validators.required),
        nationality: new FormControl({value: 'Indian', disabled: true}, Validators.required),
        zipcode: new FormControl('', [Validators.required, Validators.maxLength(6)]),
        city: new FormControl('', Validators.required),
        pan: new FormControl('', Validators.compose([Validators.required, Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}')])),
        mobile: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.pattern('^[0-9]{10}$')]),
        details: new FormControl('details'),
        receipt_id: new FormControl(""),
        order_id: new FormControl(''),
        payment_id: new FormControl(''),
        groupKey: new FormControl('GF'),
        description: new FormControl('DESC'),
        method: new FormControl('ONLINE'),
        totalAmountWithoutTax: new FormControl(0),
        addtax: new FormControl(false),
        createdAt: new FormControl(this.receiptDate?.getTime()),
        date: new FormControl(),
      });
    }


  }

  getReceiptName(): void {
    console.log('getting receipt');

    let msg = `2024-2025-80G-ONL-`;

    // Select the path for production or test
    const documentRef = `receiptcounter/counter`;

    firstValueFrom(this.db.object<number>(documentRef).valueChanges())
      .then((snapshot) => {

        if (snapshot !== null) {
          if (snapshot.toString().length === 1) {
            msg += '00' + snapshot;
          } else if (snapshot.toString().length === 2) {
            msg += '0' + snapshot;
          } else {
            msg += snapshot;
          }

          console.log(msg);
          this.currentCounter = msg
          localStorage.setItem('current_bill_counter', msg);
          this.mRoute.params.subscribe(res => {
            this.mId = res['id']

            console.log('id is', this.mId)
            this.mFirestore.collection('failedDonations').doc(this.mId).get().subscribe(snap => {
              this.mData = snap.data()


              // alert(JSON.stringify(this.mData))
              if (this.mData != null) {
                console.log('FAILED DONATION DATA ',this.mData)
                // alert(JSON.stringify(this.mData))
                let details: any
                details = this.mData.details || ''
                if (details != '' && details && !isStringJson(this.mData.details)) {
                  this.payment_id = details.metadata.payment_id
                  this.order_id = details.metadata.order_id
                }
                if (isStringJson(this.mData.details)) {
                  let d = JSON.parse(this.mData.details)
                  console.log(d)
                  details = d
                  this.payment_id = d.error.metadata.payment_id
                  this.order_id = d.error.metadata.order_id
                }
                // console.log('Transaction Details:',this.mData)
                this.myForm = new FormGroup({
                  donated_by: new FormControl(this.mData.donated_by || 'z', Validators.required),
                  address: new FormControl(this.mData.address, Validators.required),
                  state: new FormControl(this.mData.state, Validators.required),
                  donation_amount: new FormControl(this.mData.donation_amount, Validators.required),
                  donation_type: new FormControl({value: 'Online', disabled: true}, Validators.required),
                  idproof: new FormControl({value: 'PAN', disabled: true}, Validators.required),
                  email: new FormControl(this.mData.email, [Validators.required, Validators.email]),
                  country: new FormControl({value: 'India', disabled: true}, Validators.required),
                  nationality: new FormControl({value: 'Indian', disabled: true}, Validators.required),
                  zipcode: new FormControl(this.mData.zipcode, [Validators.required, Validators.maxLength(6)]),
                  city: new FormControl(this.mData.city, Validators.required),
                  pan: new FormControl(this.mData.pan, Validators.compose([Validators.required, Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}')])),
                  mobile: new FormControl(this.mData.mobile, [Validators.required, Validators.maxLength(10), Validators.pattern('^[0-9]{10}$')]),
                  details: new FormControl('details',Validators.required),
                  receipt_id: new FormControl(this.currentCounter,Validators.required),
                  order_id: new FormControl(this.order_id||'',Validators.required),
                  payment_id: new FormControl(this.payment_id||'',Validators.required),
                  groupKey: new FormControl(this.mData.groupkey||'GF',Validators.required),
                  description: new FormControl(this.mData.description||'General Donation',Validators.required),
                  method: new FormControl('ONLINE'),
                  totalAmountWithoutTax: new FormControl(this.mData.donation_amount||789,Validators.required),
                  addtax: new FormControl(false),
                  date: new FormControl(null, Validators.required),
                  createdAt: new FormControl(new Date(this.mData.createdAt),Validators.required),

                });
                if(this.mData.date) {
                  let d = (this.mData.date as Timestamp).toDate();
                  this.myForm.get('date').setValue(d);
                }
              }

            })
          })

        }
      })
      .catch((error) => {
        console.error('Error getting receipt:', error);
      });

  }

  ngOnInit(): void {
    let msg = `2024-2025-80G-ONL-`;
    this.getReceiptName()
  }


  submit() {
    if (this.myForm.valid) {
      this.myForm.get('totalAmountWithoutTax')?.patchValue(this.myForm.get('total_amount')?.value)
      if (this.updateCounter) {
        const documentRef = ref(this.mDatabase, 'receiptcounter/counter')
        set(documentRef, increment(1)).then(res => {
          this.mFirestore.collection('donations').add(this.myForm.value).then(res => {
            this.router.navigateByUrl('/home')
            let activity = {
              date: new Date().getTime(),
              section: 'Donation',
              action: 'Submit',
              description: `Donation Submit Attempt Donation by user`,
              currentIp: localStorage.getItem('currentip')!
            }
            this.mLogService.addLog(activity)
          })
        })
      } else {
        this.mFirestore.collection('donations').add(this.myForm.value).then(res => {
          this.router.navigateByUrl('/home')
        })
      }

    } else {
      console.log(this.findInvalidControls())

    }
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.myForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  private setReceiptDate(date: number, month: number, year: number, hour: number, minutes: number, ss: number) {
    let d = new Date()
    d.setDate(date)
    d.setMonth(month)
    d.setFullYear(year)
    d.setHours(hour)
    d.setMinutes(minutes)
    d.setSeconds(ss)
    this.receiptDate = d
    return d
  }
}

function isStringJson(value: any): boolean {
  try {
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
}
