

<div class="about">
  <h3 class="rounded"> Child Prescreening Form</h3>
  <br>
  <form class="row" [formGroup]="childForm">
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>First Name</mat-label>
          <input matInput (blur)="onBlur(firstname)" placeholder="First Name" required formControlName="firstName">
          <mat-error *ngIf="  firstname.invalid  &&  (firstname.touched || firstname.dirty)">
            <mat-error style="color: red;" *ngIf="firstname.errors?.required">*It is Required.</mat-error>
            <mat-error style="color: red;" *ngIf="firstname.errors?.pattern">*Enter Only characters.</mat-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>Middle Name</mat-label>
          <input matInput (blur)="onBlur(middlename)" placeholder="Middle Name"  formControlName="middleName">
          <mat-error *ngIf="  middlename.invalid  &&  (middlename.touched || middlename.dirty)">
            <mat-error style="color: red;" *ngIf="middlename.errors?.required">*It is Required.</mat-error>
            <mat-error style="color: red;" *ngIf="middlename.errors?.pattern">*Enter Only characters.</mat-error>
          </mat-error>
        </mat-form-field>

      </div>
      <div class="col-md-4 col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>Last Name</mat-label>
          <input matInput (blur)="onBlur(lastname)" placeholder="Last Name" required formControlName="lastName">
          <mat-error *ngIf="  lastname.invalid  &&  (lastname.touched || lastname.dirty)">
            <mat-error style="color: red;" *ngIf="lastname.errors?.required">*It is Required.</mat-error>
            <mat-error style="color: red;" *ngIf="lastname.errors?.pattern">*Enter Only characters.</mat-error>
          </mat-error>
        </mat-form-field>

      </div>

      <div class="col-md-4 col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>Gender</mat-label>
          <mat-select formControlName="gender">
            <mat-option value="Female">Female</mat-option>
            <mat-option value="Male">Male</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-4 col-sm-12">
        <mat-form-field appearance="outline">

          <mat-label>Age</mat-label>
          <mat-select  required formControlName="age">
            <mat-option value="0">0</mat-option>
            <mat-option value="1">1</mat-option>
            <mat-option value="2">2</mat-option>
            <mat-option value="3">3</mat-option>
            <mat-option value="4">4</mat-option>
            <mat-option value="5">5</mat-option>
            <mat-option value="6">6</mat-option>
            <mat-option value="7">7</mat-option>
            <mat-option value="8">8</mat-option>
            <mat-option value="9">9</mat-option>
            <mat-option value="10">10</mat-option>
            <mat-option value="11">11</mat-option>
            <mat-option value="12">12</mat-option>
            <mat-option value="13">13</mat-option>
            <mat-option value="14">14</mat-option>
          </mat-select>
        </mat-form-field>
      </div>


      <!--    pattern="[0-9]{10}$"-->
      <div class="col-md-4 col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>Mobile No.</mat-label>
          <input matInput placeholder="e.g. 1234567890" maxlength="10" required formControlName="mobile">
          <mat-icon matSuffix>phone</mat-icon>
          <mat-error *ngIf="  mobile.invalid  &&  (mobile.touched || mobile.dirty)">
            <mat-error style="color: red;" *ngIf="mobile.errors?.required">*It is Required.</mat-error>
            <mat-error style="color: red;" *ngIf="mobile.errors?.pattern">*Enter Only Number.</mat-error>
          </mat-error>
        </mat-form-field>
      </div>

<!--      <div class="col-md-4 col-sm-12">-->
<!--        <mat-form-field appearance="outline">-->
<!--          <mat-label>Pincode</mat-label>-->
<!--          <input #postalCode matInput required formControlName="pincode">-->
<!--          <mat-error *ngIf="  Pincode.invalid  &&  (Pincode.touched || Pincode.dirty)">-->
<!--            <mat-hint align="end">{{postalCode.value.length}} / 6</mat-hint>-->
<!--            <mat-error *ngIf="  Pincode.invalid  &&  (Pincode.touched || Pincode.dirty)">-->
<!--              <span style="color: red;" *ngIf="Pincode.errors?.required">*It is Required.</span>-->
<!--              <span style="color: red;" *ngIf="Pincode.errors?.pattern">*Enter Only Number.</span>-->
<!--              <span style="color: red;" *ngIf="Pincode.errors?.['minlength']">Zipcode must be of 6 digits</span>-->
<!--          </mat-error>-->
<!--          </mat-error>-->
<!--        </mat-form-field>-->
<!--      </div>-->

      <div class="col-md-4 col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>City</mat-label>
          <input matInput (blur)="onBlur(city)" placeholder="City" required formControlName="city">
          <mat-error *ngIf="  city.invalid  &&  (city.touched || city.dirty)">
            <mat-error style="color: red;" *ngIf="city.errors?.required">*It is Required.</mat-error>
            <mat-error style="color: red;" *ngIf="city.errors?.pattern">*Enter Only characters.</mat-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>District</mat-label>
          <input matInput (blur)="onBlur(district)" placeholder="District" required formControlName="district">
          <mat-error *ngIf="  District.invalid  &&  (District.touched || District.dirty)">
            <mat-error style="color: red;" *ngIf="District.errors?.required">*It is Required.</mat-error>
            <mat-error style="color: red;" *ngIf="District.errors?.pattern">*Enter Only characters.</mat-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>State</mat-label>
          <mat-select  placeholder="State" required formControlName="state">
          <mat-option *ngFor="let s of stateslist" [value]="s.name">{{s.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="  state.invalid  &&  (state.touched || state.dirty)">
            <mat-error style="color: red;" *ngIf="state.errors?.required">*It is Required.</mat-error>
            <mat-error style="color: red;" *ngIf="state.errors?.pattern">*Enter Only characters.</mat-error>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6 col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>Hospital Name</mat-label>
          <mat-select (blur)="onBlur(hospitalname)" matInput placeholder="hospitalname" required formControlName="hospitalname">
          <mat-option  *ngFor="let h of (hospitals$|async)" [value]="h.hospitalName">{{h.hospitalName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>


      <div class="col-md-6 col-sm-12">

        <mat-form-field appearance="outline">
          <mat-label>Diagnosis</mat-label>
          <mat-select multiple required formControlName="diagnosis">
            <mat-option disabled>--Select Diagnosis--</mat-option>
            <mat-option *ngFor="let diagnosis of (_diagnosis$|async)" [value]="diagnosis">{{diagnosis}}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="diagnosis.invalid  &&  (diagnosis.touched ||diagnosis.dirty)"></mat-error>
          <mat-error *ngIf="diagnosis.errors?.required"> *It is required</mat-error>
        </mat-form-field>

      </div>



      <div class="col-md-12 col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>Remark</mat-label>
          <input matInput (blur)="onBlur(remark)" required placeholder="remark" formControlName="remark">
          <mat-error *ngIf="  remark.invalid  &&  (remark.touched || remark.dirty)">
            <mat-error style="color: red;" *ngIf="remark.errors?.required">*It is Required.</mat-error>
            <mat-error style="color: red;" *ngIf="remark.errors?.pattern">*Enter Only Number.</mat-error>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <!--------------------------------------------- FAQ PART --------------------------------------------->
    <div class="col-md-12 col-sm-12">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              English
            </mat-panel-title>
            <mat-panel-description></mat-panel-description>

          </mat-expansion-panel-header>
          <div style="padding: 20px">
            <mat-form-field class="faq" appearance="outline">
              <mat-label><strong>Q1.</strong> Confirm the name of the child along with age. (Age limit 0-14)
              </mat-label>
              <input  matInput formControlName="q1">
            </mat-form-field>
            <mat-form-field class="faq" appearance="outline">
              <mat-label><strong>Q2.</strong> When did the family reach / come to the hospital? and where are they
                staying currently?
              </mat-label>
              <input matInput formControlName='q2'>
            </mat-form-field>

            <mat-form-field class="faq" appearance="outline">
              <mat-label><strong>Q3.</strong> Where does the family hail from (village/city/state)?</mat-label>
              <input matInput formControlName="q3">
            </mat-form-field>

            <mat-form-field class="faq" appearance="outline">
              <mat-label><strong>Q4.</strong> What is the father's profession (work)?</mat-label>
              <input matInput formControlName="q4">
            </mat-form-field>
            <mat-form-field class="faq" appearance="outline">
              <mat-label><strong>Q5.</strong> Is the child able to walk by himself/herself?</mat-label>
              <input matInput formControlName="q5">
            </mat-form-field>

            <mat-form-field class="faq" appearance="outline">
              <mat-label><strong>Q6.</strong> Do they have a patient's parents' aadhaar card & ration card? If the
                child does not have an aadhaar card, then share the birth certificate.
              </mat-label>
              <input matInput formControlName="q6">
            </mat-form-field>
            <mat-form-field class="faq" appearance="outline">
              <mat-label><strong>Q7.</strong> How many children do they have? If more than one then check whom have they
                left them with?
              </mat-label>
              <input matInput formControlName="q7">
            </mat-form-field>

            <mat-form-field class="faq" appearance="outline">
              <mat-label><strong>Q8.</strong> If the patient's mother is pregnant then she is not allowed to stay in the
                centre / in her place only one other person can stay with the patient like grandfather, grandmother,
                maternal uncle of the patient
              </mat-label>
              <input matInput formControlName="q8">
            </mat-form-field>

            <mat-form-field class="faq" appearance="outline">
              <mat-label><strong>Q9.</strong>Do they have any relatives/ friends staying in the same city (In this case
                closer to centre)?
              </mat-label>
              <input matInput formControlName="q9">
            </mat-form-field>
            <strong> Notice:</strong>

            <ol style="  text-align: justify;">
              <li>Meat, mutton is not allowed in the centre, only boiled egg can be given to the child.</li>
              <li>It is very important to maintain cleanliness in the centre.</li>
              <li>In case of any kind of misbehaviour, hurting any other patient's or misbehaving with any employee of
                the centre, they will be warned in writing.
              </li>
              <li>Only two people are allowed to stay with the patient / Please contact Access Life staff if there is
                a single occupant
              </li>
              <li>Other siblings of the patient are not allowed to stay in the centre</li>
              <li>You can stay in the centre as long as the patient is treated.</li>
            </ol>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <!--Purohit-->
      <!--7387392001-->

      <br>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              हिन्दी
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div style="padding: 20px">

            <mat-form-field class="faq" appearance="outline">
              <mat-label><strong>Q1.</strong> उम्र के साथ बच्चे के नाम की पुष्टि करें। (आयु सीमा 0 से 14 वर्ष से कम)
              </mat-label>
              <input matInput formControlName="q1">
            </mat-form-field>
            <mat-form-field class="faq" appearance="outline">
              <mat-label><strong>Q2.</strong> परिवार कब पहुंचा/अस्पताल हॉस्पिटल आया? और वे वर्तमान में कहाँ रह रहे हैं?
              </mat-label>
              <input matInput formControlName='q2'>
            </mat-form-field>

            <mat-form-field class="faq" appearance="outline">
              <mat-label><strong>Q3.</strong>परिवार (गाँव/राज्य/शहर) कहाँ से आता है?</mat-label>
              <input matInput formControlName="q3">
            </mat-form-field>

            <mat-form-field class="faq" appearance="outline">
              <mat-label><strong>Q4.</strong>पिता का पेशा (काम) क्या है?</mat-label>
              <input matInput formControlName="q4">
            </mat-form-field>
            <mat-form-field class="faq" appearance="outline">
              <mat-label><strong>Q5.</strong>क्या बच्चा अपने आप चलने में सक्षम है?</mat-label>
              <input matInput formControlName="q5">
            </mat-form-field>

            <mat-form-field class="faq" appearance="outline">
              <mat-label><strong>Q6.</strong>क्या उनके पास रोगी माता पिता का आधार कार्ड और राशन कार्ड है? अगर बच्चे के
                पास आधार कार्ड नहीं है तो जन्म प्रमाण पत्र साझा करें.
              </mat-label>

              <input matInput formControlName="q6">
            </mat-form-field>
            <mat-form-field class="faq" appearance="outline">
              <mat-label><strong>Q7.</strong>उनके कितने बच्चे हैं? यदि एक से अधिक हैं तो जांचें कि उन्होंने उन्हें किसके
                साथ छोड़ा है?
              </mat-label>

              <input matInput formControlName="q7">
            </mat-form-field>

            <mat-form-field class="faq" appearance="outline">
              <mat-label><strong>Q8.</strong>यदि रोगी की माँ गर्भवती है तो उसे केंद्र में रहने की अनुमति नहीं है / उनके
                स्थान पर रोगी के साथ अन्य केवल एक जन रह सकते हैं, जैसे रोगी के दादा, दादी, मामा।
              </mat-label>
              <input matInput formControlName="q8">
            </mat-form-field>

            <mat-form-field class="faq" appearance="outline">
              <mat-label><strong>Q9.</strong>क्या उनका कोई रिश्तेदार/मित्र एक ही शहर में रहता है (इस मामले में center के
                करीब)?
              </mat-label>

              <input matInput formControlName="q9">
            </mat-form-field>
            <strong> सूचना :</strong>
            <ol style="  text-align: justify;">
              <li>केंद्र में मांस ,मटन खाने की अनुमति नहीं है केवल उबला हुआ अंडा बच्चे को दे सकते है !</li>

              <li>केंद्र में साफ़ सफाई रखना बहुत जरुरी है !</li>
              <li> किसी भी प्रकार गलत व्यवहार ,किसी अन्य मॅरीजो को तकलिप पहुँचाना या केन्द के किसी कर्मचारी से ग़लत पेश
                आने पर उनको लिखित स्वरुप में चेतावनी दी जायेगी !
              </li>

              <li>मरीज़ के साथ केवल दो लोगो की रहनेकी अनुमति है! / यदि एक ही व्यक्ति रहनेवाले हे तो क्रिपया एक्सेस लाईफ
                के कर्मचारी से संपर्क करें
              </li>

              <li>मरीज के अन्य भाई बहन को केन्द्र में रहने की अनुमति नहीं है.</li>

              <li>जब तक मरीज का इलाज़ चलेगा तब तक आप केंद्र में रह सकते हो.</li>
            </ol>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <!--------------------------------------------- FAQ ENDED --------------------------------------------->
    <div class="row mt-2">
      <!--  <div class="col-md-3"></div>-->
      <div class="col-md-6 text-end">
        <button color="warn" mat-raised-button (click)="cancel()">Cancel</button> &nbsp;

      </div>
      <div class="col-md-6 tex-start">
        <button color="primary" mat-raised-button type="submit" (click)="SubmitData()">Save</button>

      </div>
      <!--  <div class="col-md-3"></div>-->
    </div>

  </form>


</div>




