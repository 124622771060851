<h1 class="text-center bg-warning">{{heading}}</h1>
<!--<prettyjson [obj]="yourFormGroup?.value"></prettyjson>-->

<div [formGroup]="yourFormGroup">
  <div class="container">
<div class="row">
  <mat-form-field appearance="outline"  class="col-md-3">
    <mat-label>Select</mat-label>
    <mat-select formControlName="triptype" (selectionChange)="withOrWithoutChild($event)">
      <mat-option  value="withChild">With Child</mat-option>
      <mat-option  value="withoutChild">Without Child </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-3" *ngIf="withChild" >
    <mat-label>Select Centre</mat-label>
    <mat-select   (selectionChange)="onCentreSelect($event)"  formControlName="centreCode">
      <mat-option *ngFor=" let c of (centres$|async) "
                  [value]="c.centreCode">{{c.centreName}} </mat-option>
      <!--      <mat-option  value="All">All Centres</mat-option>-->
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-12" *ngIf="withChild">
    <mat-label>Select Child</mat-label>
    <mat-select   formControlName="child_list"  (selectionChange)="onChildSelect($event)" multiple>
      <mat-option *ngFor=" let c of (childBycentre)  "
                  [value]="c" >{{c.firstName}} {{c.lastName}} </mat-option>
      <!--      <mat-option  value="All">All Centres</mat-option>-->
    </mat-select>
  </mat-form-field>
<!--  <div class="col-md-2" *ngIf="this.currentSelectedChild">-->
<!--<button mat-raised-button color="primary" (click)="addChildtoChildListArray(this.currentSelectedChild!!)" >Add Child</button>-->
<!--  </div>-->
<!--  </div>-->
<!--&lt;!&ndash;  <prettyjson [obj]="this.guardians"></prettyjson>&ndash;&gt;-->

<!--<div  *ngFor="let c of getChildList.controls;let i= index">-->
<!--  <div  formGroupName="i">-->
<!--    <table>-->
<!--    </table>-->
<!--<p>{{getChildList.at(i)?.get('firstName')?.value}} </p>-->
<!--  </div>-->





  <mat-form-field appearance="outline" class="col-md-12" *ngIf="withChild">
    <mat-label>Caretaker</mat-label>
    <mat-select  formControlName="caretakers" multiple>
      <mat-option *ngFor=" let c of (guardians)  "
                  [value]="c">{{c.firstName}} {{c.lastName}} </mat-option>
      <!--      <mat-option  value="All">All Centres</mat-option>-->
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-12 " *ngIf="withoutChild" >
    <mat-label>Person's Name</mat-label>
    <input matInput formControlName="personName" placeholder="Enter person's name">
  </mat-form-field>

  <!-- Vehicle Information Section -->


    <h2>Trip Start and End Date</h2>
    <mat-form-field appearance="outline" class="col-md-3">
      <input matInput [matDatepicker]="startDatePicker" placeholder="Start Date" formControlName="trip_started_at" required>
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-md-3">
      <input matInput [matDatepicker]="endDatePicker" placeholder="End Date" formControlName="trip_ended_at" required>
      <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-md-3 "  >
      <mat-label>From</mat-label>
      <input matInput formControlName="pick_up_location" placeholder="Enter Start location">
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-md-3"  >
    <mat-label>To</mat-label>
    <input matInput formControlName="drop_location" placeholder="Enter End Location">
  </mat-form-field>
    <mat-form-field appearance="outline" class="col-md-3 "  >
      <mat-label>Start reading</mat-label>
      <input matInput formControlName="pick_up_meter_reading" placeholder="Start Reading in km">
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-md-3 "  >
      <mat-label>End Reading</mat-label>
      <input matInput formControlName="drop_meter_reading" placeholder="End Reading in km">
    </mat-form-field>

    <h2>Vehicle Information</h2>

    <div formGroupName="vehicle_info" class="row">
    <mat-form-field appearance="outline" class="col-md-3">
      <mat-label>Driver Name:</mat-label>
      <mat-select formControlName="driverName" (selectionChange)="onDriverSelect()">
        <mat-option *ngFor="let model of drivers" [value]="model.firstName+' '+model.lastName">
          {{ model.firstName }} {{ model.lastName }}
        </mat-option>
      </mat-select>
    </mat-form-field>


<!--    <mat-form-field appearance="outline" class="col-md-3">-->
<!--      <input matInput placeholder="Driver Address" formControlName="driverAddress" required>-->
<!--    </mat-form-field>-->


<!--    <mat-form-field appearance="outline" class="col-md-3">-->
<!--      <input matInput placeholder="Driver Contact Number" formControlName="driverContactNo">-->
<!--    </mat-form-field>-->
<!--    <mat-form-field appearance="outline" class="col-md-3">-->
<!--      <input matInput placeholder="Email" formControlName="email" required>-->
<!--    </mat-form-field>-->
<!--    <mat-form-field appearance="outline" class="col-md-3">-->
<!--      <input matInput placeholder="License Number" formControlName="licenseNumber" required>-->
<!--    </mat-form-field>-->

    <mat-form-field appearance="outline" class="col-md-3">
      <mat-label>Vehicle Number</mat-label>
      <mat-select placeholder="Select Driver" formControlName="vehicleNumber" required>
        <mat-option *ngFor="let vehicle of vehicles" [value]="vehicle.vehicleNumber">{{ vehicle.vehicleNumber }}</mat-option>
      </mat-select>
    </mat-form-field>

<!--    <mat-form-field appearance="outline" class="col-md-3">-->
<!--      <mat-label>Vehicle Type</mat-label>-->
<!--      <mat-select placeholder="Select Driver" formControlName="vehicleType" required>-->
<!--        <mat-option *ngFor="let vehicle of vehicles" [value]="vehicle.type">{{ vehicle.typeOfVehicle }}</mat-option>-->
<!--      </mat-select>-->
<!--    </mat-form-field>-->


  </div>

  <!-- Trip Information Section -->
  <h2>Trip Information</h2>
<!--  <mat-form-field appearance="outline" class="col-md-3">-->
<!--    <input matInput placeholder="Drop Location" formControlName="drop_location" required>-->
<!--  </mat-form-field>-->


<!--  <mat-form-field appearance="outline" class="col-md-3">-->
<!--    <input matInput placeholder="Pick Up Location" formControlName="pick_up_location" required>-->
<!--  </mat-form-field>-->


    <!-- your-component.component.html -->
    <mat-form-field appearance="outline" class="col-md-6">
      <mat-label>Purpose of Trip</mat-label>
      <mat-select placeholder="Select Purpose"  formControlName="purpose_of_trip" required>
        <mat-option *ngFor="let purpose of purposeOfTrips$|async" value="{{purpose}}">{{purpose}}</mat-option>
      </mat-select>
    </mat-form-field>


  <mat-form-field appearance="outline" class="col-md-6">
    <input matInput placeholder="Remark" formControlName="remark" required>
  </mat-form-field>


    <
    <mat-slide-toggle
            class="example-margin" formControlName="isRoundTrip">
Return Trip
    </mat-slide-toggle>

<!--    <mat-form-field appearance="outline" class="col-md-3">-->
<!--      <mat-label>Status</mat-label>-->
<!--      <mat-select placeholder="Select Status" formControlName="status" required>-->
<!--        <mat-option value="completed">Completed</mat-option>-->
<!--        <mat-option value="canceled">Canceled in Between</mat-option>-->
<!--        <mat-option value="failed">Failed</mat-option>-->
<!--      </mat-select>-->
<!--    </mat-form-field>-->

  </div>
  <!-- Submit Button -->
 <div class="d-flex justify-content-center gap-2">
   <button mat-raised-button color="accent" (click)="onCancel()">Cancel</button>
   <button mat-raised-button color="primary" (click)="submitForm()">Submit</button>

 </div>
</div>



<!--<h1>Trip Form</h1>-->
<!--<form [formGroup]="yourFormGroup">-->

<!--  &lt;!&ndash; Common Fields &ndash;&gt;-->
<!--  <mat-radio-group formControlName="isChildTrip" (change)="switchFormType()">-->
<!--    <mat-radio-button value="withChild">With Child</mat-radio-button>-->
<!--    <mat-radio-button value="withoutChild">Without Child</mat-radio-button>-->
<!--  </mat-radio-group>-->

<!--  <div *ngIf="!isChildTrip">-->
<!--    &lt;!&ndash; Fields for Trip Without Child &ndash;&gt;-->
<!--    <mat-form-field appearance="outline">-->
<!--      <mat-label>Vehicle Number</mat-label>-->
<!--      <input matInput formControlName="vehicleNumber" required>-->
<!--    </mat-form-field>-->
<!--    &lt;!&ndash; Add other fields specific to trip without child &ndash;&gt;-->
<!--  </div>-->

<!--  <div *ngIf="isChildTrip">-->
<!--    &lt;!&ndash; Fields for Trip With Child &ndash;&gt;-->
<!--    <mat-form-field appearance="outline">-->
<!--      <mat-label>Vehicle Number</mat-label>-->
<!--      <input matInput formControlName="vehicleNumber" required>-->
<!--    </mat-form-field>-->
<!--    <mat-form-field appearance="outline">-->
<!--      <mat-label>Driver</mat-label>-->
<!--      <input matInput formControlName="driver" required>-->
<!--    </mat-form-field>-->
<!--    <mat-form-field appearance="outline">-->
<!--      <mat-label>Purpose of Trip</mat-label>-->
<!--      <input matInput formControlName="purposeOfTrip" required>-->
<!--    </mat-form-field>-->
<!--    &lt;!&ndash; Add other fields specific to trip with child &ndash;&gt;-->
<!--  </div>-->

<!--  &lt;!&ndash; Common Fields &ndash;&gt;-->
<!--  <mat-form-field appearance="outline">-->
<!--    <mat-label>Start Ek</mat-label>-->
<!--    <input matInput formControlName="startEk" required>-->
<!--  </mat-form-field>-->

<!--  <mat-form-field appearance="outline">-->
<!--    <mat-label>Start KM Image</mat-label>-->
<!--    <input matInput formControlName="startKmImage" required>-->
<!--  </mat-form-field>-->

<!--  &lt;!&ndash; Additional Fields based on Form Type &ndash;&gt;-->
<!--  <div *ngIf="isChildTrip">-->
<!--    <mat-form-field appearance="outline">-->
<!--      <mat-label>End KM</mat-label>-->
<!--      <input matInput formControlName="endKm" required>-->
<!--    </mat-form-field>-->
<!--    <mat-form-field appearance="outline">-->
<!--      <mat-label>End KM Image</mat-label>-->
<!--      <input matInput formControlName="endKmImage" required>-->
<!--    </mat-form-field>-->
<!--    &lt;!&ndash; Add other fields specific to trip with child &ndash;&gt;-->
<!--  </div>-->

<!--  <div *ngIf="!isChildTrip">-->
<!--    <mat-form-field appearance="outline">-->
<!--      <mat-label>Number of Persons Going</mat-label>-->
<!--      <input matInput formControlName="numPersonsGoing">-->
<!--    </mat-form-field>-->
<!--    <mat-form-field appearance="outline">-->
<!--      <mat-label>Names of Persons Going</mat-label>-->
<!--      <textarea matInput formControlName="namesOfPersonsGoing"></textarea>-->
<!--    </mat-form-field>-->
<!--    &lt;!&ndash; Add other fields specific to trip without child &ndash;&gt;-->
<!--  </div>-->

<!--  &lt;!&ndash; Common Fields &ndash;&gt;-->
<!--  <mat-form-field appearance="outline">-->
<!--    <mat-label>Remark</mat-label>-->
<!--    <textarea matInput formControlName="remark" required></textarea>-->
<!--  </mat-form-field>-->

<!--  &lt;!&ndash; Submit Button &ndash;&gt;-->
<!--  <button mat-raised-button color="primary" (click)="submitForm()">Submit</button>-->
<!--</form>-->

