<div class="container ">
    <div class="row">
        <div class="col-md-2 col-sm-2">
            <h4 class="text-center"><strong>Admission History</strong></h4>
            <mat-stepper orientation="vertical">
                <ng-container *ngFor="let d of  child.admissionData;let i = index">
                    <mat-step>
                        <ng-template *ngIf="i==0" matStepLabel>Admission</ng-template>
                        <ng-template *ngIf="i==1" matStepLabel>Discharge</ng-template>
                        <ng-template *ngIf="i%2==0" matStepLabel>Followup Admission</ng-template>
                        <ng-template *ngIf="i%2==1" matStepLabel>Followup Discharge</ng-template>
                        <ng-template matStepContent>
                            <ng-container *ngIf="d.dateOfAdmission">{{d.dateOfAdmission.toDate() | date : 'dd/MM/yyyy' }}
                                <span *ngIf="d.centreCode">  ({{d.centreCode}})</span></ng-container>
                            <ng-container
                                    *ngIf="d.dateOfDischarge">{{d.dateOfDischarge.toDate() | date : 'dd/MM/yyyy' }}</ng-container>
                        </ng-template>

                    </mat-step>

                </ng-container>

            </mat-stepper>

        </div>
        <div class="col-md-6">
            <div class=" pt-5 ">

                <mat-card>
                    <mat-card-header>
                        <!--            <mat-card-title-group>-->
                        <div mat-card-avatar class="example-header-image">
                            <img [src]="profileUrl" alt='child photo' class="img-fluid">
                        </div>
                        <mat-card-title>{{child.firstName}} {{child.lastName}}</mat-card-title>
                        <mat-card-subtitle> {{child.childCode}} </mat-card-subtitle>
                        <!--              <img mat-card-sm-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" >-->
                        <!--            </mat-card-title-group>-->
                    </mat-card-header>
                </mat-card>
                <hr>

                <mat-accordion class="example-headers-align" multi>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            Child Details
                        </mat-expansion-panel-header>

                        <mat-card>

                            <mat-card-content>
                                <table class="table table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th scope="row"> Base Centre</th>
                                        <td>{{child.baseCentreName}} ({{child.baseCentreCode}})</td>
                                    </tr>
                                    <tr>
                                        <th scope="row"> Current Centre</th>
                                        <td>{{child.centreName}} ({{child.centreCode}})</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Child Code</th>
                                        <td>{{child.childCode}}</td>
                                    </tr>
                                    <tr>
                                        <th>Unit Alloted</th>
                                        <td>{{child.selectUnit}}</td>
                                    </tr>

                                    <tr>
                                        <th scope="row">First Name</th>
                                        <td>{{child.firstName}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Middle Name</th>
                                        <td>{{child.middleName}}</td>
                                    </tr>

                                    <tr>
                                        <th>Last Name</th>
                                        <td>{{child.lastName}}</td>
                                    </tr>
                                    <tr>
                                      <th>Mothers Name</th>
                                      <td>{{child.mothername}}</td>
                                    </tr>
                                    <tr>
                                        <th>Mobile</th>
                                        <td>{{child.mobile}}</td>
                                    </tr>
                                    <tr>
                                        <th>Blood Group Type</th>
                                        <td>{{child.bloodGroup}}</td>
                                    </tr>
                                    <tr>
                                        <th>Date Of Birth (DD/MM/YYYY)</th>
                                        <td>{{dateFormatted| date }}</td>
                                    </tr>
                                    <tr>
                                        <th>Age as on Date</th>
                                        <td>{{getCalculatedAge(child.dob) }}</td>
                                    </tr>
                                    <tr>
                                        <th>Child Aadhar Code</th>
                                        <td>{{child.childAadhaar}}</td>
                                    </tr>
                                    <tr>
                                        <th>Address</th>
                                        <td>{{child.address}}</td>
                                    </tr>
                                    <tr>
                                        <th>City</th>
                                        <td>{{child.city}}</td>
                                    </tr>
                                    <tr>
                                        <th>District</th>
                                        <td>{{child.district}}</td>
                                    </tr>
                                    <tr>
                                        <th>State</th>
                                        <td>{{child.state}}</td>
                                    </tr>
                                    <tr>
                                        <th>Country</th>
                                        <td ><span *ngIf="child.country==null">India </span>  {{child.country}}</td>
                                    </tr>
                                    <tr>
                                        <th>Gender</th>
                                        <td>{{child.gender}}</td>
                                    </tr>
                                    <tr>
                                        <th>Pincode</th>
                                        <td>{{child.pincode}}</td>
                                    </tr>

                                    <tr>
                                        <th>Hobbies</th>
                                        <td>{{child.hobbies}}</td>
                                    </tr>
                                    <tr>
                                        <th>Apsirations</th>
                                        <td>{{child.aspirations}}</td>
                                    </tr>
                                    <tr>
                                        <th>Allergies</th>
                                        <td>{{child.allergies}}</td>
                                    </tr>
                                    <!--              <tr><th >Method</th><td>{{child.method}}</td></tr>-->
                                    <!--Basic Info-->
                                    <tr>
                                        <th>Stayed At AL Centre Before</th>
                                        <td>{{child.stayedBefore}}</td>
                                    </tr>
                                    <tr>
                                        <th>AL  Bag given</th>
                                        <td>{{child.bag}}</td>
                                    </tr>
                                    <tr>
                                        <th> Mother / Father / Guardian will stay  </th>
                                        <td>{{child.guardianStayStatus}}</td>
                                    </tr>

                                    <ng-container *ngFor="let g of child.guardians;let  i =index">
                                        <tr>
                                            <th>Caretaker</th>
                                            <td>{{i + 1}}</td>
                                        </tr>
                                        <tr>
                                            <th>Caretaker First Name</th>
                                            <td>{{g.firstName}}</td>
                                        </tr>
                                        <tr>
                                            <th>Caretaker Middle Name</th>
                                            <td>{{g.middleName}}</td>
                                        </tr>
                                        <tr>
                                            <th> Last Name</th>
                                            <td>{{g.lastName}}</td>
                                        </tr>

                                        <!--                <tr>-->
                                        <!--                  <th>Caretaker Aadhar Number</th>-->
                                        <!--                  <td>{{g.aadharNumber}}</td>-->
                                        <!--                </tr>-->
                                        <tr>
                                            <th>Annual Income</th>
                                            <td>{{g.annualIncome}}</td>
                                        </tr>
                                        <tr>
                                            <th>Caretaker Contact Number</th>
                                            <td>{{g.contactNo}}</td>
                                        </tr>

                                        <tr>
                                            <th>Caretaker Occupation</th>
                                            <td>{{g.occupation}}</td>
                                        </tr>
                                        <tr>
                                            <th>Caretaker Relation With Child</th>
                                            <td>{{g.relation}}</td>
                                        </tr>
                                        <tr>
                                            <th>Caretaker Other Documents</th>
                                            <td>{{g.otherDocuments}}</td>
                                        </tr>
                                        <tr>
                                            <th>Caretaker Other Skills</th>
                                            <td>{{g.skills}}</td>
                                        </tr>

                                    </ng-container>
                                    <tr>
                                        <th>Emergency Contact 1</th>

                                        <td> {{child.emergencyContactName1}}<br>
                                            {{child.emergencyContactNumber1}}<br>
                                            {{child.emergencyContactRelation1}}</td>

                                    </tr>

                                    <tr>
                                        <th>Emergency Contact 2</th>

                                        <td> {{child.emergencyContactName2}} <br>
                                            {{child.emergencyContactNumber2}} <br>
                                            {{child.emergencyContactRelation2}}</td>

                                    </tr>
                                    <ng-container *ngFor="let s of child.siblings;let  i =index">

                                        <tr>
                                            <th>Sibling</th>
                                            <td>{{i + 1}}</td>
                                        </tr>
                                        <tr>
                                            <th>Sibling Name</th>
                                            <td>{{s.firstName}}</td>
                                        </tr>
                                        <tr>
                                            <th>Sibling Gender</th>
                                            <td>{{s.gender}}</td>
                                        </tr>
                                        <tr>
                                            <th>Sibling Age</th>
                                            <td>{{s.age}}</td>
                                        </tr>
                                        <tr>
                                            <th>Sibling Contact Number</th>
                                            <td>{{s.mobile}}</td>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <th>Hospital Name</th>
                                        <td >
                                          <ng-container *ngIf="externalUser">{{child.hospital}}</ng-container>
                                        <ng-container *ngIf="!externalUser" >
                                          <mat-form-field appearance="outline" class="col-md-12"  >
                                            <mat-label>Select Hospital</mat-label>
                                            <mat-select  [(ngModel)]="selectedHospital" (selectionChange)="onHospitalChange($event)" >
                                              <mat-option *ngFor="let c of (hospital$ | async)" [value]="c.hospitalName">{{ c.hospitalName }}</mat-option>
                                            </mat-select>
                                          </mat-form-field>
                                          <br>
                                          <button  *ngIf="updated==false" mat-raised-button color="primary" (click)="updateHospital()">Update Hospital</button>
                                        </ng-container>

                                        </td>
                                    </tr>

                                    <tr>
                                        <th>Doctors Name</th>
                                        <td>{{child?.doctorName}}</td>
                                    </tr>
                                    <tr>
                                        <th>File Number</th>
                                        <td>{{child?.fileNo}}</td>
                                    </tr>

                                    <tr>
                                        <th>Medical Social Worker Name</th>
                                        <td>{{child?.socialName}}</td>
                                    </tr>
                                    <tr>
                                        <th>Height At the time of Admission</th>
                                        <td>{{child?.height}} (in cm)</td>
                                    </tr>
                                    <tr>
                                        <th>Weigh At the time of Admission</th>
                                        <td>{{child?.weight}} (in kg)</td>
                                    </tr>
                                    <tr>
                                        <th>Prescribed Treatment</th>
                                        <td>{{child?.prescribed}}</td>
                                    </tr>
                                    <tr>
                                        <th>Allergies</th>
                                        <td>{{child?.allergiesSelected}}</td>
                                    </tr>
                                    <tr>
                                        <th>Diagnosis</th>
                                        <td>{{child?.diagnosisSelected}}</td>
                                    </tr>
                                    <tr>
                                        <th>Child Story</th>
                                        <td>{{child?.sickness}}</td>
                                    </tr>

                                    <tr>
                                        <th>Expected Duration of Treatment</th>
                                        <td>{{child?.months}} </td>
                                        <td>{{child?.days}} </td>
                                        <td>{{child?.treatment}} </td>
                                    </tr>
                                    <tr>
                                        <th>Other Medical Information</th>
                                        <td>{{child.medical}}</td>
                                    </tr>
                                    </tbody>
                                </table>

                            </mat-card-content>
                        </mat-card>
                    </mat-expansion-panel>
                </mat-accordion>

            </div>
            <!--------------------------------------------- TABLE ENDED --------------------------------------------->

            <!--------------------------------------------- DISCHARGE PROCESS FIELDS --------------------------------------------->
          <ng-container  *ngIf="!externalUser">
          <mat-card class="mt-2" *ngIf="showDischarge">
                <mat-card-header>
                    <mat-card-title *ngIf="child.admissionData.length==1">Discharge Form</mat-card-title>
                    <mat-card-title *ngIf="child.admissionData.length!=1">Follow Up Discharge Form</mat-card-title>
                  <mat-card-title *ngIf="child.admissionData.length>2">Follow Up Admission</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <form [formGroup]="dischargeForm">

                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <mat-form-field appearance="outline" class="example-form-field">
                                    <mat-label>Height (in cm)</mat-label>
                                    <input matInput formControlName="height" maxlength="3">
                                    <mat-error *ngIf="dischargeForm.get('height')?.errors?.required">Please enter
                                        height
                                    </mat-error>
                                    <mat-error *ngIf="dischargeForm.get('height')?.errors?.['pattern']">Enter numbers
                                        only
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <mat-form-field appearance="outline" class="example-form-field">
                                    <mat-label>Weight</mat-label>
                                    <input matInput formControlName="weight" maxlength="5">
                                    <mat-error *ngIf="dischargeForm.get('weight')?.errors?.required">Please enter
                                        weight
                                    </mat-error>
                                    <mat-error *ngIf="dischargeForm.get('weight')?.errors?.['pattern']">Enter numbers
                                        only
                                    </mat-error>

                                </mat-form-field>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <mat-form-field class="example-form-field" appearance="outline">
                                    <mat-label>Choose a date</mat-label>
                                    <input matInput [matDatepicker]="dischargeDatePicker"
                                           formControlName="dischargeDate" [max]="maxDate">
                                    <mat-hint>DD/MM/YYYY</mat-hint>
                                    <mat-datepicker-toggle matSuffix [for]="dischargeDatePicker">
                                        <!--            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>-->
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #dischargeDatePicker></mat-datepicker>
                                </mat-form-field>

                            </div>

                            <div class="col-md-4 col-sm-12">
                                <mat-form-field appearance="outline" class="example-form-field">
                                    <mat-label>Next Followup Date</mat-label>
                                    <input matInput [matDatepicker]="followUpPicker"
                                           formControlName="followUpDate">
                                    <mat-hint>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        DD/MM/YYYY
                                    </mat-hint>
                                    <mat-datepicker-toggle matSuffix [for]="followUpPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #followUpPicker></mat-datepicker>
                                </mat-form-field>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field appearance="outline" class="example-form-field">
                                    <mat-label>Reason for discharge</mat-label>
                                    <mat-select formControlName="reason">
                                        <mat-option>--Reason for Discharge--</mat-option>
                                        <mat-option value="Always Champion">Always Champion</mat-option>
                                        <mat-option value="Happy Discharge">Happy Discharge</mat-option>
                                        <mat-option value="Followup Treatment Completed">Followup Treatment Completed
                                        </mat-option>
                                        <mat-option value="OM SHANTI">OM SHANTI</mat-option>
                                        <mat-option value="Palliative Discharge">Palliative Discharge</mat-option>
                                        <mat-option value="Refer to Outside Hospital">Refer to Outside Hospital
                                        </mat-option>
                                        <mat-option value="DAMA">DAMA</mat-option>
                                        <mat-option value="Non Complieance">Non-Compliance</mat-option>
                                        <mat-option value="Self Discharge">Self Discharge</mat-option>
                                    </mat-select>

                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field appearance="outline" class="example-form-field">
                                    <mat-label>Remark</mat-label>
                                    <textarea matInput placeholder="Enter Remark" formControlName="remark"></textarea>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 ">
                                <button mat-button mat-raised-button color="primary" (click)="dischareChild()">
                                    Discharge
                                </button>
                            </div>

                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
            <!--------------------------------------------- DISCHARGE PROCESS ENDED --------------------------------------------->

            <!--------------------------------------------- FOLLOWUP ADMISSION PROCESS START --------------------------------------------->
            <mat-card class="mt-2" *ngIf="!showDischarge">

                <mat-card-header>
                    <mat-card-title>Followup Admission Form</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <form [formGroup]="admissionForm">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <mat-form-field class="example-full-width col-md-12" appearance="outline" >
                                    <mat-label>Select AL Center</mat-label>
                                    <mat-select formControlName="selectCentre" required
                                                (selectionChange)="fetchUnits($event)">
                                        <mat-option> --Select Centre--</mat-option>
                                        <ng-container *ngFor="let option of (centres$|async) ;let i = index">
                                            <mat-option *ngIf="option.isArchive!=true "
                                                        [value]="this.option">{{option.centreName}}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error *ngIf="getCentre?.errors?.required">*It is Required.</mat-error>
                                    <!--        <mat-error style="color: red;" *ngIf="getCentre.errors?.pattern">*Enter Only characters.</mat-error>-->
                                    <!--          <mat-error *ngIf="getCentre.invalid  &&  (getCentre.touched ||getCentre.dirty)"></mat-error>-->

                                </mat-form-field>
                            </div>
                            <!--------------------------------------------- Admission Date --------------------------------------------->
                            <div class="col-md-6 col-sm-12">
                                <mat-form-field class="example-form-field" appearance="outline">
                                    <mat-label>Admission Date</mat-label>
                                    <input matInput [matDatepicker]="admissionDatePicker" [max]="maxDate"
                                           formControlName="dateOfAdmission">
                                    <mat-hint>DD/MM/YYYY</mat-hint>
                                    <mat-datepicker-toggle matSuffix [for]="admissionDatePicker">
                                        <!--            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>-->
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #admissionDatePicker></mat-datepicker>
                                </mat-form-field>

                            </div>
                            <div class="col-md-6 col-sm-12">
                                <mat-form-field class="example-form-field" appearance="outline">
                                    <mat-label>Tentative Discharge Date</mat-label>
                                    <input matInput [matDatepicker]="tentative"
                                           formControlName="tentativeDischargeDate">
                                    <mat-hint>DD/MM/YYYY</mat-hint>
                                    <mat-datepicker-toggle matSuffix [for]="tentative">
                                        <!--            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>-->
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #tentative></mat-datepicker>
                                </mat-form-field>

                            </div>
                            <!--------------------------------------------- Unit Selection --------------------------------------------->
                            <div class="col-md-6 col-sm-12">
                                <mat-form-field appearance="outline" class="example-form-field">
                                    <mat-label>Select Unit</mat-label>
                                    <mat-select formControlName="selectUnit" required>
                                        <mat-option *ngFor="let unit of (units$|async)" [value]="unit.key"
                                                    [disabled]="unit.status==='Alloted'">{{unit.floorNo}}
                                            - {{unit.order}} - {{unit.status}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <mat-form-field appearance="outline" class="example-form-field">
                                    <mat-label>Height (in cm)</mat-label>
                                    <input matInput formControlName="height" maxlength="3">
                                    <mat-error *ngIf="admissionForm.get('height')?.errors?.required">Please enter
                                        height
                                    </mat-error>
                                    <mat-error *ngIf="admissionForm.get('height')?.errors?.['pattern']">Enter numbers
                                        only
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <mat-form-field appearance="outline" class="example-form-field">
                                    <mat-label>Weight</mat-label>
                                    <input matInput formControlName="weight" maxlength="5">
                                    <mat-error *ngIf="admissionForm.get('weight')?.errors?.required">Please enter
                                        weight
                                    </mat-error>
                                    <mat-error *ngIf="admissionForm.get('weight')?.errors?.['pattern']">Enter numbers
                                        only
                                    </mat-error>

                                </mat-form-field>
                            </div>
                            <!--              <div class="col-md-4">-->
                            <!--                <mat-form-field appearance="outline" class="example-form-field">-->
                            <!--                  <mat-label>Reason for discharge</mat-label>-->
                            <!--                  <mat-select formControlName="reason">-->
                            <!--                    <mat-option>&#45;&#45;Reason for Discharge&#45;&#45;</mat-option>-->
                            <!--                    <mat-option value="Happy Discharge">Happy Discharge</mat-option>-->
                            <!--                    <mat-option value="Child Expired">Child Expired</mat-option>-->
                            <!--                    <mat-option value="Palliative Discharge">Palliative Discharge</mat-option>-->
                            <!--                    <mat-option value="Refer to Outside Hospital">Refer to Outside Hospital</mat-option>-->
                            <!--                    <mat-option value="DAMA">DAMA</mat-option>-->
                            <!--                    <mat-option value="Non Complieance">Non-Compliance</mat-option>-->
                            <!--                    <mat-option value="Self Discharge">Self Discharge</mat-option>-->
                            <!--                  </mat-select>-->

                            <!--                </mat-form-field>-->
                            <!--              </div>-->
                            <div class="col-md-6">
                                <mat-form-field appearance="outline" class="example-form-field">
                                    <mat-label>Remark</mat-label>
                                    <textarea matInput placeholder="Enter Remark" formControlName="remark"></textarea>
                                </mat-form-field>
                            </div>
                            <!--    Unit Allotment Part-->
                            <div class="col-md-8 col-sm-12 p-2" *ngIf="isSelectedCentre">
                                <ng-container *ngIf="selectedCenter">
                                    <div class="col-md-10 col-sm-12 mb-1 text-center" style="display: flex;flex-direction: row;  justify-content: space-around;
">
                                        <div class=" text-center " id="total" style="flex-grow: 1">
                                            <p>Total <br>
                                                {{selectedCenter.totalUnits}}</p>
                                        </div>
                                        <div class=" text-center" id="available" style="flex-grow: 1">
                                            <p>Available <br>
                                                {{selectedCenter.availableUnits}}</p>
                                        </div>
                                        <div class=" text-center" id="occupied" style="flex-grow: 1">
                                            <p>Occupied <br>
                                                {{selectedCenter.occupiedUnits}}</p>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>

                          <div class="col-md-4">
                                <button mat-button mat-raised-button color="primary" (click)="reAdmitChild()">
                                    Admission
                                </button>
                            </div>

                        </div>
                    </form>

                </mat-card-content>


              <mat-card-header>
                <mat-card-title>Followup Admission Question</mat-card-title>
              </mat-card-header>
              <form [formGroup]="followupadmissionForm">
                <div class="row">
                  <!--------------------------------------------- FAQ PART --------------------------------------------->
                  <div class="col-md-12 col-sm-12 mb-4">
                    <mat-accordion>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            English
                          </mat-panel-title>
                          <mat-panel-description></mat-panel-description>

                        </mat-expansion-panel-header>
                        <mat-form-field class="faq" appearance="outline">
                          <mat-label><strong>Q1.</strong> What are the child’s areas of interest/hobbies?
                          </mat-label>
                          <input  matInput formControlName="q1">
                        </mat-form-field>

                        <!--QUESTION SECOND-->
                        <mat-form-field class="faq" appearance="outline">
                          <mat-label><strong>Q2.</strong>Is the child going to School?</mat-label>
                          <mat-select formControlName="q2">
                            <mat-option  value="Yes">Yes</mat-option>
                            <mat-option  value="No">If No, kindly select the appropriate reasons mentioned below;</mat-option>
                          </mat-select>
                        </mat-form-field>

                        <div *ngIf="followupadmissionForm.get('q2')?.value === 'Yes'">
                          <mat-form-field class="faq" appearance="outline">
                            <mat-label>Name of the School’s</mat-label>
                            <input matInput formControlName="schoolName">
                          </mat-form-field>
                        </div>


                        <div *ngIf="followupadmissionForm.get('q2')?.value === 'Yes'">
                          <mat-form-field class="faq" appearance="outline">
                            <mat-label>Standard</mat-label>
                            <input matInput formControlName="Standard">
                          </mat-form-field>
                        </div>
                        <div *ngIf="followupadmissionForm.get('q2')?.value === 'Yes'">
                          <mat-form-field class="faq" appearance="outline">
                            <mat-label>Which Medium?</mat-label>
                            <input matInput formControlName="Medium">
                          </mat-form-field>
                        </div>
                        <div *ngIf="followupadmissionForm.get('q2')?.value === 'No'">
                          <mat-form-field class="faq" appearance="outline">
                            <mat-label>The child’s interest level is low</mat-label>
                            <textarea matInput formControlName="childInterest" rows="5"></textarea>
                          </mat-form-field>
                        </div>
                        <div *ngIf="followupadmissionForm.get('q2')?.value === 'No'">
                          <mat-form-field class="faq" appearance="outline">
                            <mat-label>Financial Problem</mat-label>
                            <textarea matInput formControlName="finacialProblem" rows="5"></textarea>
                          </mat-form-field>
                        </div>
                        <div *ngIf="followupadmissionForm.get('q2')?.value === 'No'">
                          <mat-form-field class="faq" appearance="outline">
                            <mat-label>Poor Health</mat-label>
                            <textarea matInput formControlName="poorHealth" rows="5"></textarea>
                          </mat-form-field>
                        </div>
                        <div *ngIf="followupadmissionForm.get('q2')?.value === 'No'">
                          <mat-form-field class="faq" appearance="outline">
                            <mat-label>Others</mat-label>
                            <textarea matInput formControlName="other" rows="5"></textarea>
                          </mat-form-field>
                        </div>
                        <!--QUESTION SECOND END-->

                        <!-- QUESTION THIRD-->
                        <mat-form-field class="faq" appearance="outline">
                          <mat-label><strong>Q3.</strong> Are you following any specific daily routine?</mat-label>
                          <mat-select formControlName="q3">
                            <mat-option  value="Yes">Yes</mat-option>
                            <mat-option  value="No">No</mat-option>
                          </mat-select>
                        </mat-form-field>

                        <div *ngIf="followupadmissionForm.get('q3')?.value === 'No'">
                          <mat-form-field class="faq" appearance="outline">
                            <mat-label>If No, then kindly give reason.</mat-label>
                            <textarea matInput formControlName="reason" rows="5"></textarea>
                          </mat-form-field>
                        </div>
                        <!--QUESTION THIRD END---->

                        <!---QUESTION FOURTH--->
                        <mat-form-field class="faq" appearance="outline">
                          <mat-label><strong>Q4.</strong>Are you able to provide healthy & nutritious food to your child?</mat-label>
                          <mat-select formControlName="q4">
                            <mat-option  value="Yes">Yes</mat-option>
                            <mat-option  value="No">If No, then kindly give reason. </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <div *ngIf="followupadmissionForm.get('q4')?.value === 'No'">
                          <mat-form-field class="faq" appearance="outline">
                            <mat-label>If No, then kindly give reason.</mat-label>
                            <textarea matInput formControlName="reason1" rows="5"></textarea>
                          </mat-form-field>
                        </div>
                        <!---QUESTION FOURTH END--->

                        <!--QUESTION FIFTH-->
                        <mat-form-field class="faq" appearance="outline">
                          <mat-label><strong>Q5.</strong>Have your relatives/villagers (society) accepted your child post treatment?</mat-label>
                          <mat-select formControlName="q5">
                            <mat-option  value="Yes">Yes</mat-option>
                            <mat-option  value="No">If No Reason</mat-option>
                          </mat-select>
                        </mat-form-field>

                        <div *ngIf="followupadmissionForm.get('q5')?.value === 'No'">
                          <mat-form-field class="faq" appearance="outline">
                            <mat-label>If No, then kindly give reason.</mat-label>
                            <textarea matInput formControlName="reason2" rows="5"></textarea>
                          </mat-form-field>
                        </div>
                        <div *ngIf="followupadmissionForm.get('q5')?.value === 'No'">
                          <mat-form-field class="faq" appearance="outline">
                            <mat-label>What steps have you taken</mat-label>
                            <textarea matInput formControlName="steps" rows="5"></textarea>
                          </mat-form-field>
                        </div>
                        <!---QUESTION FIFTH END--->

                        <!-----QUESTION SIXTH--->
                        <mat-form-field class="faq" appearance="outline">
                          <mat-label><strong>Q6.</strong>Are you able to practice the tips given by the counsellor?</mat-label>
                          <mat-select formControlName="q6">
                            <mat-option  value="Yes">Yes</mat-option>
                            <mat-option  value="No"> No</mat-option>
                          </mat-select>
                        </mat-form-field>

                        <div *ngIf="followupadmissionForm.get('q6')?.value === 'Yes'">
                          <mat-form-field class="faq" appearance="outline">
                            <mat-label>•	If Yes, which all do you practice </mat-label>
                            <textarea matInput formControlName="practice" rows="5"></textarea>
                          </mat-form-field>
                        </div>

                        <div *ngIf="followupadmissionForm.get('q6')?.value === 'No'">
                          <mat-form-field class="faq" appearance="outline">
                            <mat-label>If No, Reason </mat-label>
                            <textarea matInput formControlName="reason3" rows="5"></textarea>
                          </mat-form-field>
                        </div>
                        <!-----QUESTION SIXTH END----->
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                  <!--------------------------------------------- FAQ ENDED --------------------------------------------->
                </div>
                <div class="col-md-4 ">
                  <button mat-button mat-raised-button color="primary">
                    Submit
                  </button>
                </div>
              </form>
            </mat-card>
            <!--------------------------------------------- FOLLOWUP ADMISSION PROCESS END --------------------------------------------->
          </ng-container>

        </div>
<div class="col-md-4 col-sm-12 ">
    <!--------------------------------------------- Admission Documents --------------------------------------------->
    <div class="mt-1">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title> Admission Documents and Images</mat-panel-title>

                </mat-expansion-panel-header>

                <!--------------------------------------------- CONTENT START --------------------------------------------->
                <div>
                    <h4 style="color:darkred">* Child Photo, Child Photo with Family, KYC Documents are
                        compulsory *</h4>
                    <div style="display: flex;flex-direction: row-reverse">
                        <button *ngIf="!externalUser" mat-raised-button color="primary" class="m-3" (click)="UploadAdmissionDocs()">
                            Upload Document
                        </button>
                    </div>
                    <div class="cdk-virtual-scroll-data-source-example">
                        <cdk-virtual-scroll-viewport orientation="horizontal" itemSize="5"
                                                     class="example-viewport">
                            <div *cdkVirtualFor="let d of (child$|async)?.admissionDocuments;trackBy:trackByMethod;let i = index"
                                 class="example-item">


                                <div>

                                    <!--                                            <button (click)="getExtension(d.fileUrl)" mat-icon-button ><mat-icon>search</mat-icon></button>-->
                                    <!--                                            <p>{{getExtension(d.fileUrl)}}</p>-->
                                    <a (click)="openLink(d.fileUrl)">

                                        <img [src]="d.fileUrl" onerror="this.src='src/assets/sign.jpg'" style="width: 250px; height: 250px" class="img-thumbnail img-fluid">

                                    </a>

                                    <div class="d-flex">
                                        <p style="flex-grow: 9" class="text-center"> {{d.description}}</p>
                                        <button mat-icon-button color=warn *ngIf="!externalUser"
                                                (click)="deleteAdmissionFile(i,d,'admissionDocuments')">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </cdk-virtual-scroll-viewport>
                    </div>
                    <div class="d-flex">

                        <!--        <ng-container  *ngFor="let d of (child$|async)?.admissionDocuments;trackBy:trackByMethod;let i = index">-->
                        <!--        <div>-->
                        <!--          <a (click)="openLink(d.fileUrl)">-->
                        <!--          <img [src]="d.fileUrl" style="width: 250px; height: 250px"  class="img-thumbnail img-fluid">-->
                        <!--         </a>-->

                        <!--          <div  class="d-flex">-->
                        <!--           <p style="flex-grow: 9" class="text-center"> {{d.description}}</p>-->
                        <!--            <button  mat-icon-button color=warn (click)="deleteAdmissionFile(i,d,'admissionDocuments')"><mat-icon>delete</mat-icon></button>-->
                        <!--          </div>-->
                        <!--        </div>-->
                        <!--        </ng-container>-->
                    </div>
                </div>
                <!--------------------------------------------- Admission Documents Ended --------------------------------------------->
                <!--------------------------------------------- Discharg Docs --------------------------------------------->
                <!--------------------------------------------- Discharg Docs ENded --------------------------------------------->

                <!--------------------------------------------- CONTENT END --------------------------------------------->

            </mat-expansion-panel>
        </mat-accordion>
        <!--------------------------------------------- Discharge --------------------------------------------->

    </div>
    <!--------------------------------------------- Discharge Documents --------------------------------------------->
    <div class="mt-1">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    Discharge Documents and Images
                </mat-expansion-panel-header>

                <!--------------------------------------------- CONTENT START --------------------------------------------->
                <div>
                    <div style="display: flex;flex-direction: row-reverse">
                        <button *ngIf="!externalUser" mat-raised-button color="primary" class="m-3" (click)="UploadDischargeDocs()">
                            Upload Document
                        </button>
                    </div>
                    <div class="d-flex">
                        <ng-container *ngFor="let d of (child$|async)?.dischargeDocuments;let i=index">
                            <div>

                                <a (click)="openLink(d.fileUrl)">
                                    <img [src]="d.fileUrl" style="width: 250px; height: 250px" onerror="this.onerror=null; this.src='src/assets/cloud-2044823_960_720.png';"
                                         class="img-thumbnail img-fluid"></a>
                                <div class="d-flex">
                                    <p style="flex-grow: 9" class="text-center"> {{d.description}}</p>
                                    <button mat-icon-button color=warn
                                            (click)="deleteAdmissionFile(i,d,'dischargeDocuments')">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <!--------------------------------------------- Admission Documents Ended --------------------------------------------->
                <!--------------------------------------------- Discharg Docs --------------------------------------------->
                <!--------------------------------------------- Discharg Docs ENded --------------------------------------------->

                <!--------------------------------------------- CONTENT END --------------------------------------------->

            </mat-expansion-panel>
        </mat-accordion>
        <!--------------------------------------------- Discharge --------------------------------------------->

    </div>

    <!--------------------------------------------- Follow Up Admission Documents --------------------------------------------->
    <div class="mt-1">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    Follow Up Admission Documents and Images
                </mat-expansion-panel-header>

                <!--------------------------------------------- CONTENT START --------------------------------------------->
                <div>
                    <div style="display: flex;flex-direction: row-reverse">
                        <button mat-raised-button color="primary" class="m-3" *ngIf="!externalUser"
                                (click)="UploadFollowUpAdmissionDocs()">Upload Document
                        </button>
                    </div>
                    <div class="d-flex">
                        <ng-container *ngFor="let d of (child$|async)?.followUpAdmissionDocuments;let i=index">
                            <div>
                                <a (click)="openLink(d.fileUrl)">
                                    <img [src]="d.fileUrl" style="width: 250px; height: 250px"
                                         class="img-thumbnail img-fluid"></a>
                                <div class="d-flex">
                                    <p style="flex-grow: 9" class="text-center"> {{d.description}}</p>
                                    <button mat-icon-button color=warn
                                            (click)="deleteAdmissionFile(i,d,'followUpAdmissionDocuments')">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <!--------------------------------------------- Admission Documents Ended --------------------------------------------->
                <!--------------------------------------------- Discharg Docs --------------------------------------------->
                <!--------------------------------------------- Discharg Docs ENded --------------------------------------------->

                <!--------------------------------------------- CONTENT END --------------------------------------------->

            </mat-expansion-panel>
        </mat-accordion>
        <!--------------------------------------------- Discharge --------------------------------------------->

    </div>

    <!--------------------------------------------- Follow Up Discharge Documents --------------------------------------------->
    <div class="mt-1">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    Follow Up Discharge Documents and Images
                </mat-expansion-panel-header>

                <!--------------------------------------------- CONTENT START --------------------------------------------->
                <div>
                    <div style="display: flex;flex-direction: row-reverse">
                        <button mat-raised-button color="primary" class="m-3" *ngIf="!externalUser"
                                (click)="UploadFollowUpDischargeDocs()">Upload Document
                        </button>
                    </div>
                    <div class="d-flex">
                        <ng-container *ngFor="let d of (child$|async)?.followUpDischargeDocuments;let i= index">
                            <div>
                                <a (click)="openLink(d.fileUrl)">

                                    <img  [src]="d.fileUrl" style="width: 250px; height: 250px"
                                          class="img-thumbnail img-fluid">
                                </a>
                                <div class="d-flex">
                                    <p style="flex-grow: 9" class="text-center"> {{d.description}}</p>
                                    <button mat-icon-button color=warn
                                            (click)="deleteAdmissionFile(i,d,'followUpDischargeDocuments')">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <!--------------------------------------------- Admission Documents Ended --------------------------------------------->
                <!--------------------------------------------- Discharg Docs --------------------------------------------->
                <!--------------------------------------------- Discharg Docs ENded --------------------------------------------->

                <!--------------------------------------------- CONTENT END --------------------------------------------->

            </mat-expansion-panel>
        </mat-accordion>
        <!--------------------------------------------- Discharge --------------------------------------------->

    </div>
    <!*--------------------------------------------- ADMISSION DOCS--------------------------------------------->


  <div class="mt-1 ">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Treatment Records
        </mat-expansion-panel-header>

        <div>

          <div>
              <div class="d-flex justify-content-end">
                  <button *ngIf="!externalUser" mat-icon-button color="primary" (click)="addMedicalDialog()" ><mat-icon>upload</mat-icon>Upload</button>
              </div>
              <ng-container *ngFor="let d of (child$|async)?.medical_history">

              <div class="pretty-card text-center ">
                <div *ngIf="d.res_url!=null && d.res_url!=''"><img class="w-100 h-50 mb-1" src="{{d.res_url}}" alt=""></div>
                <div>
                  <table class="medical-history-table">
                      <tr *ngIf="d.title != null && d.title != ''">
                          <td><strong>Title:</strong></td>
                          <td>{{ d.title }}</td>
                      </tr>
                      <tr *ngIf="d.description != null && d.description != ''">
                          <td><strong>Description:</strong></td>
                          <td>{{ d.description }}</td>
                      </tr>

                      <tr *ngIf="d.type != null && d.type != ''">
                          <td><strong>Type:</strong></td>
                          <td>{{ d.type }}</td>
                      </tr>


                      <tr *ngIf="d.bmi != null && d.bmi != ''">
                      <td><strong>BMI:</strong></td>
                      <td>{{ d.bmi }}</td>
                    </tr>
                    <tr *ngIf="d.height != null && d.height != ''">
                      <td><strong>Height:</strong></td>
                      <td>{{ d.height }}</td>
                    </tr>
                    <tr *ngIf="d.weight != null && d.weight != ''">
                      <td><strong>Weight:</strong></td>
                      <td>{{ d.weight }}</td>
                    </tr>
                      <tr *ngIf="d.res_timing != null && d.res_timing != ''">
                          <td><strong>Date:</strong></td>
                          <td>{{ d.res_timing |date:'dd/MM/yyyy'}}</td>
                      </tr>
                    <tr >
                      <td><strong>Next OPD Date:</strong></td>
                      <td>{{d?.next_opd_date?.toDate() |date:'dd/MM/yyyy'}}</td>
                    </tr>
                      <!--                    <tr *ngIf="d.res_timing != null && d.res_timing != ''">-->
<!--                      <td><strong>Res Timing:</strong></td>-->
<!--                      <td>{{ d.res_timing | date: 'yyyy-MM-dd' }}</td>-->
<!--                    </tr>-->
                  </table>
                </div>

              </div>
            </ng-container>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>



</div>
        <!--------------------------------------------- EVENTS --------------------------------------------->
        <div class="col-md-5">
            <h2 *ngIf="child?.events" class="text-center bold">&nbsp;</h2>
            <cdk-virtual-scroll-viewport itemSize="10" class="viewport">
                <ng-container *cdkVirtualFor="let e of child.events;let  i =index">
                    <!--------------------------------------------- NO BMI --------------------------------------------->
                    <mat-card *ngIf="!e.isBMI" class="example-card">
                        <mat-card-header>
                            <!--            <div mat-card-avatar class="example-header-image"></div>-->
                            <mat-card-title class="text-capitalize">{{e.title}}</mat-card-title>
                            <mat-card-subtitle>{{e.res_timing|date: 'medium'}}</mat-card-subtitle>
                        </mat-card-header>
                        <ng-container *ngIf="e.res_type=='video'">
                            <iframe mat-card-image
                                    src="https://firebasestorage.googleapis.com/v0/b/acceife-fbdb5.appspot.com/o/Child%2F47d6bb7d-0cf1-47b7-8084-526be0fbd4244608434017738393552.mp4?alt=media&token=ba5189e6-f018-45fa-8121-895e36975c56"
                                    alt="Event Image Here"></iframe>
                        </ng-container>
                        <ng-container *ngIf="e.res_type!='video'">
                            <img mat-card-image [src]="e.res_url" alt="Event Image Here">
                        </ng-container>
                        <mat-card-content>
                            <p class="text-capitalize">
                                {{e.description}}
                            </p>
                        </mat-card-content>

                    </mat-card>
                    <!--------------------------------------------- WITH BMI --------------------------------------------->
                    <mat-card *ngIf="e.isBMI" class="example-card">
                        <mat-card-header>
                            <!--            <div mat-card-avatar class="example-header-image"></div>-->
                            <mat-card-title class="text-capitalize">{{e.title}}</mat-card-title>
                            <mat-card-subtitle>{{e.res_timing|date: 'medium'}}</mat-card-subtitle>
                        </mat-card-header>
                        <ng-container *ngIf="e.res_type=='video'">
                            <iframe mat-card-image
                                    src="https://firebasestorage.googleapis.com/v0/b/acceife-fbdb5.appspot.com/o/Child%2F47d6bb7d-0cf1-47b7-8084-526be0fbd4244608434017738393552.mp4?alt=media&token=ba5189e6-f018-45fa-8121-895e36975c56"
                                    alt="Event Image Here"></iframe>
                        </ng-container>
                        <ng-container *ngIf="e.res_type!='video'">
                            <img mat-card-image [src]="e.res_url" alt="Event Image Here">
                        </ng-container>
                        <mat-card-content>
                            <p class="text-capitalize">

                                {{e.description}}
                            </p>
                            <table style="width: 100%">
                                <th>Height</th>
                                <th>Weight</th>
                                <!--          <th>BMI</th>-->
                                <tr>
                                    <!--                <th>Height </th>-->
                                    <td> {{e?.height}}</td>
                                    <!--                <th>Weight </th>-->
                                    <td> {{e?.weight}}</td>
                                    <!--                <th>BMI </th>-->
                                    <!--                <td> {{e.bmi}}</td>-->
                                </tr>
                            </table>
                        </mat-card-content>

                    </mat-card>
                </ng-container>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>

    <!--      RESPONSE-->

</div>

