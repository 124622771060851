<div>
  <h1  class="rounded text-center mt-2"> Education MIS Report</h1>
  <div class="d-flex gap-1">
    <mat-form-field appearance="outline">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate [(ngModel)]="startDate" placeholder="Start date" />
        <input matEndDate [(ngModel)]="endDate" placeholder="End date" />
      </mat-date-range-input>
      <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

<!--    <mat-form-field appearance="outline">-->
<!--      <mat-label>Select Centre</mat-label>-->
<!--      <mat-select [(ngModel)]="selectedCentre" (selectionChange)="OnCentreChange($event)">-->
<!--        <mat-option *ngFor="let c of mCentres" [value]="c.centreCode">{{ c.centreName }}</mat-option>-->
<!--      </mat-select>-->
<!--    </mat-form-field>-->

    <button mat-mini-fab color="accent" (click)="Search()">
      <mat-icon>search</mat-icon>
    </button>
    <button mat-mini-fab color="accent" (click)="Clear()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate">Please wait Data is loading... </mat-progress-bar>
  <table>
    <thead>
    <tr>
      <th>Non-Formal Education Intervention</th>
      <th *ngFor="let centre of mCentres">{{ centre.centreName }}</th>
      <th>Total</th>
    </tr>
    </thead>
    <tbody>
    <!-- Loop through session data rows with fallback -->
    <ng-container *ngFor="let session of sessionData">
      <tr>
        <td>{{ session.type }}</td>

        <!-- Check if session.counts has data -->
        <ng-container *ngIf="session.counts && session.counts.length > 0; else noSessionData">
          <td *ngFor="let count of session.counts">{{ count || 0 }}</td>
        </ng-container>

        <!-- Fallback row when no data is available in session.counts -->
        <ng-template #noSessionData>
          <td *ngFor="let centre of mCentres">0</td>
        </ng-template>

        <!-- Total column -->
        <td class="total-column">{{ session.total || 0 }}</td>
      </tr>
    </ng-container>

    <!-- Individual Sessions by AL Teacher (C) with fallback -->
    <tr>
      <td>Individual Sessions by AL Teacher (C)</td>
      <!-- Check if allCentreSessions has data -->
      <ng-container *ngIf="allCentreSessions && allCentreSessions.length > 0; else noCentreData">
        <td *ngFor="let centreData of allCentreSessions">{{ centreData.sessionCount || 0 }}</td>
      </ng-container>

      <!-- Fallback row when no data is available in allCentreSessions -->
      <ng-template #noCentreData>
        <td *ngFor="let centre of mCentres">0</td>
      </ng-template>

      <td class="total-column">{{ totalSessionCount || 0 }}</td>
    </tr>

    <!-- Individual Sessions done by Volunteers (D) TSS with fallback -->
    <tr>
      <td>Individual Sessions done by Volunteers (D) TSS</td>

      <ng-container *ngIf="allCentreSessions && allCentreSessions.length > 0; else noCentreDataD">
        <td *ngFor="let centreData of allCentreSessions">{{ centreData.tssVolunteerCount || 0 }}</td>
      </ng-container>

      <ng-template #noCentreDataD>
        <td *ngFor="let centre of mCentres">0</td>
      </ng-template>

      <td class="total-column">{{ totalTssVolunteerCount || 0 }}</td>
    </tr>
<!--    <tr>-->
<!--      <td> Volunteers sessions</td>-->
<!--      <ng-container *ngIf="allCentreSessions && allCentreSessions.length > 0; else noCentreDataG">-->
<!--        <td *ngFor="let centreData of allCentreSessions">{{ centreData.withouttssVolunteerCount || 0}}</td>-->
<!--      </ng-container>-->

<!--      <ng-template #noCentreDataG>-->
<!--        <td *ngFor="let centre of mCentres">0</td>-->
<!--      </ng-template>-->

<!--      <td class="total-column">{{  0 }}</td>-->
<!--    </tr>-->
    <!-- Group sessions (G) with fallback -->
    <tr>
      <td>Group sessions (G)</td>

      <ng-container *ngIf="allCentreSessions && allCentreSessions.length > 0; else noCentreDataG">
        <td *ngFor="let centreData of allCentreSessions">{{ centreData.sessionCountBySession || 0 }}</td>
      </ng-container>

      <ng-template #noCentreDataG>
        <td *ngFor="let centre of mCentres">0</td>
      </ng-template>

      <td class="total-column">{{ totalSessionCountBySession || 0 }}</td>
    </tr>

    <!-- Total sessions (A+B+C+D+E+F+G) with fallback -->
    <tr>
      <td>Total sessions (A+B+C+D+E+F+G)</td>

      <ng-container *ngIf="combinedAllTotals && combinedAllTotals.length > 0; else noCombinedTotals">
        <td *ngFor="let total of getCombinedCenterTotals()">{{ total || 0 }}</td>
      </ng-container>

      <ng-template #noCombinedTotals>
        <td *ngFor="let centre of mCentres">0</td>
      </ng-template>

      <td class="total-column">{{ calculateTotalSessions() || 0 }}</td>
    </tr>

    <!-- Child's average session done with fallback -->
    <tr>
      <td>Child's average session done</td>

      <ng-container *ngIf="centerAverages && centerAverages.length > 0; else noAverageData">
        <td *ngFor="let centreAverage of centerAverages">{{ (centreAverage || 0).toFixed(0) }}</td>
      </ng-container>

      <ng-template #noAverageData>
        <td *ngFor="let centre of mCentres">0</td>
      </ng-template>

      <td class="total-column">{{ (totalAverage || 0).toFixed(0) }}</td>
    </tr>
    </tbody>
  </table>
</div>
