import {Component, Inject, isDevMode, OnInit} from '@angular/core';
import {MatSelectChange} from "@angular/material/select";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CentreService} from "../../../services/centre.service";
import {ChildService} from "../../child/child.service";
import {VehicleService} from "../vehicle.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {Centre} from "../../../interfaces/Centres";
import {environment} from "../../../../environments/environment";
import {child} from "@angular/fire/database";
import {LogService} from "../../../services/log.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-edit-car-log-book',
  templateUrl: './edit-car-log-book.component.html',
  styleUrls: ['./edit-car-log-book.component.scss']
})
export class EditCarLogBookComponent implements OnInit {
  carLogForm!: FormGroup;
  centre_Name: string = '';
  child_list: any[] = [];
  care_taker_list: any[] = [];
  withoutChild : boolean = false;
  withChild : boolean = false;
  purposeOfTrips$!: Observable<any>;
  mData: any
  env = isDevMode() ? environment.testMode : environment.productionMode
  constructor(private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private centreService: CentreService,
              private childService: ChildService,
              private mLogService : LogService,
              private _snackBar : MatSnackBar,
              private mDialogRef: MatDialogRef<EditCarLogBookComponent>,
              private mVehicleService: VehicleService,
              private mDatabase: AngularFireDatabase,
              private readonly mFirestore: AngularFirestore) {
    this.mData = data;
  }

  ngOnInit(): void {
    this.initializeForm();
    this.purposeOfTrips$ = this.mDatabase.list("types/Trip Purpose").valueChanges();
    this.fetchCenterByCenterCodeWithName();
  }

  initializeForm(): void {
    this.carLogForm = this.fb.group({
        drop_location: [this.mData?.drop_location || ''],
        pick_up_location: [this.mData?.pick_up_location || ''],
        childList: [this.mData?.childList || ''],
        purpose_of_trip: [this.mData?.purpose_of_trip || ''],
        reason: [this.mData?.reason || ''],
        person_name_list: [this.mData?.person_name_list || ''],
        isRoundTrip: [this.mData?.isRoundTrip || false],
        remark: [this.mData?.remark || ''],
        status: [this.mData?.status || ''],
        trip_ended_at: [this.mData?.trip_ended_at || '',Validators.required ],
        trip_started_at: [this.mData?.trip_started_at || '',Validators.required],
        pick_up_meter_reading: [this.mData?.pick_up_meter_reading || '',Validators.required],
        drop_meter_reading: [this.mData?.drop_meter_reading || '',Validators.required],
        triptype: [this.mData?.triptype || '',Validators.required],
        submittedFrom: ['MySpace'],
        vehicle_info: this.fb.group({
          driverAddress: [this.mData?.vehicle_info?.driverAddress || ''],
          driverContactNo: [this.mData?.vehicle_info?.driverContactNo || ''],
          driverName: [this.mData?.vehicle_info?.driverName || '',Validators.required],
          licenseNumber: [this.mData?.vehicle_info?.licenseNumber || ''],
          vehicleNumber: [this.mData?.vehicle_info?.vehicleNumber || '',Validators.required],
          vehicleType: [this.mData?.vehicle_info?.vehicleType || '']
        })
      })

    if(this.carLogForm.get('triptype')?.value == 'Without Child'){
      this.withoutChild = true;
      this.withChild = false;
      this.centre_Name = '';
    }else {
      this.withChild = true;
      this.withoutChild = false;
    }
    if (this.mData) {
      // Convert Firestore timestamp to Date object
      const tripStartedDate = this.convertFirestoreTimestampToDate(this.mData?.trip_started_at);
      const tripEndedDate = this.convertFirestoreTimestampToDate(this.mData?.trip_ended_at);

      // Set the values in the form
      this.carLogForm.patchValue({
        trip_started_at: tripStartedDate,
        trip_ended_at: tripEndedDate
      });
    }

  }
  convertFirestoreTimestampToDate(timestamp: any): Date | null {
    if (timestamp) {
      const seconds = timestamp.seconds;
      const nanoseconds = timestamp.nanoseconds;
      return new Date(seconds * 1000 + nanoseconds / 1000000); // Convert to milliseconds
    }
    return null;
  }

  fetchCenterByCenterCodeWithName(): void {
    const childList = this.carLogForm.get('childList')?.value;
    this.child_list = childList.map((child: any) => child.firstName + ' ' + child.lastName);
    this.care_taker_list = childList.map((child: any) => child.firstName +' '+ child.lastName);
    if (childList && Array.isArray(childList)) {
      const centreCodes = childList.map((child: any) => child.centreCode);
      const uniqueCentreCode = centreCodes[0]; // Or use Set for uniqueness
      this.mFirestore.collection<Centre>(this.env.centres).doc(uniqueCentreCode).get().subscribe(docSnapshot => {
          if (docSnapshot.exists) {
            const centreData = docSnapshot.data();
            if (centreData) {
              this.centre_Name =  centreData.centreName
            }
          }
        }, error => {
          console.error('Error fetching centre data:', error);
        });
    }
  }

  onCancel(){
    this.mDialogRef.close()
  }

  submitForm(): void {
    if (this.carLogForm.valid) {
      const docId = this.mData?.id;
      this.mFirestore.doc(`ALTrips/${docId}`).set(this.carLogForm.value, { merge: true })
        .then(() => {
          let activity = {
            date: new Date().getTime(),
            section: 'Vehicle',
            action: 'Update',
            description: `Trip Log update for ${this.mData.vehicle_info.driverName} by user`,
            currentIp: localStorage.getItem('currentip')!
          }
          this.mLogService.addLog(activity)
          this._snackBar.open('Form updated successfully!', 'Close', {
            duration: 3000,
            panelClass: ['success-snackbar']
          });
          this.mDialogRef.close();
        })
        .catch(error => {
          console.error('Error submitting form:', error);
        });
    } else {
      console.log('Form is invalid');
    }
  }
}
