import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Vehicle} from "../add-vehicles/add-vehicles.component";
import {VehicleService} from "../vehicle.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {LogService} from "../../../services/log.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-update-vehicles',
  templateUrl: './update-vehicles.component.html',
  styleUrls: ['./update-vehicles.component.scss']
})
export class UpdateVehiclesComponent implements OnInit {
  vehicleForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private mService: VehicleService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private mLogService: LogService,
              private mSnackbar: MatSnackBar,
              private  mDialog : MatDialog) {
    this.vehicleForm = this.formBuilder.group({
      vehicleName: [this.data.vehicleName, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      vehicleNumber: [this.data.vehicleNumber, [Validators.required, Validators.pattern('^[A-Za-z0-9]+$')]],
      vehicleBrand: [this.data.vehicleBrand, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      typeOfBody: [this.data.typeOfBody, Validators.maxLength(50)],
      typeOfVehicle: [this.data.typeOfVehicle, Validators.maxLength(50)],
      makersName: [this.data.makersName, Validators.maxLength(50)],
      manufactureDate: [this.data.manufactureDate?.toDate(), Validators.maxLength(50)],
      numberOfCylinders: [this.data.numberOfCylinders, Validators.pattern('^[0-9]*$')],
      horsePower: [this.data.horsePower, Validators.pattern('^[0-9]*$')],
      cubicCapacity: [this.data.cubicCapacity, Validators.pattern('^[0-9]*$')],
      // makersClassification: [this.data.makersClassification, Validators.maxLength(50)],
      wheelBase: [this.data.wheelBase, Validators.maxLength(50)],
      chassisNumber: [this.data.chassisNumber, Validators.maxLength(50)],
      engineNumber: [this.data.engineNumber, Validators.maxLength(50)],
      seatingCapacity: [this.data.seatingCapacity, Validators.pattern('^[0-9]*$')],
      fuelUsed: [this.data.fuelUsed, Validators.maxLength(50)],
      unladenWeight: [this.data.unladenWeight, Validators.pattern('^[0-9]*$')],
      previousRegistration: [this.data.previousRegistration, Validators.maxLength(50)],
      bodyColor: [this.data.bodyColor, Validators.maxLength(50)],
      servicingDate: [this.data.servicingDate.toDate(), Validators.maxLength(50)],
      servicingDuration: [this.data.servicingDuration, Validators.maxLength(50)],
      driver: [this.data.driver, Validators.maxLength(50)],
      vehicleStatus: [this.data.vehicleStatus, Validators.maxLength(50)],
      providerName: [this.data.providerName, Validators.maxLength(50)],
      location:[this.data.location]
    });  }

  ngOnInit(): void {
    console.log(this.data)
  }

  onSubmit() {
    if (this.vehicleForm.valid) {
      // Form submission logic here
      const vehicleData: Vehicle = this.vehicleForm.value;
      const  id = this.data.id
      this.mService.updateVehicle(id, vehicleData).then(r => {
        this.mSnackbar.open('Data Update Successfullty' ,'',{duration:3000,panelClass:'success'})
        this.mDialog.closeAll();
        let activity = {
          date: new Date().getTime(),
          section: 'Vehicle',
          action: 'Update',
          description: `Vehicle updated by user`,
          currentIp: localStorage.getItem('currentip')!
        }
        this.mLogService.addLog(activity)
      })
    } else {
      console.log('Form is invalid');
    }
  }
  onCancle(){
    this.mDialog.closeAll()
  }
}
