<h1 class="text-center bg-warning">Edit Car Log</h1>
<div [formGroup]="carLogForm">
  <div class="container">
    <div class="row">
      <mat-form-field appearance="outline"  class="col-md-3">
        <mat-label>Select</mat-label>
        <input matInput formControlName="triptype" readonly/>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-3" *ngIf="withChild">
        <mat-label>Select Centre</mat-label>
          <input matInput [value]="centre_Name" readonly/>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-12"  *ngIf="withChild">
        <mat-label>Select Child</mat-label>
        <input matInput [value]="child_list" readonly/>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-12"  *ngIf="withChild">
        <mat-label>Caretaker</mat-label>
        <input matInput [value]="care_taker_list" readonly/>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-12 " *ngIf="withoutChild" >
        <mat-label>Person's Name</mat-label>
        <input matInput formControlName="person_name_list" placeholder="Enter person's name">
      </mat-form-field>

      <!-- Vehicle Information Section -->


      <h2>Trip Start and End Date</h2>
      <mat-form-field appearance="outline" class="col-md-3">
        <input matInput [matDatepicker]="startDatePicker" placeholder="Start Date" formControlName="trip_started_at" required>
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-3">
        <input matInput [matDatepicker]="endDatePicker" placeholder="End Date" formControlName="trip_ended_at" required>
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-3 "  >
        <mat-label>From</mat-label>
        <input matInput formControlName="pick_up_location">
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-3"  >
        <mat-label>To</mat-label>
        <input matInput formControlName="drop_location" placeholder="Enter End Location">
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-3 "  >
        <mat-label>Start reading</mat-label>
        <input matInput formControlName="pick_up_meter_reading" placeholder="Start Reading in km">
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-3 "  >
        <mat-label>End Reading</mat-label>
        <input matInput formControlName="drop_meter_reading" placeholder="End Reading in km">
      </mat-form-field>

      <h2>Vehicle Information</h2>

      <div formGroupName="vehicle_info" class="row">
        <mat-form-field appearance="outline" class="col-md-3">
          <mat-label>Driver Name:</mat-label>
          <input matInput formControlName="driverName" required>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-3">
          <mat-label>Vehicle Number</mat-label>
          <input matInput formControlName="vehicleNumber" required>
        </mat-form-field>
      </div>

      <!-- Trip Information Section -->
      <h2>Trip Information</h2>
      <mat-form-field appearance="outline" class="col-md-6">
        <mat-label>Purpose of Trip</mat-label>
        <mat-select placeholder="Select Purpose"  formControlName="purpose_of_trip" required>
          <mat-option *ngFor="let purpose of purposeOfTrips$|async" value="{{purpose}}">{{purpose}}</mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field appearance="outline" class="col-md-6">
        <input matInput placeholder="Remark" formControlName="remark">
      </mat-form-field>

      <mat-slide-toggle
        class="example-margin" formControlName="isRoundTrip">
        Return Trip
      </mat-slide-toggle>

    </div>
    <!-- Submit Button -->
    <div class="d-flex justify-content-center gap-2">
      <button mat-raised-button color="accent" (click)="onCancel()">Cancel</button>
      <button mat-raised-button color="primary" (click)="submitForm()" [disabled]="carLogForm.invalid">Submit</button>
    </div>
  </div>
</div>

