import {NgModule, ENVIRONMENT_INITIALIZER} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ReactiveFormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginComponent} from './login/login.component';
import {NgChartsModule} from 'ng2-charts';
import {environment} from '../environments/environment';
import {getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService} from '@angular/fire/analytics';
import {AuthService} from "./auth.service";
import {FIREBASE_OPTIONS} from "@angular/fire/compat";
import {AuthGuard} from "@angular/fire/auth-guard";
import {AllCentersComponent} from "./components/dashboard/allcenters/all-centers.component";
import {HeaderComponent} from "./dashboard/header/header.component";
import {CreateEmployee} from "./components/employee/create-employee/create-employee";
import {SidenavComponent} from "./dashboard/sidenav/sidenav.component";
import {VolunteersComponent} from "./components/volunteers/volunteers.component";
import {ChildCreateComponent} from "./components/child/child-create/child-create.component";
import {EventsComponent} from "./components/events/events.component";
import {UnitsComponent} from "./components/units/units.component";
import {CaretakersComponent} from "./components/caretakers/caretakers.component";
import {VehiclesComponent} from "./components/vehicles/vehicles.component";
import {DonationComponent} from "./components/Donationt/donation/donation.component";
import {CreatecenterComponent} from "./components/centers/createcenter/createcenter.component";
import {CentrelistComponent} from "./components/centers/centrelist/centrelist.component";
import {DragDropDirective} from "./drag-drop.directive";
import {CommonModule, DatePipe, NgOptimizedImage, TitleCasePipe} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {HttpClientModule} from "@angular/common/http";
import {ChildlistComponent} from './components/child/childlist/childlist.component';
import {ChildService} from "./components/child/child.service";
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {DonationtableComponent} from './components/Donationt/donationtable/donationtable.component';
import {ChildEditComponent} from './components/child/child-edit/child-edit.component';
import {RenderReceiptComponent} from './components/Donationt/render-receipt/render-receipt.component';
import {ViewDonationComponent} from './components/Donationt/view-donation/view-donation.component';
import {VolunteerregisComponent} from './components/volunteers/volunteerregis/volunteerregis.component';
import {VolunteerlistComponent} from './components/volunteers/volunteerlist/volunteerlist.component';
import {EmployeelistComponent} from './components/employee/employeelist/employeelist.component';
import {ChildadmissionComponent} from './components/child/childadmission/childadmission.component';
import {ChildguardianComponent} from './components/child/childguardian/childguardian.component';
import {ChildHealthComponent} from './components/child/child-health/child-health.component';
import {DonorTableComponent} from './components/donoradmin/donor-table/donor-table.component';
import {DonorAdminComponent} from './components/donoradmin/donor-admin/donor-admin.component';
import {ChilddocumentComponent} from "./components/child/childdocument/childdocument.component";
import {FullcontentchildComponent} from './components/child/fullcontentchild/fullcontentchild.component';
import {EditCentreComponent} from './components/centers/edit-centre/edit-centre.component';
import {ViewCentreComponent} from './components/centers/view-centre/view-centre.component';
import {HospitalsComponent} from './components/hospitals/hospitals.component';
import {AddHospitalComponent} from './components/hospitals/add-hospital/add-hospital.component';
import {EditHospitalComponent} from './components/hospitals/edit-hospital/edit-hospital.component';
import {ChildRegistrationTableComponent} from './components/child/child-registration-table/child-registration-table.component';
import {ListCaretakerComponent} from './components/caretakers/list-caretaker/list-caretaker.component';
import {ViewRegisteredChildComponent} from './components/child/view-registered-child/view-registered-child.component';
import {AdminssionFaqComponent} from './components/child/adminssion-faq/adminssion-faq.component';
import {ViewDonorComponent} from './components/donoradmin/view-donor/view-donor.component';
import {ViewChildComponent} from './components/child/view-child/view-child.component';
import {TypesComponent} from './components/types/types.component';
import {AddTypeComponent} from './components/types/add-type/add-type.component';
import {EditTypeComponent} from './components/types/edit-type/edit-type.component';
import {AddFieldComponent} from './components/types/add-field/add-field.component';
import {RequestedVolunteerComponent} from './components/volunteers/requested-volunteer/requested-volunteer.component';
import {AdHocAdmissionComponent} from './components/child/ad-hoc-admission/ad-hoc-admission.component';
import {MealsCalenderComponent} from "./components/meals/meal-calender/meals-calender.component";
import {MealSlotsComponent} from "./components/meals/meal-slots/meal-slots.component";
import {MealsComponent} from "./components/meals/meals.component";
import {AddMealEventComponent} from './components/meals/add-meal-event/add-meal-event.component';
import {ViewMealEventComponent} from './components/meals/view-meal-event/view-meal-event.component';
import {EditFullChildComponent} from './components/child/edit-full-child/edit-full-child.component';
import {AddDonorComponent} from "./components/donoradmin/add-donor/add-donor.component";
import {initializeDialogService} from "../main";
import {CreateEventComponent} from './components/events/create-event/create-event.component';
import {PaymentRemindersComponent} from './components/reminders/payment-reminders/payment-reminders.component';
import {DashboardRemindersComponent} from './components/reminders/dashboard-reminders/dashboard-reminders.component';
import {FollowupAdmissionComponent} from './components/reminders/followup-admission/followup-admission.component';
import {EditDonorComponent} from './components/donoradmin/edit-donor/edit-donor.component';
import {MyTestComponent} from './my-test/my-test.component';
import {ViewImageComponent} from './components/child/view-registered-child/view-image/view-image.component';
import {SharedModule} from "./modules/shared/shared.module";
import {EditMealEventComponent} from './components/meals/edit-meal-event/edit-meal-event.component';
import {ViewEventComponent} from './components/events/view-event/view-event.component';
import {LogoutComponent} from './logout/logout.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import {MatCheckboxModule} from "@angular/material/checkbox";
import { DeleteCenterComponent } from './components/centers/delete-center/delete-center.component';
import { UpdatePaymentComponent } from './components/reminders/update-payment/update-payment.component';
import { MisComponent } from './components/dashboard/mis/mis.component';
import { FigurecardComponent } from './components/dashboard/figurecard/figurecard.component';
import { AgeBarComponent } from './components/dashboard/age-bar/age-bar.component';
import { HospitalsBarGraphComponent } from './components/dashboard/hospitals-bar-graph/hospitals-bar-graph.component';
import {PrettyJsonModule} from "angular2-prettyjson";
import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';
import { FinancialReportTableComponent } from './components/dashboard/financial-report-table/financial-report-table.component';
import { MealMateEventTableComponent } from './components/meals/meal-mate-event-table/meal-mate-event-table.component';
import { RazorpayDataComponent } from './components/razorpay-data/razorpay-data.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { RegisteredChildExcelComponent } from './components/child/registered-child-excel/registered-child-excel.component';
import { DiagnosisComponent } from './components/dashboard/diagnosis/diagnosis.component';
import {SidebarComponent} from "./main/sidebar/sidebar.component";
import {NgScrollbarModule} from "ngx-scrollbar";
import { MainComponent } from './main/main.component';
import {FeatherModule} from "angular-feather";
import { Header2Component } from './main/header2/header2.component';
import {DirectionService} from "./services/direction.service";
import {RightSidebarService} from "./services/rightsidebar.service";
import {RightSidebarComponent} from "./main/right-sidebar/right-sidebar.component";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { DisplayHospitalComponent } from './components/hospitals/display-hospital/display-hospital.component';
import { RenameComponent } from './components/rename/rename.component';
import { RoleComponent } from './components/rename/role/role.component';
import { AddUserRoleComponent } from './components/rename/add-user-role/add-user-role.component';
import { EditUserRoleComponent } from './components/rename/edit-user-role/edit-user-role.component';
import { ViewFollowUpChildDetailsComponent } from './components/reminders/view-follow-up-child-details/view-follow-up-child-details.component';
import { ActivityLogComponent } from './components/activity-log/activity-log.component';
import { Mis2Component } from './components/dashboard/mis2/mis2.component';
import { SendPhotoEmailComponent } from './components/meals/send-photo-email/send-photo-email.component';
import {UnitSponsorshipComponent} from "./components/unit-sponsorship/unit-sponsorship.component";
import {
  AddUnitSponsorshipComponent
} from "./components/unit-sponsorship/add-unit-sponsorship/add-unit-sponsorship.component";
import { TransferUnitSponsorshipComponent } from './components/unit-sponsorship/transfer-unit-sponsorship/transfer-unit-sponsorship.component';
import { RenewalUnitSponsorshipComponent } from './components/unit-sponsorship/renewal-unit-sponsorship/renewal-unit-sponsorship.component';
import { SponsorTableComponent } from './components/unit-sponsorship/sponsor-table/sponsor-table.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AllotChildSponsorshipComponent } from './components/unit-sponsorship/allot-sponsorship-child/allot-child-sponsorship.component';
import { SponsorshipImageComponent } from './components/unit-sponsorship/sponsorship-image/sponsorship-image.component';
import { SponsorshipDashboardComponent } from './components/unit-sponsorship/sponsorship-dashboard/sponsorship-dashboard.component';
import { UnitTransferComponent } from './components/units/unit-transfer/unit-transfer.component';
import { GlossaryComponent } from './components/glossary/glossary.component';
import { RazorpayTransactionComponent } from './components/razorpay-transaction/razorpay-transaction.component';
import { EditEmployeeInfoComponent } from './components/employee/edit-employee-info/edit-employee-info.component';
import { AddGlossaryComponent } from './components/glossary/add-glossary/add-glossary.component';
import { EditGlossaryComponent } from './components/glossary/edit-glossary/edit-glossary.component';
import { EditAdmissionReminderComponent } from './components/reminders/edit-admission-reminder/edit-admission-reminder.component';
import { AdminChildEditComponent } from './components/child/admin-child-edit/admin-child-edit.component';
import { ChangesComponent } from './components/activity-log/changes/changes.component';
import {MatRadioModule} from "@angular/material/radio";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatInputModule} from "@angular/material/input";
import { UnsponsorChildrenTableComponent } from './components/unit-sponsorship/unsponsor-children-table/unsponsor-children-table.component';
import { AddInfoComponent } from './components/vehicles/add-info/add-info.component';
import { AddVehiclesComponent } from './components/vehicles/add-vehicles/add-vehicles.component';
import { UpdateDriverComponent } from './components/vehicles/update-driver/update-driver.component';
import { UpdateVehiclesComponent } from './components/vehicles/update-vehicles/update-vehicles.component';
import { VehicleTableComponent } from './components/vehicles/vehicle-table/vehicle-table.component';
import { DriverTableComponent } from './components/vehicles/driver-table/driver-table.component';
import { AddMedicalHistoryComponent } from './components/child/add-medical-history/add-medical-history.component';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MY_FORMATS} from "./utils/formats";
import { AdmissionChartComponent } from './components/dashboard/admission-chart/admission-chart.component';
import { ApexHospitalChartComponent } from './components/dashboard/apex-hospital-chart/apex-hospital-chart.component';
import { ApexAgeChartComponent } from './components/dashboard/apex-age-chart/apex-age-chart.component';
import { CentresChartComponent } from './components/dashboard/centres-chart/centres-chart.component';
import { TotalUnitsChartComponent } from './components/dashboard/total-units-chart/total-units-chart.component';
import { UniqueAdmissionsChartComponent } from './components/dashboard/unique-admissions-chart/unique-admissions-chart.component';
import {FloorPlanComponent} from "./components/units/floor-plan/floor-plan.component";
import { RecentAdmissionsComponent } from './components/dashboard/recent-admissions/recent-admissions.component';
import { RecentDischargeComponent } from './components/dashboard/recent-discharge/recent-discharge.component';
import { CarLogBookComponent } from './components/vehicles/car-log-book/car-log-book.component';
import { AllCarFuelDetailsComponent } from './components/vehicles/all-car-fuel-details/all-car-fuel-details.component';
import { CodeComponent } from './code/code.component';
import { QuarterReportComponent } from './quarter-report/quarter-report.component';
import {  AllCarFuelsDetailsNewComponent} from "./components/vehicles/all-car-fuels-details-new/all-car-fuels-details-new.component";
import {  AllServicingDetailsNewComponent} from "./components/vehicles/all-car-servicing-details-new/all-car-servicing-details-new.component";
import { AddCarFuelsDetailsComponent } from './components/vehicles/add-car-fuels-details/add-car-fuels-details.component';
import { AddServicingDetailsComponent } from './components/vehicles/add-servicing-details/add-servicing-details.component';

import { ChildJourneyComponent } from './components/child/child-journey/child-journey.component';
import { PhotoGalleryComponent } from './components/photo-gallery/photo-gallery.component';
import { AddCarLogBookComponent } from './components/vehicles/add-car-log-book/add-car-log-book.component';
import { ViewCarLogBookComponent } from './components/vehicles/view-car-log-book/view-car-log-book.component';
import { ManageOnlineSlotsComponent } from './components/meals/manage-online-slots/manage-online-slots.component';
import { AllotOnlineSlotComponent } from './components/meals/manage-online-slots/allot-online-slot/allot-online-slot.component';
import { ViewSponsorComponent } from './components/unit-sponsorship/view-sponsor/view-sponsor.component';
import { ViewVehicleComponent } from './components/vehicles/view-vehicle/view-vehicle.component';
import { ViewDriverComponent } from './components/vehicles/view-driver/view-driver.component';
import { OnlineDonorsTableComponent } from './modules/cms/online-donors-table/online-donors-table.component';
import { ViewOnlineDonorsComponent } from './modules/cms/online-donors-table/view-online-donors/view-online-donors.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { EditCarLogBookComponent } from './components/vehicles/edit-car-log-book/edit-car-log-book.component';
import { CentreEfficiencyComponent } from './components/dashboard/centre-efficiency/centre-efficiency.component';
import { DataCorrectionComponent } from './components/data-correction/data-correction.component';
import { ManualDonationComponent } from './components/Donationt/manual-donation/manual-donation.component';
import { FailedDonationsComponent } from './components/Donationt/failed-donations/failed-donations.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import {NgxPrintModule} from "ngx-print";
import {initializeApp, provideFirebaseApp} from "@angular/fire/app";
import {connectAuthEmulator, getAuth, provideAuth} from "@angular/fire/auth";
import {connectDatabaseEmulator, getDatabase, provideDatabase} from "@angular/fire/database";
import {connectFirestoreEmulator, getFirestore, provideFirestore} from "@angular/fire/firestore";
import { ManualAttemptedDonationRecieptComponent } from './components/Donationt/manual-attempted-donation-reciept/manual-attempted-donation-reciept.component';
import { ViewAttemptedDonationRecieptComponent } from './components/Donationt/view-attempted-donation-reciept/view-attempted-donation-reciept.component';
import { GetPlacesComponent } from './get-places/get-places.component';
import { ViewServicingDetailsComponent } from './components/vehicles/view-servicing-details/view-servicing-details.component';
import {
  ViewCarFuelsDetailsComponent
} from "./components/vehicles/view-car-fuels-details/view-car-fuels-details.component";
import {
  EducationMisReposrtComponent
} from "./modules/education/components/education-mis-reposrt/education-mis-reposrt.component";

@NgModule({
    declarations: [

        AppComponent,
        LoginComponent,
        AllCentersComponent,
        HeaderComponent,
        SidenavComponent,
        VolunteersComponent,
        ChildCreateComponent,
        EventsComponent,
        UnitsComponent,
        CaretakersComponent,
        VehiclesComponent,
        DonationComponent,
        ChilddocumentComponent,
        CreatecenterComponent,
        CentrelistComponent,
        DragDropDirective,
        ChildlistComponent,
        DashboardComponent,
        DonationtableComponent,
        ChildEditComponent,
        RenderReceiptComponent,
        ViewDonationComponent,
        VolunteerregisComponent,
        VolunteerlistComponent,
        ChildRegistrationTableComponent,


        EmployeelistComponent,
        ChildadmissionComponent,
        ChildguardianComponent,
        ChildHealthComponent,
        DonorAdminComponent,
        FullcontentchildComponent,
        EditCentreComponent,
        ViewCentreComponent,
        HospitalsComponent,
        AddHospitalComponent,
        EditHospitalComponent,
        ListCaretakerComponent,
        ViewRegisteredChildComponent,
        AdminssionFaqComponent,
        ViewDonorComponent,
        ViewChildComponent,
        TypesComponent,
        AddTypeComponent,
        EditTypeComponent,
        AddFieldComponent,
        RequestedVolunteerComponent,
        AdHocAdmissionComponent,
        MealsComponent,
        MealSlotsComponent,
        MealsCalenderComponent,
        AddMealEventComponent,
        ViewMealEventComponent,
        EditFullChildComponent,
        CreateEmployee,
        DonorTableComponent,
        AddDonorComponent,
        CreateEventComponent,
        PaymentRemindersComponent,
        DashboardRemindersComponent,
        FollowupAdmissionComponent,
        EditDonorComponent,
        MyTestComponent,
        ViewImageComponent,
        EditMealEventComponent,
        ViewEventComponent,
        LogoutComponent,
        DeleteCenterComponent,
        UpdatePaymentComponent,
        MisComponent,
        FloorPlanComponent,
        FigurecardComponent,
        AgeBarComponent,
        HospitalsBarGraphComponent,
        BulkUploadComponent,
        FinancialReportTableComponent,
        MealMateEventTableComponent,
        RazorpayDataComponent,
        RegisteredChildExcelComponent,
        DiagnosisComponent,
        DisplayHospitalComponent,
        RenameComponent,
        RoleComponent,
        AddUserRoleComponent,
        EditUserRoleComponent,
        ViewFollowUpChildDetailsComponent,
        UnitSponsorshipComponent,
        AddUnitSponsorshipComponent,
        ActivityLogComponent,
        Mis2Component,
        SendPhotoEmailComponent,
        TransferUnitSponsorshipComponent,
        RenewalUnitSponsorshipComponent,
        SponsorTableComponent,
        AllotChildSponsorshipComponent,
        SponsorshipImageComponent,
        SponsorshipDashboardComponent,
        UnitTransferComponent,
        EditAdmissionReminderComponent,
        AdminChildEditComponent,


        GlossaryComponent,
        RazorpayTransactionComponent,
        EditEmployeeInfoComponent,
        AddGlossaryComponent,
        EditGlossaryComponent,

        ChangesComponent,

        RightSidebarComponent,
        SidebarComponent,
        MainComponent,
        Header2Component,
        UnsponsorChildrenTableComponent,
        AddInfoComponent,
        AddVehiclesComponent,
        UpdateDriverComponent,
        UpdateVehiclesComponent,
        VehicleTableComponent,
        DriverTableComponent,
        AddMedicalHistoryComponent,
        AdmissionChartComponent,
        ApexHospitalChartComponent,
        ApexAgeChartComponent,
        CentresChartComponent,
        TotalUnitsChartComponent,
        UniqueAdmissionsChartComponent,
        UniqueAdmissionsChartComponent,
        RecentAdmissionsComponent,
        RecentDischargeComponent,
        CarLogBookComponent,
        AllCarFuelDetailsComponent,
        CodeComponent,
        QuarterReportComponent,
      AllCarFuelsDetailsNewComponent,
      AllServicingDetailsNewComponent,
      AddCarFuelsDetailsComponent,
      AddServicingDetailsComponent,
      ChildJourneyComponent,
      PhotoGalleryComponent,
      AddCarLogBookComponent,
      ViewCarLogBookComponent,
      ManageOnlineSlotsComponent,
      AllotOnlineSlotComponent,
      ViewSponsorComponent,
      OnlineDonorsTableComponent,
      ViewVehicleComponent,
      ViewDriverComponent,
      // AlphabeticalSortDirectiv
      EditCarLogBookComponent,
      CentreEfficiencyComponent,
      DataCorrectionComponent,
      ManualDonationComponent,
      FailedDonationsComponent,
      ChangePasswordComponent,
      ManualAttemptedDonationRecieptComponent,
      ViewAttemptedDonationRecieptComponent,
      GetPlacesComponent,
      ViewServicingDetailsComponent,
      ViewCarFuelsDetailsComponent,
      EducationMisReposrtComponent
    ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    NgChartsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    HttpClientModule,
    SharedModule,
    MatCheckboxModule,
    PrettyJsonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    NgScrollbarModule,
    MatSlideToggleModule,
      NgOptimizedImage,
      MatAutocompleteModule ,
      MatFormFieldModule,
      NgxPrintModule,
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      // provideAnalytics(() => getAnalytics()),
      // provideAuth(() => getAuth()),
      // provideDatabase(() => getDatabase()),
      // provideFirestore(() => getFirestore()),
      // provideAnalytics(() => getAnalytics()),
      // AngularFireFunctionsModule,
      // provideFunctions(() => getFunctions()),
      // { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost', 9099] : undefined },
      // { provide: USE_DATABASE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9000] : undefined },
      // { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
      // { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined },
      provideDatabase(() => {
          const db = getDatabase()

          // if (environment.useEmulators) connectDatabaseEmulator(db, 'localhost', 9000);
// connectDatabaseEmulator(db, 'localhost', 9000);

          return (db);
      }),
      provideFirestore(() => {
          const firestore = getFirestore()

          // if (environment.useEmulators) connectFirestoreEmulator(firestore, 'localhost', 8080);

          return (firestore);

      }),
      provideAuth(() => {
          const auth = getAuth();

          // if (!environment.useEmulators) connectAuthEmulator(auth, `http://localhost:9099`)

          return (auth);
      }),

    //   CalendarModule.forRoot({
    //   provide: DateAdapter,
    //   useFactory: adapterFactory,
    // }),
  ],
    exports: [
        SidenavComponent,
    ],
    providers: [
      ScreenTrackingService, UserTrackingService, AuthService,
        {provide: FIREBASE_OPTIONS, useValue: environment.firebase}, AuthGuard, ChildService, DatePipe,TitleCasePipe,
        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},   // To change date formats by dd/MM/yyyy format
        {
            provide: ENVIRONMENT_INITIALIZER,
            useFactory: initializeDialogService,
            deps: [MatDialog],
            multi: true
        },
      DirectionService,
      RightSidebarService,
        // { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        // {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        // {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}


