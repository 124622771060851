<div class="container">
  <h1 class="text-center">Edit Role Permissions</h1>
  <div class="justify-content-end d-flex">
    <button mat-raised-button color="primary"  (click)="openDialog()">
      Add Role Permission
    </button>
  </div>
  <mat-form-field appearance="outline" *ngIf="(roles$|async)!!.length>=1">
    <mat-label>SELECT ROLE</mat-label>
    <mat-select [(ngModel)]="roleSelected" (selectionChange)="onRoleSelect($event)"  >
      <mat-option *ngFor="let r of roles$|async" [value]="r.id" > {{r.id}}</mat-option>
    </mat-select>
  </mat-form-field>
  <table class="table mt-4 ">
    <thead>
    <tr >
      <th class="head">MySpace Function</th>
      <th class="head">All</th>
      <th class="head">Add</th>
      <th class="head">Edit</th>
      <th class="head">Archive</th>
      <th class="head">View</th>
      <th class="head">Excel</th>
      <th class="head">Menu</th>
      <th class="head">Action</th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let d of (permissionsData)">
      <td *ngIf="d.functionName!='Employee'" >{{d.functionName}}</td>
      <td *ngIf="d.functionName=='Employee'" >Users</td>
      <td><input type="checkbox" [(ngModel)]="d.all" (change)="selectionChangeRoleCheckBox(d.all,d.id,'all')"></td>
      <td><input type="checkbox" [(ngModel)]="d.add" (change)="selectionChangeRoleCheckBox(d.add,d.id,'add')"></td>
      <td><input type="checkbox" [(ngModel)]="d.edit"  (change)="selectionChangeRoleCheckBox(d.edit,d.id,'edit')"></td>
      <td><input type="checkbox" [(ngModel)]="d.archive"  (change)="selectionChangeRoleCheckBox(d.archive,d.id,'archive')"></td>
      <td><input type="checkbox" [(ngModel)]="d.view"  (change)="selectionChangeRoleCheckBox(d.view,d.id,'view')"></td>
      <td><input type="checkbox" [(ngModel)]="d.excel"  (change)="selectionChangeRoleCheckBox(d.excel,d.id,'excel')"></td>
      <td><input type="checkbox" [(ngModel)]="d.showMenu"  (change)="selectionChangeRoleCheckBox(d.showMenu,d.id,'showMenu')"></td>
      <td>
        <button color="warn" mat-icon-button   (click)="deletePermission(d.id)" matTooltip="Delete" ><mat-icon>delete</mat-icon>&nbsp;</button>&nbsp;
      </td>
    </tr>

    </tbody>
  </table>
</div>
