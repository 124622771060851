import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {RazorpayDataDataSource, RazorpayDataItem} from './razorpay-data-datasource';
import {RazorpayService} from "./razorpay.service";
import {DatePipe} from "@angular/common";
import {MatSnackBar} from "@angular/material/snack-bar";
import {LoadingService} from "../../services/loading.service";

@Component({
    selector: 'app-razorpay-data',
    templateUrl: './razorpay-data.component.html',
    styleUrls: ['./razorpay-data.component.css']
})
export class RazorpayDataComponent implements AfterViewInit, OnInit {
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    mStartDate!: Date
    mEndDate!: Date

    @ViewChild(MatTable) table!: MatTable<RazorpayDataItem>;
    // dataSource: RazorpayDataDataSource;
    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ['email','contact' ,'amount', 'status', 'method','order_id', 'id','group', 'created_at'];
    uniqueStatuses: string[] = [];

    response: any

    /** Columns displayed in the assessment-table. Columns IDs can be added, removed, or reordered. */

    constructor(private readonly razorpay: RazorpayService,
                private readonly datePipe: DatePipe,
                private readonly mSnackbar: MatSnackBar,
                private readonly LoaderService : LoadingService
    ) {
        this.dataSource = new MatTableDataSource<any>();
        this.dataSource.sort = this.sort;
        this.getUniqueStatuses();
        console.log('into razorpay-data component')
    }

    getUniqueStatuses() {
        this.uniqueStatuses = Array.from(new Set(this.dataSource.data.map(payment => payment.status)));
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    ngAfterViewInit(): void {
        this.table.dataSource = this.dataSource;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    ngOnInit(): void {
        // this.razorpay.getPayments().subscribe(res=>[
        //     this.response=res
        //
        // ])

    }

//     search() {
//         if (this.mStartDate != null && this.mEndDate != null) {
//             const startDate = this.datePipe.transform(this.mStartDate, 'yyyy-MM-dd')!!
//             const endDate = this.datePipe.transform(this.mEndDate, 'yyyy-MM-dd')!!
//             // const date = new Date()
//             // const endDate=new Date()
// // this.razorpay.getTransactionsByDateRange(this.datePipe.transform(startDate,'yyyy-MM-dd')!!,this.datePipe.transform(endDate,'yyyy-MM-dd')!!).subscribe(res=>{
//             this.razorpay.getTransactionsByDateRange(startDate, endDate).subscribe(res => {
//                 // alert(JSON.stringify(res))
//                 console.log('RAZORPAY', res)
//                 // @ts-ignore
//                 console.log('items', res["items"])
//                 // @ts-ignore
//                 this.dataSource = new MatTableDataSource<any>(res['items'])
//                 this.dataSource.sort = this.sort;
//                 this.dataSource.paginator = this.paginator;
//             })
//         } else this.mSnackbar.open('Please select a date range', '', {
//             duration: 3000,
//             panelClass: 'success'
//         })
//
//     }

  search() {
    this.LoaderService.setLoading(true);
    if (this.mStartDate != null && this.mEndDate != null) {
      const startDate = this.datePipe.transform(this.mStartDate, 'yyyy-MM-dd')!;
      const endDate = this.datePipe.transform(this.mEndDate, 'yyyy-MM-dd')!;

      this.razorpay.getTransactionsByDateRange(startDate, endDate).subscribe({
        next: (res:any) => {
          console.log('RAZORPAY', res);
          this.dataSource = new MatTableDataSource<any>(res['items']);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        },
        error: (err) => {
          console.error("Error fetching transactions:", err);
          this.mSnackbar.open('Failed to load data', '', {
            duration: 3000,
            panelClass: 'error'
          });
        },
        complete: () => {
          this.LoaderService.setLoading(false); // Ensure loader stops after completion
        }
      });
    } else {
      this.LoaderService.setLoading(false); // Prevent infinite loading when no dates are selected
      this.mSnackbar.open('Please select a date range', '', {
        duration: 3000,
        panelClass: 'success'
      });
    }
  }

  getDate(created_at: any) {
        return  this.datePipe.transform( new Date(created_at*1000),'dd-MMM-yyyy hh:mm:aa')
    }
}
