import {Component, Input, OnInit} from '@angular/core';
import {
    ChartComponent,
    ApexAxisChartSeries,
    ApexChart,
    ApexXAxis,
    ApexDataLabels,
    ApexStroke,
    ApexMarkers,
    ApexYAxis,
    ApexGrid,
    ApexTitleSubtitle,
    ApexPlotOptions,
    ApexTooltip,
    ApexLegend,
} from 'ng-apexcharts';

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    stroke: ApexStroke;
    dataLabels: ApexDataLabels;
    markers: ApexMarkers;
    colors: string[];
    yaxis: ApexYAxis;
    grid: ApexGrid;
    legend: ApexLegend;
    title: ApexTitleSubtitle;
    tooltip: ApexTooltip;
    plotOptions: ApexPlotOptions;
};
@Component({
    selector: 'app-admission-chart',
    templateUrl: './admission-chart.component.html',
    styleUrls: ['./admission-chart.component.scss']
})
export class AdmissionChartComponent implements OnInit {
    public areaChartOptions!: Partial<ChartOptions>;
    @Input() centreNames: string[] = []
    @Input() unique: number[] = []
    @Input() followUp: number[] = []
  dataTableLoaded = false;

    constructor() {
      console.log('ADMISSION CHART ',`${this.centreNames.length},${this.unique.length},${this.followUp.length}`)
    }
    ngOnInit(): void {
        this.areachart()
    }
    private areachart() {
        this.areaChartOptions = {
            series: [
                {
                    name: 'Unique Admissions',
                    data: this.unique,
                },
                {
                    name: 'FollowUp Admissions',
                    data: this.followUp,
                },
            ],
            chart: {
                height: 350,
                type: 'area',
                toolbar: {
                    show: true,
                },
                foreColor: '#9aa0ac',
            },
            colors: [ '#6973C6','#FC8380'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
            },
            xaxis: {
                type: 'category',
                categories: this.centreNames
                // categories: [
                //     '2018-09-19',
                //     '2018-09-20',
                //     '2018-09-21',
                //     '2018-09-22',
                //     '2018-09-23',
                //     '2018-09-24',
                //     '2018-09-25',
                // ],
            },
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'center',
                offsetX: 0,
                offsetY: 0,
            },
            grid: {
                show: true,
                borderColor: '#9aa0ac',
                strokeDashArray: 1,
            },
            tooltip: {
                theme: 'dark',
                marker: {
                    show: true,
                },
                x: {
                    show: true,
                },
            },
        };
    }
}
