import {Injectable, isDevMode} from '@angular/core';
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {environment} from "../../../environments/environment";
import {MatSnackBar} from "@angular/material/snack-bar";
import firebase from "firebase/compat/app";
import {increment} from "@angular/fire/firestore";

import {InventoryRequest} from "./requests/requests-table/requests-table.component";
import {PurchaseProduct} from "../../interfaces/purchase-product";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {Reciept} from "../../components/Donationt/DonationModel";
import {StockTransfer} from "./inteventory.model";
import {stockAdjustment} from "../../interfaces/stock-transfer";
import {Product} from "../../interfaces/product";

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  env = isDevMode() ? environment.testMode : environment.productionMode
  requested_data: any;

  constructor(private mDatabase: AngularFireDatabase,
              private mSnackbar: MatSnackBar,
              private storage: AngularFireStorage,
              private mFirestore: AngularFirestore) {
  }

//? To fetch current sku counter
  fetchCounter() {
    //? Fetching current counter from ALCounters/sku
    // Returning a Promise here
    return this.mDatabase.database.ref(this.env.skuCounter).get()
      .then(res => {
        return res.val();
      })
      .catch(err => {
        throw err; // Rethrow the error to be caught by the caller
      });
  }

// //? To increase sku counter
//   increaseCounter() {
//
//     const documentRef = ref(this.mDatabase.database, `${this.env.skuCounter}`)
//     return set(documentRef, increment(1)).then(() => {
//       console.log('counter updated by 1')
//     })
//       .catch(() => {
//       });
//   }

//* Issuing product from requests
  issueProducts(formData: any, transformedProducts: any[], issueDate: Date, RequestdocumentId: string) {
    const centreCode = formData['centreCode']
    const issuedArray = []
    // this.mSnackbar.open(centreCode)._dismissAfter(3000)
    // step1 get batch Constant
    const batch = this.mFirestore.firestore.batch()
    //step 2 get Collection References
    const centreInventoryRef = this.mFirestore.firestore.doc(`${this.env.centreInventory}/` + centreCode) //! Centre Inventory Reference
    const inventoryRequest = this.mFirestore.firestore.doc(`${this.env.inventoryRequests}/` + RequestdocumentId)

    for (let product of transformedProducts) {
      if (product.status == 'Issued') {
        //Add batch operation to decrease quantity in centreInventoryRef
        const sku_Quantity = `${product.sku}`
        const issuedQuantity = +product.issue_quantity
        this.mSnackbar.open(`${sku_Quantity}  - ${issuedQuantity}`)._dismissAfter(3000)
        this.mSnackbar.open('Product Issued Successfully', '',
          {
            panelClass: 'success'
          })._dismissAfter(3000)
        // alert(JSON.stringify(product))
        //** FOR CENTRE INVENTORY CALCULATIONS
        batch.set(centreInventoryRef, {
            //FOR CENTRE INVENTORY
            'products': {
              [product.sku]: {
                product_name: product.name,
                category: product?.category,
                sub_category: product?.sub_category || '',
                brand: product.brand,
                quantity: firebase.firestore.FieldValue.increment(-issuedQuantity),
              }
            }
          }
          , {merge: true})
        issuedArray.push(product)
      }
    }

    for (let product of transformedProducts) {
      console.log('XXX', product)
      console.log('XXX.PRODUCTS', product.status)
      const item = product.status
      const quantity = product.issue_quantity
      let obj = {
        products: {
          [item]: 'Updated',
          [quantity]: firebase.firestore.FieldValue.increment(product.issue_quantity),

        }
      }

      batch.set(inventoryRequest, obj, {merge: true})

    }
    let allStatus = 'Pending'
// if(transformedProducts.filter(x=>x.status=="Issued").length>1) allStatus="Partialy Issued"

    if (transformedProducts.length == transformedProducts.filter(x => x.status == "Issued").length) {
      allStatus = "Issued"
    }

    let issueLogData = {
      centreName: formData.centreName,
      requested_by: formData.requested_by,
      requestedDate: formData.date,
      products: transformedProducts,
      issueDate: issueDate,
      status: allStatus
      // status:product.status
    }
    alert(JSON.stringify(issueLogData))
    // const issueLog = this.mFirestore.firestore.doc('InventoryIssueLog/' + RequestdocumentId)
    batch.set(inventoryRequest, issueLogData, {merge: true})
    return batch.commit()


  }

  //issued product-tbl
  fetchIssuedProductList() {
    return this.mFirestore.collection('InventoryIssueLog/').valueChanges({idField: 'key'})
  }

  addRequest(value: any) {
    return this.mFirestore.collection(`${this.env.inventoryRequests}`).add(value)
  }

  updateRequest(key: any, value: any) {
    return this.mFirestore.collection(`${this.env.inventoryRequests}`).doc(key).update(value).then(() => {

    })
  }

  fetchRequest() {
    return this.mFirestore.collection<InventoryRequest>(`${this.env.inventoryRequests}`).valueChanges({idField: 'key'})

  }

  fetchRequestByCentre(centreCode: string) {
    return this.mFirestore.collection<InventoryRequest>(`${this.env.inventoryRequests}`, ref1 => ref1.where('centreCode', '==',
      centreCode)).valueChanges({idField: 'key'})

  }

  fetchAllRequest() {
    return this.mFirestore.collection<InventoryRequest>(`${this.env.inventoryRequests}`,).valueChanges({idField: 'key'})

  }

  deleteRequest(key: any) {
    return this.mFirestore.collection<InventoryRequest>(`${this.env.inventoryRequests}/`).doc(key).delete().then(() => {

    })
  }

  // PRODUCT PURCHASE
  fetchpProductPurchaseByInvoice() {
    return this.mFirestore.collection<PurchaseProduct>(`${this.env.purchaseInvoices}`).valueChanges({idField: 'key'})
  }

  fetchpProductPurchaseByCentre(centercode: any) {
    return this.mFirestore.collection<PurchaseProduct>(`${this.env.centreInventory}/`).doc(centercode).valueChanges({idField: 'key'})
  }

  upload_file(file: File): Promise<string> {
    if (file) {
      const filePath = `upload/${file.name}`;
      const fileRef = this.storage.ref(filePath);
      return fileRef.put(file).then(() => fileRef.getDownloadURL().toPromise());
    } else {
      console.error('File is undefined or null.');
      return Promise.reject('File is undefined or null.');
    }

  }

  fetchData() {
    return this.mFirestore.collection<PurchaseProduct>('add_product').valueChanges({idField: 'key'})
  }

  fetchData_Bycentrecode(centreCode: any) {
    return this.mFirestore.doc(`${this.env.centreInventory}/${centreCode}`).valueChanges({idField: 'key'})
  }

  editpurchaseproduct(key: String, Add_product: any) {
    return this.mFirestore.doc(`add_product/${key}`).update(Add_product)
  }

  deleteFile(fileUrl: string) {
    return this.storage.storage.refFromURL(fileUrl).delete()
  }

  DeleteProductPurchase(add_product: any) {
    return this.mFirestore.doc('/add_product/' + add_product.key).delete();
  }

  //request product-tbl opeartion
  add_request_product(form: any) {
    this.mFirestore.collection(`${this.env.inventoryRequests}`).add(form).then()
  }

  fetch_request_product() {
    return this.mFirestore.collection(`${this.env.inventoryRequests}`).valueChanges({idField: 'key'})
  }

//FETCH ACCORDING TO SPECFIC DOCUMENT
  fetch_request_product_BYDocument(document_id: string) {
    return this.mFirestore.collection(`${this.env.inventoryRequests}`).doc(document_id).valueChanges({idField: 'key'})
  }

  edit_request_product(key: string, request_product: any) {
    return this.mFirestore.doc(`${this.env.inventoryRequests}/${key}`).update(request_product)
  }

  delete_request_product(request_product: any) {
    return this.mFirestore.doc(`/${this.env.inventoryRequests}/` + request_product.key).delete();
  }

  //ADD PRODUCT
  addProduct(product: any) {
    // alert(product.sku)
    return this.mFirestore.collection(this.env.products).doc(product.sku.toString()).set(product)
  }

  fetchProducts() {
    return this.mFirestore.collection<Product>(this.env.products).valueChanges({idField: 'key'})
  }

  fetch_counter_sku() {
    return this.mFirestore.collection('inventory_counter').doc('counter').valueChanges({idField: 'key'})
  }

  updateSku(newValue: number) {
    return this.mFirestore.collection('inventory_counter').doc('counter').update({
      current_counter: newValue
    });
  }

  edit(key: string, product: any) {
    return this.mFirestore.doc(`${this.env.products}/${key}`).update(product)
  }

  delete(product: any) {
    return this.mFirestore.doc(`/${this.env.products}/` + product.key).delete()
  }

  FetchProductBYCategory(categorytype: any) {
    return this.mFirestore.collection(`${this.env.products}`, ref => ref.where("category", '==', categorytype)).valueChanges()
  }

  get_requested_data() {
    return this.requested_data;
    // alert(this.requested_data)

  }

  set_requested_data(data: any) {
    this.requested_data = data
  }

  fetchFailedDonation() {
    return this.mFirestore.collection('failedDonations').valueChanges()
  }

  fetchAttemptedDonation() {
    return this.mFirestore.collection('attemptedDonations',ref => ref.orderBy('date','desc')).valueChanges({idField:'id'})
  }

  getByRangeAttemptedDonations(startDate: any, endDate: any) {
    return this.mFirestore.collection<Reciept>('attemptedDonations', ref => ref.where('date', '>=', startDate)
      .where('date', '<=', endDate)
    ).valueChanges()

  }

  getByRangeFailedDonations(startDate: any, endDate: any) {
    return this.mFirestore.collection<Reciept>('failedDonations', ref => ref.where('date', '>=', startDate)
      .where('date', '>=', startDate)
    ).valueChanges()

  }

  //GET STOCK ADJUSTMENT

  fetchpStockByCentre(centercode: any) {
    return this.mFirestore.collection<stockAdjustment>(`${this.env.stockAdjustment}/`).doc(centercode).valueChanges({idField: 'key'})
  }

  fetchStockAdjustmentByCentre() {
    return this.mFirestore.collection<stockAdjustment>(`${this.env.stockAdjustment}/`).valueChanges({idField: 'key'})
  }

  fetchStockTransferByCentre(centercode: any) {
    return this.mFirestore.collection<StockTransfer>(`${this.env.transferInventory}/`, ref1 => ref1.where('from', '==', centercode))
      .valueChanges({idField: 'key'})
  }

  fetchStockTransferByCentreFromIncomingStock(centercode: any) {
    return this.mFirestore.collection<StockTransfer>(`${this.env.transferInventory}/`, ref1 => ref1.where('to', '==', centercode))
      .valueChanges({idField: 'key'})
  }

  directIssue(form: any) {
    //? batch start
    const batch = this.mFirestore.firestore.batch()
    const centreCode = form.centreCode
    const documentId = this.mFirestore.firestore.collection(`${this.env.IssueInventory}`).doc().id
    const mDirectIssueLog = this.mFirestore.firestore.doc(`${this.env.IssueInventory}/${documentId}`)  //! Invoice Document Reference
    const mCenterInventoryRef = this.mFirestore.firestore.doc(`${this.env.centreInventory}/${centreCode}`)
    batch.set(mDirectIssueLog, form, {merge: true})

    for (let product of form.products) {
      console.log("PRODUCT IN ARRAY", JSON.stringify(product))
      const sku = product.sku
      /** {
        "current_quantity": 109,
        "name": "Wheat flour",
        "sku": "3",
        "quantity": 0,
        "category": "Food",
        "brand": "Ashirwad"
    }*/
      const decquantity = product.issue_quantity
      const mProducts = {
        products: {
          [sku]: {
            product_name: product.name,
            brand: product.brand,
            sub_category: product.sub_category,
            quantity: increment(-decquantity),
            category: product.category,
            sku: product.sku
          }
        }
      }
      batch.set(mCenterInventoryRef, mProducts, {merge: true})
    }

    return batch.commit()
    // const mDirectIssueCollection = this.mFirestore.

  }
  fetchPurchaseList(centreCode:string){
  return   this.mFirestore.collection(this.env.purchaselist).doc(centreCode).valueChanges({idField:'id'})
  }
}

