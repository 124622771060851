<h2 class="text-center">Add Role Permissions</h2>
<!--<prettyjson [obj]='roleForm.value'></prettyjson>-->
<form [formGroup]="roleForm" (ngSubmit)="addRole()">
  <div class="row">
    <mat-form-field appearance="outline" class="col-md-12" *ngIf="(roles$|async)!.length>=1">
      <mat-label>Select Role</mat-label>
      <mat-select formControlName="roleName">
        <mat-option *ngFor="let r of roles$|async" [value]="r"> {{ r }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-md-12">
      <mat-label>Function Name</mat-label>
      <input matInput formControlName="functionName"/>
    </mat-form-field>
  </div>
  <div class="justify-content-center d-flex gap-2">

    <button mat-raised-button color="warn" (click)="dialogRef.close()">Cancel</button>
    <button mat-raised-button color="accent" (click)="addDefultPermissions()">Default Permissions</button>
    <button mat-raised-button color="primary" type="submit" [disabled]="roleForm.invalid">Update</button>
  </div>
</form>
