import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {Units} from "../../interfaces/Units";
import {CentreService} from "../../services/centre.service";
import {Observable, Subject, takeUntil} from "rxjs";
import {Centre} from "../../interfaces/Centres";
import {MatSelectChange} from "@angular/material/select";
import {UnitService} from "../../services/unit.service";
import {MatDialog} from "@angular/material/dialog";
import {FloorPlanComponent} from "./floor-plan/floor-plan.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AdHocAdmissionComponent} from "../child/ad-hoc-admission/ad-hoc-admission.component";
import {UnitTransferComponent} from "./unit-transfer/unit-transfer.component";

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.scss']
})
export class UnitsComponent implements OnInit, AfterViewInit, OnDestroy {
  displayedColumns: string[] = ['centreName', 'id', 'floorNo', 'name', 'allotedTo', 'status', 'action'];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource = new MatTableDataSource<Units>;
  $center: Observable<Centre[]>
  private _destroyed$ = new Subject()
  selectedCentre!: Centre
  unitsArray: Units[] = []
  floorselected?: string
  @ViewChild(MatSort) matSort!: MatSort;
  public centers!: Observable<Centre[]>;
  constructor(private mCentreService: CentreService,
              private mUnitService: UnitService,
              private dialog: MatDialog,
              public _snackBar: MatSnackBar) {
    this.$center = mCentreService.getAllCentres()
    this.dataSource = new MatTableDataSource(this.unitsArray);
  }


  ngOnInit(): void {
  }

  onCenterSelected(event$: MatSelectChange) {
    this.selectedCentre = event$.value
    this.unitsArray = []
 //   console.log(event$.value)
    let centre = event$.value
    this.mUnitService.getUnit(centre.centreCode).pipe(takeUntil(this._destroyed$)).subscribe((units) => {
      this.unitsArray = []

      units.forEach(unit => {
        this.unitsArray.push(unit as Units)
      //  console.log('unit', unit)
        this.dataSource = new MatTableDataSource(this.unitsArray);
        this.dataSource.paginator = this.paginator;
      })
    })

  }

  ngAfterViewInit(): void {

    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  setFloor(row: any) {
    console.log(this.selectedCentre.centreCode)
    const dialogRef = this.dialog.open(FloorPlanComponent, {
      data: row
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      this.floorselected = result;
      this.mUnitService.allotFloor(this.selectedCentre, row.key, this.floorselected).then(() => {
        this._snackBar.open('Floor Added!', '', {duration: 3000})
      })
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next(null)
    this._destroyed$.complete()
  }

  AssignAdHOC(key:string) {
    const allotDialogRef = this.dialog.open(AdHocAdmissionComponent, {
      width: '500px',
      height: '400px',
      data:key
    })
    allotDialogRef.afterClosed().subscribe(result => {
      console.log(result)
    })

  }

  transfer() {
    // Open the MatDialog when the "Transfer" button is clicked
    this.dialog.open(UnitTransferComponent, {
      width: '97%',
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '100ms',
      data: this.dataSource,
      autoFocus: false
      // @ts-ignore


    });
  }
}
