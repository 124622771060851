import {Component, Inject, OnInit} from '@angular/core';
import {UserRole} from "../../rename/add-user-role/add-user-role.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs";

@Component({
  selector: 'app-add-role-permissions',
  templateUrl: './add-role-permissions.component.html',
  styleUrls: ['./add-role-permissions.component.scss']
})
export class AddRolePermissionsComponent implements OnInit {
  roleForm!: FormGroup;
  roles$: Observable<any[]>;
  defaultPermissions = [
    'Child Admission',
    'Child Prescreening',
    'Counselling',

    'Education',
    'Meal Mate',
    'Unit Sponsorship',
    'General Donation',
    'CMS',
    'Education Report',
    'Donation Report',
    'Weekly Report',
    'Hospital',
    'Unit',
    'Types',
    'Donor',
    'Event',
    'Audit Logs',
    'Inventory',
    'Vehicles',
    // Add other function names as needed
    'Audit Logs',
    'Dashboard',
    'Child Admission',
    'Donation Report',
    'Child Report',
    'Unit Sponsorship',
    'Centres',
    'Employees',
    'Tasks',
    'Ops Reports',
    'Counseling Report'
  ];
  permissionsMap: { [key: string]: UserRole[] } = {};

  constructor(
    public dialogRef: MatDialogRef<AddRolePermissionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { selectedRole: string },
    private fb: FormBuilder,
    private mDatabase: AngularFireDatabase,
    private firestore: AngularFirestore
  ) {
    this.roles$ = this.mDatabase.list('types/Employee Roles').valueChanges()
  }

  ngOnInit(): void {
    this.roleForm = this.fb.group({
      roleName: ['', Validators.required], // Role name input
      functionName: [''],
      all: [false],
      add: [false],


      edit: [false],
      archive: [false],
      view: [false],
      excel: [false],
      showMenu: [false]
    });
  }

  private getDefaultPermissions(): UserRole[] {
    return this.defaultPermissions.map((functionName: string) => {
      return {
        functionName,
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      };
    });
  }


  async addRole() {
    const roleName = this.roleForm.value.roleName;
    if (!roleName) return;

    const roleRef = this.firestore.collection('user_permissions').doc(roleName);

    try {
      // Create role document
      await roleRef.set({createdAt: new Date()});

      // Create new permission
      const newPermission: UserRole = {
        functionName: this.roleForm.get('functionName')?.value || '',
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      };

      // Add permission document to 'permissions' subcollection
      await roleRef.collection('permissions').add(newPermission);

      console.log(`Role ${roleName} added successfully!`);
    } catch (error) {
      console.error('Error adding role:', error);
    }

    // Reset the form after the operation
    this.roleForm.reset();
  }


  addDefultPermissions() {
    const roleName = this.roleForm.value.roleName;

    const roleRef = this.firestore.collection('user_permissions').doc(roleName);

    let permissions = this.getDefaultPermissions()
    permissions.forEach(it => {
      roleRef.collection('permissions').add(it)
    })
    console.log(permissions)
  }
}
