import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {VehicleService} from "../vehicle.service";
import {MatDialog} from "@angular/material/dialog";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {AddServicingDetailsComponent} from "../add-servicing-details/add-servicing-details.component";
import {MatTableDataSource} from "@angular/material/table";
import {ViewServicingDetailsComponent} from "../view-servicing-details/view-servicing-details.component";
import {Timestamp} from "@angular/fire/firestore";
import {DatePipe} from "@angular/common";
import {LoadingService} from "../../../services/loading.service";

@Component({
  selector: 'app-all-car-servicing-details-new',
  templateUrl: './all-car-servicing-details-new.component.html',
  styleUrls: ['./all-car-servicing-details-new.component.scss']
})
export class AllServicingDetailsNewComponent implements OnInit {
  displayedColumns: string[] = [ 'driverName','EntryDate','dateOfFilling','vehicleNumber','paidAmount','meter', 'action'];
  // dataSource: any[] = []
  dataSource: MatTableDataSource<any>

  drivers: any[] =[];
  vehicles: any[]=[];
  driverName: any;
  vehicles_no = '';
  driver_name = '';
  selectedVehicle: any;
  selectedDriver: any;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(private mDialog: MatDialog,private datePipe: DatePipe,
              private loadingService: LoadingService,
              private mVehicleService: VehicleService) {
    this.dataSource =new MatTableDataSource<any>()

  }

  ngOnInit(): void {
    this.loadingService.setLoading(true);
 this.mVehicleService.getServicingData().subscribe(res =>{
   this.dataSource = new MatTableDataSource(res)
   this.loadingService.setLoading(false);
    })

    this.mVehicleService.getDriver().subscribe(res => {
      this.drivers = res
    })

    this.mVehicleService.getVehicles().subscribe(res => { console.log(res)
      this.vehicles = res
    })
  }
  displayDate(timestamp: Timestamp): string {
    // Check if timestamp exists and is a Firestore Timestamp
    if (timestamp && timestamp.toDate) {
      const date = timestamp.toDate(); // Convert to JavaScript Date
      return this.datePipe.transform(date, 'dd/MM/yyyy') || '';
    }
    return '';
  }

  OpenDialog() {
    this.mDialog.open(AddServicingDetailsComponent, {
      maxHeight: '60vh',
    });

  }
  viewServicingDetails(element:any){
    this.mDialog.open(ViewServicingDetailsComponent, {
      data:element,
      width: '700px'
    })
  }

  editInfo(element:any) {
    this.mDialog.open(AddServicingDetailsComponent, {
      data:element
    })
  }

  deleteInfo(element:any) {
    this.mVehicleService.deleteServicingData(element.id).then(res => {
      console.log("Deleted Successfully")
    })
  }

  onDriverSelect($event:any){
    this.driverName = $event.value
  }

  OnVehicleSelect($event:any){
    this.selectedVehicle = $event.value
  }

  filterData() {debugger
    // Set custom filter predicate
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const filterValues = JSON.parse(filter); // Parse filter string
      const matchDriver = filterValues.driverName ? data.driverName.toLowerCase().includes(filterValues.driverName.toLowerCase()) : true;
      const matchVehicle = filterValues.vehicleNumber ? data.vehicleNumber.toLowerCase().includes(filterValues.vehicleNumber.toLowerCase()) : true;
      return matchDriver && matchVehicle;
    };

    const filterObject = {
      driverName: this.selectedDriver,
      vehicleNumber: this.selectedVehicle
    };
    // Set the filter to the dataSource
    this.dataSource.filter = JSON.stringify(filterObject); // Apply filter
  }
  clearAll() {debugger
    // Reset selected driver and vehicle
    this.driver_name = '';
    this.vehicles_no =''
    this.dataSource.filter = '';
    // Optionally, refetch the original data to reset the table
    this.mVehicleService.getServicingData().subscribe(res => {
      this.dataSource.data = res; // Reset data in the table
    });
  }


  applyFilter(event: KeyboardEvent) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.dataSource.filter = filterValue; // This will filter based on all columns
  }
}
