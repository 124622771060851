<div>
  <br>
  <h3 class="rounded">Edit Prescreening Child Registration Form</h3>
  <br>
  <form class="about" [formGroup]="childForm">
    <div class="row">
      <!--                                                    Center Code      -->

      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>First Name</mat-label>
        <input matInput (blur)="onBlur(firstname)" placeholder="First Name" required formControlName="firstName">
        <mat-error *ngIf="firstname.invalid  &&  (firstname.touched || firstname.dirty)">
          <span style="color: red;" *ngIf="firstname.errors?.required">*It is  Required.</span>
          <span style="color: red;" *ngIf="firstname.errors?.pattern">*Enter Only characters.</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>Middle Name</mat-label>
        <input matInput (blur)="onBlur(middleName)" placeholder="Middle Name" required formControlName="middleName">
        <mat-error *ngIf="  middleName.invalid  &&  (middleName.touched || middleName.dirty)">
          <span style="color: red;" *ngIf="middleName.errors?.required">*It is  Required.</span>
          <span style="color: red;" *ngIf="middleName.errors?.pattern">*Enter Only characters.</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>Last Name</mat-label>
        <input matInput (blur)="onBlur(lastName)" placeholder="Last Name" required formControlName="lastName">
        <mat-error *ngIf="  lastName.invalid  &&  (lastName.touched || lastName.dirty)">
          <span style="color: red;" *ngIf="lastName.errors?.required">*It is  Required.</span>
          <span style="color: red;" *ngIf="lastName.errors?.pattern">*Enter Only characters.</span>
        </mat-error>

      </mat-form-field>


      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>Gender</mat-label>
        <mat-select formControlName="gender">
          <mat-option value="Female">Female</mat-option>
          <mat-option value="Male">Male</mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">

        <mat-label>Age</mat-label>
        <mat-select required formControlName="age">
          <mat-option value="0">0</mat-option>
          <mat-option value="1">1</mat-option>
          <mat-option value="2">2</mat-option>
          <mat-option value="3">3</mat-option>
          <mat-option value="4">4</mat-option>
          <mat-option value="5">5</mat-option>
          <mat-option value="6">6</mat-option>
          <mat-option value="7">7</mat-option>
          <mat-option value="8">8</mat-option>
          <mat-option value="9">9</mat-option>
          <mat-option value="10">10</mat-option>
          <mat-option value="11">11</mat-option>
          <mat-option value="12">12</mat-option>
          <mat-option value="13">13</mat-option>
          <mat-option value="14">14</mat-option>
        </mat-select>
      </mat-form-field>


      <!--    pattern="[0-9]{10}$"-->

      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>Mobile No.</mat-label>
        <input matInput placeholder="Mobileno" required formControlName="mobile">
        <mat-icon matSuffix>phone</mat-icon>
        <mat-error *ngIf="  mobile.invalid  &&  (mobile.touched || mobile.dirty)">
          <span style="color: red;" *ngIf="mobile.errors?.required">*It is  Required.</span>
          <span style="color: red;" *ngIf="mobile.errors?.pattern">*Enter Only Number.</span>
        </mat-error>

      </mat-form-field>


      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>City</mat-label>
        <input matInput (blur)="onBlur(city)" placeholder="City" formControlName="city">
        <mat-error *ngIf="  city.invalid  &&  (city.touched || city.dirty)">
          <span style="color: red;" *ngIf="city.errors?.required">*It is  Required.</span>
          <span style="color: red;" *ngIf="city.errors?.pattern">*Enter Only characters.</span>
        </mat-error>
      </mat-form-field>


      <mat-form-field appearance="outline" class="col-md-6 col-sm-12">
        <mat-label>District</mat-label>
        <input matInput (blur)="onBlur(district)" placeholder="District" formControlName="district">
        <mat-error *ngIf="  district.invalid  &&  (district.touched || district.dirty)">
          <mat-error style="color: red;" *ngIf="district.errors?.required">*It is Required.</mat-error>
          <mat-error style="color: red;" *ngIf="district.errors?.pattern">*Enter Only characters.</mat-error>
        </mat-error>
      </mat-form-field>


      <!--          <mat-form-field appearance="outline" class="col-md-6 col-sm-12">-->
      <!--              <mat-label>State</mat-label>-->

      <!--              <input matInput (blur)="onBlur(state)"  placeholder="State"  formControlName="state">-->
      <!--              <mat-error *ngIf="  state.invalid  &&  (state.touched || state.dirty)">-->
      <!--                  <mat-error style="color: red;" *ngIf="state.errors?.required">*It is Required.</mat-error>-->
      <!--                  <mat-error style="color: red;" *ngIf="state.errors?.pattern">*Enter Only characters.</mat-error>-->
      <!--              </mat-error>-->
      <!--          </mat-form-field>-->

      <mat-form-field appearance="outline" class="col-md-6 col-sm-12">
        <mat-label>State</mat-label>
        <mat-select formControlName="state">
          <mat-option [value]="state.value">{{ state.value }}</mat-option>
          <!-- Add more options if needed -->
        </mat-select>
        <mat-error *ngIf="state.invalid && (state.touched || state.dirty)">
          <mat-error style="color: red;" *ngIf="state.errors?.required">*It is Required.</mat-error>
          <mat-error style="color: red;" *ngIf="state.errors?.pattern">*Enter Only characters.</mat-error>
        </mat-error>
      </mat-form-field>


      <mat-form-field appearance="outline" class="col-md-6 col-sm-12">
        <mat-label>Diagnosis</mat-label>
        <mat-select multiple required formControlName="diagnosis">
          <mat-option disabled>--Select Diagnosis--</mat-option>
          <mat-option *ngFor="let diagnosis of (_diagnosis$|async)" [value]="diagnosis">{{ diagnosis }}</mat-option>
        </mat-select>
        <mat-error
          *ngIf="diagnosis.invalid  &&  (diagnosis.touched ||diagnosis.dirty)"></mat-error>
        <mat-error *ngIf="diagnosis.errors?.required"> *It is required</mat-error>
      </mat-form-field>


      <mat-form-field appearance="outline" class="col-md-6 col-sm-12">
        <mat-label>Hospital name</mat-label>
        <mat-select placeholder="hospitalname" required formControlName="hospitalname">
          <mat-option *ngFor="let h of (hospitals$|async)" [value]="h.hospitalName">{{ h.hospitalName }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-6 col-sm-12">
        <mat-label>Status</mat-label>
        <mat-select placeholder="Status" required formControlName="status">
          <mat-option *ngFor="let h of (prescreeningStatus$|async)" [value]="h">{{ h }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-6 col-sm-12">
        <mat-label>Date of Closure</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="dateOfClosure" placeholder="Choose a date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="dateOfClosure.invalid && (dateOfClosure.touched || dateOfClosure.dirty)">
          <span style="color: red;" *ngIf="dateOfClosure.errors?.required">*It is Required.</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-6 col-sm-12">
        <mat-label>Reason of Closure</mat-label>
        <mat-select formControlName="reasonOfClosure">
          <mat-option *ngFor="let r of (reasonOfClosure$|async)" [value]="r">{{r}}</mat-option>
        </mat-select>

        <mat-error *ngIf="reasonOfClosure.invalid && (reasonOfClosure.touched || reasonOfClosure.dirty)">
          <span style="color: red;" *ngIf="reasonOfClosure.errors?.required">*It is Required.</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-12 col-sm-12">
        <mat-label>Remark</mat-label>
        <input matInput (blur)="onBlur(remark)" required placeholder="remark" formControlName="remark">
        <mat-error *ngIf="  remark.invalid  &&  (remark.touched || remark.dirty)">
          <mat-error style="color: red;" *ngIf="remark.errors?.required">*It is Required.</mat-error>
          <mat-error style="color: red;" *ngIf="remark.errors?.pattern">*Enter Only Number.</mat-error>
        </mat-error>
      </mat-form-field>


      <div class="col-md-12 col-sm-12">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                English
              </mat-panel-title>
              <mat-panel-description></mat-panel-description>

            </mat-expansion-panel-header>
            <div style="padding: 20px">
              <mat-form-field class="faq" appearance="outline">
                <mat-label><strong>Q1.</strong> Confirm the name of the child along with age. (Age limit 0 To under 14)
                </mat-label>
                <input matInput formControlName="q1">
              </mat-form-field>
              <mat-form-field class="faq" appearance="outline">
                <mat-label><strong>Q2.</strong> When did the family reach / come to the hospital? And where are they
                  staying currently?
                </mat-label>
                <input matInput formControlName='q2'>
              </mat-form-field>

              <mat-form-field class="faq" appearance="outline">
                <mat-label><strong>Q3.</strong>Where does the family hail from (village/state/city)?</mat-label>
                <input matInput formControlName="q3">
              </mat-form-field>

              <mat-form-field class="faq" appearance="outline">
                <mat-label><strong>Q4.</strong>What is the father's profession (work)?</mat-label>
                <input matInput formControlName="q4">
              </mat-form-field>
              <mat-form-field class="faq" appearance="outline">
                <mat-label><strong>Q5.</strong> Is the child able to walk by himself/herself?</mat-label>
                <input matInput formControlName="q5">
              </mat-form-field>

              <mat-form-field class="faq" appearance="outline">
                <mat-label><strong>Q6.</strong>Do they have a patient mother father Aadhar card & Ration card? If the
                  child does not have an Aadhaar card, then share the birth certificate.
                </mat-label>
                <input matInput formControlName="q6">
              </mat-form-field>
              <mat-form-field class="faq" appearance="outline">
                <mat-label><strong>Q7.</strong>How many children do they have? If more than one then check whom have
                  they
                  left them with?
                </mat-label>
                <input matInput formControlName="q7">
              </mat-form-field>

              <mat-form-field class="faq" appearance="outline">
                <mat-label><strong>Q8.</strong>If the patient's mother is pregnant then she is not allowed to stay in
                  the
                  center / in her place only one other person can stay with the patient like grandfather, grandmother,
                  maternal uncle of the patient
                </mat-label>
                <input matInput formControlName="q8">
              </mat-form-field>

              <mat-form-field class="faq" appearance="outline">
                <mat-label><strong>Q9.</strong>Do they have any relatives/ friends staying in the same city (In this
                  case
                  closer to center)?
                </mat-label>
                <input matInput formControlName="q9">
              </mat-form-field>
              <strong> Notice:</strong>

              <ol style="  text-align: justify;">
                <li>Meat, mutton is not allowed in the center, only boiled egg can be given to the child.</li>
                <li>It is very imliortant to keeli cleanliness in the center.</li>
                <li>In case of any kind of misbehavior, hurting any other liatients or misbehaving with any emliloyee of
                  the center, they will be warned in writing.
                </li>
                <li>Only two lieolile are allowed to stay with the liatient./ Please contact Access Life staff if there
                  is
                  a single occuliant
                </li>
                <li>other siblings of the liatient are not allowed to stay in the center</li>
                <li>You can stay in the center as long as the patient is treated.</li>
              </ol>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <!--Purohit-->
        <!--7387392001-->

        <br>
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                हिन्दी
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div style="padding: 20px">

              <mat-form-field class="faq" appearance="outline">
                <mat-label><strong>Q1.</strong> उम्र के साथ बच्चे के नाम की पुष्टि करें। (आयु सीमा 0 से 14 वर्ष से कम)
                </mat-label>
                <input matInput formControlName="q1">
              </mat-form-field>
              <mat-form-field class="faq" appearance="outline">
                <mat-label><strong>Q2.</strong> परिवार कब पहुंचा/अस्पताल हॉस्पिटल आया? और वे वर्तमान में कहाँ रह रहे
                  हैं?
                </mat-label>
                <input matInput formControlName='q2'>
              </mat-form-field>

              <mat-form-field class="faq" appearance="outline">
                <mat-label><strong>Q3.</strong>परिवार (गाँव/राज्य/शहर) कहाँ से आता है?</mat-label>
                <input matInput formControlName="q3">
              </mat-form-field>

              <mat-form-field class="faq" appearance="outline">
                <mat-label><strong>Q4.</strong>पिता का पेशा (काम) क्या है?</mat-label>
                <input matInput formControlName="q4">
              </mat-form-field>
              <mat-form-field class="faq" appearance="outline">
                <mat-label><strong>Q5.</strong>क्या बच्चा अपने आप चलने में सक्षम है?</mat-label>
                <input matInput formControlName="q5">
              </mat-form-field>

              <mat-form-field class="faq" appearance="outline">
                <mat-label><strong>Q6.</strong>क्या उनके पास रोगी माता पिता का आधार कार्ड और राशन कार्ड है? अगर बच्चे के
                  पास आधार कार्ड नहीं है तो जन्म प्रमाण पत्र साझा करें.
                </mat-label>

                <input matInput formControlName="q6">
              </mat-form-field>
              <mat-form-field class="faq" appearance="outline">
                <mat-label><strong>Q7.</strong>उनके कितने बच्चे हैं? यदि एक से अधिक हैं तो जांचें कि उन्होंने उन्हें
                  किसके
                  साथ छोड़ा है?
                </mat-label>

                <input matInput formControlName="q7">
              </mat-form-field>

              <mat-form-field class="faq" appearance="outline">
                <mat-label><strong>Q8.</strong>यदि रोगी की माँ गर्भवती है तो उसे केंद्र में रहने की अनुमति नहीं है /
                  उनके
                  स्थान पर रोगी के साथ अन्य केवल एक जन रह सकते हैं, जैसे रोगी के दादा, दादी, मामा।
                </mat-label>
                <input matInput formControlName="q8">
              </mat-form-field>

              <mat-form-field class="faq" appearance="outline">
                <mat-label><strong>Q9.</strong>क्या उनका कोई रिश्तेदार/मित्र एक ही शहर में रहता है (इस मामले में center
                  के
                  करीब)?
                </mat-label>

                <input matInput formControlName="q9">
              </mat-form-field>
              <strong> सूचना :</strong>
              <ol style="  text-align: justify;">
                <li>केंद्र में मांस ,मटन खाने की अनुमति नहीं है केवल उबला हुआ अंडा बच्चे को दे सकते है !</li>

                <li>केंद्र में साफ़ सफाई रखना बहुत जरुरी है !</li>
                <li> किसी भी प्रकार गलत व्यवहार ,किसी अन्य मॅरीजो को तकलिप पहुँचाना या केन्द के किसी कर्मचारी से ग़लत पेश
                  आने पर उनको लिखित स्वरुप में चेतावनी दी जायेगी !
                </li>

                <li>मरीज़ के साथ केवल दो लोगो की रहनेकी अनुमति है! / यदि एक ही व्यक्ति रहनेवाले हे तो क्रिपया एक्सेस लाईफ
                  के कर्मचारी से संपर्क करें
                </li>

                <li>मरीज के अन्य भाई बहन को केन्द्र में रहने की अनुमति नहीं है.</li>

                <li>जब तक मरीज का इलाज़ चलेगा तब तक आप केंद्र में रह सकते हो.</li>
              </ol>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

  </form>
  <div class="row mt-2">
    <!--  <div class="col-md-3"></div>-->
    <div class="col-md-6 text-end">
      <button color="warn" mat-raised-button (click)="cancel()">Cancel</button> &nbsp;

    </div>
    <div class="col-md-6 tex-start">
      <button color="primary" mat-raised-button [disabled]="childForm.invalid" (click)="Update()">Update</button>


    </div>
    <!--  <div class="col-md-3"></div>-->
  </div>
</div>


<!--<h5>Personal Details</h5>-->
<!--       <p>Name</p>-->
<!-- <p>Address</p>-->
<!-- <p>Gender</p>-->
<!-- <p>Child Code</p>-->
<!-- <p>centre Code</p>-->
<!-- <p>Diagnosis</p>-->
<!-- <p>Allergies</p>-->
<!-- <p>Hobbies</p>-->
<!-- <p>Aspirations</p>-->
<!-- <p>Registered by</p>-->
<!-- <p>all Referance no.</p>-->
<!-- <p>Admission Type</p>-->



