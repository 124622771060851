import {NavigationEnd, Router} from "@angular/router";
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
} from '@angular/core';
import {ROUTES} from './sidebar-items';
import {RouteInfo} from './sidebar.metadata';
import {DOCUMENT} from "@angular/common";
import {UserData} from "../../auth.service";
import {UserRole} from "../../components/rename/add-user-role/add-user-role.component";
import {AngularFirestore} from "@angular/fire/compat/firestore";


interface MenuPermissions {
  [permission: string]: boolean;
}


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  defaultPermissions = [
    'Child Admission',
    'Child Prescreening',
    'Counselling',

    'Education',
    'Meal Mate',
    'Unit Sponsorship',
    'General Donation',
    'CMS',
    'Education Report',
    'Donation Report',
    'Weekly Report',
    'Hospital',
    'Unit',
    'Types',
    'Donor',
    'Event',
    'Audit Logs',
    'Inventory',
    'Vehicles',
    // Add other function names as needed
    'Audit Logs',
    'Dashboard',
    'Child Admission',
    'Donation Report',
    'Child Report',
    'Unit Sponsorship',
    'Centres',
    'Employees',
    'Tasks',
    'Ops Reports',
    'Counseling Report'
  ];
  admissions: UserRole[] = [];

  dashboardPermission = this.admissions.filter((x) => x.functionName == 'Dashboard')
  counsellingPermission = this.admissions.filter((x) => x.functionName == 'Counselling')
  educationPermission = this.admissions.filter((x) => x.functionName == 'Education')
  childAdmissionPermission = this.admissions.filter((x) => x.functionName == 'Child Admission')
  donationReport = this.admissions.filter((x) => x.functionName == 'Donation Report')
  childReport = this.admissions.filter((x) => x.functionName == 'Child Report')
  weeklyReport = this.admissions.filter((x) => x.functionName == 'Weekly Report')
  mealMate = this.admissions.filter((x) => x.functionName == 'Meal Mate')
  unitSponsorship = this.admissions.filter((x) => x.functionName == 'Unit Sponsorship')
  CMS = this.admissions.filter((x) => x.functionName == 'CMS')
  centres = this.admissions.filter((x) => x.functionName == 'Centres')
  Employees = this.admissions.filter((x) => x.functionName == 'Employees')
  Hospital = this.admissions.filter((x) => x.functionName == 'Hospital')
  Unit = this.admissions.filter((x) => x.functionName == 'Unit')
  Types = this.admissions.filter((x) => x.functionName == 'Types')
  Donor = this.admissions.filter((x) => x.functionName == 'Donor')
  Events = this.admissions.filter((x) => x.functionName == 'Event')
  AuditLogs = this.admissions.filter((x) => x.functionName == 'Audit Logs')


  public sidebarItems!: RouteInfo[];
  public innerHeight?: number;
  public bodyTag!: HTMLElement;
  listMaxHeight?: string;
  listMaxWidth?: string;
  headerHeight = 60;
  routerObj;
  userData: UserData
  userName = ''
  role = ''
  show = false;
  permissionsMap: { [key: string]: UserRole[] } = {};
  department: string

  constructor(
    private  readonly mFirestore:AngularFirestore,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    // private authService: AuthService,
    private router: Router
  ) {
    this.userData = JSON.parse(localStorage.getItem('userData')!) as UserData
    this.department = this.userData.department

    this.userName = this.userData.userName
    this.role = this.userData.role
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
      }
    });
    const storedPermissions = localStorage.getItem('permissions');
    this.admissions = storedPermissions ? JSON.parse(storedPermissions) : this.getDefaultPermissions();
    this.initializePermissions();

    // Adding to firebase
    // ROUTES.forEach(it=>{
    //
    //   this.mFirestore.collection('sidebarItems').doc('Menu').set(ROUTES)
    //
    //
    // })
    this.mFirestore.collection('sidebarItems').doc('Menu').set({menu:ROUTES})


  }


  private initializePermissions(): void {
    const storedPermissions = localStorage.getItem('permissions');
    this.admissions = storedPermissions ? JSON.parse(storedPermissions) : this.getDefaultPermissions();


    this.admissions.forEach((permission: UserRole) => {
      const {functionName} = permission;
      // console.log('FUNCTION NAME IS',functionName)
      if (!this.permissionsMap[functionName]) {
        this.permissionsMap[functionName] = [];
      }
      this.permissionsMap[functionName].push(permission);
    });
  }


  // Generate default permissions if not stored
  private getDefaultPermissions(): UserRole[] {
    return this.defaultPermissions.map((functionName: string) => {
      return {
        functionName,
        all: false,
        add: false,
        edit: false,
        archive: false,
        view: false,
        excel: false,
        showMenu: false
      };
    });
  }


  @HostListener('window:resize', ['$event'])
  windowResizecall() {
    this.setMenuHeight();
    this.checkStatuForResize(true);
  }


  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }


  callToggleMenu(event: Event, length: number) {
    if (length > 0) {
      const parentElement = (event.target as HTMLInputElement).closest('li');
      const activeClass = parentElement?.classList.contains('active');


      if (activeClass) {
        this.renderer.removeClass(parentElement, 'active');
      } else {
        this.renderer.addClass(parentElement, 'active');
      }
    }
  }


  ngOnInit() {
    // if (this.authService.currentUserValue) {
    this.sidebarItems = ROUTES.filter((sidebarItem) => sidebarItem);
    this.checkRoles(this.sidebarItems)


    // }
    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }


  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }


  initLeftSidebar() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }


  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }


  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }


  checkStatuForResize(firstTime: boolean) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }


  mouseHover() {
    const body = this.elementRef.nativeElement.closest('body');


    if (body.classList.contains('submenu-closed')) {
      this.renderer.addClass(this.document.body, 'side-closed-hover');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
  }


  mouseOut() {
    const body = this.elementRef.nativeElement.closest('body');


    if (body.classList.contains('side-closed-hover')) {
      this.renderer.removeClass(this.document.body, 'side-closed-hover');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }


  private checkRoles_GEMINI_NEEDS_WORK(sidebarItems: RouteInfo[]) {


    console.log('into check roles')
    try {
      console.log('into try')
      // Create a map for efficient permission lookup
      const permissionMap = new Map<string, UserRole>();
      for (const [functionName, permission] of (permissionMap as Map<string, UserRole>).entries()) {
        console.log('into loop')
        permissionMap.set(functionName, permission);
        console.log(JSON.stringify(permissionMap))
      }


      // Iterate directly through sidebarItems and submenus for optimized handling
      for (const item of sidebarItems) {


        const permission = permissionMap.get(item.title);
        const showMenu = permission?.showMenu || false;


        if (item.title !== 'Masters') {
          item.showMenu = showMenu; // General case for most items
        } else {
          item.showMenu = true; // Always show Masters menu
        }


        if (item.submenu) {
          for (const subItem of item.submenu) {
            const subPermission = permissionMap.get(subItem.title);
            subItem.showMenu =
              (subPermission?.showMenu || false) &&
              (
                // Combine conditions for conciseness
                // (item.title === 'Masters' && (showAdmission || showContacts)) ||
                // (item.title === 'Reminders' && (showAdmission || showDonationReport)) ||
                showMenu
              );
          }
        }


        // Handle CMS-specific logic directly for clarity
        if (item.title === 'CMS') {
          item.showMenu =
            this.userData.email === 'genmgr@accesslife.org' ||
            this.userData.email === 'test1@gmail.com' ||
            showMenu;
        }
      }
    } catch (error) {
      console.error('Error in checkRoles:', error);
    }
  }


//ORIGINAL
  private checkRoles2(sidebarItems: RouteInfo[]) {
    // console.log('checkRoles Called')
    try {
      const showDashboard = this.permissionsMap['Dashboard'][0]?.showMenu || false;
      const showAdmission = this.permissionsMap['Child Admission'][0]?.showMenu || false;
      const showEducation = this.permissionsMap['Education'][0]?.showMenu || false;
      const showCounselling = this.permissionsMap['Counselling'][0]?.showMenu || false;
      const showCounsellingReport = this.permissionsMap['Counseling Report'][0]?.showMenu || false;
      const showEducationReport = this.permissionsMap['Education Report'][0]?.showMenu || false;
      const showDonationReport = this.permissionsMap['Donation Report'][0]?.showMenu || false;
      const showChildReport = this.permissionsMap['Child Report'][0]?.showMenu || false;
      const showMealMate = this.permissionsMap['Meal Mate'][0]?.showMenu || false;
      const shoeUnitSponsorship = this.permissionsMap['Unit Sponsorship'][0]?.showMenu || false;
      const showReports = this.permissionsMap['Child Report'][0]?.showMenu || this.permissionsMap['Counseling Report'][0]?.showMenu || this.permissionsMap['Education Report'][0]?.showMenu || this.permissionsMap['Donation Report'][0]?.showMenu || false;


      const showCentres = this.permissionsMap['Centres'][0]?.showMenu || false;
      const showUsers = this.permissionsMap['Employees'][0]?.showMenu || false;
      const showHospitals = this.permissionsMap['Hospital'][0]?.showMenu || false;
      const showDonor = this.permissionsMap['Donor'][0]?.showMenu || false;
      const showEvent = this.permissionsMap['Event'][0]?.showMenu || false;
      const showAuditLogs = this.userData.email == 'genmgr@accesslife.org' || this.userData.email == 'test1@gmail.com'
      const showContacts = true
      const showTypes = true
      const showGlossary = true


      let dashboard = this.sidebarItems.find(x => x.title == "Dashboard")
      // console.log(JSON.stringify(dashboard))
      dashboard!.showMenu = showDashboard
      // * ADMISSIONS
      let admissionMenu = this.sidebarItems.find(x => x.title == "Admissions")
      admissionMenu!.showMenu = showAdmission
      let childListMenu = admissionMenu?.submenu.find(x => x.title == "Child List")
      let prescreeningMenu = admissionMenu?.submenu.find(x => x.title == "Prescreening List")
      let unitsMenu = admissionMenu?.submenu.find(x => x.title == "Units")
      if (childListMenu) childListMenu.showMenu = showAdmission
      if (prescreeningMenu) prescreeningMenu.showMenu = showAdmission
      // if (unitsMenu) unitsMenu.showMenu = showAdmission ||s
// * Education
      let educationMenu = this.sidebarItems.find(x => x.title == "Education")
      if (educationMenu) educationMenu.showMenu = showEducation
      let educationIndividualMenu = educationMenu!.submenu.find(x => x.title == "Individual")
      let educationGroupSessionsMenu = educationMenu!.submenu.find(x => x.title == "Group Sessions")
      let educationReportMenu = educationMenu!.submenu.find(x => x.title == "Reports")
      if (educationIndividualMenu) educationIndividualMenu!.showMenu = showEducation
      if (educationGroupSessionsMenu) educationGroupSessionsMenu!.showMenu = showEducation
      if (educationReportMenu) educationReportMenu!.showMenu = showEducation
// * Counselling
      let counsellingMenu = this.sidebarItems.find(x => x.title == "Counselling")
      if (counsellingMenu) counsellingMenu.showMenu = showCounselling
      let counsellingIndividualMenu = counsellingMenu!.submenu.find(x => x.title == "Individual Sessions")
      if (counsellingIndividualMenu) counsellingIndividualMenu.showMenu = showCounselling
      let counsellingGroupMenu = counsellingMenu!.submenu.find(x => x.title == "Group Sessions")
      if (counsellingGroupMenu) counsellingGroupMenu.showMenu = showCounselling


      let counsellingAssessmentMenu = counsellingMenu!.submenu.find(x => x.title == "Assessments")
      if (counsellingAssessmentMenu) counsellingAssessmentMenu.showMenu = showCounselling


      let counsellingReportMenu = counsellingMenu!.submenu.find(x => x.title == "Reports")
      if (counsellingReportMenu) counsellingReportMenu.showMenu = showCounselling
// * Reports
      let reportsMenu = this.sidebarItems.find(x => x.title == "Reports")
      if (reportsMenu) reportsMenu.showMenu = showReports
      let razorPayPayments = reportsMenu!.submenu.find(x => x.title == 'Razorpay Payments')
      let quaterlyReport = reportsMenu!.submenu.find(x => x.title == 'Quarterly Report')
      if (quaterlyReport) quaterlyReport!.showMenu = showReports
      if (razorPayPayments) razorPayPayments.showMenu = (this.userData.email == 'genmgr@accesslife.org' || this.userData.email == 'test1@gmail.com')


      const donationReport = reportsMenu!.submenu.find(x => x.title == 'Donation Report')


      if (donationReport) donationReport.showMenu = showDonationReport


      const childReport = reportsMenu!.submenu.find(x => x.title == 'Child Report')
      childReport!.showMenu = showChildReport
      const admissionReport = reportsMenu!.submenu.find(x => x.title == 'Admission Report')
      admissionReport!.showMenu = showChildReport


      const weeklyReport = reportsMenu!.submenu.find(x => x.title == 'Weekly Report')
      weeklyReport!.showMenu = showChildReport
      if (childReport) childReport.showMenu = showChildReport


      if (weeklyReport) weeklyReport.showMenu = showChildReport
      weeklyReport!.showMenu = showChildReport
      const educationImpactReportMenu = reportsMenu!.submenu.find(x => x.title == 'Education Impact Report')
      educationImpactReportMenu!.showMenu = showEducationReport
      const reportCardMenu = reportsMenu!.submenu.find(x => x.title == 'Report Card')
      reportCardMenu!.showMenu = showEducationReport
      const attendanceReport = reportsMenu!.submenu.find(x => x.title == 'Education Analytical Report')
      attendanceReport!.showMenu = showEducationReport
      const counselingImpactReport = reportsMenu!.submenu.find(x => x.title == 'Counseling Impact Report')
      counselingImpactReport!.showMenu = showCounsellingReport
      const challengesReport = reportsMenu!.submenu.find(x => x.title == 'Challenges Report')
      challengesReport!.showMenu = showCounsellingReport
      const progressReport = reportsMenu!.submenu.find(x => x.title == 'Progress Report')
      progressReport!.showMenu = showEducationReport
      const interventionReport = reportsMenu!.submenu.find(x => x.title == 'Intervention  Report')
      interventionReport!.showMenu = showCounsellingReport
      educationImpactReportMenu!.showMenu = showEducationReport
// * Donation
      const donationMenu = this.sidebarItems.find(x => x.title == "Donation")
      donationMenu!.showMenu = showDonationReport
      const mealMateMenu = donationMenu!.submenu.find(x => x.title == 'Mealmate')
      mealMateMenu!.showMenu = showMealMate


      const unitSponsorship = donationMenu!.submenu.find(x => x.title == 'Unit Sponsorship')
      unitSponsorship!.showMenu = shoeUnitSponsorship
      //* Reminders
      let remindersMenu = this.sidebarItems.find(x => x.title == 'Reminders')
      let paymentsMenu = remindersMenu!.submenu.find(x => x.title == 'Payments')
      paymentsMenu!.showMenu = showDonationReport


      let FollowupsMenu = remindersMenu!.submenu.find(x => x.title == 'Followups')
      FollowupsMenu!.showMenu = showAdmission


      let Birthdays = remindersMenu!.submenu.find(x => x.title == 'Birthdays')
      Birthdays!.showMenu = showChildReport || showAdmission
      // * CMS
      let CMS = this.sidebarItems.find(x => x.title == 'CMS')
      CMS!.showMenu = (this.userData.email == 'genmgr@accesslife.org' || this.userData.email == 'test1@gmail.com') || this.permissionsMap['CMS'][0]?.showMenu;


// * Masters Menu
      const mastersMenu = this.sidebarItems.find(x => x.title == 'Masters')
      const centresMenu = mastersMenu!.submenu.find(x => x.title == 'Centres')
      const typesMenu = mastersMenu!.submenu.find(x => x.title == 'Types')
      const glossaryMenu = mastersMenu!.submenu.find(x => x.title == 'Glossary')
      const contactsMenu = mastersMenu!.submenu.find(x => x.title == 'Contacts')
      const eventsMenu = mastersMenu!.submenu.find(x => x.title == 'Events')
      const donorMenu = mastersMenu!.submenu.find(x => x.title == 'Donor')
      const auditMenu = mastersMenu!.submenu.find(x => x.title == 'Audit Logs')
      const userMenu = mastersMenu!.submenu.find(x => x.title == 'Users')
      auditMenu!.showMenu = this.userData.email == 'genmgr@accesslife.org' || this.userData.email == 'test1@gmail.com'
      typesMenu!.showMenu = showTypes || showContacts || showAdmission || showEducation || showCounselling
      contactsMenu!.showMenu = showContacts
      eventsMenu!.showMenu = showEvent
      donorMenu!.showMenu = showDonor
      glossaryMenu!.showMenu = showGlossary
      centresMenu!.showMenu = showCentres
      userMenu!.showMenu = showUsers
      // const unitsMenu = mastersMenu!.submenu.find(x => x.title == 'Units')
      unitsMenu!.showMenu = showAdmission || showEducation
      mastersMenu!.showMenu = true


    } catch (e: any) {
      console.log(e)


    }
  }


  private checkRoles1(sidebarItems: RouteInfo[]) {
    // console.log('checkRoles Called')
    try {
      const showDashboard = this.permissionsMap['Dashboard'][0]?.showMenu || false;
      const showAdmission = this.permissionsMap['Child Admission'][0]?.showMenu || false;
      const showEducation = this.permissionsMap['Education'][0]?.showMenu || false;
      const showCounselling = this.permissionsMap['Counselling'][0]?.showMenu || false;
      const showCounsellingReport = this.permissionsMap['Counseling Report'][0]?.showMenu || false;
      const showEducationReport = this.permissionsMap['Education Report'][0]?.showMenu || false;
      const showDonationReport = this.permissionsMap['Donation Report'][0]?.showMenu || false;
      const showChildReport = this.permissionsMap['Child Report'][0]?.showMenu || false;
      const showMealMate = this.permissionsMap['Meal Mate'][0]?.showMenu || false;
      const shoeUnitSponsorship = this.permissionsMap['Unit Sponsorship'][0]?.showMenu || false;
      const showReports = this.permissionsMap['Child Report'][0]?.showMenu || this.permissionsMap['Counseling Report'][0]?.showMenu || this.permissionsMap['Education Report'][0]?.showMenu || this.permissionsMap['Donation Report'][0]?.showMenu || false;

      const showCentres = this.permissionsMap['Centres'][0]?.showMenu || false;
      const showUsers = this.permissionsMap['Employees'][0]?.showMenu || false;
      const showHospitals = this.permissionsMap['Hospital'][0]?.showMenu || false;
      const showDonor = this.permissionsMap['Donor'][0]?.showMenu || false;
      const showEvent = this.permissionsMap['Event'][0]?.showMenu || false;
      const showAuditLogs = this.userData.email == 'genmgr@accesslife.org' || this.userData.email == 'test1@gmail.com'
      const showContacts = true
      const showTypes = true
      const showGlossary = true

      let dashboard = this.sidebarItems.find(x => x.title == "Dashboard")
      // console.log(JSON.stringify(dashboard))
      dashboard!.showMenu = showDashboard
      // * ADMISSIONS
      let admissionMenu = this.sidebarItems.find(x => x.title == "Admissions")
      admissionMenu!.showMenu = showAdmission
      let childListMenu = admissionMenu?.submenu.find(x => x.title == "Child List")
      let prescreeningMenu = admissionMenu?.submenu.find(x => x.title == "Prescreening List")
      let unitsMenu = admissionMenu?.submenu.find(x => x.title == "Units")
      if (childListMenu) childListMenu.showMenu = showAdmission
      if (prescreeningMenu) prescreeningMenu.showMenu = showAdmission
      // if (unitsMenu) unitsMenu.showMenu = showAdmission ||s
// * Education
      let educationMenu = this.sidebarItems.find(x => x.title == "Education")
      if (educationMenu) educationMenu.showMenu = showEducation
      let educationIndividualMenu = educationMenu!.submenu.find(x => x.title == "Individual")
      let educationGroupSessionsMenu = educationMenu!.submenu.find(x => x.title == "Group Sessions")
      let educationReportMenu = educationMenu!.submenu.find(x => x.title == "Reports")
      if (educationIndividualMenu) educationIndividualMenu!.showMenu = showEducation
      if (educationGroupSessionsMenu) educationGroupSessionsMenu!.showMenu = showEducation
      if (educationReportMenu) educationReportMenu!.showMenu = showEducation
// * Counselling
      let counsellingMenu = this.sidebarItems.find(x => x.title == "Counselling")
      if (counsellingMenu) counsellingMenu.showMenu = showCounselling
      let counsellingIndividualMenu = counsellingMenu!.submenu.find(x => x.title == "Individual Sessions")
      if (counsellingIndividualMenu) counsellingIndividualMenu.showMenu = showCounselling
      let counsellingGroupMenu = counsellingMenu!.submenu.find(x => x.title == "Group Sessions")
      if (counsellingGroupMenu) counsellingGroupMenu.showMenu = showCounselling


      let counsellingAssessmentMenu = counsellingMenu!.submenu.find(x => x.title == "Assessments")
      if (counsellingAssessmentMenu) counsellingAssessmentMenu.showMenu = showCounselling


      let counsellingReportMenu = counsellingMenu!.submenu.find(x => x.title == "Reports")
      if (counsellingReportMenu) counsellingReportMenu.showMenu = showCounselling
// * Reports
      let reportsMenu = this.sidebarItems.find(x => x.title == "Reports")
      if (reportsMenu) reportsMenu.showMenu = showReports
      let razorPayPayments = reportsMenu!.submenu.find(x => x.title == 'Razorpay Payments')
      let quaterlyReport = reportsMenu!.submenu.find(x => x.title == 'Quarterly Report')
      if (quaterlyReport) quaterlyReport!.showMenu = showReports
      if (razorPayPayments) razorPayPayments.showMenu = (this.userData.email == 'genmgr@accesslife.org' || this.userData.email == 'test1@gmail.com')


      const donationReport = reportsMenu!.submenu.find(x => x.title == 'Donation Report')


      if (donationReport) donationReport.showMenu = showDonationReport


      const childReport = reportsMenu!.submenu.find(x => x.title == 'Child Report')
      childReport!.showMenu = showChildReport
      const admissionReport = reportsMenu!.submenu.find(x => x.title == 'Admission Report')
      admissionReport!.showMenu = showChildReport


      const weeklyReport = reportsMenu!.submenu.find(x => x.title == 'Weekly Report')
      weeklyReport!.showMenu = showChildReport
      if (childReport) childReport.showMenu = showChildReport


      if (weeklyReport) weeklyReport.showMenu = showChildReport
      weeklyReport!.showMenu = showChildReport
      const educationImpactReportMenu = reportsMenu!.submenu.find(x => x.title == 'Education Impact Report')
      educationImpactReportMenu!.showMenu = showEducationReport
      const reportCardMenu = reportsMenu!.submenu.find(x => x.title == 'Report Card')
      reportCardMenu!.showMenu = showEducationReport
      const attendanceReport = reportsMenu!.submenu.find(x => x.title == 'Education Analytical Report')
      attendanceReport!.showMenu = showEducationReport
      const counselingImpactReport = reportsMenu!.submenu.find(x => x.title == 'Counseling Impact Report')
      counselingImpactReport!.showMenu = showCounsellingReport
      const challengesReport = reportsMenu!.submenu.find(x => x.title == 'Challenges Report')
      challengesReport!.showMenu = showCounsellingReport
      const progressReport = reportsMenu!.submenu.find(x => x.title == 'Progress Report')
      progressReport!.showMenu = showEducationReport
      const interventionReport = reportsMenu!.submenu.find(x => x.title == 'Intervention  Report')
      interventionReport!.showMenu = showCounsellingReport
      educationImpactReportMenu!.showMenu = showEducationReport
// * Donation
      const donationMenu = this.sidebarItems.find(x => x.title == "Donation")
      donationMenu!.showMenu = showDonationReport
      const mealMateMenu = donationMenu!.submenu.find(x => x.title == 'Mealmate')
      mealMateMenu!.showMenu = showMealMate


      const unitSponsorship = donationMenu!.submenu.find(x => x.title == 'Unit Sponsorship')
      unitSponsorship!.showMenu = shoeUnitSponsorship
      //* Reminders
      let remindersMenu = this.sidebarItems.find(x => x.title == 'Reminders')
      let paymentsMenu = remindersMenu!.submenu.find(x => x.title == 'Payments')
      paymentsMenu!.showMenu = showDonationReport


      let FollowupsMenu = remindersMenu!.submenu.find(x => x.title == 'Followups')
      FollowupsMenu!.showMenu = showAdmission


      let Birthdays = remindersMenu!.submenu.find(x => x.title == 'Birthdays')
      Birthdays!.showMenu = showChildReport || showAdmission
      // * CMS
      let CMS = this.sidebarItems.find(x => x.title == 'CMS')
      CMS!.showMenu = (this.userData.email == 'genmgr@accesslife.org' || this.userData.email == 'test1@gmail.com') || this.permissionsMap['CMS'][0]?.showMenu;


// * Masters Menu
      const mastersMenu = this.sidebarItems.find(x => x.title == 'Masters')
      const centresMenu = mastersMenu!.submenu.find(x => x.title == 'Centres')
      const typesMenu = mastersMenu!.submenu.find(x => x.title == 'Types')
      const glossaryMenu = mastersMenu!.submenu.find(x => x.title == 'Glossary')
      const contactsMenu = mastersMenu!.submenu.find(x => x.title == 'Contacts')
      const eventsMenu = mastersMenu!.submenu.find(x => x.title == 'Events')
      const donorMenu = mastersMenu!.submenu.find(x => x.title == 'Donor')
      const auditMenu = mastersMenu!.submenu.find(x => x.title == 'Audit Logs')
      alert(auditMenu!.title)
      const userMenu = mastersMenu!.submenu.find(x => x.title == 'Users')
      auditMenu!.showMenu = this.userData.email == 'genmgr@accesslife.org' || this.userData.email == 'test1@gmail.com'
      typesMenu!.showMenu = showTypes || showContacts || showAdmission || showEducation || showCounselling
      contactsMenu!.showMenu = showContacts
      eventsMenu!.showMenu = showEvent
      donorMenu!.showMenu = showDonor
      glossaryMenu!.showMenu = showGlossary
      centresMenu!.showMenu = showCentres
      userMenu!.showMenu = showUsers
      // const unitsMenu = mastersMenu!.submenu.find(x => x.title == 'Units')
      unitsMenu!.showMenu = showAdmission || showEducation
      mastersMenu!.showMenu = true


    } catch (e: any) {
      console.log(e)


    }
  }


  //CONSISE
  private checkRoles(sidebarItems: RouteInfo[]) {
    console.log(sidebarItems)
    try {
      // Consolidated conditional logic for efficiency
      const showCMS = (this.userData.email === 'genmgr@accesslife.org' || this.userData.email === 'test1@gmail.com') || this.permissionsMap['CMS'][0]?.showMenu;
      const showAuditLogs = this.userData.email === 'genmgr@accesslife.org' || this.userData.email === 'test1@gmail.com';
      const showChildAdmissions = this.permissionsMap['Child Admission'][0]?.showMenu || false;
      const showEducation = this.permissionsMap['Education'][0]?.showMenu || false;
      const showCounselling = this.permissionsMap['Counselling'][0]?.showMenu  || false;
      const showUnit = this.permissionsMap['Unit'][0]?.showMenu || false;
      const showVehicles = this.permissionsMap['Vehicles'][0]?.showMenu || false;
      const showContacts = true;
      const showTypes = true;
      const showGlossary = false;
      const showTasks = this.department != 'External User'
      // Loop through sidebar items and set showMenu based on permissions
      for (const item of sidebarItems) {
        let showItem = false; // Default showMenu value


        switch (item.title) {
          case 'Dashboard': {
            showItem = this.permissionsMap['Dashboard'][0]?.showMenu || false;
            console.log('DASHBOARD', showItem)
            item.showMenu = showItem

          }
            break;
          case 'Admissions': {
            showItem = this.permissionsMap['Child Admission'][0]?.showMenu || false;
            item.showMenu = showItem
            // Set submenu visibility within Admissions
            if (item.submenu) {

              const childListMenu = item.submenu.find(x => x.title === 'Child List');
              childListMenu!!.showMenu = showChildAdmissions || showEducation || showCounselling
              const prescreeningMenu = item.submenu.find(x => x.title === 'Prescreening List');
              prescreeningMenu!!.showMenu = showChildAdmissions
              const unitsMenu = item.submenu.find(x => x.title === 'Units');
              unitsMenu!!.showMenu = showUnit
              // childListMenu?.(childListMenu => (childListMenu.showMenu = showItem));
              // prescreeningMenu?.(prescreeningMenu => (prescreeningMenu.showMenu = showItem));
              // unitsMenu.(unitsMenu => (unitsMenu.showMenu = showItem || this.permissionsMap['Education'][0]?.showMenu || false)); // Assuming units are related to both Admission and Education
              // item.submenu?.showMenu = showItem
            }
            break;
          }
          case 'Education': {
            showItem = this.permissionsMap['Education'][0]?.showMenu || false;
            // Set submenu visibility within Education
            item.showMenu = showItem

            if (item.submenu) {
              const educationIndividualMenu = item.submenu.find(x => x.title === 'Individual');
              const educationGroupSessionsMenu = item.submenu.find(x => x.title === 'Group Sessions');
              const educationReportMenu = item.submenu.find(x => x.title === 'Reports');
              educationIndividualMenu!!.showMenu = showItem
              educationGroupSessionsMenu!!.showMenu = showItem
              educationReportMenu!!.showMenu = false
              // educationIndividualMenu?.(educationIndividualMenu => (educationIndividualMenu.showMenu = showItem));
              // educationGroupSessionsMenu?.(educationGroupSessionsMenu => (educationGroupSessionsMenu.showMenu = showItem));
              // educationReportMenu?.(educationReportMenu => (educationReportMenu.showMenu = this.permissionsMap['Education Report'][0]?.showMenu || false)); // Reports permission assumed for Education Reports
            }
            item.showMenu = showItem

            break;
          }
          case 'Counselling': {// Replace with logic based on your permission structure
            showItem = this.permissionsMap['Counselling'][0]?.showMenu || false;
            item.showMenu = showItem

            // Set submenu visibility within Counselling (add logic based on your permissions)
            if (item.submenu) {
              const counsellingIndividualMenu = item.submenu.find(x => x.title === 'Individual Sessions');
              const counsellingGroupSessionsMenu = item.submenu.find(x => x.title === 'Group Sessions');
              const assessmentsSessionsMenu = item.submenu.find(x => x.title === 'Assessments');
              const followUpMenu = item.submenu.find(x => x.title === 'Follow Up');
              assessmentsSessionsMenu!.showMenu = showItem
              // const counsellingFollowUpAssessmentMenu = item.submenu.find(x => x.title === 'Follow Up Assessment');
              const counsellingReportsMenu = item.submenu.find(x => x.title === 'Reports');
              // const counsellingFollowUpQuesiontsMenu = item.submenu.find(x => x.title === 'Follow Up Questions');
              counsellingGroupSessionsMenu!!.showMenu = showCounselling
              counsellingIndividualMenu!!.showMenu = showCounselling
              followUpMenu!!.showMenu = showCounselling
              // counsellingFollowUpAssessmentMenu!!.showMenu = showCounselling
              counsellingReportsMenu!!.showMenu = false
              // counsellingFollowUpQuesiontsMenu!!.showMenu = showCounselling
              // ... (add logic for submenu visibility based on counselling permissions)


            }
            break;
          }
          case 'Transport': {

            showItem = this.permissionsMap['Vehicles'][0]?.showMenu || false
            item.showMenu = showItem
            break;
          }
          case 'Donation Reports': {

            showItem = this.permissionsMap['Donation Report'][0]?.showMenu || false
            // item.showMenu = showItem
            // alert(`Item.showmenu ${item.showMenu}`)
            const donationReportMenu = item.submenu.find(x => x.title === 'Donation Report');
            const attemptedDonations = item.submenu.find(x => x.title === 'Attempted Donations');
            const failedDonations = item.submenu.find(x => x.title === 'Failed Donations');
            failedDonations!!.showMenu = showItem
            donationReportMenu!!.showMenu = showItem
            attemptedDonations!!.showMenu = showItem
            const razorPayReports = item.submenu.find(x => x.title === 'Razorpay Payments')
            razorPayReports!!.showMenu = showItem
            // console.log('Inside Donation Reports', showItem)
            item.showMenu = showItem
            break;
          }

          case 'Ops Reports': {

            showItem = this.permissionsMap['Child Report'][0]?.showMenu || false
            // item.showMenu = showItem
            // alert(`Item.showmenu ${item.showMenu}`)
            const childReport = item.submenu.find(x => x.title === 'Child Report');
            const AdmissionReport = item.submenu.find(x => x.title === 'Admission Report');
            const WeeklyReport = item.submenu.find(x => x.title === 'Weekly Report');
            childReport!!.showMenu = showItem
            AdmissionReport!!.showMenu = showItem
            WeeklyReport!!.showMenu = showItem
            // console.log('Inside Donation Reports', showItem)
            item.showMenu = showItem
            break;
          }

          case 'Inventory': {
            try {

              showItem = this.permissionsMap['Inventory'][0]?.showMenu || false;
              item.showMenu = showItem
              const productsMenu = item.submenu.find(x => x.title == 'Products')
              productsMenu!!.showMenu != showItem
              const instockEntry = item.submenu.find(x => x.title === 'Instock Entry')
              instockEntry!!.showMenu = showItem

              const centreInventory = item.submenu.find(x => x.title === 'Centre Inventory')
              centreInventory!!.showMenu = showItem

              const stockInventory = item.submenu.find(x => x.title === 'Stock Adjustment')
              stockInventory!!.showMenu = showItem
              const stockTransfer = item.submenu.find(x => x.title === 'Stock Transfer')
              stockTransfer!!.showMenu = showItem
              const directIssue = item.submenu.find(x => x.title === 'Direct Issue')
              directIssue!!.showMenu = showItem
              const requestProducts = item.submenu.find(x => x.title === 'Request Products')
              requestProducts!!.showMenu = showItem
              // const issueProducts = item.submenu.find(x => x.title === 'Issue Products')
              // issueProducts!!.showMenu = showItem

              const issuedProducts = item.submenu.find(x => x.title === 'Issued Products')
              issuedProducts!!.showMenu = showItem
              const purchaseList = item.submenu.find(x => x.title === 'Purchase List')
              purchaseList!!.showMenu = showItem

            } catch (e) {
              console.log('error  in inventory roles', e)
            }

            break;
          }
          case 'Education Reports': // Replace with logic based on your permission structure
            showItem = this.permissionsMap['Education Report'][0]?.showMenu || false
            item.showMenu = showItem
            // Set submenu visibility within Reports (add logic based on your permissions)
            if (item.submenu) {
              // ... (add logic for submenu visibility based on reports permissions)
            }
            break;
          case 'Counseling Reports': // Replace with logic based on your permission structure
            showItem = this.permissionsMap['Counseling Report'][0]?.showMenu || false
            item.showMenu = showItem
            // Set submenu visibility within Reports (add logic based on your permissions)
            if (item.submenu) {
              // ... (add logic for submenu visibility based on reports permissions)
            }
            break;

          case 'Donation': // Replace with logic based on your permission structure
            try {
              showItem = (this.permissionsMap['General Donation'][0]?.showMenu || this.permissionsMap['Unit Sponsorship'][0]?.showMenu || this.permissionsMap['Meal Mate'][0]?.showMenu) || false;
              // Set submenu visibility within Donation (add logic based on your permissions)
              item.showMenu = showItem
              if (item.submenu) {
                // ... (add logic for submenu visibility based on donation permissions)
                const mealMateMenu = item.submenu.find(x => x.title === 'Mealmate');
                const bookingsMenu = item.submenu.find(x => x.title === 'Bookings');
                bookingsMenu!!.showMenu = this.permissionsMap['Meal Mate'][0]?.showMenu
                const unitSponsorshipMenu = item.submenu.find(x => x.title === 'Unit Sponsorship');
                unitSponsorshipMenu!!.showMenu = this.permissionsMap['Unit Sponsorship'][0]?.showMenu
                mealMateMenu!!.showMenu = this.permissionsMap['Meal Mate'][0]?.showMenu
              }

            } catch (e) {
              console.log('error in donation ', e)
            }
            break;
          case 'Reminders': {

            showItem = ((this.permissionsMap['Child Admission'][0]?.showMenu
              || this.permissionsMap['Meal Mate'][0]?.showMenu
              || this.permissionsMap['Child Admission'][0]?.showMenu
            ) || false ) && this.department != 'External User'
            if (item.submenu) {
              // ... (add logic for submenu visibility based on donation permissions)
              const paymentsMenu = item.submenu.find(x => x.title === 'Payments');
              const followUpMenu = item.submenu.find(x => x.title === 'Followups');
              paymentsMenu!!.showMenu = this.permissionsMap['General Donation'][0]?.showMenu
              followUpMenu!!.showMenu = this.permissionsMap['Child Admission'][0]?.showMenu && this.department!='External User'

            }
            break;
          }

        }
        // item.showMenu = showItem
      }

      // * Masters Menu
      const mastersMenu = sidebarItems.find(x => x.title == 'Masters')
      const CMSMENU = sidebarItems.find(x => x.title == 'CMS')
      const centresMenu = mastersMenu!.submenu.find(x => x.title == 'Centres')
      const typesMenu = mastersMenu!.submenu.find(x => x.title == 'Types')
      const glossaryMenu = mastersMenu!.submenu.find(x => x.title == 'Glossary')
      const contactsMenu = mastersMenu!.submenu.find(x => x.title == 'Contacts')
      const eventsMenu = mastersMenu!.submenu.find(x => x.title == 'Events')
      const donorMenu = mastersMenu!.submenu.find(x => x.title == 'Donor')
      const auditMenu = mastersMenu!.submenu.find(x => x.title == 'Audit Logs')
      const userMenu = mastersMenu!.submenu.find(x => x.title == 'Users')
      const remindersMenu = sidebarItems.find(x => x.title == 'Reminders')
      const followupMenu = remindersMenu!.submenu.find(x => x.title == 'Followups')
      auditMenu!.showMenu = true
      console.log(auditMenu)
      const showDashboard = this.permissionsMap['Dashboard'][0]?.showMenu || false;
      const showAdmission = this.permissionsMap['Child Admission'][0]?.showMenu || false;

      const showEducationReport = this.permissionsMap['Education Report'][0]?.showMenu || false;
      const showDonationReport = this.permissionsMap['Donation Report'][0]?.showMenu || false;
      const showChildReport = this.permissionsMap['Child Report'][0]?.showMenu || false;
      const showMealMate = this.permissionsMap['Meal Mate'][0]?.showMenu || false;
      const shoeUnitSponsorship = this.permissionsMap['Unit Sponsorship'][0]?.showMenu || false;
      const showReports = this.permissionsMap['Child Report'][0]?.showMenu || this.permissionsMap['Counseling Report'][0]?.showMenu || this.permissionsMap['Education Report'][0]?.showMenu || this.permissionsMap['Donation Report'][0]?.showMenu || false;
      const tasksMenu = sidebarItems.find(x => x.title == 'Tasks')


      const showCentres = this.permissionsMap['Centres'][0]?.showMenu || false;
      const showUsers = this.permissionsMap['Employees'][0]?.showMenu || false;
      const showHospitals = this.permissionsMap['Hospital'][0]?.showMenu || false;
      const showDonor = this.permissionsMap['Donor'][0]?.showMenu || false;
      const showEvent = this.permissionsMap['Event'][0]?.showMenu || false;
      const showCounsellingReport = this.permissionsMap['Counseling Report'][0]?.showMenu || false;
      // const showContacts = true
      // const showTypes = true
      // const showGlossary = true
      CMSMENU!.showMenu = this.userData.email == 'genmgr@accesslife.org' || this.userData.email == 'test1@gmail.com'
      auditMenu!.showMenu = this.userData.email == 'genmgr@accesslife.org' || this.userData.email == 'test1@gmail.com'
      auditMenu!.showMenu = showAuditLogs

      typesMenu!.showMenu = showTypes || showContacts || showAdmission || showEducation || showCounselling
      contactsMenu!.showMenu = showContacts
      eventsMenu!.showMenu = showEvent
      donorMenu!.showMenu = showDonor
      glossaryMenu!.showMenu = showGlossary
      centresMenu!.showMenu = showCentres
      userMenu!.showMenu = showUsers
      mastersMenu!.showMenu = this.department != 'External User'
      tasksMenu!.showMenu = this.department != 'External User'
      remindersMenu!.showMenu = this.department != 'External User'
      followupMenu!.showMenu = this.department != 'External User'
      // counselingReportsMenu!.showMenu = showCounsellingReport
    } catch (e) {
      console.error(e);
    }
    //FOR MASTERS MENU

  }





  private setSubMenuPermissions(submenu: RouteInfo[] | undefined, permissionFlag: boolean) {
    if (submenu) {
      submenu.forEach(subItem => {
        subItem.showMenu = permissionFlag;
      });
    }
  }


}

