<div class="container mt-4">
  <h1 class="bg-warning d-flex justify-content-center"> Driver Add Form</h1>
<!--  <prettyjson [obj]="driverForm.value"></prettyjson>-->
<div class="dialog-content-scroll">
  <form [formGroup]="driverForm">
    <div class="row">
      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>First Name</mat-label>
        <input matInput placeholder="First Name" formControlName="firstName" required>
        <mat-error *ngIf="driverForm.get('firstName')?.hasError('required') && driverForm.get('firstName')?.touched">
          First name is required.
        </mat-error>
        <mat-error *ngIf="driverForm.get('firstName')?.hasError('pattern') && driverForm.get('firstName')?.touched">
          Only alphabets are allowed.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Middle Name</mat-label>
        <input matInput placeholder="Middle Name" formControlName="middleName">
        <mat-error *ngIf="driverForm.get('middleName')?.hasError('pattern') && driverForm.get('middleName')?.touched">
          Only alphabets are allowed.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Last Name</mat-label>
        <input matInput placeholder="Last Name" formControlName="lastName" required>
        <mat-error *ngIf="driverForm.get('lastName')?.hasError('required') && driverForm.get('lastName')?.touched">
          Last name is required.
        </mat-error>
        <mat-error *ngIf="driverForm.get('lastName')?.hasError('pattern') && driverForm.get('lastName')?.touched">
          Only alphabets are allowed.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Current Address</mat-label>
        <input matInput placeholder="Current Address" formControlName="currentAddress" required>
        <mat-error *ngIf="driverForm.get('currentAddress')?.hasError('required') && driverForm.get('currentAddress')?.touched">
          Current address is required.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Previous Address</mat-label>
        <input matInput placeholder="Previous Address" formControlName="previousAddress" required>
        <mat-error *ngIf="driverForm.get('previousAddress')?.hasError('required') && driverForm.get('previousAddress')?.touched">
          Previous address is required.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Phone</mat-label>
        <input matInput type="number" placeholder="Phone Number" formControlName="phone" required>
        <!-- Required error -->
        <mat-error *ngIf="driverForm.get('phone')?.hasError('required') && driverForm.get('phone')?.touched">
          Phone number is required.
        </mat-error>
        <!-- Pattern error -->
        <mat-error *ngIf="driverForm.get('phone')?.hasError('pattern') && driverForm.get('phone')?.touched">
          Please enter a valid phone number (10 digits).
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Date of Birth</mat-label>
        <input matInput [matDatepicker]="picker" placeholder="Choose a date" formControlName="dob" required>
        <mat-error *ngIf="driverForm.get('dob')?.hasError('required') && driverForm.get('dob')?.touched">
          Date is required.
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Date of Joining</mat-label>
        <input matInput [matDatepicker]="picker2" placeholder="Choose a date" formControlName="dateOfJoining" required>
        <mat-error *ngIf="driverForm.get('dateOfJoining')?.hasError('required') && driverForm.get('dateOfJoining')?.touched">
          Date is required.
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
      </mat-form-field>



      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Social Security</mat-label>
        <input matInput placeholder="Social Security" formControlName="socialSecurity" required>
        <mat-error *ngIf="driverForm.get('socialSecurity')?.hasError('required') && driverForm.get('socialSecurity')?.touched">
          Social security is required.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Emergency Contact Name</mat-label>
        <input matInput placeholder="Emergency Contact Name" formControlName="emergencyContactName" required>
        <mat-error *ngIf="driverForm.get('emergencyContactName')?.hasError('required') && driverForm.get('emergencyContactName')?.touched">
          Emergency contact name is required.
        </mat-error>
        <mat-error *ngIf="driverForm.get('emergencyContactName')?.hasError('pattern') && driverForm.get('emergencyContactName')?.touched">
          Please enter a alphabets only.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Relation to Contact</mat-label>
        <input type="number" matInput placeholder="Relation to Contact" formControlName="relationToContact" required>
        <mat-error *ngIf="driverForm.get('relationToContact')?.hasError('required') && driverForm.get('relationToContact')?.touched">
          Relation to contact number is required.
        </mat-error>
        <mat-error *ngIf="driverForm.get('relationToContact')?.hasError('pattern') && driverForm.get('relationToContact')?.touched">
          Please enter a valid phone number (10 digits).
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Contact Address</mat-label>
        <input matInput placeholder="Contact Address" formControlName="contactAddress" required>
        <mat-error *ngIf="driverForm.get('contactAddress')?.hasError('required') && driverForm.get('contactAddress')?.touched">
          Relation to contact name is required.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Contact Phone</mat-label>
        <input  type="number" matInput placeholder="Contact Phone" formControlName="contactPhone" required>
        <mat-error *ngIf="driverForm.get('contactPhone')?.hasError('required') && driverForm.get('contactPhone')?.touched">
          Relation to contact name is required.
        </mat-error>
        <mat-error *ngIf="driverForm.get('contactPhone')?.hasError('pattern') && driverForm.get('contactPhone')?.touched">
          Please enter a valid phone number (10 digits).
        </mat-error>
      </mat-form-field>




      <h2>KYC Section</h2>

      <!-- PAN Document -->
      <div class="col-md-4 col-sm-12">
        <label>PAN Document:</label>
        <input type="file" (change)="onFileSelected($event, 'kycData.pan')" formControlName="kycData.pan" required>
      </div>

      <!-- Aadhar Document -->
      <div class="col-md-4 col-sm-12">
        <label>Aadhar Document:</label>
        <input type="file" (change)="onFileSelected($event, 'kycData.aadhar')" formControlName="kycData.aadhar" required>
      </div>

      <!-- Bank Details Document -->
      <div class="col-md-4 col-sm-12 mb-2">
        <label>Bank Details Document:</label>
        <input type="file" (change)="onFileSelected($event, 'kycData.bankDetails')" formControlName="kycData.bankDetails" required>
      </div>

      <!-- Passport Photo -->
      <div class="col-md-4 col-sm-12">
        <label>Passport Photo:</label>
        <input type="file" (change)="onFileSelected($event, 'kycData.passportPhoto')" formControlName="kycData.passportPhoto" required>
      </div>

      <!-- Police Verification Document -->
      <div class="col-md-4 col-sm-12">
        <label>Police Verification Document:</label>
        <input type="file" (change)="onFileSelected($event, 'kycData.policeVerification')" formControlName="kycData.policeVerification" required>
      </div>

      <!-- Electric Bill Document -->
      <div class="col-md-4 col-sm-12 mb-4">
        <label>Electric Bill Document:</label>
        <input type="file" (change)="onFileSelected($event, 'kycData.electricBill')" formControlName="kycData.electricBill" required>
      </div>



      <h2>Driver's License Information</h2>
      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>State</mat-label>
        <input matInput placeholder="State" formControlName="state" required>
        <mat-error *ngIf="driverForm.get('state')?.hasError('required') && driverForm.get('state')?.touched">
          State is required.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>License </mat-label>
        <input matInput placeholder="License Type" formControlName="license" required>
        <mat-error *ngIf="driverForm.get('license')?.hasError('required') && driverForm.get('license')?.touched">
          License type is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label> Type</mat-label>
        <input matInput placeholder="Type" formControlName="type" required>
        <mat-error *ngIf="driverForm.get('type')?.hasError('required') && driverForm.get('type')?.touched">
          License type is required.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Expiration Date</mat-label>
        <input matInput [matDatepicker]="expiredDatePicker" placeholder="Choose a date" formControlName="expirationDate" required>
        <mat-error *ngIf="driverForm.get('expirationDate')?.hasError('required') && driverForm.get('expirationDate')?.touched">
          Expiration date is required.
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="expiredDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #expiredDatePicker></mat-datepicker>
      </mat-form-field>



      <h2>Driver Experience</h2>
      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Type of Equipment</mat-label>
        <input matInput placeholder="Type of Equipment" formControlName="typeOfEquipment" required>
        <mat-error *ngIf="driverForm.get('typeOfEquipment')?.hasError('required') && driverForm.get('typeOfEquipment')?.touched">
          Type of equipment is required.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>From Date</mat-label>
        <input matInput [matDatepicker]="fromDatePicker" placeholder="Choose a date" formControlName="fromDate" required>
        <mat-error *ngIf="driverForm.get('fromDate')?.hasError('required') && driverForm.get('fromDate')?.touched">
          From Date is required.
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>To Date</mat-label>
        <input matInput [matDatepicker]="toDatePicker" placeholder="Choose a date" formControlName="toDate" required>
        <mat-error *ngIf="driverForm.get('toDate')?.hasError('required') && driverForm.get('toDate')?.touched">
          To Date is required.
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #toDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Approximate Miles</mat-label>
        <input matInput placeholder="Approximate Miles" formControlName="approxMiles" required>
        <mat-error *ngIf="driverForm.get('approxMiles')?.hasError('required') && driverForm.get('approxMiles')?.touched">
          Approximate milese is required.
        </mat-error>
      </mat-form-field>
    </div>
    <!-- Submit Button -->
    <div class="d-flex justify-content-center mb-4 gap-3">
      <button mat-raised-button color="accent" (click)="onCancel()">Cancel</button>
      <button mat-raised-button color="primary" (click)="onSubmit()">Submit</button>
    </div>
  </form>
</div>
</div>
