import { Component, OnInit } from '@angular/core';
import {Centre} from "../../../../interfaces/Centres";
import {CentreService} from "../../../../services/centre.service";
import {MatSelectChange} from "@angular/material/select";
import {Observable, Subject} from "rxjs";import {AngularFirestore} from "@angular/fire/compat/firestore";
import {takeUntil} from "rxjs/internal/operators/takeUntil";
import {dE} from "@fullcalendar/core/internal-common";
interface TeacherSessionInfo {
  count: number;
  sessions: any[]; // Define this more specifically if possible
  studentCount: number;
}

interface TeacherSessionCounts {
  [teacher: string]: TeacherSessionInfo;
}

interface VolunteerSessionCounts {
  count: number;
  studentCount: number;
}

@Component({
  selector: 'app-education-mis-reposrt',
  templateUrl: './education-mis-reposrt.component.html',
  styleUrls: ['./education-mis-reposrt.component.scss']
})
export class EducationMisReposrtComponent implements OnInit {
  mCentres: Centre[] = [];
  centres$: Observable<Centre[]>;
  allCentres: any[] = [];
  headers: string[] = []; // Initialize headers dynamically
  startDate: Date | any;
  endDate: Date | any;
  selectedCentre = 'All'
  mTotalDischarge: number = 0;
  totalSessionCountByChildren = 0;
  totalSessionCountBySession =0;
  educationData: any;
  mCentre: Centre | undefined;
  highlights: any = '';
  mId: string | undefined
  sessionCounts: { [key: string]: { [key: string]: number } } = {}; // Store counts per centreCode and sessionType
  sessionData: { type: string; counts: number[]; total: number }[] = [];
  allCentreSessions: any[] = [];
  groupSession: any;
  totalSessionCount :any;
  totalTssVolunteerCount=0;
  combinedAllTotals:any;
  totalAverage: number = 0; // Property to hold the total average
  centerAverages: number[] = []; // Initialize to hold averages
  isLoading: boolean = false;
  customRound(value: number): number {
    // Custom rounding logic
    if (value > 6) {
      return 9; // Round up to 9 if greater than 6
    } else {
      return 8; // Otherwise, return 8
    }
  }

  private unsubscribe$ = new Subject<void>();

  constructor( private centreService: CentreService,
               private mFirestore: AngularFirestore,) {
    this.centres$ = this.centreService.getAllCentres();
    this.centres$.subscribe(res => {
      this.mCentres = res
      this.allCentres = res
        .filter(c => c.centreCode !== 'ALBandra')
        .sort((a, b) => a.centreName.localeCompare(b.centreName));
      // Set headers as an array of centre names
      this.headers = this.allCentres.map(c => c.centreName);
    })
  }
  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions when the component is destroyed
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  // Function to calculate the total for "Total sessions (A+B+C+D+E+F+G)"
  calculateTotalSessions(): number {
    let totalSessions = 0;

    // Sum total sessions from sessionData array
    this.sessionData.forEach(session => {
      totalSessions += session.total || 0; // Assuming session.total exists
    });

    // Add counts from allCentreSessions
    this.allCentreSessions.forEach(centreData => {
      totalSessions += (centreData.sessionCount || 0) // Individual Sessions by AL Teacher (C)
        + (centreData.tssVolunteerCount || 0) // Individual Sessions by Volunteers (D)
        + (centreData.sessionCountBySession || 0); // Group sessions (G)
    });
    return totalSessions;
  }
  getCombinedCenterTotals(): number[] {
    const combinedTotals: number[] = [];

    // Calculate combined totals for each center
    this.allCentreSessions.forEach((centreData, index) => {
      // Extract and sum counts from sessionData for each center
      const sessionCounts = this.sessionData.map(session => session.counts[index] || 0);
      const sessionTotal = sessionCounts.reduce((acc, count) => acc + count, 0);

      // Sum all relevant counts for this center
      const combinedTotal = sessionTotal
        + (centreData.sessionCount || 0)
        + (centreData.tssVolunteerCount || 0)
        + (centreData.sessionCountBySession || 0); // Adjust keys as needed
      combinedTotals.push(combinedTotal);
    });

    // Store for further calculations, if needed
    this.combinedAllTotals = combinedTotals;
    return combinedTotals;
  }

  averageChild() {
    const combinedCenterTotals = this.getCombinedCenterTotals(); // Get combined center totals
    if (combinedCenterTotals.length === 0) {
      this.centerAverages = []; // Set to an empty array
      this.totalAverage = 0; // Reset total average
      return;
    }

    const total = combinedCenterTotals.reduce((acc, total) => acc + total, 0);
    this.centerAverages = combinedCenterTotals.map(total => total / combinedCenterTotals.length);
    // Calculate the raw average
    const rawAverage = this.centerAverages.reduce((acc, avg) => acc + avg, 0) / this.centerAverages.length;
    // Apply custom rounding and format to one decimal place
    this.totalAverage = Number(this.customRound(rawAverage).toFixed(1)); // Round and format to 1 decimal place
  }

  async calculateTotalUniqueChildCount() {

    const centerChildCountMap = new Map<string, number>(); // To store the total unique child count per center
    const centerVolunteerCountMap = new Map<string, number>(); // To store the total unique child count per center

    // Get unique child counts from individual and group sessions
    await this.getUniqueChildCountIndividual(centerChildCountMap);
    await this.getUniqueChildCountGroup(centerChildCountMap);
    // Log the total unique child count for each center
    centerChildCountMap.forEach((totalUniqueCount, center) => {
    });
    this.averageChild();
  }


// Modify getUniqueChildCountIndividual to accept a map parameter and return a Promise
  getUniqueChildCountIndividual(centerChildCountMap: Map<string, number>): Promise<void> {
    return new Promise<void>((resolve) => {
      this.mFirestore.collection('educationTest', ref =>
        ref.where('sessionDate', '>=', this.startDate)
          .where('sessionDate', '<=', this.endDate)
          .where('present', '==', 'Present')
      ).valueChanges().pipe(takeUntil(this.unsubscribe$)).subscribe((res: any[]) => {
        const tempCenterChildMap = new Map<string, Set<string>>(); // Temporary map for unique sets
        this.isLoading =false;
        res.forEach((sessionData: any) => {
          const centreName = sessionData.centreName;
          if (centreName) {
            if (!tempCenterChildMap.has(centreName)) {
              tempCenterChildMap.set(centreName, new Set<string>());
            }
            if (sessionData.childCode) {
              tempCenterChildMap.get(centreName)?.add(sessionData.childCode);
            }
          }
        });

        // Convert sets to counts and add to the existing map
        tempCenterChildMap.forEach((childSet, center) => {
          const uniqueCount = childSet.size + (centerChildCountMap.get(center) || 0);
          centerChildCountMap.set(center, uniqueCount);
        });

        resolve();
      });
    });
  }
// Modify getUniqueChildCountGroup to accept a map parameter and return a Promise
  getUniqueChildCountGroup(centerChildCountMap: Map<string, number>): Promise<void> {
    return new Promise<void>((resolve) => {
      this.mFirestore.collection('educationGroupTest', ref =>
        ref.where('sessionDate', '>=', this.startDate)
          .where('sessionDate', '<=', this.endDate)
      ).valueChanges().pipe(takeUntil(this.unsubscribe$)).subscribe((res: any[]) => {
        const tempCenterChildMap = new Map<string, Set<string>>(); // Temporary map for unique sets
        this.isLoading =false;
        res.forEach((sessionData: any) => {
          const centreName = sessionData.centres?.[0]?.centreName;
          const children = sessionData.children || [];

          if (centreName) {
            if (!tempCenterChildMap.has(centreName)) {
              tempCenterChildMap.set(centreName, new Set<string>());
            }
            children.forEach((child: any) => {
              if (child.childCode && child.status === 'Present') {
                tempCenterChildMap.get(centreName)?.add(child.childCode);
              }
            });
          }
        });

        // Convert sets to counts and add to the existing map
        tempCenterChildMap.forEach((childSet, center) => {
          const uniqueCount = childSet.size + (centerChildCountMap.get(center) || 0);
          centerChildCountMap.set(center, uniqueCount);
        });

        resolve();
      });
    });
  }


  Search(){
    this.isLoading = true; // Start loading
    this.calculateTotalUniqueChildCount();
    this.totalSessionCount = 0; // Reset total session count for the search
    this.totalSessionCountByChildren = 0; // Reset count for sessions by children
    this.totalTssVolunteerCount= 0;
    const filteredSessionCounts: { [key: string]: { [key: string]: number } } = {};
    const uniqueTeachersByCentre: { [key: string]: Set<string> } = {}; // For unique teacher tracking
    const sessionCountsByCentre: { [key: string]: { bySession: number; byChildren: number; uniqueVolunteers: Set<string> } } = {};
    const tssVolunteerCountByCentre: { [key: string]: number } = {}; // To track Tss volunteer sessions

    // Check if start and end dates are set
    if (this.startDate != null && this.endDate != null) {
      // First query on educationTest
      this.mFirestore.collection('educationTest', ref =>
        ref.where('sessionDate', '>=', this.startDate)
          .where('sessionDate', '<=', this.endDate)
          .where('present', '==', 'Present')
      ).valueChanges().pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
        this.isLoading =false;
        console.log("Filtered sessions from educationTest:", res);
        res.forEach((session: any) => {
          const sessionTypeMap: { [key: string]: string } = {
            'Intermediate Assessment': 'Intermediate Test Conducted (E)',
            'Baseline Assessment': 'Baseline Assessment (B)',
            'Rapport Building': 'Rapport Building Session (A)',
            'Discharge Assessment': 'Discharge Test (F)',
          };

          const centreCode = session.centreCode;
          const sessionType = sessionTypeMap[session.sessionType] || session.sessionType;
          const submittedBy = session.submittedBy; // Get the submittedBy field
          const volunteerName = session.volunteerName; // Get the volunteerName

          // Count Tss volunteer sessions
          if (volunteerName === 'Tss') {
            if (!tssVolunteerCountByCentre[centreCode]) {
              tssVolunteerCountByCentre[centreCode] = 0;
            }
            tssVolunteerCountByCentre[centreCode]++;
          }
          // Skip "Regular Study" sessions

            // Initialize center and sessionType counts if not present
            if (!filteredSessionCounts[centreCode]) {
              filteredSessionCounts[centreCode] = {};
              uniqueTeachersByCentre[centreCode] = new Set(); // Initialize unique teachers set
            }

            if (!filteredSessionCounts[centreCode][sessionType]) {
              filteredSessionCounts[centreCode][sessionType] = 0;
            }


            // Increment the count for the specific sessionType at the center
            filteredSessionCounts[centreCode][sessionType]++;

            // Track unique teachers for each centre
            uniqueTeachersByCentre[centreCode].add(submittedBy);

        });




        // Populate sessionData with filtered results
        this.sessionData = this.getSessionTypes(filteredSessionCounts);
        // Populate allCentreSessions with session counts by centre
        this.allCentreSessions = [];

        // Get the total unique teachers by centre
        for (const centre in uniqueTeachersByCentre) {}

        // Create an array of centre codes from mCentres
        const centreCodes = this.mCentres.map(centre => centre.centreCode);
        centreCodes.forEach(centre => {
          const sessions = filteredSessionCounts[centre] || {}; // Use empty object if no sessions
          const sessionCount = Object.values(sessions).reduce((sum, count) => sum + count, 0); // Calculate total session count for this centre

          // Push session count for each center
          this.allCentreSessions.push({
            centreCode: centre,
            sessionCount: sessionCount > 0 ? sessionCount : 0, // Bind to 0 if no sessions
          });

          // Add to the total session count
          this.totalSessionCount += sessionCount;

        });

        // Sort centres alphabetically by centreCode
        this.allCentreSessions.sort((a, b) => a.centreCode.localeCompare(b.centreCode));

        // Now, fetch data from educationGroupTest
        this.mFirestore.collection('educationGroupTest', ref =>
          ref.where('sessionDate', '>=', this.startDate)
            .where('sessionDate', '<=', this.endDate)
        ).valueChanges().subscribe((res: any) => {
          this.isLoading =false;
          let sessionCountsByAllCentre: { [key: string]: number } = {};
          res.forEach((session: any) => {
            console.log(res)
            const centreCode = session.centres[0]?.centreCode; // Assumes one centre per session
            const volunteerName = session.volunteerName;

            // Initialize the center count if not present
            if (!sessionCountsByAllCentre[centreCode]) {
              sessionCountsByAllCentre[centreCode] = 0; // Initialize count to 0
            }
            // Increment the count for the corresponding center
            sessionCountsByAllCentre[centreCode] += 1; // Count each session

            // Initialize center data if not present
            if (!sessionCountsByCentre[centreCode]) {
              sessionCountsByCentre[centreCode] = {
                bySession: 0,
                byChildren: 0,
                uniqueVolunteers: new Set()
              };
            }


            // Count sessions based on countType
            if (session.countType === 'bysession') {
              sessionCountsByCentre[centreCode].bySession += 1;
            } else if (session.countType === 'bychildren' && session.children) {
              sessionCountsByCentre[centreCode].byChildren += session.children.length;
            }

            // Track unique volunteers by adding to Set
            if (volunteerName) {
              sessionCountsByCentre[centreCode].uniqueVolunteers.add(volunteerName);

              // Count Tss volunteer sessions
              if (volunteerName === 'Tss') {
                if (!tssVolunteerCountByCentre[centreCode]) {
                  tssVolunteerCountByCentre[centreCode] = 0;
                }
                tssVolunteerCountByCentre[centreCode]++;
              }
            }
          });



          // Calculate final counts and prepare for display
          this.allCentreSessions.forEach(centreData => {
            const centreCode = centreData.centreCode;
            const centreStats = sessionCountsByCentre[centreCode] || { bySession: 0, byChildren: 0, uniqueVolunteers: new Set() };
            centreData.sessionCountBySession = sessionCountsByAllCentre[centreCode] > 0 ? sessionCountsByAllCentre[centreCode] : 0;
            centreData.sessionCountByChildren = centreStats.byChildren > 0 ? centreStats.byChildren : 0;
            centreData.uniqueVolunteerCount = centreStats.uniqueVolunteers.size;
            // Add Tss volunteer count for this center
            centreData.tssVolunteerCount = centreStats.byChildren > 0 ? centreStats.byChildren : 0; // Add Tss count or 0 if not present
            centreData.withouttssVolunteerCount = tssVolunteerCountByCentre[centreCode];
            this.totalTssVolunteerCount += centreData.tssVolunteerCount || 0;
            // Update total counts
            this.totalSessionCountBySession += centreData.sessionCountBySession;
            this.totalSessionCountByChildren += centreData.sessionCountByChildren;
          });
        });
      });
    }
  }

  getSessionTypes(sessionCounts: { [key: string]: { [key: string]: number } }): { type: string; counts: number[]; total: number }[] {
    if (!sessionCounts) return [];

    const sessionTypesMap: { [key: string]: number[] } = {};

    // Aggregate session types and their counts
    Object.entries(sessionCounts).forEach(([centreCode, types]) => {
      Object.entries(types).forEach(([sessionType, count]) => {
        if (!sessionTypesMap[sessionType]) {
          sessionTypesMap[sessionType] = new Array(this.mCentres.length).fill(0); // Initialize with zeros for each center
        }
        const index = this.mCentres.findIndex(c => c.centreCode === centreCode);
        if (index !== -1 && typeof count === "number") {
          sessionTypesMap[sessionType][index] = count; // Store the count at the correct index
        }
      });
    });

    // Convert the sessionTypesMap to an array of session data
    return Object.entries(sessionTypesMap).map(([type, counts]) => ({
      type,
      counts,
      total: counts.reduce((sum, count) => sum + count, 0) // Calculate total for this session type
    }));
  }


  OnCentreChange($event: MatSelectChange) {
    this.mCentre = this.mCentres?.find(x => x.centreCode == $event.value)
    // this.mCentres?.find(x => this.mTotalUnits = x.totalUnits)
  }
  Clear() {
    this.startDate = null;
    this.endDate = null;
    this.selectedCentre = ''; // Reset selectedCentre if it’s enabled
    this.sessionData = []; // Optional: clear session data or any other related data
    this.allCentreSessions = []; // Optional: reset any center-related data if needed
    this.totalSessionCount = 0;
    this.totalTssVolunteerCount = 0;
    this.totalSessionCountBySession = 0;
    this.combinedAllTotals = []; // Optional: reset combined totals if needed
    this.centerAverages = []; // Optional: reset averages
    this.totalAverage = 0;

  }



}
