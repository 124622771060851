<div class="container">


<!--<prettyjson [obj]="mAllData"> </prettyjson>-->

        <section>
          <div class="ngxTableHeader">
            <ul class="header-buttons-left ms-0 mb-0">
              <li class="dropdown">
                <h2>Bookings</h2>
              </li>
              <li class="dropdown m-l-20">
                <label id="search-input"><i class="material-icons search-icon">search</i></label>
                <input placeholder="Search" type="text" class="browser-default search-field"
                       (keyup)='applyFilter($event)' aria-label="Search box" #input>
              </li>
            </ul>
            <ul class="header-buttons mr-2">
              <!--      <li>-->
              <!--        <button   mat-mini-fab color="primary" (click)="openAddCarLog()">-->
              <!--          <mat-icon class="col-white" >add</mat-icon>-->
              <!--        </button>-->
              <!--      </li>-->
            </ul>
          </div>

          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

            <!-- Donor Name Column -->
            <ng-container matColumnDef="donated_by">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Donor Name</th>
              <td mat-cell *matCellDef="let element">
                <span class="truncate-text" [matTooltip]="element.donated_by">{{element.donated_by}}</span>
              </td>
            </ng-container>

            <!-- City Column -->
            <ng-container matColumnDef="city">
              <th mat-header-cell *matHeaderCellDef>City</th>
              <td mat-cell *matCellDef="let element">
                <span class="truncate-text" [matTooltip]="element.city">{{element.city}}</span>
              </td>
            </ng-container>

            <!-- Address Column -->
            <ng-container matColumnDef="Address">
              <th mat-header-cell *matHeaderCellDef>Address</th>
              <td mat-cell *matCellDef="let element">
                <span class="truncate-text" [matTooltip]="element.address">{{element.address}}</span>
              </td>
            </ng-container>

            <!-- Pan No Column -->
            <ng-container matColumnDef="pan">
              <th mat-header-cell *matHeaderCellDef>Pan No.</th>
              <td mat-cell *matCellDef="let element">
                <span class="truncate-text" [matTooltip]="element.pan">{{element.pan}}</span>
              </td>
            </ng-container>

            <!-- Meal Slot Column -->
            <ng-container matColumnDef="mealSlot">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Meal Slot</th>
              <td mat-cell *matCellDef="let element">
                <span class="truncate-text" [matTooltip]="element.mealSlot">{{element.mealSlot}}</span>
              </td>
            </ng-container>

            <!-- Event Name Column -->
            <ng-container matColumnDef="eventName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Event Name</th>
              <td mat-cell *matCellDef="let element">
                <span class="truncate-text" [matTooltip]="element.eventName">{{element.eventName}}</span>
              </td>
            </ng-container>

            <!-- Event Message Column -->
            <ng-container matColumnDef="event_message">
              <th mat-header-cell *matHeaderCellDef>Event Message</th>
              <td mat-cell *matCellDef="let element">
                <span class="truncate-text" [matTooltip]="element.eventMessage">{{element.eventMessage}}</span>
              </td>
            </ng-container>

            <!-- Centre Code Column -->
            <ng-container matColumnDef="centreCode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Centre Code</th>
              <td mat-cell *matCellDef="let element">
                <span class="truncate-text" [matTooltip]="element.centreCode">{{element.centreCode}}</span>
              </td>
            </ng-container>

            <!-- Booking Date Column -->
            <ng-container matColumnDef="start">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Booking Date</th>
              <td mat-cell *matCellDef="let element">
                <span class="truncate-text" [matTooltip]="element.start">{{element.start}}</span>
              </td>
            </ng-container>

            <!-- Payment Date Column -->
            <ng-container matColumnDef="payment_date">
              <th mat-header-cell *matHeaderCellDef>Payment Date</th>
              <td mat-cell *matCellDef="let element">
                <span class="truncate-text" [matTooltip]="element.payment_date">{{element.payment_date}}</span>
              </td>
            </ng-container>

            <!-- State Column -->
            <ng-container matColumnDef="state">
              <th mat-header-cell *matHeaderCellDef>State</th>
              <td mat-cell *matCellDef="let element">
                <span class="truncate-text" [matTooltip]="element.state">{{element.state}}</span>
              </td>
            </ng-container>

            <!-- Booking ID Column -->
            <ng-container matColumnDef="booking_id">
              <th mat-header-cell *matHeaderCellDef>Booking ID</th>
              <td mat-cell *matCellDef="let element">
                <span class="truncate-text" [matTooltip]="element.order_id">{{element.order_id}}</span>
              </td>
            </ng-container>

            <!-- Need 80G Column -->
            <ng-container matColumnDef="otg_certificate">
              <th mat-header-cell *matHeaderCellDef>Need 80G</th>
              <td mat-cell *matCellDef="let element">
                <span class="truncate-text" [matTooltip]="element.need80g ? 'Yes' : 'No'">{{element.need80g ? 'Yes' : 'No'}}</span>
              </td>
            </ng-container>

            <!-- Receipt No Column -->
            <ng-container matColumnDef="recieptNo">
              <th mat-header-cell *matHeaderCellDef>Receipt No</th>
              <td mat-cell *matCellDef="let element">
                <span class="truncate-text" [matTooltip]="element.receipt_id">{{element.receipt_id}}</span>
              </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
              <td mat-cell *matCellDef="let element">
                <button *ngIf="mealMatePermissions[0].all || mealMatePermissions[0].edit" mat-icon-button color="primary" (click)="allotSlot(element)" matTooltip="Allot">
                  <mat-icon>book</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <mat-paginator [pageSizeOptions]="[25,50,100,200,250,500,1000]" aria-label="Select page of users"></mat-paginator>


        </section>






</div>
