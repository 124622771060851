import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {VehicleService} from "../vehicle.service";
import {finalize} from "rxjs/operators";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {LogService} from "../../../services/log.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";


export interface Driverdata {
  firstName: string;
  middleName: string;
  lastName: string;
  currentAddress: string;
  previousAddress: string;
  phone: string;
  dob: Date;
  dateOfJoining: Date;
  id:string;
  socialSecurity: string;
  emergencyContactName: string;
  relationToContact: string;
  contactAddress: string;
  contactPhone: string;
  state: string;
  license: string;
  type: string;
  expirationDate: string;
  typeOfEquipment: string;
  fromDate: Date;
  toDate: Date;
  approxMiles: string;
  kycData:KYCData
}

export interface KYCData {
  pan: string;
  aadhar: string;
  bankDetails: string;
  passportPhoto: string;
  policeVerification: string;
  electricBill: string;
}


@Component({
  selector: 'app-add-info',
  templateUrl: './add-info.component.html',
  styleUrls: ['./add-info.component.scss']
})
export class AddInfoComponent implements OnInit {
  driverForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private mService: VehicleService,
              private matSnackbar:MatSnackBar,
              private storage: AngularFireStorage,
              private mLogService: LogService,
              private mRouter: Router,
              private mDialog:MatDialog) {
    this.driverForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      middleName: ['', Validators.pattern('^[a-zA-Z ]*$')],
      lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      currentAddress: ['', Validators.required],
      previousAddress: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      dob: ['', Validators.required],
      dateOfJoining: ['', Validators.required],
      socialSecurity: ['', Validators.required],
      emergencyContactName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      relationToContact: ['', Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      contactAddress: ['', Validators.required],
      contactPhone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],

      kycData: this.formBuilder.group({
        pan: ['default-pan-url', Validators.required],
        aadhar: ['default-aadhar-url', Validators.required],
        bankDetails: ['default-bank-url', Validators.required],
        passportPhoto: ['default-passport-url', Validators.required],
        policeVerification: ['default-police-url', Validators.required],
        electricBill: ['default-electric-bill-url', Validators.required],
      }),

      state: ['', Validators.required],
      license: ['', Validators.required],
      type: ['', Validators.required],
      expirationDate: ['', Validators.required],

      typeOfEquipment: ['', Validators.required],
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      approxMiles: ['', Validators.required],
    });

  }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.driverForm.valid) {
      const driverData: Driverdata = this.driverForm.value;
      console.log(driverData)
      this.mService.addDriverData(driverData).then(r => {
        this.matSnackbar.open('Data added successfully',"",{duration:3000,panelClass:'success-snackbar'})
        console.log("Data added successfully")
        let activity = {
          date: new Date().getTime(),
          section: 'Driver',
          action: 'Submit',
          description: `Driver added by user`,
          currentIp: localStorage.getItem('currentip')!
        }
        this.mLogService.addLog(activity)
        this.mDialog.closeAll();
      })

    } else {
      console.log("Form is invalid");
    }
    console.log(this.driverForm.value)
  }
  onCancel() {
    this.mDialog.closeAll();
  }


  onFileSelected(event: any, controlName: string) {
    const file = event.target.files[0];
    if (file) {
      const filePath = `kyc/${new Date().getTime()}_${file.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);

      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((downloadURL: any) => {
            this.driverForm.get(controlName)!.setValue(downloadURL);
            this.driverForm.get(controlName)!.markAsDirty();
            this.matSnackbar.open('Document Uploaded',"",{duration:3000})
          });
        })
      ).subscribe();
    } else {
      this.driverForm.get(controlName)!.setValue(null);
      this.driverForm.get(controlName)!.markAsDirty();
    }
  }

}
