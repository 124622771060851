import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {FullChild} from "../../../interfaces/FullChild";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialogRef} from "@angular/material/dialog";
import {UnitSponsorshipService} from "../unit-sponsorship.service";
import {LogService} from "../../../services/log.service";
import {DonorService} from "../../donoradmin/donor.service";

@Component({
  selector: 'app-add-unit-sponsorship',
  templateUrl: './add-unit-sponsorship.component.html',
  styleUrls: ['./add-unit-sponsorship.component.scss']
})
export class AddUnitSponsorshipComponent implements OnInit {
  form: any;
  childData: FullChild[] = []
submit=true
  constructor(private mDatabase: AngularFirestore,
              private matSnackBar: MatSnackBar,
              private readonly mDonorService:DonorService,
              private  readonly mService:UnitSponsorshipService,
              private matDialogReg: MatDialogRef<AddUnitSponsorshipComponent>,
              private mLogService: LogService
  ) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      "donorName": new FormControl('', [Validators.required, Validators.pattern('[a-z A-Z]*')]),
      "date": new FormControl('', [Validators.required]),
      "date2": new FormControl('', [Validators.required]),
      "email": new FormControl('', [Validators.required, Validators.email]),
      "address": new FormControl('', [Validators.required]),
      "mobile": new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}$'), Validators.minLength(10), Validators.maxLength(10)]),
      "city": new FormControl('', [Validators.required, Validators.pattern('[a-z A-Z]*')]),
      "country": new FormControl('', [Validators.required, Validators.pattern('[a-z A-Z]*')]),
      "state": new FormControl('', [Validators.required, Validators.pattern('[a-z A-Z]*')]),
      "pincode": new FormControl('', [Validators.required, Validators.pattern('[0-9 ]*'), Validators.minLength(6), Validators.maxLength(6)]),
      "angleName": new FormControl('', [Validators.required, Validators.pattern('[a-z A-Z]*')]),
      "paymentInformation": new FormControl('', [Validators.required]),
      "remark": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9!@#$%^&*()-=_+{}\[\]:;"\'<>,.?/|\\]*')]),
      "totalTransactionAmount": new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]), // Updated pattern to allow only numbers
      "remainingAmount": new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]), // Updated pattern to allow only numbers
      "panNumber": new FormControl('', [Validators.required, Validators.pattern('[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}')]),

    })
  }
  get donorName() {
    return this.form.get('donorName');
  }
  get email() {
    return this.form.get('email');
  }
  get mobile() {
    return this.form.get('mobile');
  }

  get country() {
    return this.form.get('country');
  }

  get city() {
    return this.form.get('city');
  }

  get amount() {
    return this.form.get('totalTransactionAmount');
  }

  get RemainingAmount() {
    return this.form.get('remainingAmount');
  }

  get pincode() {
    return this.form.get('pincode');
  }

  get angleName() {
    return this.form.get('angleName');
  }
  get panCard() {
    return this.form.get('panNumber');
  }


  cancel() {
    this.matDialogReg.close()


  }

  SubmitData() {
this.submit=false
    let amount = this.form.get('totalTransactionAmount').value
    this.form.get('remainingAmount').setValue(this.form.get('totalTransactionAmount').value)
    if (this.form.valid) {
      if (amount % 25000 == 0) {
        const formData = this.form.value;

        console.log('Data added to Firestore');

        this.mService.addSponsorship(this.form.value)
          .then(() => {
            console.log('Data added to Firestore');
this.form.addControl(new FormControl('pan',this.panCard.value))
            this.form.removeControl(this.panCard)
            this.mDonorService.addDonor(this.form.value(),this.panCard())
            let activity={
              date:new Date().getTime(),
              section: 'Unit Sponsorship',
              action: 'Add',

              description: `Unit Sponsorship Added by`,
              currentIp: localStorage.getItem('currentip')!
            }
            this.mLogService.addLog(activity)
            this.matDialogReg.close()
          })
          .catch((error:any) => {
            console.error('Error adding data to Firestore:', error);
          });
      } else {
        this.matSnackBar.open('Amount should be multiple of Rs.30000 ')._dismissAfter(3000)
      this.submit=false
      }
    } else {
      this.submit=true
      // alert(this.findInvalidControls())
      console.log(this.findInvalidControls())

    }


  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

}

