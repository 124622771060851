import { Component, Inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import {MatDialogModule, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogData} from "../interfaces/confirm-dialog-data";


@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [MatIconModule,MatDialogModule,MatButtonModule],
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {}
  // Handles the click event for "No"
  onNoClick(): void {
    this.dialogRef.close(false); // Return `false` to the caller
  }
}
