import {Injectable, isDevMode, OnDestroy} from '@angular/core';
import {AssessmentModel} from "../../interfaces/AssessmentModel";
import {Subject} from "rxjs";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {shareReplay} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {ɵFormGroupValue, ɵTypedOrUntyped} from "@angular/forms";
import {AngularFireDatabase} from "@angular/fire/compat/database";

@Injectable({
  providedIn: 'root'
})
export class CounselingService implements OnDestroy {
  private _destroyed$ = new Subject()
  env = isDevMode() ? environment.testMode : environment.productionMode

  private centreDataSource = new Subject();
  public data = this.centreDataSource.asObservable();

  constructor(private readonly mFirestore: AngularFirestore,
              private readonly mDatabase: AngularFireDatabase,
  ) {
  }

  getAssessMentsList() {
    let userData = JSON.parse(localStorage.getItem('userData')!)
    console.log('into getallcentres')
    let query = this.mFirestore.collection<AssessmentModel>(this.env.assessment + '/allAssessments/assessments/', ref => ref.orderBy('dateOfAssessment', 'desc')).valueChanges({idField: 'id'})
    if (userData.role == 'Centre' || userData.role == 'Admin' || userData.role == 'Psychologist') query = this.getSortedCounsellingListByRole(userData.centreCode)
    return query


  }

  getUpcomingAssessMentsList(centreCode: string, previousDate: Date, threshold: Date) {
    let userData = JSON.parse(localStorage.getItem('userData')!)
    console.log('into getallcentres')
    let query = this.mFirestore.collection<AssessmentModel>(this.env.assessment + '/allAssessments/assessments/', ref => ref.where('centreCode', '==', centreCode).where("dateOfAssessment", '>=', previousDate).where("dateOfAssessment", '<=', threshold)).valueChanges({idField: 'id'})
    if(centreCode!='All') query = this.mFirestore.collection<AssessmentModel>(this.env.assessment + '/allAssessments/assessments/', ref => ref.where("dateOfAssessment", '>=', previousDate).where("dateOfAssessment", '<=', threshold)).valueChanges({idField: 'id'})

    // if(userData.role=='Centre'  ||userData.role =='Admin' || userData.role =='Psychologist') query =   this.getSortedCounsellingListByRole(userData.centreCode)
    return query


  }

  getAssessMentsListSorted(centreCode: string) {
    console.log('in counsellin service: ' + centreCode)
    let userData = JSON.parse(localStorage.getItem('userData')!)
    console.log('into getallcentres')
    console.log('ROLE=>', userData.role)
    let query = this.mFirestore.collection<AssessmentModel>(this.env.assessment + '/allAssessments/assessments/').valueChanges({idField: 'id'})

    if (centreCode == 'All') query = this.mFirestore.collection<AssessmentModel>(this.env.assessment + '/allAssessments/assessments/', ref => ref.orderBy('dateOfAssessment', 'desc')).valueChanges({idField: 'id'})
    else query = this.mFirestore.collection<AssessmentModel>(this.env.assessment + '/allAssessments/assessments/', ref => ref.orderBy('dateOfAssessment', 'desc').where('centreCode', '==', centreCode)).valueChanges({idField: 'id'})


    return query


  }

  getSortedCounsellingListByRole(centreCode: string) {
    const centerRef = this.mFirestore.collection<AssessmentModel>(this.env.counseling, ref1 => ref1.orderBy('dateOfAssessment', 'desc').where('centreCode', '==', centreCode)
      // .orderBy("sr", 'desc')
    )

    return centerRef.valueChanges({idField: 'id'}).pipe(
      // tap(arr=>console.log(`centre: read (${ this.centreRead +=arr.length } )docs` ),
      shareReplay(1)
    )
  }

  ngOnDestroy(): void {
    // @ts-ignore
    this._destroyed$.next()
    this._destroyed$.complete()
  }

  addAssessment(form: any) {
    const childCode = form['childCode']
    const type = form['type']
    console.log(childCode)
    console.log(type)
    const batchWrite = this.mFirestore.firestore.batch()
    //* We used Realtime Database only to get pushId in below line
    const dockey = this.mDatabase.database.ref(`${this.env.assessment}/childAssessment/${childCode}`).push().key
    const childSessionRef = this.mFirestore.firestore.doc(`${this.env.assessment}/childAssessment/${childCode}/${dockey}`)
    const allSessionRef = this.mFirestore.firestore.doc(`${this.env.assessment}/allAssessments/assessments/${dockey}`)
    batchWrite.set(childSessionRef, form, {merge: true})
    batchWrite.set(allSessionRef, form, {merge: true})

    return batchWrite.commit()
    // console.assessment-table(form)
  }

  addIndividualSession(form: ɵTypedOrUntyped<any, ɵFormGroupValue<any>, any>) {
    const batchWrite = this.mFirestore.firestore.batch()
    const childCode = form['childCode']
    //* We used Realtime Database only to get pushId in below line
    const dockey = this.mDatabase.database.ref(`${this.env.counseling}/childSession/${childCode}`).push().key
    const childSessionRef = this.mFirestore.firestore.doc(`${this.env.counseling}/childSessions/${childCode}/${dockey}`)
    const allSessionRef = this.mFirestore.firestore.doc(`${this.env.counseling}/allSessions/sessions/${dockey}`)
    batchWrite.set(childSessionRef, form, {merge: true})
    batchWrite.set(allSessionRef, form, {merge: true})
    return batchWrite.commit()
  }

  addGroupSession(form: ɵTypedOrUntyped<any, ɵFormGroupValue<any>, any>) {
    const batchWrite = this.mFirestore.firestore.batch()
    // const childCode = form['childCode']
    //* We used Realtime Database only to get pushId in below line
    const dockey = this.mDatabase.database.ref(`${this.env.counseling}/childSession/`).push().key
    // const childSessionRef = this.mFirestore.firestore.doc(`${this.env.counseling}/childSessions/${childCode}/${dockey}`)
    const allSessionRef = this.mFirestore.firestore.doc(`${this.env.counseling}/allSessions/groupSessions/${dockey}`)
    // batchWrite.set(childSessionRef,form,{merge:true})
    batchWrite.set(allSessionRef, form, {merge: true})
    return batchWrite.commit()
  }

  fetchGroupSessions() {
    return this.mFirestore.collection<any>(this.env.counseling + '/allSessions/groupSessions', ref => ref.orderBy('date', 'desc')).valueChanges({idField: 'id'})
  }
  fetchGroupSessionsfrom2024(date:Date) {
    return this.mFirestore.collection<any>(this.env.counseling + '/allSessions/groupSessions', ref => ref.orderBy('date', 'desc')
      .where('date','>=',date)).valueChanges({idField: 'id'})
  }

  fetchGroupSessionsByCentre(centrecode: string, centreName: string) {
    let obj = {
      centreName: centreName,
      centreCode: centrecode
    }
    return this.mFirestore.collection(this.env.counseling + '/allSessions/groupSessions', ref => ref.where('centres', 'array-contains', obj).orderBy('date', 'desc')).valueChanges({idField: 'id'})
  }

  fetchIndividualSessionsByCentreByDate(centrecode: string, startDate: Date, endDate: Date) {
    return this.mFirestore.collection(this.env.counseling + '/allSessions/sessions', ref => ref.where('centreCode', '==', centrecode)
      .where('date', '>=', startDate)
      .where('date', '<=', endDate)
      .orderBy('date', 'desc')).valueChanges({idField: 'id'})
  }

  fetchGroupSessionsByCentreByDate(centrecode: string, startDate: Date, endDate: Date) {
    if (centrecode != 'All') {
      return this.mFirestore.collection(this.env.counseling + '/allSessions/groupSessions', ref => ref.where('centreCode', '==', centrecode)
        .where('date', '>=', startDate)
        .where('date', '<=', endDate)
        .orderBy('date', 'desc')).valueChanges({idField: 'id'})
    } else {
      return this.mFirestore.collection(this.env.counseling + '/allSessions/groupSessions', ref => ref
        .where('date', '>=', startDate)
        .where('date', '<=', endDate)
        .orderBy('date', 'desc')).valueChanges({idField: 'id'})
    }
  }


  fetchGroupSessionsByChild(childObject: any) {
    return this.mFirestore.collection(this.env.counseling + '/allSessions/groupSessions', ref => ref.orderBy('date', 'desc').where('children', 'array-contains', childObject)).valueChanges({idField: 'id'})
  }

  fetchGroupSessionsSorted(selectedCentre: any) {
    if (selectedCentre.centreCode == 'All') return this.mFirestore.collection(this.env.counseling + '/allSessions/groupSessions', ref => ref.orderBy('date', 'desc')).valueChanges({idField: 'id'})

    return this.mFirestore.collection(this.env.counseling + '/allSessions/groupSessions', ref => ref.orderBy('date', 'desc').where('centreCode', '==', selectedCentre.centreCode)).valueChanges({idField: 'id'})

  }


  fetchIndividualSessions() {
    return this.mFirestore.collection(this.env.counseling + '/allSessions/sessions').valueChanges({idField: 'id'})

  }
  fetchIndividualSessionsRegular() {
    return this.mFirestore.collection(this.env.counseling + '/allSessions/sessions',ref => ref.where(
      'type','==','Regular Counselling'
    )).valueChanges({idField: 'id'})

  }
  fetchIndividualSessionsRegularFrom2024(date:Date) {
    return this.mFirestore.collection(this.env.counseling + '/allSessions/sessions',ref => ref.where
      ('type', 'in', ['Regular Counselling', 'Follow-up Counselling'])
      .where('date','>=',date)
    ).valueChanges({idField: 'id'})

  }
  fetchAssessmentsFrom2024(date:Date) {
    return this.mFirestore.collection( 'Assessments/allAssessments/assessments',ref => ref.where
      ('type', 'in', ['Admission Baseline Assessment', 'Intermediate Assessment','Discharge Assessment'])
        .where('dateOfAssessment','>=',date)
    ).valueChanges({idField: 'id'})

  }

  fetchfollowupAssessmentsFrom2024(date:Date) {
    return this.mFirestore.collection('CounselingFollowUpAssessment',ref => ref
      .where('date','>=',date)
    ).valueChanges({idField: 'id'})

  }

  fetchIndividualSessions2024(date:Date) {
    return this.mFirestore.collection(this.env.counseling + '/allSessions/sessions',ref =>
      ref.where('sessionDate','>=',date
    )).valueChanges({idField: 'id'})

  }
  fetchIndividualSessionsByCentre(centreCode: any) {
    return this.mFirestore.collection(this.env.counseling + '/allSessions/sessions', ref => ref.where('centreCode', '==', centreCode)).valueChanges({idField: 'id'})

  }

  fetchIndividualSessionsByChild(selectedChildCode: string) {
    return this.mFirestore.collection(this.env.counseling + '/allSessions/sessions', ref => ref.
        orderBy('date','desc')
        .where('childCode', '==', selectedChildCode)).valueChanges({idField: 'id'})

  }

  fetchIndividualSessionsWithSorting(centreCode: string) {
    if (centreCode == 'All') return this.mFirestore.collection(this.env.counseling + '/allSessions/sessions', ref => ref.orderBy('date', 'desc')).valueChanges({idField: 'id'})
    else return this.mFirestore.collection(this.env.counseling + '/allSessions/sessions', ref => ref.orderBy('date', 'desc').where('centreCode', '==', centreCode)).valueChanges({idField: 'id'})

  }

  UploadTestCounselling(centreCode: string) {
    if (centreCode == 'All') return this.mFirestore.collection(this.env.counseling + '/allSessions/sessions').valueChanges({idField: 'id'})
    else return this.mFirestore.collection('CounsellingUploadTest', ref => ref.where('centreCode', '==', centreCode)).valueChanges({idField: 'id'})

  }

  editIndividualSession(form: ɵTypedOrUntyped<any, ɵFormGroupValue<any>, any>, id: string) {
    const batchWrite = this.mFirestore.firestore.batch()
    const childCode = form['childCode']
    //* We used Realtime Database only to get pushId in below line
    const dockey = id
    const childSessionRef = this.mFirestore.firestore.doc(`${this.env.counseling}/childSessions/${childCode}/${dockey}`)
    const allSessionRef = this.mFirestore.firestore.doc(`${this.env.counseling}/allSessions/sessions/${dockey}`)
    batchWrite.update(childSessionRef, form, {merge: true})
    batchWrite.update(allSessionRef, form, {merge: true})
    return batchWrite.commit()
  }

  editGroupSession(form: ɵTypedOrUntyped<any, ɵFormGroupValue<any>, any>, id: string) {
    const batchWrite = this.mFirestore.firestore.batch()
    // const childCode = form['childCode']
    //* We used Realtime Database only to get pushId in below line
    const dockey = id
    // const childSessionRef = this.mFirestore.firestore.doc(`${this.env.counseling}/childSessions/${childCode}/${dockey}`)
    const allSessionRef = this.mFirestore.firestore.doc(`${this.env.counseling}/allSessions/groupSessions/${dockey}`)
    // batchWrite.set(childSessionRef,form,{merge:true})
    batchWrite.update(allSessionRef, form, {merge: true})
    return batchWrite.commit()
  }

  editAssessment(form: any, id: string) {
    const childCode = form['childCode']
    const type = form['type']
    console.log(childCode)
    console.log(type)
    const batchWrite = this.mFirestore.firestore.batch()
    //* We used Realtime Database only to get pushId in below line
    const dockey = id
    const childSessionRef = this.mFirestore.firestore.doc(`${this.env.assessment}/childAssessment/${childCode}/${dockey}`)
    const allSessionRef = this.mFirestore.firestore.doc(`${this.env.assessment}/allAssessments/assessments/${dockey}`)
    batchWrite.update(childSessionRef, form, {merge: true})
    batchWrite.update(allSessionRef, form, {merge: true})

    return batchWrite.commit()
  }

  deleteIndividualSession(element: any) {
    const batchWrite = this.mFirestore.firestore.batch()
    const childCode = element.childCode
    //* We used Realtime Database only to get pushId in below line
    const dockey = element.id
    const childSessionRef = this.mFirestore.firestore.doc(`${this.env.counseling}/childSessions/${childCode}/${dockey}`)
    const allSessionRef = this.mFirestore.firestore.doc(`${this.env.counseling}/allSessions/sessions/${dockey}`)
    batchWrite.delete(childSessionRef)
    batchWrite.delete(allSessionRef)
    return batchWrite.commit()
  }

  deleteGroupAssessment(element: any) {
    const batchWrite = this.mFirestore.firestore.batch()
    // const childCode = form['childCode']
    //* We used Realtime Database only to get pushId in below line
    const dockey = element.id
    // const childSessionRef = this.mFirestore.firestore.doc(`${this.env.counseling}/childSessions/${childCode}/${dockey}`)
    const allSessionRef = this.mFirestore.firestore.doc(`${this.env.counseling}/allSessions/groupSessions/${dockey}`)
    // batchWrite.set(childSessionRef,form,{merge:true})
    batchWrite.delete(allSessionRef)
    return batchWrite.commit()
  }

  deleteAssessment(element: any) {
    const childCode = element['childCode']
    const type = element['type']
    console.log(childCode)
    console.log(type)
    const batchWrite = this.mFirestore.firestore.batch()
    //* We used Realtime Database only to get pushId in below line
    const dockey = element.id
    const childSessionRef = this.mFirestore.firestore.doc(`${this.env.assessment}/childAssessment/${childCode}/${dockey}`)
    const allSessionRef = this.mFirestore.firestore.doc(`${this.env.assessment}/allAssessments/assessments/${dockey}`)
    batchWrite.delete(childSessionRef)
    batchWrite.delete(allSessionRef)

    return batchWrite.commit()
  }

  fectchAssessmentsByChild(selectedChildCode: string) {
    return this.mFirestore.collection<AssessmentModel>('Assessments/allAssessments/assessments/', ref => ref.where('childCode', '==', selectedChildCode)).valueChanges({idField: 'id'})
  }
  CounselingFollowUpAssessmentByChild(selectedChildCode: string) {
    return this.mFirestore.collection<AssessmentModel>('CounselingFollowUpAssessment', ref => ref
        .orderBy('date','desc')
        .where('childCode', '==', selectedChildCode)).valueChanges({idField: 'id'})
  }
  updateCentreName(value: any) {
    this.centreDataSource.next(value);
  }

  fetchIndividualSessionsWithSortingByDate(centreCode: string, start: Date | undefined, end: Date) {
    if (centreCode == 'All') return this.mFirestore.collection(this.env.counseling + '/allSessions/sessions', ref => ref.orderBy('date', 'desc').where('date', '>=', start).where('date', '<=', end)).valueChanges({idField: 'id'})
    else return this.mFirestore.collection(this.env.counseling + '/allSessions/sessions', ref => ref.orderBy('date', 'desc').where('centreCode', '==', centreCode).where('date', '>=', start).where('date', '<=', end)).valueChanges({idField: 'id'})

  }
}
