
<h1 class="bg-warning text-center rounded">
  {{ isUpdateMode ? 'Update Fuel Details Form' : 'Add Fuel Details Form' }}
</h1>
<form [formGroup]="carForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <mat-form-field appearance="outline" class="col-md-4">
      <mat-label>Choose a date</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="date">
      <mat-hint>dd/MM/yyyy</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="carForm.get('date')?.invalid && carForm.get('date')?.touched">
        Date is required.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-md-4">
      <mat-label>Qty Filled:</mat-label>
      <input matInput type="text" formControlName="totalQuantityField" />
      <mat-error *ngIf="carForm.get('totalQuantityField')?.invalid && carForm.get('totalQuantityField')?.touched">
        <ng-container *ngIf="carForm.get('totalQuantityField')?.errors?.required">Qty is required.</ng-container>
        <ng-container *ngIf="carForm.get('totalQuantityField')?.errors?.pattern">Only numbers are allowed.</ng-container>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-md-4">
      <mat-label>Meter Reading:</mat-label>
      <input matInput type="text" formControlName="meterReading" />
      <mat-error *ngIf="carForm.get('meterReading')?.invalid && carForm.get('meterReading')?.touched">
        <ng-container *ngIf="carForm.get('meterReading')?.errors?.required">Meter reading is required.</ng-container>
        <ng-container *ngIf="carForm.get('meterReading')?.errors?.pattern">Only numbers are allowed.</ng-container>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-md-4">
      <mat-label>Vehicle Number:</mat-label>
      <mat-select formControlName="vehicleNumber">
        <mat-option *ngFor="let model of vehicles" [value]="model.vehicleNumber">
          {{ model.vehicleNumber }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="carForm.get('vehicleNumber')?.invalid && carForm.get('vehicleNumber')?.touched">
        Vehicle Number is required.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-md-4">
      <mat-label>Driver Name:</mat-label>
      <mat-select formControlName="driverName">
        <mat-option *ngFor="let model of drivers" [value]="model.firstName + ' ' + model.lastName">
          {{ model.firstName }} {{ model.lastName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="carForm.get('driverName')?.invalid && carForm.get('driverName')?.touched">
        Driver Name is required.
      </mat-error>
    </mat-form-field>
  </div>
  <div class="d-flex justify-content-center mb-4 gap-3">
    <button mat-raised-button color="primary" class="mb-4"  type="submit" (click)="onSubmit()" [disabled]="carForm.invalid">{{ isUpdateMode ? 'Update' : 'Submit' }}</button>
    <button mat-raised-button color="accent" class="mb-4"  type="button" (click)="cancel()">Cancel</button>
  </div>
</form>
