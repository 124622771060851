import { Component, OnInit } from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
newPassword=''
  uid=''
  constructor(private afAuth: AngularFireAuth, private http: HttpClient) { }


  ngOnInit(): void {
  }
  changePassword() {


    const currentUser = this.uid;
    if (!currentUser) {
      // Handle not authenticated error
      return;
    }

    const data = { newPassword: this.newPassword ,
    uid:this.uid};

    this.http.post<any>('/api/changePassword', data) // Replace with your Cloud Function URL
      .subscribe(response => {
        console.log('Password changed successfully:', response.message);
        // Handle successful password change (e.g., clear form, show success message)
      }, error => {
        console.error('Error changing password:', error);
        // Handle errors from the Cloud Function
      });
  }
}
