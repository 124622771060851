import {Component,  isDevMode, OnInit} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import {Event, Router, NavigationStart, NavigationEnd} from '@angular/router';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {IpService} from "./services/ip.service";
import {Analytics} from "@angular/fire/analytics";
import {AuthService, UserData} from "./auth.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {FullChild} from "./interfaces/FullChild";
import {Centre} from "./interfaces/Centres";
import firebase from "firebase/compat/app";
import {Donor} from "./components/donoradmin/donor-table/donor-table.component";
import {map} from "rxjs/operators";
import {environment} from "../environments/environment";


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    currentUrl: string = '';
    ipAddress: string = '';
    customerName: string = 'fsf';
  env = isDevMode() ? environment.testMode : environment.productionMode

    constructor(
        private title: Title,
        private router: Router,
        private mAuth: AngularFireAuth,
        private mDatabase: AngularFireDatabase,
        private mAuthService: AuthService,
        private readonly mFirestore: AngularFirestore,
        private ipService: IpService,

    ) {
        title.setTitle(isDevMode() ? 'Development' : 'Accesslife');
        this.setupRouterEvents();

    }

    ngOnInit(): void {
        // this.CopyData('ALCentres','devCentre')
        this.getIpAddress();
        this.initializeUserData();
        // this.addGenderToIndividualCounselling()
        // this.setSponsorshipStatus();
        // this.bulkState('Maharshtra','Maharashtra')
        // this.bulkCenterChange('AL Gurunanak','AL Gurunanak Niwas')
        // this.addGenderToIn dividualCounselling()
        // this.replaceInterventionToArray()
    // this.shiftDonorstoALDonors()
   // this.setBulkAge()
   //    this.setBulkAge()
   //      this.addGenderToIndividualCounselling()
// this.bulkUploadEducationReview()
    }
    bulkUploadEducationReview(){
      let tDate = new Date()
        tDate.setDate(31)
        tDate.setMonth(4)
        tDate.setFullYear(2023)
      this.mFirestore.collection('educationTest',ref => ref.where('sessionDate','<=',tDate)).get().subscribe(res=>{
          res.docs.forEach(doc=>{

              this.mFirestore.collection(this.env.education).doc(doc.id).update({
                  reviewedBy:'Dwani Gujral',
                  status:'Verified',
                  reviewRemark:'Bulk Verification from Database'
              }).then(r=>{
                  console.log(`EDUCATION GROUP SESSION UPDATED ${doc.id}`)
              })
          })
      })
    }
  setUnitsBulk(childArray: string[]) {
    childArray.forEach(it => {
      this.mFirestore.collection<FullChild>('ALChild').doc(it).valueChanges().subscribe(child => {
        console.log(it, child?.childCode)
        this.mDatabase.object(`ALUnits/${child?.centreCode}/${child?.selectUnit}`).update(
          {
            allotedTo: child?.childCode,
            centreCode: child?.centreCode,
            name: child?.firstName + ' ' + child?.lastName,
            status: 'Alloted'
          }
        ).then(() => {
          console.log('Updated', 'alloted' + child?.selectUnit)
        })
      })

    })

  }
  getCalculatedAge(child: firebase.firestore.Timestamp, childCode: string) {
    let timeDiff = Math.abs(Date.now() - (child).toDate().getTime());
    let age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
    // * UPDATING AGE IN FIRESTORE ALCHILD
    this.mFirestore.collection(this.env.child).doc(childCode).update({age: age}).then(() => {
      console.log(`SET DATE TO ${childCode} as`, age)
    })
      .catch(() => {
        console.log('ERROR ADDING AGE IN', childCode)
      })
    console.log(age)
    return age
  }

  setBulkAge() {
    this.mFirestore.collection<FullChild>(this.env.child).get().subscribe(res => {
      alert(res.docs.length)

      res.forEach(it => {
          let c = it.data()
          console.log(c.dob)
          if (c.dob) this.getCalculatedAge(c.dob, c.childCode)
          else console.log('DOB NOT AVAILABLE IN ', c.childCode)
        }
      )
    })
  }



    private setupRouterEvents(): void {
        this.router.events.subscribe((routerEvent: Event) => {
            if (routerEvent instanceof NavigationStart) {
                this.currentUrl = routerEvent.url.substring(
                    routerEvent.url.lastIndexOf('/') + 1
                );
            }
            if (routerEvent instanceof NavigationEnd) {
                // Add any additional logic for NavigationEnd events here.
            }
            window.scrollTo(0, 0);
        });
    }

    private getIpAddress(): void {
        this.ipService.getIpAddress().subscribe((response: any) => {
            this.ipAddress = response.ip;
            localStorage.setItem('currentip', this.ipAddress);
        });
    }

    private initializeUserData(): void {
        const uid = localStorage.getItem('uid');
        const permissions = localStorage.getItem('permissions');

        if (!uid || !permissions) {
            localStorage.clear();
            this.router.navigateByUrl('login').then(() => {
            });
        } else {
            this.mDatabase.object(`users/${uid}`).valueChanges().subscribe(res => {
                const userData = res as UserData;
                localStorage.setItem('ROLE', userData.role);
                if (userData.permissions) localStorage.setItem('permissions', JSON.stringify(userData.permissions));
                //         console.log('JSON PERMISSIONS', JSON.parse(localStorage.getItem('permissions')!));
            });
        }
    }

    private addGenderToIndividualCounselling() {
        this.mFirestore.collection('Counselling/allSessions/sessions').get().subscribe(res => {
            let sessions = res.docs.map(doc => {
                // @ts-ignore
                return {id: doc.id, ...doc.data()};
            });
            let nullsessions = sessions.filter(x => x.state == null || x.state == '')
            console.log()
            console.log(sessions); // or do whatever you need with the sessions array
            nullsessions.forEach(it => {
                this.mFirestore.collection<FullChild>('ALChild').doc(it.childCode).get().subscribe(res => {
                    let childData = res.data()

                    this.mFirestore.collection('Counselling/allSessions/sessions').doc(it.id).update({state: childData?.state}).then(res => {
                            console.log('Updated in record', it.id)
                        }
                    )
                })
            })
        });
    }

    private bulkState(maharshtra: string, maharashtra: string) {
        this.mFirestore.collection<FullChild>('ALChild', ref => ref.where('state', '==', 'Gujrat ')).get().subscribe(res => {
            let mData = res.docs.map(y => y.data())
            alert(mData.length)
            mData.forEach(it => {
                this.mFirestore.collection('ALChild').doc(it.childCode).update({'state': 'Gujarat'}).then(res => {
                    console.log('Updated state in ' + it.childCode)
                })
            })
        })
    }

    private bulkCenterChange(field1: string, field2: string) {
        this.mFirestore.collection<FullChild>('ALChild', ref => ref.where('centreName', '==', field1)).get().subscribe(res => {
            let mData = res.docs.map(y => y.data())
            alert(mData.length)
            mData.forEach(it => {
                this.mFirestore.collection('ALChild').doc(it.childCode).update({'centreName': field2}).then(res => {
                    console.log('Updated cetnreNames in ' + it.childCode)
                })
            })
        })
    }


    private CopyData(alChild: string, devChild: string) {
        this.mFirestore.collection<Centre>(alChild).get().subscribe(res => {
            res.docs.forEach(it => {
                let child = it.data()
                this.mFirestore.collection(devChild).doc(it.data()!!.centreCode).set(child)
                    .then(res => {
                        console.log('Added Child with child code', child.centreCode)
                    })
            })
        })
    }

    private replaceInterventionToArray() {
        this.mFirestore.collection<any>('Counselling/allSessions/sessions').get().subscribe(querySnapshot => {
            querySnapshot.forEach(doc => {
                // Extract the value of the "interventions" field
                const interventionString: string = doc.data().interventions || '';

                // Check if interventions field is not null, empty, or undefined
                if (interventionString) {
                    // Update the document with interventions array
                    this.mFirestore.collection('Counselling/allSessions/sessions').doc(doc.id).update(
                        { interventions: firebase.firestore.FieldValue.arrayUnion(interventionString.toString()) }
                    ).then(() => {
                        console.log('Updated interventions in ' + doc.id);
                    }).catch(error => {
                        console.error('Error updating interventions:', error);
                    });
                }
            });
        });
    }

    private shiftDonorstoALDonors() {
        this.mFirestore.collection('donors').get()

            .pipe(map(x=>x.docs.map(y=>y.data()as Donor))).subscribe(res=>{
            res.forEach(it=>{
                this.mFirestore.collection('ALDonors').doc(it.pan!!).set(it).then(res=>{
                    console.log('Donor addded',it.pan)
                })

            })
        })
    }
//     setSponsorshipStatus() {
//
//         this.mFirestore.collection<FullChild>('ALChild')
//             .valueChanges()
//             .subscribe(child => {
//
//                 let children = child.filter(x=> x.sponsored ==undefined)
//                 alert('set sponsorship status'+children.length)
// children.forEach(it=>{
//     // console.log('TOTAL UNSPONSORED CHILDREN',child.docs.length)
// //     this.mFirestore.collection('ALChild').doc(it.childCode).update({sponsored: false}).then(res => {
// //     console.log('added sponsored status to '+it.id)
// // })
//             })
//             })
//     }
}
function onCopy(event: globalThis.Event | undefined, ClipboardEvent: { new(type: string, eventInitDict?: ClipboardEventInit | undefined): ClipboardEvent; prototype: ClipboardEvent; }) {
    throw new Error('Function not implemented.');
}

