import {Guardian} from "./Guardian";
import {Sibling} from "../models/Sibling";
import {AdmissionType} from "./AdmissionType";
import {UploadFile} from "./uploadFile";
import firebase from "firebase/compat";
import Timestamp = firebase.firestore.Timestamp;
import {ChildObject} from "../components/events/events.component";
import {FormControl, Validators} from "@angular/forms";

export interface FullChild {
  mothername: string;
  centres: any;
  id?: string;
  alloted?: boolean
  childCode: string;
  centreCode: string;
  baseCentreCode: string;
  age:number;
  ageAsOnAdmission:number
  admissionData:AdmissionType[]
  medical_history:any[]
  baseCentreName: string;
  centreName: string;
  firstName: string;
  allergiesSelected: string[];
  diagnosisSelected: any[];
  middleName: string;
  heightin: string;
  lastName: string;
  bloodGroup: string;
  dob: Timestamp;
  gender: string;
  mobile: string;
  address: string;
  district: string;
  city: string;
  country: string;
  state: string;
  sponsored:false
  pincode: string;
  childAadhaar: string;
  hobbies: string;
  allergies: string;
  aspirations: string;
  // Basic Info
  selectCentre: string;
  stayedBefore: string;
  selectUnit: string;  //this is original
  currentUnit: string;  //this is wrong
  bag: string;
  guardianStayStatus: string;
  dateOfAdmission: Timestamp;

  //Basic Info End
  // Doc Section
  documents: string;
  drivingLicense: string;
  rationCard: string;
  electionId: string;
  // Doc Section End
//Guardian Info
  guardians: Guardian[],
  siblings: Sibling[],

  doctorName: string;
  fileNo: string;
  socialName: string;
  status:string
  isAlloted:boolean
  height: string;
  weight: string;
  prescribed: string;
  sickness: string;
  treatment: string;
  emergencyContactName:string;
  emrgencyContactNo:number
  medical: string;
  hospital: string;
  // allergies: new FormControl('', [Validators.required]),
  diagnosis: string;
  months: string;
  days: string;
  q1: string;
  q2: string;
  q3: string;
  q4: string;
  q5: string;
  q6: string;
  q7: string;
  q8: string;
  q9: string;
  events?: Events[];

  //Discharge Options
  dischargeDate?:Timestamp;
  dateOfDischarge?:Timestamp;
  followUpDate?:Date;
  reason?:string;
  remark?:string;
expired?:boolean;
  emergencyContactName1: string,
  emergencyContactRelation1: string,
  emergencyContactNumber1:string,
  emergencyContactName2: string,
  emergencyContactRelation2: string,
  emergencyContactNumber2: string,


admissionDocuments?:UploadFile[]
dischargeDocuments?:UploadFile[]
followUpAdmissionDocuments?:UploadFile[]
followUpDischargeDocuments?:UploadFile[]

}

export interface Events {
  description: string
  id: number
  res_timing: Date
  hash_tags:string
  res_type: string
  res_url: string
  title: string
  isBMI?:boolean;
  height?:string;
  child_codes:ChildObject[]
  event_name?:string;
  bmi?:string;
  weight?:string;



}

export interface ChildData {
  id?: string;
  alloted?: boolean
  childCode: string;
  centreCode: string;
  baseCentreCode: string;
  type?:string
  // currentCentreCode?:string;
  // currentCentreName?:string;
  age:number;
  ageAsOnAdmission:number
  admissionData:AdmissionType[]
  medical_history:any[]
  baseCentreName: string;
  centreName: string;
  admissionCycle?:number
  firstAdmission?:Timestamp
  firstDischarge?:Timestamp
  firstName: string;
  allergiesSelected: string[];
  diagnosisSelected: any[];
  middleName: string;
  heightin: string;
  lastName: string;
  bloodGroup: string;
  dob: Timestamp;
  gender: string;
  mobile: string;
  address: string;
  district: string;
  city: string;
  country: string;
  state: string;
  sponsored:false
  pincode: string;
  childAadhaar: string;
  hobbies: string;
  allergies: string;
  aspirations: string;
  // Basic Info
  selectCentre: string;
  stayedBefore: string;
  selectUnit: string;  //this is original
  currentUnit: string;  //this is wrong
  bag: string;
  guardianStayStatus: string;
  dateOfAdmission: Timestamp;

  //Basic Info End
  // Doc Section
  documents: string;
  drivingLicense: string;
  rationCard: string;
  electionId: string;
  // Doc Section End
//Guardian Info
  guardians: Guardian[],
  siblings: Sibling[],

  doctorName: string;
  fileNo: string;
  socialName: string;
  status:string
  isAlloted:boolean
  height: string;
  weight: string;
  prescribed: string;
  sickness: string;
  treatment: string;
  emergencyContactName:string;
  emrgencyContactNo:number
  medical: string;
  hospital: string;
  // allergies: new FormControl('', [Validators.required]),
  diagnosis: string;
  months: string;
  days: string;
  q1: string;
  q2: string;
  q3: string;
  q4: string;
  q5: string;
  q6: string;
  q7: string;
  q8: string;
  q9: string;
  events?: Events[];

  //Discharge Options
  dischargeDate?:Timestamp;
  dateOfDischarge?:Timestamp;
  followUpDate?:Timestamp;
  reason?:string;
  remark?:string;
  expired?:boolean;
  emergencyContactName1: string,
  emergencyContactRelation1: string,
  emergencyContactNumber1:string,
  emergencyContactName2: string,
  emergencyContactRelation2: string,
  emergencyContactNumber2: string,


  admissionDocuments?:UploadFile[]
  dischargeDocuments?:UploadFile[]
  followUpAdmissionDocuments?:UploadFile[]
  followUpDischargeDocuments?:UploadFile[]

}
