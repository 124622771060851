import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from '@angular/material/dialog';
import {ChildService} from "../child.service";
import {MAT_DATE_FORMATS} from "@angular/material/core";
import {MY_FORMATS} from "../../../utils/formats";
import {MatSnackBar} from "@angular/material/snack-bar";

import {needConfirmation} from "../../../confirm-dialog/confirm-dialog.decorator";
import {Observable} from "rxjs";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {Hospitals} from "../../../interfaces/Hospitals";
import {ListType} from "../../types/types.component";
import statesdata from "../../../../assets/states.json";
import {States} from "../../../interfaces/States";
import {TitleCasePipe} from "@angular/common";
import {LogService} from "../../../services/log.service";

@Component({
    selector: 'app-child-create',
    templateUrl: './child-create.component.html',
    styleUrls: ['./child-create.component.scss'],
    providers: [
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
    ]
})

export class ChildCreateComponent implements OnInit {
    hospitals$: Observable<Hospitals[]>
    childForm: any;
    submitted = false;
    stateslist = statesdata as States[]
  userData = JSON.parse(localStorage.getItem('userData')!)
    _diagnosis$: Observable<ListType[]>

    constructor(
        public dialogRef: MatDialogRef<ChildCreateComponent>,
        private childService: ChildService,
        private titlecasePipe: TitleCasePipe,
        private readonly mDatabase: AngularFireDatabase,
        private readonly mLogService: LogService,
        private _snackBar: MatSnackBar
    ) {
        this.hospitals$ = this.mDatabase.list<Hospitals>('Hospitals').valueChanges()
        this._diagnosis$ = this.mDatabase.list<ListType>('types/Diagnosis').valueChanges()

    }

    ngOnInit(): void {
        this.childForm = new FormGroup({
            // "centrecode": new FormControl('', [Validators.required, Validators.pattern('[0-9 ]*')]),
            // "childcode": new FormControl('', [Validators.required, Validators.pattern('[0-9 ]*')]),
            // "centreno": new FormControl('', [Validators.required, Validators.pattern('[0-9 ]*')]),
            // "regis": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
            "firstName": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
            "lastName": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
            "middleName": new FormControl('',),
            "age": new FormControl('', [Validators.required]),
            "mobile": new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(10), Validators.maxLength(10)]),
            "hospitalname": new FormControl('', [Validators.required]),
            "state": new FormControl('', [Validators.required]),
            // "pincode": new FormControl('', [Validators.maxLength(6),Validators.minLength(6)]),
            "createdAt": new FormControl(new Date()),
            "status": new FormControl('Active'),
            "city": new FormControl('', [Validators.required]),
            "district": new FormControl('', [Validators.required]),
            "remark": new FormControl('', [Validators.required]),
            "diagnosis": new FormControl('', [Validators.required,]),
            "gender": new FormControl('', [Validators.required]),
            "dateOfClosure": new FormControl(null),
            "reasonOfClosure": new FormControl('', ),
            "createdBy": new FormControl(this.userData.userName, ),
            q1: new FormControl(''),
            q2: new FormControl(''),
            q3: new FormControl(''),
            q4: new FormControl(''),
            q5: new FormControl(''),
            q6: new FormControl(''),
            q7: new FormControl(''),
            q8: new FormControl(''),
            q9: new FormControl(''),
        })
        //** TO GET PRESCREENING CHILD
        // this.childService.fetchPreScreeningChild().subscribe((snapshot) => {
        //   console.log(snapshot.data())
        //   this.childForm.setValue(snapshot.data())
        // })

    }

    @needConfirmation()
    SubmitData() {
//first sumbit data
        this.submitted = true;
        //Ideal Code Should be like this
        if (this.childForm.valid) {
            console.log(this.childForm.value)
            // then submit data to service
            this.childService.addTempChild(this.childForm.value)
            // then close dialog
            let activity = {
                date: new Date().getTime(),
                section: 'Prescreening',
                action: 'Submit',

                description: 'Child added to prescreening ',
                currentIp: localStorage.getItem('currentip')!
            }
            this.mLogService.addLog(activity)
            this.dialogRef.close()
        } else if (!this.childForm.valid) {
            this.submitted = false;
            this._snackBar.open("Form is Invalid , Please fill  form properly!", '', {duration: 3000})
            this.findInvalidControls()
        }
    }

    public findInvalidControls() {
        const invalid = [];
        const controls = this.childForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        return invalid;
    }

    cancel() {
        this.dialogRef.close()
        this.submitted = false;
        console.log(this.childForm.reset())
    }

    get centrecode() {
        return this.childForm.get('centrecode');
    }

    get childcode() {
        return this.childForm.get('childcode');
    }

    get centreno() {
        return this.childForm.get('centreno');
    }

    get regis() {
        return this.childForm.get('regis');
    }

    get firstname() {
        return this.childForm.get('firstName');
    }

    get lastname() {
        return this.childForm.get('lastName');
    }

    get middlename() {
        return this.childForm.get('middleName');
    }


    get mobile() {
        return this.childForm.get('mobile');
    }


    get hospitalname() {
        return this.childForm.get('"HospitalName');
    }

    get state() {
        return this.childForm.get('state');
    }

    get district() {
        return this.childForm.get('district');
    }

    get remark() {
        return this.childForm.get('remark');
    }

    get diagnosis() {
        return this.childForm.get('diagnosis');
    }

    get city() {
        return this.childForm.get('city');
    }

    get Pincode() {
        return this.childForm.get('pincode');
    }

    get District() {
        return this.childForm.get('district');
    }

    fetchTest() {
        this.mDatabase.list('list')
    }


    onBlur(formControl: any) {
        if (formControl.value !== null) {
            formControl.setValue(this.titlecasePipe.transform(formControl.value.toString().trim().toUpperCase()))
        }
    }

}
