<!-- AddVehiclesComponent HTML -->
<div class="card p-2">
  <div class="ngxTableHeader">
    <ul class="header-buttons-left ms-0 mb-0">
      <li class="dropdown">
        <h2>Vehicles</h2>
      </li>
          <li class="dropdown m-l-20">
            <label for="search-input"><i class="material-icons search-icon">search</i></label>
            <input placeholder="Search" type="text" class="browser-default search-field"
                   (keyup)='applyFilter($event)' aria-label="Search box" #input>
          </li>
    </ul>
    <ul class="header-buttons mr-2">

      <li>
        <button *ngIf="vehicle[0].all || vehicle[0].add" mat-mini-fab color="primary" (click)="openVehicle()">
          <mat-icon class="col-white">add</mat-icon>
        </button>
      </li>

      <!--      <li>-->
      <!--        <div class="export-button m-l-10" matTooltip="XLSX">-->
      <!--          <img src="assets/images/icons/xlsx.png" alt=""/>-->
      <!--        </div>-->
      <!--      </li>-->
    </ul>
  </div>

  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

      <!-- Vehicle Name Column -->
      <ng-container matColumnDef="vehicleName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Vehicle Name</th>
        <td mat-cell *matCellDef="let element"> {{element.vehicleName}} </td>
      </ng-container>

      <!-- Vehicle Number Column -->
      <ng-container matColumnDef="vehicleNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > Vehicle Number</th>
        <td mat-cell *matCellDef="let element"> {{element.vehicleNumber}} </td>
      </ng-container>
      <!-- Vehicle Number Column -->
      <ng-container matColumnDef="tripStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Trip Status</th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container>


      <!-- Vehicle Brand Column -->
      <ng-container matColumnDef="vehicleBrand">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Vehicle Brand</th>
        <td mat-cell *matCellDef="let element"> {{element.vehicleBrand}} </td>
      </ng-container>
      <!-- Vehicle Brand Column -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Location</th>
        <td mat-cell *matCellDef="let element"> {{element.location}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action</th>
        <td mat-cell *matCellDef="let element">
          <button *ngIf="vehicle[0].view || vehicle[0].all" mat-icon-button color="accent" matTooltip="View" (click)="openView(element)" matTooltip="View">	<mat-icon>visibility</mat-icon></button>
          <button *ngIf="vehicle[0].edit || vehicle[0].all" mat-icon-button (click)="editVehicle(element)" color="primary" matTooltip="Edit"><mat-icon>edit</mat-icon></button>
          <button mat-icon-button *ngIf="vehicle[0].archive || vehicle[0].all" (click)="deleteVehicle(element)" color="warn" matTooltip="delete"><mat-icon>delete</mat-icon></button>
       </td>
     </ng-container>

     <!-- Other Columns... -->

      <!-- Table Row -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [length]="200" [pageSizeOptions]="[25, 50, 100, 200, 500]" aria-label="Select page">
    </mat-paginator>
  </div>
</div>
