import {Injectable, isDevMode} from '@angular/core';
import {ɵFormGroupValue, ɵTypedOrUntyped} from "@angular/forms";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {FullChild} from "../../interfaces/FullChild";
import {el} from "@fullcalendar/core/internal-common";
import firebase from "firebase/compat";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class EducationService {
    env = isDevMode() ? environment.testMode : environment.productionMode

    constructor(private readonly mFireStore: AngularFirestore) {
    }

    individualSearchResults(centreCode: string, startDate?: Date | undefined, endDate?: Date | undefined, attendance?: string | null, submittedBy?: string | null, sessionType?: string | null,vounteer?:string){
        console.log('centercode',centreCode)
        let myQuery :firebase.firestore.Query<any>=this.mFireStore.firestore.collection(this.env.education).orderBy('sessionDate','desc')
    if(centreCode!='All' && centreCode)myQuery = myQuery.where('centreCode','==',centreCode)
    if(startDate && endDate)myQuery= myQuery.where('sessionDate','>=',startDate).where('sessionDate','<=',endDate)
    if(attendance && attendance!='All')myQuery = myQuery.where('present','==',attendance)
    if(submittedBy && submittedBy!='All')myQuery= myQuery.where('submittedBy','==',submittedBy)
    if(sessionType && sessionType!='All')myQuery= myQuery.where('sessionType','==',sessionType)
    if(vounteer && vounteer!='All')myQuery= myQuery.where('volunteerName','==',vounteer)
return myQuery.get()
}


    addIndividualSession(value: ɵTypedOrUntyped<any, ɵFormGroupValue<any>, any>) {
        return this.mFireStore.collection(this.env.education).add(value)
    }

    updateIndividualSession(value: ɵTypedOrUntyped<any, ɵFormGroupValue<any>, any>, id: string) {
        return this.mFireStore.collection(this.env.education).doc(id).update(value)
    }

    addGroupEducationSession(value: ɵTypedOrUntyped<any, ɵFormGroupValue<any>, any>) {
        return this.mFireStore.collection(this.env.educationGroup).add(value)
    }

    editGroupEducationSession(value: ɵTypedOrUntyped<any, ɵFormGroupValue<any>, any>, id: string) {
        return this.mFireStore.collection(this.env.educationGroup).doc(id).update(value)
    }

    getEducation(centerCode: string) {

       let q= this.mFireStore.collection<any>(this.env.education, ref => ref.orderBy('sessionDate', 'desc').where('centreCode', '==', centerCode)).valueChanges({idField: 'id'})

      if(centerCode=='All') q=  this.mFireStore.collection<any>(this.env.education, ref => ref.orderBy('sessionDate', 'desc')).valueChanges({idField: 'id'})
        return q
    }

    getIndividualEducationList() {
        return this.mFireStore.collection<any>(this.env.education, ref => ref.orderBy('sessionDate', 'desc')).valueChanges({idField: 'id'})
    }
  getIndividualEducationListPresentOnly2024(date:Date) {
    return this.mFireStore.collection<any>(this.env.education, ref => ref.orderBy('sessionDate', 'desc')
        .where('sessionDate','>=',date)
        .where('present','==','Present')
      ).valueChanges({idField: 'id'})
  }

  getIndividualEducationListPresentOnly2024Borivali(date:Date) {
    return this.mFireStore.collection<any>(this.env.education, ref => ref.orderBy('sessionDate', 'desc')
      .where('sessionDate','>=',date)
      .where('present','==','Present')
      .where('centreCode','==','ALBorivali')
    ).valueChanges({idField: 'id'})
  }

  getIndividualEducationList2024(date:Date) {
    return this.mFireStore.collection<any>(this.env.education, ref => ref.orderBy('sessionDate', 'desc')
      .where('sessionDate','>=',date)).valueChanges({idField: 'id'})
  }
    getIndividualEducationListByCenter(centreCode: string | undefined) {
        if (centreCode != 'All') {
            return this.mFireStore.collection<any>(this.env.education, ref => ref
                .where('centreCode', '==', centreCode)
                .orderBy('sessionDate', 'desc')).valueChanges({idField: 'id'})
        } else return this.mFireStore.collection<any>(this.env.education, ref => ref
            // .where('centreCode','==',centreCode)
            .orderBy('sessionDate', 'desc')).valueChanges({idField: 'id'})

    }
    getIndividualSessionByCentreOrDate(centreCode:string,start:Date,end:Date){
        if (centreCode != 'All') {
            return this.mFireStore.collection<any>(this.env.education, ref => ref
                .where('centreCode', '==', centreCode)
                .orderBy('sessionDate', 'desc')
                .where('sessionDate','>=',start)
                .where('sessionDate','<=',end)
            )

                .valueChanges({idField: 'id'})
        } else return this.mFireStore.collection<any>(this.env.education, ref => ref
            // .where('centreCode','==',centreCode)
            .orderBy('sessionDate', 'desc')).valueChanges({idField: 'id'})


    }
    getIndividualEducationListByCenterByDate(centreCode: string | undefined, startDate: Date, endDate: Date) {
        return this.mFireStore.collection<any>(this.env.education, ref => ref.orderBy('sessionDate', 'desc').where('centreCode', '==', centreCode)).valueChanges({idField: 'id'})
    }
    getGroupEducationSessions(centerCode: string, selectedCentre: any) {
    let q= this.mFireStore.collection<any>(this.env.educationGroup, ref => ref
            .orderBy("sessionDate", "desc")
            .where('centres', 'array-contains', selectedCentre))
            .valueChanges({idField: 'id'})

     if(centerCode =='All') {
         q = this.mFireStore.collection<any>(this.env.educationGroup, ref => ref
             .orderBy("sessionDate", "desc"))
             // .where('centres', 'array-contains', selectedCentre))
             .valueChanges({idField: 'id'})

     }
        return q
    }


    getGroupEducationSessions2() {
        return this.mFireStore.collection<any>(this.env.educationGroup).valueChanges({idField: 'id'})
    }
  getGroupEducationSessions2024(date:Date) {
    return this.mFireStore.collection<any>(this.env.educationGroup,ref => ref.where('sessionDate','>=',date)).valueChanges({idField: 'id'})
  }
  getGroupEducationSessions2024Borivali(date:Date) {
    return this.mFireStore.collection<any>(this.env.educationGroup,ref => ref.where('sessionDate','>=',date)
      .where('centreCode','==','ALBorivali')
    ).valueChanges({idField: 'id'})
  }
    getGroupEducationSessionsByCenter(childObject: any,) {
        return this.mFireStore.collection<any>(this.env.educationGroup, ref => ref.orderBy('sessionDate', 'desc').where('children', 'array-contains', childObject)).valueChanges({idField: 'id'})
    }

    getGroupEducationSessionsByCenterCode(centreObject: any,) {
        return this.mFireStore.collection<any>(this.env.educationGroup, ref => ref.orderBy('sessionDate', 'desc').where('centres', 'array-contains', centreObject)).valueChanges({idField: 'id'})
    }

    deleteIndividualSession(element: any) {
        return this.mFireStore.collection(this.env.education).doc(element.id).delete()

    }

    deleteGroupSession(element: any) {
        return this.mFireStore.collection(this.env.educationGroup).doc(element.id).delete()

    }

    getIndividualSessionsOfAChild(selectedChild: FullChild) {
        return this.mFireStore.collection<any>(this.env.education, ref => ref.where('childCode', '==', selectedChild.childCode)).valueChanges({idField: 'id'})

    }

    getLastDischargeAssessmentByChild(childCode: string) {
       return this.mFireStore.collection(environment.productionMode.education,ref => ref.where('childCode','==',childCode).where('sessionType','==','Discharge Assessment')).valueChanges()
    }

  getTotalSessions(childCode: string,subject:string) {
    return this.mFireStore.collection(environment.productionMode.education,ref => ref.where('childCode','==',childCode).where('present','==','Present').where('subject','array-contains',subject)).valueChanges()
  }

  getChildIndividualDischargeSession(childCode: string) {
   return   this.mFireStore.collection(environment.productionMode.education,ref => ref.where('sessionType','==','Discharge Assessment').where('childCode','==',childCode)).valueChanges()
  }

    dischargeAssssmentSessionChildren(centreCode:string) {
return      this.mFireStore.collection('educationTest',ref => ref.where('centreCode','==',centreCode).where('sessionType','==','Discharge Assessment')).valueChanges()

    }
}
