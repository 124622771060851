
<div class="container mt-5 mis-dashboard">

  <!--    totalUnits {{totalUnits}}-->
  <!--   totalAvailableUnits  {{totalAvailableUnits}}-->
  <!--   totalOccupiedUnits  {{totalOccupiedUnits}}-->
  <!--    OCCUPANCY REPORT-->
  <!--{{mStartDate}}-->
  <!--    {{mEndDate}}-->
  <div class="row " >
    <div class="row mis-dashboard">
      <!*--------------------------------------------- OCCUPANCY TABLE--------------------------------------------->
      <!*--------------------------------------------- OCCUPANCY TABLE--------------------------------------------->

      <!*--------------------------------------------- Allocation Details--------------------------------------------->

      <!--<prettyjson [obj]="dataSource.data"></prettyjson>-->
      <div class="col-md-12 col-sm-12 mb-4 card">
        <h3 class="text-center rounded  mt-3"> <strong class="text-black">Current Occupancy</strong></h3>
        <div *ngIf="!dataTableLoaded" class="body d-flex justify-content-center">
          <mat-spinner></mat-spinner>
        </div>
        <table mat-table [dataSource]="dataSource"  *ngIf="dataTableLoaded" matSort>

          <!-- Position Column -->
          <ng-container matColumnDef="centername"  >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Centre Name</th>
            <td mat-cell *matCellDef="let element" class="scale-on-hover"  [ngClass]="element.isArchive?'archived':'normal'"
                [matTooltip]="element.centreCode"> {{element?.centreName}} </td>
            <td mat-footer-cell *matFooterCellDef  class="scale-on-hover"><strong> Total</strong> </td>

          </ng-container>

          <!-- Available Units  Column -->
          <ng-container matColumnDef="availableUnits">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Available </th>
            <td mat-cell *matCellDef="let element"  class="scale-on-hover"
                [ngClass]="element.isArchive?'archived':'normal'"> {{element?.availableUnits}}   </td>
            <td mat-footer-cell *matFooterCellDef  class="scale-on-hover"> <strong>{{lastRow?.availableUnits}}</strong> </td>

          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="occupiedUnits">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Occupied </th>
            <td mat-cell *matCellDef="let element"  class="scale-on-hover"
                [ngClass]="element.isArchive?'archived':'normal'"> {{element?.occupiedUnits}} </td>
            <td mat-footer-cell *matFooterCellDef  class="scale-on-hover"><strong> {{lastRow?.occupiedUnits}}</strong> </td>

          </ng-container>
          <ng-container matColumnDef="sr">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Rank </th>
            <td mat-cell *matCellDef="let element;let i =index"  class="scale-on-hover"
                [ngClass]="element.isArchive?'archived':'normal'"> {{i+1}} </td>
            <td mat-footer-cell *matFooterCellDef  class="scale-on-hover"><strong> </strong> </td>

          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="totalUnits" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Rooms</th>
            <td mat-cell *matCellDef="let element"  class="scale-on-hover"
                [ngClass]="element.isArchive?'archived':'normal'"> {{element?.totalUnits}} </td>
            <td mat-footer-cell *matFooterCellDef  class="scale-on-hover"><strong> {{lastRow?.totalUnits}}</strong> </td>

          </ng-container>
          <ng-container matColumnDef="centrePercentage" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header > Occupancy %</th>
            <td mat-cell *matCellDef="let element"  class="scale-on-hover" > <span class="label" *ngIf="element" [ngClass]="{
    ' bg-green': element.centrePercentage === 100,
    ' label-warning': element.centrePercentage >= 90 && element.centrePercentage <= 99,
    ' label-yellow': element.centrePercentage >= 81 && element.centrePercentage <= 89,
    ' label-danger': element.centrePercentage <= 80 }" > {{element?.centrePercentage}}%</span>   </td>
            <td mat-footer-cell *matFooterCellDef><span *ngIf="lastRow" class="label label-info"> {{getPercentage(lastRow!)}}%</span> </td>

          </ng-container>
          <ng-container matColumnDef="occupancyPercentage">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Efficiency </th>
            <td mat-cell *matCellDef="let element"  class="scale-on-hover " > <span class="label" *ngIf="element" [ngClass]="{
    ' bg-green': element.totalRanking >= 100,
    ' label-warning': element.totalRanking >= 91 && element.totalRanking <= 99,
    ' label-yellow': element.totalRanking >= 81 && element.totalRanking <= 90,
    ' label-danger': element.totalRanking <= 80 }"> {{element?.totalRanking}}%</span></td>
            <td mat-footer-cell *matFooterCellDef  class="scale-on-hover"><strong> </strong> </td>

          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr *matNoDataRow class="mat-row">
            <!--                <td class="mat-cell" colspan="4"  >No data matching the filter "{{input.value}}"</td>-->
          </tr>
        </table>
      </div>

      <hr>

      <!*--------------------------------------------- Selection --------------------------------------------->
      <div class=" d-flex justify-content-center  ">

        <mat-form-field appearance="outline" class="col-md-2">
          <mat-label>From</mat-label>
          <input matInput [matDatepicker]="start" [min]="startRange" [(ngModel)]="mStartDate">
          <mat-hint>DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle  matSuffix [for]="start" ></mat-datepicker-toggle>
          <mat-datepicker #start></mat-datepicker>
        </mat-form-field> &nbsp;&nbsp;&nbsp;

        <mat-form-field appearance="outline" class="col-md-2">
          <mat-label>To</mat-label>
          <input matInput [matDatepicker]="end" [min]="startRange" [(ngModel)]="mEndDate">
          <mat-hint>DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
          <mat-datepicker #end></mat-datepicker>
        </mat-form-field>&nbsp;&nbsp;&nbsp;
        <mat-form-field appearance="outline" class="ms-2">
          <mat-label>Select Centre</mat-label>
          <mat-select [formControl]="selectCentre" (selectionChange)="onCentreSelection($event)"
          >
            <mat-option *ngFor="let c of centreSelectionArray"
                        [value]=c.centreCode>{{c.centreName}}</mat-option>
          </mat-select>
        </mat-form-field>


        <button (click)="search()" color="accent" mat-mini-fab><mat-icon>search</mat-icon></button> &nbsp; &nbsp;
        <button mat-mini-fab color="warn" (click)="resetDate()"><mat-icon>clear</mat-icon></button>
      </div>
      <!*--------------------------------------------- CARDS --------------------------------------------->
      <div class="row">
        <!*--------------------------------------------- UNIT ALLOCATION--------------------------------------------->
        <div class="col-lg-3 col-sm-6">
          <div class="info-box7 l-bg-orange order-info-box7 scale-on-hover" >
            <div class="info-box7-block">
              <h4 class="m-b-20">Current Unit Allocation</h4>
              <!--                          <div class="row clearfix" *ngIf="!dataHospitalLoaded">-->
              <!--                              <div class="body  d-flex justify-content-center">-->
              <!--                                <div class="demo-preloader">-->
              <!--                                  <div class="preloader pl-size-sm">-->
              <!--                                    <div class="spinner-layer">-->
              <!--                                      <div class="circle-clipper left">-->
              <!--                                        <div class="circle"></div>-->
              <!--                                      </div>-->
              <!--                                      <div class="circle-clipper right">-->
              <!--                                        <div class="circle"></div>-->
              <!--                                      </div>-->
              <!--                                    </div>-->
              <!--                                  </div>-->
              <!--                              </div>-->
              <!--                            </div>-->
              <!--                          </div>-->


              <!--                          <div *ngIf="dataHospitalLoaded">-->
              <h2 class="text-end"><i class="fas fa-bed float-start"></i><span>{{totalUnits}}</span></h2>
              <div class=" m-b-0 d-flex justify-content-between">
                <div> Available <br>{{totalAvailableUnits}}</div>
                <div> Occupied <br> {{totalOccupiedUnits}}  </div>
              </div>
              <!--                          </div>-->

            </div>
          </div>
        </div>
        <!*--------------------------------------------- ADMISSION COUNT--------------------------------------------->
        <div class="col-lg-3 col-sm-6">
          <div class="info-box7 l-bg-card3 order-info-box7 scale-on-hover">
            <div class="info-box7-block">
              <h4 class="m-b-20">Admission Count</h4>
              <h2 class="text-end"><i class="fas fa-chart-line float-start"></i><span>{{totalAdmissionCount}} </span></h2>
              <div class=" m-b-0 d-flex justify-content-between">
                <div> Unique {{uniqueAdmissionsCount}} <br> (B) {{freshBoys}} (G) {{freshGirls}}</div>
                <div> FollowUp {{followUpAdmissionsCount}}  <br>  (B) {{followupBoys}} (G) {{followupGirls}}</div>
              </div>
            </div>
          </div>
        </div>

        <!*--------------------------------------------- UNIQUE ADMISSIONS--------------------------------------------->
        <div class="col-lg-3 col-sm-6">
          <div class="info-box7 l-bg-card1 order-info-box7 scale-on-hover">
            <div class="info-box7-block">
              <h4 class="m-b-20">New Admissions</h4>
              <h2 class="text-end"><i class="fas fa-smile float-start"></i><span>{{totalRegisteredChildren}}</span></h2>
              <div class=" m-b-0 d-flex justify-content-between">
                <div> Boys <br>{{totalRegisteredBoys}}</div>
                <div> Girls <br> {{totalRegisteredGirls}}  </div>
              </div>
            </div>
          </div>
        </div>
        <!*--------------------------------------------- TOTAL FOLLOW UP--------------------------------------------->
        <div class="col-lg-3 col-sm-6">
          <div class="info-box7 l-bg-card2 order-info-box7 scale-on-hover">
            <div class="info-box7-block">
              <h4 class="m-b-20">Total Follow Admissions</h4>
              <h2 class="text-end"><i class="fas fa-user-friends float-start"></i><span>{{followUpAdmissionsCount}}</span></h2>
              <!--                            <p class="m-b-0">Boys:{{followupBoys}} Girls {{followupGirls}}</p>-->
              <div class=" m-b-0 d-flex justify-content-between">
                <div>Boys <br>{{followupBoys}}</div>
                <div> Girls <br> {{followupGirls}}</div>
              </div>
            </div>
          </div>
        </div>

        <!*--------------------------------------------- TOTAL EDUCATION--------------------------------------------->
        <div class="col-lg-3 col-sm-6">
          <div class="info-box7 l-bg-orange order-info-box7 scale-on-hover">
            <div class="info-box7-block">
              <h4 class="m-b-20">Counselling Sessions ( Till Date )</h4>
              <h2 class="text-end"><i class="fas fa-chart-line float-start"></i><span>{{mTotalCounsellingIndividual+mTotalCounsellingGroup}}  </span></h2>
              <div class=" m-b-0 d-flex justify-content-between">
                <div>  </div>
                <div>   </div>
                <div> &nbsp;</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6">
          <div class="info-box7 l-bg-card3 order-info-box7 scale-on-hover">
            <div class="info-box7-block">
              <h4 class="m-b-20">Counselling Sessions  </h4>
              <h2 class="text-end"><i class="fas fa-chart-line float-start"></i><span>{{mTotalCounsellingIndividual+mTotalCounsellingGroup+mTotalCounsellingAssestment+mTotalCounsellingFAssestment}}</span></h2>
              <div class=" m-b-0 d-flex justify-content-between">
                <div> Individual {{mTotalCounsellingIndividual}}</div>
                <div> Group {{mTotalCounsellingGroup}}  </div>
                <!--  <div> Assestment {{mTotalCounsellingAssestment}}</div>
                <div> Followp Assetment {{mTotalCounsellingFAssestment}} </div>  -->
              </div>
            </div>
          </div>
        </div>


        <div class="col-lg-3 col-sm-6">
          <div class="info-box7 l-bg-card1 order-info-box7 scale-on-hover">
            <div class="info-box7-block">
              <h4 class="m-b-20">Education  Sessions (Till Date)</h4>
              <h2 class="text-end"><i class="fas fa-chart-line float-start"></i><span> {{mTotalEducationIndividual +mTotalEducationGroup}} </span></h2>
              <div class=" m-b-0 d-flex justify-content-between">
                <div>  </div>

                <div>  &nbsp;  </div>
              </div>
            </div>
          </div>
        </div>   <div class="col-lg-3 col-sm-6">
        <div class="info-box7 l-bg-card2 order-info-box7 scale-on-hover">
          <div class="info-box7-block">
            <h4 class="m-b-20">Education Sessions</h4>
            <h2 class="text-end"><i class="fas fa-chart-line float-start"></i><span>{{ mTotalEducationIndividual + mTotalEducationGroup }} </span></h2>
            <div class=" m-b-0 d-flex justify-content-between">
              <div> Individual {{mTotalEducationIndividual}} </div>
              <div> Group {{mTotalEducationGroup}}  </div>
            </div>
          </div>
        </div>
      </div>

      </div>
      <h3 class="mt-2  dashboard-heading  "> Age Chart </h3>

<!--      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" >-->
<!--        <div class="row clearfix" *ngIf="!dataHospitalLoaded">-->
<!--          <div class="card">-->
<!--            Admission Chart-->
<!--            <div class="body  d-flex justify-content-center">-->
<!--              <div class="demo-preloader">-->
<!--                <div class="preloader pl-size-xl">-->
<!--                  <div class="spinner-layer">-->
<!--                    <div class="circle-clipper left">-->
<!--                      <div class="circle"></div>-->
<!--                    </div>-->
<!--                    <div class="circle-clipper right">-->
<!--                      <div class="circle"></div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->


<!--        <app-admission-chart *ngIf="showAdmissionChart" [centreNames]="mAdmissionChartCentres"-->
<!--                             [unique]="mAdmissionChartUniqueCount"-->
<!--                             [followUp]="mAdmissionChartFollowUpCount"></app-admission-chart>        </div>-->


      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-4" >
        <div class="row clearfix" *ngIf="!dataHospitalLoaded">
          <div class="card">
            Age Chart
            <div class="body  d-flex justify-content-center">
              <div class="demo-preloader">
                <div class="preloader pl-size-xl">
                  <div class="spinner-layer">
                    <div class="circle-clipper left">
                      <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <app-apex-age-chart *ngIf="ageArray.length>1 && show" [ageArrayInput]="ageArray" [followUpArray]="mFollowUpAgeArray"  ></app-apex-age-chart>

      </div>
    </div>
    <!--------------------------------------------- OCCUPANCY TABLE --------------------------------------------->

    <!--        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-4" >-->
    <!--          <div class="row clearfix" *ngIf="!showAdmissionChart">-->
    <!--              <div class="card">-->
    <!--                <div class="header">-->
    <!--                  <h2>Admissions Chart</h2>-->
    <!--                </div>-->
    <!--                <div class="body  d-flex justify-content-center">-->
    <!--                  <div class="demo-preloader">-->
    <!--                    <div class="preloader pl-size-xl">-->
    <!--                      <div class="spinner-layer">-->
    <!--                        <div class="circle-clipper left">-->
    <!--                          <div class="circle"></div>-->
    <!--                        </div>-->
    <!--                        <div class="circle-clipper right">-->
    <!--                          <div class="circle"></div>-->
    <!--                        </div>-->
    <!--                      </div>-->
    <!--                    </div>-->
    <!--                  </div>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--          </div>-->

    <!--        <app-admission-chart *ngIf="showAdmissionChart" [centreNames]="mAdmissionChartCentres"-->
    <!--                             [unique]="mAdmissionChartUniqueCount"-->
    <!--                             [followUp]="mAdmissionChartFollowUpCount"></app-admission-chart>-->
    <!--     </div>-->

    <!--{{mAllChildren.length}}-->

    <ng-container  >
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">

        <div class="row clearfix" *ngIf="!showRecentDischarge">
          <div class="card">
            <div class="header">
              <h2>Recent Unique Admissions</h2>
            </div>
            <div class="body  d-flex justify-content-center">
              <div class="demo-preloader">
                <div class="preloader pl-size-xl">
                  <div class="spinner-layer">
                    <div class="circle-clipper left">
                      <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <app-recent-admissions *ngIf="mAllChildren.length>1 && showRecentDischarge" [mChildren]="mAllChildren"></app-recent-admissions>
      </div>

    </ng-container>
    <!*--------------------------------------------- UNIQUE DISCHARGES--------------------------------------------->
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <div class="row clearfix" *ngIf="!showRecentDischarge">
        <div class="card">
          <div class="header">
            <h2>Recent Unique Discharge </h2>
          </div>
          <div class="body  d-flex justify-content-center">
            <div class="demo-preloader">
              <div class="preloader pl-size-xl">
                <div class="spinner-layer">
                  <div class="circle-clipper left">
                    <div class="circle"></div>
                  </div>
                  <div class="circle-clipper right">
                    <div class="circle"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="mAllChildren.length>1 && showRecentDischarge" >
        <app-recent-discharge [mChildren]="mAllChildren"></app-recent-discharge>
      </ng-container>
    </div>
    <!*--------------------------------------------- UNIQUE DISCHARGES--------------------------------------------->

    <!--        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-4" >-->
    <!--            <app-admission-chart *ngIf="showAdmissionChart" [centreNames]="mAdmissionChartCentres"-->
    <!--                                 [unique]="mAdmissionChartUniqueCount"-->
    <!--                                 [followUp]="mAdmissionChartFollowUpCount"></app-admission-chart>-->
    <!--        </div>-->
<!--    <div class="d-flex flex-wrap">-->


<!--      <div class=" mt-4 col-md-12 mb-5 height-auto  ">-->
<!--        <app-financial-report-table></app-financial-report-table>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
