import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {environment} from "../../../environments/environment";
@Component({
  selector: 'app-data-correction',
  templateUrl: './data-correction.component.html',
  styleUrls: ['./data-correction.component.scss']
})

export class DataCorrectionComponent implements OnInit {
  env = environment.productionMode
  mData :any[]=[]
  constructor(private readonly mFirestore: AngularFirestore,
              private readonly mDatabase: AngularFireDatabase) {

  }

  ngOnInit(): void {
  }

  correctStateInCounsellingIndividual(incorrect: string, correct: string) {
    this.mFirestore.collection(this.env.counseling + '/allSessions/sessions',ref =>
    ref.where('state','==',incorrect)).get().subscribe(res => {
     // @ts-ignore
      this.mData = res.docs.map(x=> ({id:x.id, ...x.data()}))
     this.mData.forEach(it=>{
       this.mFirestore.doc(this.env.counseling + '/allSessions/sessions/'+it.id).update({'state':correct}).then(()=>{
         console.log(`Updated doc ${it.id}`)
       })
     })

      console.log(this.mData)
    })
  }

  processData() {
    // this.correctStateInCounsellingIndividual('Panjab','Punjab')
    // this.addCentresInGroupCounselling()
    this.addCentresInGroupCounselling()

  }
// addCentresin Group Counssling
  private addCentresInGroupCounselling() {
    this.mFirestore.collection<any>('Counselling/allSessions/groupSessions').valueChanges({idField:'id'})
        .subscribe(res=>{
res.forEach(it=>{
  this.mFirestore.collection('Counselling/allSessions/groupSessions').doc(it.id).update({
    centreCode: it.centres[0].centreCode,
    centreName: it.centres[0].centreName
  }).then(()=>{
    console.log(`added `)
  })
})
            }
        )
  }
}
