import {Component} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {MatDialogRef} from "@angular/material/dialog";
import {HospitalsComponent} from "../hospitals.component";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {HospitalService} from "../../../services/hospital.service";
import {Hospitals} from "../../../interfaces/Hospitals";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TitleCasePipe} from "@angular/common";
import {LogService} from "../../../services/log.service";

@Component({
  selector: 'app-add-hospital',
  templateUrl: './add-hospital.component.html',
  styleUrls: ['./add-hospital.component.css']
})
export class AddHospitalComponent {
  addressForm = this.fb.group({
    // company: null,
    hospitalName: new FormControl('', [Validators.required]),
    // lastName: [null, Validators.required],
    socialWorkerName: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
    doctorsName: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
    address: new FormControl('', Validators.required),
    shortName: new FormControl('', Validators.required),
    // address2: new FormControl(''),
    city: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
    state: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
    zipcode: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('[0-9]*')])
    // shipping: ['free', Validators.required]
  });

  hasUnitNumber = false;

  mState = [
    {code: "AN", name: "Andaman and Nicobar Islands"},
    {code: "AP", name: "Andhra Pradesh"},
    {code: "AR", name: "Arunachal Pradesh"},
    {code: "AS", name: "Assam"},
    {code: "BR", name: "Bihar"},
    {code: "CG", name: "Chandigarh"},
    {code: "CH", name: "Chhattisgarh"},
    {code: "DH", name: "Dadra and Nagar Haveli"},
    {code: "DD", name: "Daman and Diu"},
    {code: "DL", name: "Delhi"},
    {code: "GA", name: "Goa"},
    {code: "GJ", name: "Gujarat"},
    {code: "HR", name: "Haryana"},
    {code: "HP", name: "Himachal Pradesh"},
    {code: "JK", name: "Jammu and Kashmir"},
    {code: "JH", name: "Jharkhand"},
    {code: "KA", name: "Karnataka"},
    {code: "KL", name: "Kerala"},
    {code: "LD", name: "Lakshadweep"},
    {code: "MP", name: "Madhya Pradesh"},
    {code: "MH", name: "Maharashtra"},
    {code: "MN", name: "Manipur"},
    {code: "ML", name: "Meghalaya"},
    {code: "MZ", name: "Mizoram"},
    {code: "NL", name: "Nagaland"},
    {code: "OR", name: "Odisha"},
    {code: "PY", name: "Puducherry"},
    {code: "PB", name: "Punjab"},
    {code: "RJ", name: "Rajasthan"},
    {code: "SK", name: "Sikkim"},
    {code: "TN", name: "Tamil Nadu"},
    {code: "TS", name: "Telangana"},
    {code: "TR", name: "Tripura"},
    {code: "UK", name: "Uttarakhand"},
    {code: "UP", name: "Uttar Pradesh"},
    {code: "WB", name: "West Bengal"}]

  constructor(private fb: FormBuilder,
              private mService: HospitalService,
              private matDialogRef: MatDialogRef<AddHospitalComponent>,
              private _snackBar: MatSnackBar,
              private titlecasePipe: TitleCasePipe,
              private readonly mLogService: LogService) {
  }

  get city() {
    return this.addressForm.get('city')!
  }

  get docterName() {
    return this.addressForm.get('doctorsName')!
  }

  get hospitalName() {
    return this.addressForm.get('hospitalName')!
  }

  get shortName() {
    return this.addressForm.get('shortName')!
  }

  get socialWorkerName() {
    return this.addressForm.get('socialWorkerName')!
  }
  get address2() {
    return this.addressForm.get('address2')!
  }

  get address() {
    return this.addressForm.get('address')!
  }

  get state() {
    return this.addressForm.get('state')!
  }

  get zipcode() {
    return this.addressForm.get('zipcode')!
  }

  onSubmit() {
    if (this.addressForm.valid) {
      console.table(this.addressForm.value)
      this.mService.addHospital(this.addressForm.value)
        .then(() => {
          let activity={
            date:new Date().getTime(),
            section: 'Hospital',
            action: 'Submit',

            description: `Hospital added  by`,
            currentIp: localStorage.getItem('currentip')!
          }
          this.mLogService.addLog(activity)

          this.matDialogRef.close()

        })
    } else this._snackBar.open('Please enter the form properly', '', {duration: 3000})
  }

  onCancel() {
    this.matDialogRef.close()
  }

  onBlur(formControl: any) {
    if(formControl.value !== null) {
      formControl.setValue(this.titlecasePipe.transform(formControl.value.toString().trim().toUpperCase()))
    }
  }
}
