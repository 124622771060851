import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserRole} from "../../rename/add-user-role/add-user-role.component";
import {Reciept} from "../DonationModel";
import {Subject, takeUntil} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {HttpClient} from "@angular/common/http";
import {MatDialog} from "@angular/material/dialog";
import {DonationService} from "../donation.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {ExportExcelService} from "../../../services/export-excel.service";
import {map} from "rxjs/operators";
import {DonationComponent} from "../donation/donation.component";
import {ViewDonationComponent} from "../view-donation/view-donation.component";
import {MatSelectChange} from "@angular/material/select";
import * as XLSX from "xlsx";
import {LogService} from "../../../services/log.service";

@Component({
  selector: 'app-failed-donations',
  templateUrl: './failed-donations.component.html',
  styleUrls: ['./failed-donations.component.scss']
})
export class FailedDonationsComponent implements AfterViewInit, OnDestroy {
  allpermission = JSON.parse(localStorage.getItem('permissions')!) as UserRole[]

  Donation = this.allpermission.filter((x) => x.functionName == 'General Donation')[0]
  /*name of the excel-file which will be downloaded. */
  fileName = 'Donation_Report.xlsx';
  //* Extra fields are there because of donations report from arya team
  displayedColumns: string[] = ['receipt_id', '80g', 'date', 'donated_by',  'email',  'mobile', 'address', 'city', 'state', 'country', 'zipcode', 'nationality',  'pan', 'type', 'donation_amount','actions'];
  receipts: Reciept[] = [];
  title = 'angular-export-to-excel';
  dataForExcel: any[] = [];

 private _destroyed$ = new Subject()
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

@ViewChild(MatPaginator) paginator!: MatPaginator;
@ViewChild(MatSort) sort!: MatSort;

  dataSource!: MatTableDataSource<Reciept>;
  startDate?: Date | null
  endDate?: Date | null
  status: string | null = 'Failed'

  constructor(private _httpClient: HttpClient, private matdialog: MatDialog, private mDonationService: DonationService,
      private _snackBar: MatSnackBar,
      private router: Router,
              private mLogService:LogService,
              public exportExcelService: ExportExcelService
) {
    console.log(this.dataSource)
  }

  ngAfterViewInit() {
    //  this.exampleDatabase = new ExampleHttpDatabase(this._httpClient);

    // If the user changes the sort order, reset back to the first page.
    // @ts-ignore
    this.sort.sortChange.pipe(takeUntil(this._destroyed$)).subscribe(() => (this.paginator.pageIndex = 0));

    const donations = this.mDonationService.getFailedDonationsWithId().pipe(map(
        //getting all snapshots
        snaps => {
          return snaps.map((snap) => ({id: snap.payload.doc.id, ...snap.payload.doc.data()} as Reciept))
        }),)
    donations.subscribe(value => {
      this.isLoadingResults = false;
      this.isRateLimitReached = value === null;
      console.log('d: ' + value.length)
      this.receipts = value
      console.log(this.receipts)
      this.receipts.forEach(i => {
        i.country = ' India '
        // i.isdCode = '91'
      })
      this.dataSource = new MatTableDataSource(this.receipts);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  exportToExcel() {
    console.table(this.receipts)
    this.receipts.forEach((row: Reciept, index) => {
      for (let x1 of this.receipts) {
        let x2 = Object.keys(x1);
        let temp = []
        for (let y of x2) {
          // @ts-ignore
          temp.push(x1[y])
        }
        // worksheet.addRow(temp)
        this.dataForExcel.push(Object.values(temp))
      }

      // this.dataForExcel.push(Object.values(row))
    })

    let reportData = {
      title: 'Donation Sales Report - Jan 2020',
      data: this.dataForExcel,
      headers: Object.keys(this.receipts[0])
    }

    this.exportExcelService.exportExcel(reportData);
  }

  openpopup() {
   this.router.navigateByUrl('/home/manual-donation')
  }

  getReceipt(element: Reciept) {
    // console.log('sdfs: ' + receipt_id)
    // if (receipt_id !== undefined) {
    //   this.matdialog.open(GetreceiptComponent, {
    //     data: element,
    //     width: '80%',
    //     height: '80%',
    //     // data:element
    //   })
    const id = element.id as string
    // this.router.navigate('getreceipt',id)
    this.router.navigate(['getreceipt', id]);
    /*} else {
      console.log('No Receipt Found')
      this._snackBar.open('No Document Found', '', {
        duration: this.durationInSeconds * 1000
      })
    }*/
  }


  delete(doc_id: any) {
    console.log(doc_id)
  }

  viewRecepit(receipt: Reciept) {
    console.log('View Receipt clicked')
    this.matdialog.open(ViewDonationComponent, {
      data: receipt,
      width: '60%',
      height: '80%'
    })

    let activity = {
      date: new Date().getTime(),
      section: 'Donation',
      action: 'View',
      description: `Donation view Faild Donation Vehicle by user`,
      currentIp: localStorage.getItem('currentip')!
    }
    this.mLogService.addLog(activity)
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next(null)
    this._destroyed$.complete()
  }

  getSortedData($event: MatSelectChange) {
    let donations = this.mDonationService.getDonationsWithId().pipe(map(
        //getting all snapshots
        snaps => {
          //like for each for snapshots
          return snaps.map((snap) => ({id: snap.payload.doc.id, ...snap.payload.doc.data()} as Reciept)
          )

        }
    ))

    const filterbyDays = this.gettimestampfordaysbefore($event.value)

    if ($event.value !== 0) {
      donations = this.mDonationService.getFilteredDonationsWithId(+filterbyDays).pipe(map(
          //getting all snapshots
          snaps => {

            //like for each for snapshots
            return snaps.map((snap) => ({id: snap.payload.doc.id, ...snap.payload.doc.data()} as Reciept)
            )

          }
      ))
    }
    console.log($event.value)
    donations.subscribe(value => {
      this.receipts = []
      this.isLoadingResults = false;
      this.isRateLimitReached = value === null;
      console.log('d: ' + value.length)
      this.receipts = value
      this.dataForExcel = value
      this.dataSource = new MatTableDataSource(this.receipts);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  gettimestampfordaysbefore(number: number): number {
    let now = new Date()
    let weekbefore = new Date(now.getFullYear(), now.getMonth(), now.getDate() - number)
    const daysmilli = new Date(weekbefore)
    console.log('daysmilli', daysmilli)
    console.log('Timecalculated', daysmilli.getTime())
    return daysmilli.getTime()
  }

//** Original Excel
  exportexcel() {
    /* assessment-table id is passed over here */
    let element = document.getElementById('excel-table');

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    // const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
    // const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(this.receipts)
    ws['!cols'] = [];
    ws['!cols'][19] = {hidden: true};

    /* O1 is your Column in Excel*/

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);


  }

  exportAllexcel() {
    /* assessment-table id is passed over here */
    let element = document.getElementById('excel-assessment-table');
    // const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.receipts)

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);


  }

  testExcel() {
    /* assessment-table id is passed over here */
    let element = document.getElementById('excel-assessment-table');
    // const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.receipts)

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);


  }

  fullForm(data: any) {
    switch (data.groupkey) {
      case 'GF': {
        return 'General Fund';
        break;
      }
      case 'TR': {
        return 'Transportation';
        break;
      }
      case 'ACM': {
        return 'Accomodation';
        break;
      }
      case 'CF': {
        return 'Corpus Fund';
        break;
      }
      case 'AAF': {
        return 'Adoopt a Family';
        break;
      }
      case 'MM': {
        return 'Mealmate';
        break;
      }
      case 'type': {
        return data.groupkey
        break;
      }
      case 'Mealmate':{
        return  'Mealmate'
        break;

      }
      default :
        return data.description
    }
  }

  searchbyDateRange() {
    this.mDonationService.getByRange(this.startDate, this.endDate)
        .subscribe(value => {
          this.receipts = []
          this.isLoadingResults = false;
          this.isRateLimitReached = value === null;
          console.log('d: ' + value.length)
          this.receipts = value
          this.dataForExcel = value
          this.dataSource = new MatTableDataSource(this.receipts);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
    let activity = {
      date: new Date().getTime(),
      section: 'Donation',
      action: 'Filter',
      description: `Donation Filter Attempt Donation by user`,
      currentIp: localStorage.getItem('currentip')!
    }
    this.mLogService.addLog(activity)
  }
}
