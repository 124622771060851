
<div class="row">
    <mat-form-field appearance="outline" class="col-md-3">
      <mat-label>Select a Joining date</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="selectedDate" placeholder="Choose a date">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-3">
    <mat-label>Driver Name:</mat-label>
    <mat-select  [(ngModel)]="drivers_name"  (selectionChange)="onDriverSelect($event)">
      <mat-option *ngFor="let model of drivers" [value]="model.firstName+' '+model.lastName">
        {{ model.firstName }} {{ model.lastName }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="justify-content-start gap-2 d-flex col-md-3">
    <button mat-mini-fab  (click)="searchData()"><mat-icon>search</mat-icon></button>
    <button mat-mini-fab (click)="clearAll()" color="accent"><mat-icon>clear</mat-icon></button>
  </div>
</div>
<div class="card p-2">
  <div class="ngxTableHeader">
    <ul class="header-buttons-left ms-0 mb-0">
      <li class="dropdown">
        <h2>Drivers</h2>
      </li>
      <li class="dropdown m-l-20">
        <label for="search-input"><i class="material-icons search-icon">search</i></label>
        <input placeholder="Search" id="search-input" type="text" class="browser-default search-field"
               (keyup)='applyFilter($event)' aria-label="Search box" #input>
      </li>
    </ul>
    <ul class="header-buttons mr-2">
      <li>
        <button *ngIf="vehicle[0].all || vehicle[0].add" mat-mini-fab color="primary" (click)="openDiver()">
          <mat-icon class="col-white">add</mat-icon>
        </button>
      </li>
      <!--      <li>-->
      <!--        <div class="export-button m-l-10" matTooltip="XLSX">-->
      <!--          <img src="assets/images/icons/xlsx.png" alt=""/>-->
      <!--        </div>-->
      <!--      </li>-->
    </ul>
  </div>

  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort  class="mat-elevation-z8">

      <ng-container matColumnDef="driverName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Driver Name</th>
        <td mat-cell *matCellDef="let element"> {{element.firstName}}  {{element.lastName}} </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone Number</th>
        <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
      </ng-container>

      <ng-container matColumnDef="dateOfJoining">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date of Joining</th>
        <td mat-cell *matCellDef="let element"> {{(element.dateOfJoining).toDate() | date:'dd/MM/yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="license">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> License</th>
        <td mat-cell *matCellDef="let element"> {{element.license}} </td>
      </ng-container>

      <ng-container matColumnDef="expirationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Expiration Date</th>
        <td mat-cell *matCellDef="let element"> {{(element.expirationDate).toDate() | date:'dd/MM/yyyy'}} </td>
      </ng-container>


      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action</th>
        <td mat-cell *matCellDef="let element">
          <button *ngIf="vehicle[0].view || vehicle[0].all" mat-icon-button color="accent" matTooltip="View" (click)="openView(element)">	<mat-icon>visibility</mat-icon></button>
          <button mat-icon-button *ngIf="vehicle[0].all || vehicle[0].edit" (click)="editVehicle(element)" color="primary" matTooltip="edit"><mat-icon>edit</mat-icon></button>
          <button mat-icon-button *ngIf="vehicle[0].archive || vehicle[0].all" (click)="deleteVehicle(element)"  color="warn" matTooltip="delete"><mat-icon>delete</mat-icon> </button>

         </td>
       </ng-container>


       <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
       <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
     </table>
     <mat-paginator [length]="200" [pageSizeOptions]="[25, 50, 100, 200, 500]" aria-label="Select page">
     </mat-paginator>
   </div>
 </div>
