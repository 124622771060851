import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-view-vehicle',
  templateUrl: './view-vehicle.component.html',
  styleUrls: ['./view-vehicle.component.scss']
})
export class ViewVehicleComponent implements OnInit {
   vehicleData: any;
  displayedColumns: string[] = ['dateOfAdmission', 'dateOfDischarge', 'duration'];
  datasource = new MatTableDataSource()
  constructor(@Inject(MAT_DIALOG_DATA) public data:any,
              private mDialog :MatDialog) { }

  ngOnInit(): void {
    console.log(this.data)
    this.vehicleData = this.data
  }
  onCancel(){
    this.mDialog.closeAll();
  }

}
