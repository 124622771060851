import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Driverdata} from "../add-info/add-info.component";
import {VehicleService} from "../vehicle.service";
import {finalize} from "rxjs/operators";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {LogService} from "../../../services/log.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-update-driver',
  templateUrl: './update-driver.component.html',
  styleUrls: ['./update-driver.component.scss']
})
export class UpdateDriverComponent implements OnInit {
  driverForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
              private mService: VehicleService,
              private storage: AngularFireStorage,
              private mLogService: LogService,
              private matSnackbar : MatSnackBar,
              private  mDialog : MatDialog) {
    this.driverForm = this.formBuilder.group({

      firstName: [data.firstName || '', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      middleName: [data.middleName || '', Validators.pattern('^[a-zA-Z ]*$')],
      lastName: [data.lastName || '', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      currentAddress: [data.currentAddress || '', Validators.required],
      previousAddress: [data.previousAddress || '', Validators.required],
      phone: [data.phone || '', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      dob: [data.dob.toDate(), Validators.required],

      dateOfJoining: [data.dateOfJoining.toDate() || null, Validators.required],
      socialSecurity: [data.socialSecurity || '', Validators.required],
      emergencyContactName: [data.emergencyContactName || '', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      relationToContact: [data.relationToContact || '', Validators.required],
      contactAddress: [data.contactAddress || '', Validators.required],
      contactPhone: [data.contactPhone || '', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],


      kycData: this.formBuilder.group({
        pan: [data.kycData?.pan || 'default-pan-url', Validators.required],
        aadhar: [data.kycData?.aadhar || 'default-aadhar-url', Validators.required],
        bankDetails: [data.kycData?.bankDetails || 'default-bank-url', Validators.required],
        passportPhoto: [data.kycData?.passportPhoto || 'default-passport-url', Validators.required],
        policeVerification: [data.kycData?.policeVerification || 'default-police-url', Validators.required],
        electricBill: [data.kycData?.electricBill || 'default-electric-bill-url', Validators.required],
      }),

      state: [data.state || '', Validators.required],
      license: [data.license || '', Validators.required],
      type: [data.type || '', Validators.required],
      expirationDate: [data.expirationDate.toDate() || null, Validators.required],
      typeOfEquipment: [data.typeOfEquipment || '', Validators.required],
      fromDate: [data.fromDate.toDate() || null, Validators.required],
      toDate: [data.toDate?.toDate() || null, Validators.required],
      approxMiles: [data.approxMiles || '', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.driverForm.valid) {
      const driverData: Driverdata = this.driverForm.value;
      console.log(driverData)
      this.mService.updateDriverData(driverData, this.data.id).then(r => {
        this.matSnackbar.open('Data Updated successfully',"",{duration:3000,panelClass:'success-snackbar'})
        console.log("Data Updated successfully")
        let activity = {
          date: new Date().getTime(),
          section: 'Driver',
          action: 'Update',
          description: `Vehicle updated by user`,
          currentIp: localStorage.getItem('currentip')!
        }
        this.mLogService.addLog(activity)
      })

    } else {
      console.log("Form is invalid");
    }
    console.log(this.driverForm.value)


  }

  onFileSelected(event: any, controlName: string) {
    const file = event.target.files[0];

    if (file) {
      const filePath = `kyc/${new Date().getTime()}_${file.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);

      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe((downloadURL: any) => {
            this.driverForm.get(controlName)!.setValue(downloadURL);
            this.driverForm.get(controlName)!.markAsDirty();
          });
        })
      ).subscribe();
    } else {
      this.driverForm.get(controlName)!.setValue(null);
      this.driverForm.get(controlName)!.markAsDirty();
    }
  }

  onCancel() {
    this.mDialog.closeAll();
  }



}
