import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FullChild} from "../../../interfaces/FullChild";
import firebase from "firebase/compat/app";
import Timestamp = firebase.firestore.Timestamp;
import {AngularFirestore, AngularFirestoreDocument} from "@angular/fire/compat/firestore";
import {Observable} from "rxjs";
import {Centre} from "../../../interfaces/Centres";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {UnitService} from "../../../services/unit.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatSelectChange} from "@angular/material/select";
import {Units} from "../../../interfaces/Units";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {FileUploadComponent} from "../../../upload/file-upload/file-upload.component";
import {FileUploadDialogComponent} from "../../../upload/file-upload-dialog/file-upload-dialog.component";
import {UploadFile} from "../../../interfaces/uploadFile";
import {ViewImageComponent} from "./view-image/view-image.component";
import {AngularFireStorage, AngularFireStorageReference} from "@angular/fire/compat/storage";
import {needConfirmation} from "../../../confirm-dialog/confirm-dialog.decorator";
import {CentreService} from "../../../services/centre.service";
import {HttpClient} from '@angular/common/http';
import {LogService} from "../../../services/log.service";
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import {AddMedicalHistoryComponent} from "../add-medical-history/add-medical-history.component";

@Component({
    selector: 'app-view-registered-child',
    templateUrl: './view-registered-child.component.html',
    styleUrls: ['./view-registered-child.component.scss']
})
export class ViewRegisteredChildComponent implements OnInit {
    currentYear = new Date().getFullYear();
    minAdmissionDate = new Date( this.currentYear,0,-3) ;
    maxAdmissionDate = new Date( this.currentYear,0,+1) ;
    dateFormatted: any
    centreName$?: Observable<Centre | undefined>
    dischargeForm!: FormGroup
    followupadmissionForm!:FormGroup
    isSelectedCentre = false
    selectedCenter: Centre | undefined | null
    units$!: Observable<Units[]>
    admissionForm!: FormGroup
    fb: FormBuilder
    showDischarge = true
    centres$: Observable<Centre[]>
    profileUrl?: string
    child$: Observable<FullChild | undefined>
    childData:any
    maxDate=new Date()
    // admissionDocuments$:Observable<UploadFile>
    // admissionDocuments$:Observable<UploadFile>
mMedicalHistory:any[] | undefined=[]
    constructor(@Inject(MAT_DIALOG_DATA) public child: FullChild,
                private mFirestore: AngularFirestore,
                private mDatabase: AngularFireDatabase,
                private fbApi: FormBuilder,
                private centerService: CentreService,
                private mStorage: AngularFireStorage,
                private unitService: UnitService,
                private _snackBar: MatSnackBar,
                private readonly matDialog: MatDialog,
                private readonly mLogService: LogService,
                private http: HttpClient,
                private _dialog: MatDialogRef<ViewRegisteredChildComponent>
    ) {
        // console.assessment-table(child)
        // this.dob = child.dob
        this.dateFormatted = (child.dob as unknown as Timestamp).toDate()
        // this.dob =(this.dob).toDate()
        //Need to change
        //Need to change
        // this.dob = (child.dob as Timestamp)
        this.fb = fbApi
        this.centres$ = this.centerService.getSortedCenterList()
        // mFirestore.collection<Centre>('centres', ref1 => ref1.orderBy("sr", 'desc')).valueChanges()
        this.child$ = this.mFirestore.collection(this.centerService.env.child).doc<FullChild>(this.child.childCode).valueChanges()
        this.child$.subscribe(res => {
            this.mMedicalHistory = res?.medical_history
            res?.admissionDocuments?.forEach(it => {
                if (it.description == "Child Photo") this.profileUrl = it.fileUrl
            })
        })
      this.childData = child
      console.log(this.childData)
    }

    get getCentre() {
        return this.admissionForm.get('selectCentre');
    }

    ngOnInit(): void {
        this.centreName$ = this.mFirestore.collection<Centre>(this.centerService.env.centres).doc(this.child.centreCode).valueChanges()
        this.showDischarge = this.child.isAlloted
        if (this.showDischarge) this.initDischargeForm()
        if (!this.showDischarge) this.initAdmissionForm()
      if (!this.showDischarge) this.initfollowupAdmissionForm()
    }

    showDischargeToast() {
        this._snackBar.open('Child Discharged Successfully', '', {duration: 4000})
    }

    showAdmissionToast() {
        this._snackBar.open('Child Admitted Successfully', '', {duration: 4000})
    }

    initDischargeForm() {
        this.dischargeForm = this.fb.group({
            dischargeDate: new FormControl('', Validators.required),
            followUpDate: new FormControl(null),
            reason: new FormControl('', Validators.required),
            remark: new FormControl('', Validators.required),
            height: new FormControl('', [Validators.required, Validators.pattern('[0-9.]*'), Validators.maxLength(5)]),
            weight: new FormControl('', [Validators.required, Validators.pattern('[0-9.]*'), Validators.maxLength(5)]),
        })
    }

    initAdmissionForm() {
        this.admissionForm = this.fb.group({
            dateOfAdmission: new FormControl('', Validators.required),
            tentativeDischargeDate: new FormControl('', Validators.required),
            selectCentre: new FormControl(''),
            selectUnit: new FormControl(''),
            height: new FormControl('', [Validators.required, Validators.pattern('[0-9.]*'), Validators.maxLength(5)]),

            weight: new FormControl('', [Validators.required, Validators.pattern('[0-9.]*'), Validators.maxLength(5)]),
            //  reason: new FormControl('', Validators.required),
            remark: new FormControl('', Validators.required),

        })
    }

  initfollowupAdmissionForm(){
    this.followupadmissionForm = this.fb.group({
      q1: new FormControl(''),
      q2: new FormControl(''),
      schoolName:new FormControl(''),
      Standard:new FormControl(''),
      Medium:new FormControl(''),
      childInterest:new FormControl(''),
      finacialProblem:new FormControl(''),
      poorHealth:new FormControl(''),
      other:new FormControl(''),
      q3: new FormControl(''),
      reason:new FormControl(''),
      q4: new FormControl(''),
      reason1:new FormControl(''),
      q5: new FormControl(''),
      reason2:new FormControl(''),
      steps:new FormControl(''),
      q6: new FormControl(''),
      practice: new FormControl(''),
      reason3:new FormControl(''),
    })
  }
    dischareChild() {
        //  console.assessment-table(this.dischargeForm.value)

     // if(child.sponsored==true)
     // {
     //   this._snackBar.open('Child Discharged Successfully', '', {duration: 4000})
     // }
        if (this.dischargeForm.valid) {
            //  this.showDischarge = false
            console.log('discharge Reminder', this.dischargeForm.get('followUpDate')?.value)
            this.unitService.dischargeProcess(this.dischargeForm.value, this.child, this.dischargeForm.get('followUpDate')?.value != null).then(() => {
                this.showDischargeToast()
              let activity={
                date:new Date().getTime(),
                section: 'Admission',
                action: 'Submit',

                description: `Child Discharged ${this.child.firstName} ${this.child.lastName} ${this.child.childCode} by  `,
                currentIp: localStorage.getItem('currentip')!
              }
              this.mLogService.addLog(activity)

                this._dialog.close()
            })

            // console.assessment-table(this.child)
        }
    }

    reAdmitChild() {
        // console.assessment-table(this.admissionForm.value)
        // if (this.admissionForm.valid) {
        //   this.showDischarge = false
        this.unitService.reAdmissionProcess(this.selectedCenter, this.admissionForm!.get('selectUnit')?.value, this.child.childCode, this.admissionForm.value, this.child)
            .then(() => {
                this.showAdmissionToast()
              let activity={
                date:new Date().getTime(),
                section: 'Admission',
                action: 'Submit',
                description: `Child Admitted ${this.child.firstName} ${this.child.lastName} by  `,
                currentIp: localStorage.getItem('currentip')!
              }
              this.mLogService.addLog(activity)
                this._dialog.close()
            })
    }

    fetchUnits($event: MatSelectChange) {
        this.isSelectedCentre = true
        console.log('fetchUnits', $event.value)
        // this.selectedCenter  = this.allCentres.at()
        this.selectedCenter = $event.value
        this.units$ = this.mDatabase.list<Units>(`${this.centerService.env.units}/${this.selectedCenter?.centreCode}`, ref => ref.orderByChild('order')).valueChanges()
        console.log('selected', $event.value)
// this.childForm.setControl()
        this.admissionForm.addControl('centreCode', new FormControl(this.selectedCenter?.centreCode),)
        this.admissionForm.addControl('centreName', new FormControl(this.selectedCenter?.centreName),)
        // this.childForm.setControl( 'centreCode', this.selectedCenter?.centreCode)
    }

    UploadAdmissionDocs() {
        this.matDialog.open(FileUploadDialogComponent, {
            data: {
                type: 'admissionDocuments',
                childCode: this.child.childCode
            }
        })
    }

    UploadDischargeDocs() {
        this.matDialog.open(FileUploadDialogComponent, {
            data: {
                type: 'dischargeDocuments',
                childCode: this.child.childCode
            }
        })
    }

    UploadFollowUpAdmissionDocs() {
        this.matDialog.open(FileUploadDialogComponent, {
            data: {
                type: 'followUpAdmissionDocuments',
                childCode: this.child.childCode
            }
        })
    }

    UploadFollowUpDischargeDocs() {
        this.matDialog.open(FileUploadDialogComponent, {
            data: {
                type: 'followUpDischargeDocuments',
                childCode: this.child.childCode
            }
        })
    }

    viewImage(followUpDischargeDocuments: UploadFile[], uploadFile: UploadFile) {
        this.matDialog.open(ViewImageComponent, {data: uploadFile})

    }

    @needConfirmation()
    deleteAdmissionFile(i: number, d: UploadFile, category: string) {
        const iRef = this.mStorage.storage.refFromURL(d.fileUrl)
        iRef.delete().then(() => {
            this.mFirestore.collection(this.centerService.env.child).doc(this.child.childCode).update({
                [category]: firebase.firestore.FieldValue.arrayRemove(d)
            }).then(() => {
                this._snackBar.open('File Deleted Successfully')._dismissAfter(2000)
            })
        })
      let activity={
        date:new Date().getTime(),
        section: 'Admission',
        action: 'Submit',

        description: ` Document Deleted ${this.child.childCode} ${this.child.firstName} ${this.child.lastName} ${d.description}  by  `,
        currentIp: localStorage.getItem('currentip')!
      }
      this.mLogService.addLog(activity)
        console.log(iRef)
    }

    trackByMethod(index: number, el: any): number {
        return el.id;
    }

    openLink(fileUrl: string) {
        window.open(fileUrl)

    }

    getCalculatedAge(child: firebase.firestore.Timestamp) {
        let timeDiff = Math.abs(Date.now() - (child).toDate().getTime());
        let age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
        // console.log(age)
        return age
    }


    // @ts-ignore
    async getExtension(fileUrl: string) {
        let reg = this.mStorage.storage.refFromURL(fileUrl)
        const m = await reg.getMetadata()
        let contentType = m.contentType
        if (contentType) {
            // Extract the file extension from the content type
            const fileExtension = contentType.split('/')[1];
            console.log(fileExtension)

            return fileExtension;
        } else ''
    }

    addMedicalDialog() {
        this.matDialog.open(AddMedicalHistoryComponent,{
            data:this.childData
        })
    }
}
