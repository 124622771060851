import {Component, OnInit, ViewChild} from '@angular/core';
import {AddInfoComponent, Driverdata} from "../add-info/add-info.component";
import {MatDialog} from "@angular/material/dialog";
import {VehicleService} from "../vehicle.service";
import {UpdateDriverComponent} from "../update-driver/update-driver.component";
import {UserRole} from "../../rename/add-user-role/add-user-role.component";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {ViewVehicleComponent} from "../view-vehicle/view-vehicle.component";
import {ViewDriverComponent} from "../view-driver/view-driver.component";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {LoadingService} from "../../../services/loading.service";

@Component({
  selector: 'app-driver-table',
  templateUrl: './driver-table.component.html',
  styleUrls: ['./driver-table.component.scss']
})
export class DriverTableComponent implements OnInit {
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = ['driverName', 'phone', 'dateOfJoining', 'license', 'expirationDate', 'action']; // Add other column names here
  permissions = JSON.parse(localStorage.getItem('permissions')!) as UserRole[]
  vehicle=this.permissions.filter((x)=>x.functionName=='Vehicles')
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  mStartDate: Date | null = null; // Initialize as null
  mEndDate: Date | null = null;
  selectedDate: Date | null = null;
  drivers_name = '';
  searchText: any;
  drivers: any[] = []
  driverName: any;
  selectedVehicle: any;
  constructor(private mDialog:MatDialog,
              private mFirestore: AngularFirestore,
              private loadingService: LoadingService,
              private mService: VehicleService) {
    this.dataSource =new MatTableDataSource<any>()
  }


  ngOnInit(): void {
    this.featchData();
  }
  featchData(){
    this.loadingService.setLoading(true);
    this.mService.getDriver().subscribe(res => {
      this.drivers = res
      this.dataSource = new MatTableDataSource(res)
      console.log(this.dataSource)
      this.dataSource.paginator = this.paginator
      this.dataSource.sort = this.sort
      this.loadingService.setLoading(false);
    })
  }
  searchData() {
    console.log(this.selectedDate);
    console.log(this.driverName);
    this.loadingService.setLoading(true);
    this.mFirestore.collection("ALDrivers").valueChanges().subscribe((res: any[]) => {
      console.log(res);

      const filteredData = res.filter((item: any) => {
        if (!item.dateOfJoining) return false; // Ensure dateOfJoining exists

        const joinDate = new Date(item.dateOfJoining.seconds * 1000 + item.dateOfJoining.nanoseconds / 1e6);

        // Check if selected date matches or is undefined
        const isDateMatch = !this.selectedDate || joinDate.toDateString() === this.selectedDate.toDateString();

        // Combine firstName and lastName to compare with driverName
        const fullName = `${item.firstName} ${item.lastName}`.toUpperCase();

        // Check if the full name matches the selected driver name (case-insensitive)
        const isDriverMatch = !this.driverName || fullName === this.driverName.toUpperCase();
        return isDateMatch && isDriverMatch;
      });

      // Update the dataSource with filtered and sorted data
      this.dataSource.data = filteredData.sort((a: any, b: any) => {
        const dateA = new Date(a.dateOfJoining.seconds * 1000 + a.dateOfJoining.nanoseconds / 1e6);
        const dateB = new Date(b.dateOfJoining.seconds * 1000 + b.dateOfJoining.nanoseconds / 1e6);
        return dateB.getTime() - dateA.getTime(); // Sort by date in descending order
      });
      // Set paginator and sort after data assignment
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.loadingService.setLoading(false);
    });
  }




  onDriverSelect($event: any) {
    this.driverName = $event.value
  }

  OnVehicleSelect($event: any) {
    this.selectedVehicle = $event.value

  }
  clearAll(){
    this.selectedDate = null;
    this.drivers_name = ''
    this.featchData();
  }

  openDiver() {
    const dialogRef = this.mDialog.open(AddInfoComponent, {
      // width: '1000px',
      maxHeight: '100vh',
      panelClass: 'custom-dialog-container'
    });

  }

  editVehicle(element:any) {
    this.mDialog.open(UpdateDriverComponent, {
      data: element,
      maxHeight: '90vh',
      panelClass: 'custom-dialog-container'
    });

  }


  deleteVehicle(element:any) {
    // console.log(element.id)
    this.mService.deleteDriverData(element.id).then(res => {
      alert("Data deleted successfully")
    })

  }
  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    // @ts-ignore
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  openView(element:any) {
    this.mDialog.open(ViewDriverComponent, {
      data:element,
      maxHeight: '100vh',
      width: '100%'
    })

  }
}
