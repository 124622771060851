import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {Timestamp} from "@angular/fire/firestore";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-view-servicing-details',
  templateUrl: './view-servicing-details.component.html',
  styleUrls: ['./view-servicing-details.component.scss']
})
export class ViewServicingDetailsComponent implements OnInit {

  servicepic : any
  constructor( @Inject(MAT_DIALOG_DATA) public Data:any,private datePipe: DatePipe, private mDialog:MatDialog){
    console.log(Data);
  }


    ngOnInit(): void {
      this.servicepic = this.Data.servicepic || '';
    }
  displayDate(timestamp: Timestamp): string {
    // Check if timestamp exists and is a Firestore Timestamp
    if (timestamp && timestamp.toDate) {
      const date = timestamp.toDate(); // Convert to JavaScript Date
      return this.datePipe.transform(date, 'dd/MM/yyyy') || '';
    }
    return '';
  }

}
