import { Component, OnInit } from '@angular/core';
import {GooglePlacesService, PlaceDetails} from "./google-places.service";

@Component({
  selector: 'app-get-places',
  templateUrl: './get-places.component.html',
  styleUrls: ['./get-places.component.scss']
})
export class GetPlacesComponent implements OnInit {
  places: PlaceDetails[] = [];

  constructor(private googlePlacesService: GooglePlacesService) {
    this.getPlacesByZipCode("444303")
  }

  getPlacesByZipCode(zipCode: string) {
    this.googlePlacesService.getPlacesByZipCode(zipCode).subscribe(
      (places) => {
        this.places = places;
        console.log(this.places);
      },
      (error) => {
        console.error('Error fetching places:', error);
      }
    );
  }
  ngOnInit(): void {
  }

}
