<div class="container" xmlns="http://www.w3.org/1999/html">
  <h3 class=" bg-warning text-center">Unit Transfer Section</h3>

<div class="text-center">
    <mat-radio-group [(ngModel)]="type" aria-label="Select an option">
        <mat-radio-button  value="Unit Transfer">Unit Transfer</mat-radio-button> &nbsp;
        <mat-radio-button value="Centre Transfer">Centre Transfer</mat-radio-button>
    </mat-radio-group></div>



<div class="d-flex justify-content-center gap-2 ">


    <mat-form-field appearance="outline">
        <mat-label>Select Centre</mat-label>
        <mat-select (selectionChange)="onCenterSelected($event)" >
            <!--        <mat-option value="centreCode">centreName</mat-option>-->
            <ng-container *ngFor="let center of (centre|async );" >
                <mat-option  *ngIf="center.isArchive!=true" [value]="center">{{center.centreName}}</mat-option></ng-container>
        </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="type=='Centre Transfer'" appearance="outline">
        <mat-label>Select Centre</mat-label>
        <mat-select (selectionChange)="onCenterSelected2($event)"  >
            <!--        <mat-option value="centreCode">centreName</mat-option>-->
            <ng-container *ngFor="let center of (centre|async );" >
                <mat-option  *ngIf="center.isArchive!=true" [value]="center">{{center.centreName}}</mat-option></ng-container>
        </mat-select>
    </mat-form-field>

</div>
<div class="d-flex justify-content-center gap-2">
        <mat-form-field  appearance="outline" class="col-md-4">
            <mat-label>From</mat-label>
            <mat-select [(ngModel)]="fromChild" (selectionChange)="fetchChildDataFromUnitA($event)">
                <mat-option *ngFor="let u of unitsArray" [value]="u">{{u.name}}  {{u.floorNo}} {{u.key}} </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field  appearance="outline" class="col-md-4">
            <mat-label>To</mat-label>
            <mat-select [(ngModel)]="toChild" (selectionChange)="fetchChildDataFromUnitB($event)">
                <mat-option *ngFor="let u of unitsArray2" [value]="u">{{u.name}}  {{u.floorNo}} {{u.key}} </mat-option>
            </mat-select>
        </mat-form-field>
</div>
<div class="d-flex justify-content-center">
  <button mat-raised-button color="accent" [disabled]="!fromChild || fromChild.allotedTo=='NA'|| !toChild " (click)="submit()">Submit</button>
</div>
</div>



