export interface Guardians {
  key:Guardian[]
}
export  interface Guardian{
  aadharNumber: number
  alternateContactNo: string
  annualIncome: string
  childCode: string
  contactNo: string
  drivingLicense: string
  electionId: string
  firstName: string
  lastName: string
  middleName: string
  occupation: string
  otherDocuments: string
  rationCard: string
  relation: string
  skills: string
  childName:string
}
