import {Component, Inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HospitalService} from '../../../../services/hospital.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TitleCasePipe} from '@angular/common';
import {LogService} from '../../../../services/log.service';
import {Observable} from "rxjs";
import {AngularFireDatabase} from "@angular/fire/compat/database";

@Component({
  selector: 'app-add-hospital',
  templateUrl: './add-hospital.component.html',
  styleUrls: ['./add-hospital.component.css']
})
export class AddHospitalComponent implements OnInit {
  addressForm!: FormGroup;
  drDesignation$!:Observable<any[]>
  userData: any = JSON.parse(localStorage.getItem('userData')!);
  mState = [
    {code: "AN", name: "Andaman and Nicobar Islands"},
    {code: "AP", name: "Andhra Pradesh"},
    {code: "AR", name: "Arunachal Pradesh"},
    {code: "AS", name: "Assam"},
    {code: "BR", name: "Bihar"},
    {code: "CG", name: "Chandigarh"},
    {code: "CH", name: "Chhattisgarh"},
    {code: "DH", name: "Dadra and Nagar Haveli"},
    {code: "DD", name: "Daman and Diu"},
    {code: "DL", name: "Delhi"},
    {code: "GA", name: "Goa"},
    {code: "GJ", name: "Gujarat"},
    {code: "HR", name: "Haryana"},
    {code: "HP", name: "Himachal Pradesh"},
    {code: "JK", name: "Jammu and Kashmir"},
    {code: "JH", name: "Jharkhand"},
    {code: "KA", name: "Karnataka"},
    {code: "KL", name: "Kerala"},
    {code: "LD", name: "Lakshadweep"},
    {code: "MP", name: "Madhya Pradesh"},
    {code: "MH", name: "Maharashtra"},
    {code: "MN", name: "Manipur"},
    {code: "ML", name: "Meghalaya"},
    {code: "MZ", name: "Mizoram"},
    {code: "NL", name: "Nagaland"},
    {code: "OR", name: "Odisha"},
    {code: "PY", name: "Puducherry"},
    {code: "PB", name: "Punjab"},
    {code: "RJ", name: "Rajasthan"},
    {code: "SK", name: "Sikkim"},
    {code: "TN", name: "Tamil Nadu"},
    {code: "TS", name: "Telangana"},
    {code: "TR", name: "Tripura"},
    {code: "UK", name: "Uttarakhand"},
    {code: "UP", name: "Uttar Pradesh"},
    {code: "WB", name: "West Bengal"}]

  constructor(
    private fb: FormBuilder,
    private hospitalService: HospitalService,
    private matDialogRef: MatDialogRef<AddHospitalComponent>,
    private snackBar: MatSnackBar,
    private titlecasePipe: TitleCasePipe,
    private mFirDatabase :AngularFireDatabase,
    private logService: LogService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.drDesignation$ = this.mFirDatabase.list<any>('types/Doctor Designation').valueChanges()
  }

  ngOnInit(): void {
    this.initForm();
    if (this.data) { // Check if data exists AND has drDetails
      this.patchFormValues();
    } else {
      this.addDrDetail(); // Call addDrDetail, not createDrDetail
      this.addSocialWorkerDetail();
    }
  }

  private initForm(): void {
    this.addressForm = this.fb.group({
      hospitalName: ['', Validators.required],
      address: ['', Validators.required],
      shortName: ['', Validators.required],
      city: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      state: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
      zipcode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('[0-9]*')]],
      drDetails: this.fb.array([]),
      socialWorkerDetails: this.fb.array([]),
    });
  }

  private patchFormValues(): void {
    if (this.data) {
      this.addressForm.patchValue({
        hospitalName: this.data.hospitalName,
        address: this.data.address,
        shortName: this.data.shortName,
        city: this.data.city,
        state: this.data.state,
        zipcode: this.data.zipcode,
      });
      if (this.data.drDetails && typeof this.data.drDetails === 'object') {
        const drDetailsArray = Object.values(this.data.drDetails); // Convert object to array
        drDetailsArray.forEach((dr: any) => {
          this.drDetail.push(
            this.fb.group({
              doctorsName: [dr?.doctorsName || '', Validators.required],
              designation: [dr?.designation || '', Validators.required],
              phoneNo: [dr?.phoneNo || '', [ Validators.pattern(/^[0-9]{10}$/)]],
              email: [dr?.email, [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]]
            })
          );
        });
      }
      if (this.data.socialWorkerDetails && typeof this.data.socialWorkerDetails === 'object') {
        const socialWorkerArray = Object.values(this.data.socialWorkerDetails); // Convert object to array
        socialWorkerArray.forEach((sw: any) => {
          this.socialWorkerDetail.push(
            this.fb.group({
              socialWorkerName: [sw?.socialWorkerName || '', Validators.required],
              phoneNo: [sw?.phoneNo || '', [ Validators.pattern(/^[0-9]{10}$/)]],
              email: [sw?.email, [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]]
            })
          );
        });
      }
    }
    if (
      (!this.data.drDetails || (Array.isArray(this.data.drDetails) && this.data.drDetails.length === 0)) &&
      (!this.data.socialWorkerDetails || (Array.isArray(this.data.socialWorkerDetails) && this.data.socialWorkerDetails.length === 0))
    ) {
      this.addDrDetail();
      this.addSocialWorkerDetail();
    }

  }


  get drDetail(): FormArray {
    return this.addressForm.get('drDetails') as FormArray;
  }

  get socialWorkerDetail(): FormArray {
    return this.addressForm.get('socialWorkerDetails') as FormArray;
  }

  createDrDetail(): FormGroup {
    return this.fb.group({
      doctorsName: ['', Validators.required],
      designation:['',Validators.required],
      phoneNo: ['',Validators.pattern(/^[0-9]{10}$/)],
      email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]]
    });
  }

  createSocialWorkerDetail(): FormGroup {
    return this.fb.group({
      socialWorkerName: ['', Validators.required],
      phoneNo: ['',Validators.pattern(/^[0-9]{10}$/)],
      email: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)]]
    });
  }

  addDrDetail(): void {
    this.addressForm.markAsUntouched();
    this.drDetail.push(this.createDrDetail());
  }

  addSocialWorkerDetail(): void {
    this.socialWorkerDetail.push(this.createSocialWorkerDetail());
  }

  removeDrDetail(index: number): void {
    this.drDetail.removeAt(index);
  }

  removeSocialWorkerDetail(index: number): void {
    this.socialWorkerDetail.removeAt(index);
  }

  onSubmit(): void {
    if (this.addressForm.invalid) {
      this.snackBar.open('Please enter the form properly', '', {duration: 3000});
      return;
    }
    const formData = {...this.addressForm.value, createdDate: new Date().toISOString(), addedBy: this.userData?.email};
    if(this.data){
      this.hospitalService.updatehospital(this.data.id, formData).then(() => {
        this.snackBar.open('Hospital updated successfully', '', { duration: 3000 });
        this.logService.addLog({
          date: Date.now(),
          section: 'Hospital',
          action: 'Update',
          description: `Hospital updated by ${this.userData?.email}`,
          currentIp: localStorage.getItem('currentip')!
        });

        this.matDialogRef.close();
      });
    }else{
      this.hospitalService.addHospital(formData).then(() => {
        this.logService.addLog({
          date: Date.now(),
          section: 'Hospital',
          action: 'Submit',
          description: `Hospital added by ${this.userData?.email}`,
          currentIp: localStorage.getItem('currentip')!
        });
        this.matDialogRef.close();
      });
    }
  }

  onCancel(): void {
    this.matDialogRef.close();
  }

  onBlur(formControl: FormControl): void {
    if (formControl.value) {
      formControl.setValue(this.titlecasePipe.transform(formControl.value.trim().toUpperCase()));
    }
  }
}
