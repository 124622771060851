import {Component, OnInit, ViewChild} from '@angular/core';
import {AddVehiclesComponent, Vehicle} from "../add-vehicles/add-vehicles.component";
import {VehicleService} from "../vehicle.service";
import {MatDialog} from "@angular/material/dialog";
import {UpdateVehiclesComponent} from "../update-vehicles/update-vehicles.component";
import {UserRole} from "../../rename/add-user-role/add-user-role.component";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {ViewCarLogBookComponent} from "../view-car-log-book/view-car-log-book.component";
import {TripData} from "../car-log-book/car-log-book.component";
import {ViewVehicleComponent} from "../view-vehicle/view-vehicle.component";
import {MatTableDataSource} from "@angular/material/table";
import {LoadingService} from "../../../services/loading.service";
import {ConfirmDialogComponent} from "../../../confirm-dialog/confirm-dialog.component";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {LogService} from "../../../services/log.service";

@Component({
  selector: 'app-vehicle-table',
  templateUrl: './vehicle-table.component.html',
  styleUrls: ['./vehicle-table.component.scss']
})
export class VehicleTableComponent implements OnInit {
  dataSource: MatTableDataSource<Vehicle>
  displayedColumns: string[] = ['vehicleName', 'vehicleNumber','tripStatus', 'vehicleBrand','location', 'action'];
  permissions = JSON.parse(localStorage.getItem('permissions')!) as UserRole[]
  vehicle=this.permissions.filter((x)=>x.functionName=='Vehicles')
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(private mService:VehicleService,
              private loadingService: LoadingService,
              private mDialog: MatDialog,
              private firestore : AngularFirestore,
              private logService : LogService,) {
this.dataSource =new MatTableDataSource<Vehicle>()
  }

  ngOnInit(): void {
    this.loadingService.setLoading(true);
    this.mService.getVehicles().subscribe(res =>{
      this.dataSource =new MatTableDataSource<Vehicle>(res)
      this.dataSource.paginator =this.paginator
      this.dataSource.sort =this.sort
      this.loadingService.setLoading(false);

    })
  }
private getNextStatus(currentStatus: string): string {
  const statuses = ['off_trip', 'on_trip', 'round_trip'];
    const currentIndex = statuses.indexOf(currentStatus);
    if (currentIndex === -1) {
      throw new Error('Invalid status');
    }
    const nextIndex = (currentIndex + 1) % statuses.length;
    return statuses[nextIndex];
  }


  openConfirmationDialog(data: any): void {
    const dialogRef = this.mDialog.open(ConfirmDialogComponent, {
      width: '300px',
      height: '150px',
      data: {
        status: data.status,
        data:data,
        message: 'Do you want to change the status?'
      }
    });

    dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
        let newStatus = ''
        if(data.status === 'on_trip'){
           newStatus = data.status = 'off_trip';
        }else if(data.status === 'round_trip'){
          newStatus = data.status ='off_trip';
        }
        this.changeStatus(data.id, newStatus,data);
      } else {
        console.log('Status change cancelled');
      }
    });
  }
  changeStatus(id: string, newStatus: string,data:any): void {
    console.log(`Attempting to update status for document ID: ${id} to ${newStatus}`);
    let activity = {
      date: new Date().getTime(),
      section: 'Vehicle',
      action: 'Update',
      description: `Update by user vehicle status by is ,${data.vehicle_info?.firstName+''+data.vehicle_info?.lastName}, ${id}`,
      currentIp: localStorage.getItem('currentip')!,
    };
    this.logService.addLog(activity);
    // Assuming 'site_products_list' is your collection name
    this.firestore.collection('ALVehicles').doc(id).update({
      status: newStatus
    }).then(() => {
      console.log('Status updated successfully for document ID:', id);
      // Optionally, you can refresh the data or show a success message here
    }).catch((error) => {
      console.error('Error updating status:', error);
    });
  }

  editVehicle(element:any) {
    const dialogRef = this.mDialog.open(UpdateVehiclesComponent, {
      width: '1000px',
      maxHeight: '90vh',
      data: element
    });

  }
  openVehicle() {
    const dialogRef = this.mDialog.open(AddVehiclesComponent, {
      width: '1000px',
      maxHeight: '90vh', // Adjust the value based on your requirements
      panelClass: 'custom-dialog-container'
    });


  }
  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    // @ts-ignore
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  deleteVehicle(element:any) {
    this.mService.deleteVehicle(element.id).then(res => {
      // alert("data deleted successfully")
    })

  }

  openView(element:any) {
    this.mDialog.open(ViewVehicleComponent, {
      data:element,
      maxHeight: '100vh',
      width: '100%'
    })

  }
}
