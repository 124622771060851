import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {MatDialog} from "@angular/material/dialog";
import {AddCarLogBookComponent} from "../add-car-log-book/add-car-log-book.component";
import {ViewCarLogBookComponent} from "../view-car-log-book/view-car-log-book.component";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from '@angular/material/table';
import {VehicleService} from "../vehicle.service";
import {LoadingService} from "../../../services/loading.service";
import {ConfirmDialogComponent} from "../../../confirm-dialog/confirm-dialog.component";
import {LogService} from "../../../services/log.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {EditCarLogBookComponent} from "../edit-car-log-book/edit-car-log-book.component";


export interface TripData {
  drop_location: string;
  endTripImage: string;
  id: string;
  pick_up_location: string;
  purpose_of_trip: string;
  reason: string | null;
  remark: string;
  startTripImage: string;
  status: string;
  trip_ended_at?: {
    nanoseconds: number;
    seconds: number;
  };
  trip_started_at: {
    nanoseconds: number;
    seconds: number;
  };
  vehicle_info: {
    driverAddress: string;
    driverContactNo: string | null;
    driverName: string;
    email: string;
    licenseNumber: string;
    vehicleNumber: string;
    vehicleType: string;
  };
}

@Component({
  selector: 'app-car-log-book',
  templateUrl: './car-log-book.component.html',
  styleUrls: ['./car-log-book.component.scss']
})
export class CarLogBookComponent implements OnInit {
  displayedColumns: string[] = ['tripDate', 'tripFrom', 'tripTo', 'vehicleNo', 'driverName', 'startTripDate', 'endTripDate', 'tripType', 'totalKms', 'action'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  // @ViewChild(MatSort) sort!: MatSort
  mStartDate: Date | null = null; // Initialize as null
  mEndDate: Date | null = null;
  drivers_name = '';
  vehicles_no = ''
  searchText: any;
  getEmail: string = '';
  drivers: any[] = []
  vehicles: any[] = [];
  driverName: any;
  driverNames: any
  selectedVehicle: any;  //dropdown option use
  vehicleNumbers: any; //dropdown option use
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private mFirestore: AngularFirestore,
    private loadingService: LoadingService,
    private matDialog: MatDialog,
    private mLogService: LogService,
    private snackBar: MatSnackBar,
    private readonly mVehicleService: VehicleService) {
    this.dataSource = new MatTableDataSource<any>()
  }

  ngOnInit(): void {
    this.getEmail = localStorage.getItem("userEmail") || '';
    this.mVehicleService.getDriver().subscribe(res => {
      this.drivers = res
    })
    this.mVehicleService.getVehicles().subscribe(res => {
      this.vehicles = res
    })
    this.loadData();
  }

  loadData() {
    this.loadingService.setLoading(true);
    this.mFirestore.collection("ALTrips", ref => ref.orderBy('trip_started_at', 'desc').limit(100)).valueChanges().subscribe(res => {
      const uniqueDriverNames = new Set<string>();
      const vehicleNumbers = new Set<string>();
      res.forEach((vNo: any) => {
        const vehicleNo = vNo.vehicle_info.vehicleNumber;
        vehicleNumbers.add(vehicleNo);
      })
      res.forEach((trip: any) => {
        const driverName = trip.vehicle_info?.driverName;
        if (driverName) {
          uniqueDriverNames.add(driverName); // Add to Set (duplicates will be ignored)
        }
      });
      // Convert Set to Array and log unique driver names
      this.driverNames = Array.from(uniqueDriverNames);
      this.vehicleNumbers = Array.from(vehicleNumbers);
      this.dataSource = new MatTableDataSource<any>(res)
      this.dataSource.paginator = this.paginator
      this.dataSource.sort = this.sort;
      this.loadingService.setLoading(false);
    });
  }

  clearAll() {
    this.mStartDate = null;
    this.mEndDate = null
    this.drivers_name = ''
    this.vehicles_no = ''
    this.loadData();
  }

  openAddCarLog() {
    this.matDialog.open(AddCarLogBookComponent, {
      maxHeight: '100vh',
      maxWidth: '90vw'
    })
  }

  openView(element: TripData) {
    this.matDialog.open(ViewCarLogBookComponent, {
      data: element,
      maxHeight: '600px',
      maxWidth: '900vw',
    })
  }

  displayDate(dateObject: { nanoseconds: number; seconds: number } | null): string {
    if (!dateObject) {
      return 'N/A'; // or any default value for null dates
    }
    const date = new Date(dateObject.seconds * 1000 + dateObject.nanoseconds / 1e6);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
  }

  displayDate2(dateObject: { nanoseconds: number; seconds: number }): string {
    const date = new Date(dateObject.seconds * 1000 + dateObject.nanoseconds / 1e6);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear().toString().slice(-2);
    return `${day}/${month}/${year}`;
  }

  // edit(element: any) {
  //   this.matDialog.open(AddCarLogBookComponent, {
  //     maxHeight: '100vh',
  //     maxWidth: '90vw',
  //     data: element
  //   })
  // }
  edit(element: any) {
    this.matDialog.open(EditCarLogBookComponent, {
      maxHeight: '100vh',
      maxWidth: '90vw',
      data: element
    })
  }

  confirmDelete(element: any) {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: {
        data: element,
        message: `Are you sure you want to delete this record with  ${element?.vehicle_info?.driverName}?`
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        let activity = {
          date: new Date().getTime(),
          section: 'Vehicle',
          action: 'Delete',
          description: ` Trip Delete for
          ${element?.vehicle_info?.driverName},
          ${element?.vehicle_info?.vehicleNumber},Meter start Reading
          ${element?.pick_up_meter_reading},Meter End Reading
          ${element?.drop_meter_reading} by user`,
          currentIp: localStorage.getItem('currentip')!,
        };
        this.mLogService.addLog(activity);
        this.deleteLog(element);
      }
    });
  }

  deleteLog(element: any) {
    this.mVehicleService.carLogDelete(element.id).then(res => {
      this.snackBar.open(`Record ${element?.vehicle_info?.driverName} deleted successfully`, '', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      });
    })
  }

  calculateTotalKms(element: any): number {
    const pickUpMeterReading = parseFloat(element.pick_up_meter_reading);
    const dropMeterReading = parseFloat(element.drop_meter_reading);
    if (pickUpMeterReading == null || dropMeterReading == null) {
      // Handle missing or invalid data, e.g., return 0 or show an error
      return 0;
    } else if (isNaN(pickUpMeterReading) || isNaN(dropMeterReading)) {
      // Handle missing or invalid data, e.g., return 0 or show an error
      return 0;
    }
    const totalKms = Math.max(0, dropMeterReading - pickUpMeterReading);
    return totalKms;
  }

  // searchData() {
  //   this.loadingService.setLoading(true);
  //   this.mFirestore.collection("ALTrips",ref => ref.orderBy('trip_started_at', 'desc')).valueChanges().subscribe((res: any[]) => {
  //     const filteredData = res.filter((item: any) => {
  //         const tripDate = new Date(item.trip_started_at.seconds * 1000 + item.trip_started_at.nanoseconds / 1e6);
  //         const isDateInRange = this.mStartDate && this.mEndDate && (tripDate >= this.mStartDate && tripDate <= this.mEndDate);
  //         const isVehicleMatch = this.selectedVehicle && item.vehicle_info.vehicleNumber === this.selectedVehicle;
  //         const isDriverMatch = this.driverName && item.vehicle_info.driverName === this.driverName;
  //         if (this.mStartDate && this.mEndDate && this.selectedVehicle && this.driverName) {
  //           // All filters: Date range, vehicle, and driver
  //           return isDateInRange && isVehicleMatch && isDriverMatch;
  //         } else if (this.mStartDate && this.mEndDate && this.selectedVehicle) {
  //           // Date range and vehicle only
  //           return isDateInRange && isVehicleMatch;
  //         } else if (this.mStartDate && this.mEndDate && this.driverName) {
  //           // Date range and driver only
  //           return isDateInRange && isDriverMatch;
  //         } else if (this.selectedVehicle && this.driverName) {
  //           // Vehicle and driver only
  //           return isVehicleMatch && isDriverMatch;
  //         } else {
  //           // Default to OR logic if no specific combination is required
  //           return isDateInRange || isVehicleMatch || isDriverMatch;
  //         }
  //       }
  //     );
  //
  //     // Sort the filtered data by trip_started_at in descending order
  //     this.dataSource.data = filteredData.sort((a: any, b: any) => {
  //       const dateA = new Date(a.trip_started_at.seconds * 1000 + a.trip_started_at.nanoseconds / 1e6);
  //       const dateB = new Date(b.trip_started_at.seconds * 1000 + b.trip_started_at.nanoseconds / 1e6);
  //
  //       // Sort in descending order
  //       return dateB.getTime() - dateA.getTime();
  //     });
  //     this.loadingService.setLoading(false);
  //   });
  // }
  searchData() {
    this.loadingService.setLoading(true);

    this.mFirestore.collection("ALTrips", ref => ref.orderBy('trip_started_at', 'desc')).valueChanges().subscribe((res: any[]) => {
      const startDate = this.mStartDate ? new Date(this.mStartDate.setHours(0, 0, 0, 0)) : null;
      const endDate = this.mEndDate ? new Date(this.mEndDate.setHours(23, 59, 59, 999)) : null;

      const filteredData = res.filter((item: any) => {
        // Convert Firestore timestamp to JavaScript Date
        const tripDate = new Date(item.trip_started_at.seconds * 1000 + item.trip_started_at.nanoseconds / 1e6);

        // Ensure date is in range (inclusive)
        const isDateInRange = startDate && endDate && (tripDate >= startDate && tripDate <= endDate);
        const isVehicleMatch = this.selectedVehicle && item.vehicle_info.vehicleNumber === this.selectedVehicle;
        const isDriverMatch = this.driverName && item.vehicle_info.driverName === this.driverName;

        if (this.mStartDate && this.mEndDate && this.selectedVehicle && this.driverName) {
          return isDateInRange && isVehicleMatch && isDriverMatch;
        } else if (this.mStartDate && this.mEndDate && this.selectedVehicle) {
          return isDateInRange && isVehicleMatch;
        } else if (this.mStartDate && this.mEndDate && this.driverName) {
          return isDateInRange && isDriverMatch;
        } else if (this.selectedVehicle && this.driverName) {
          return isVehicleMatch && isDriverMatch;
        } else {
          return isDateInRange || isVehicleMatch || isDriverMatch;
        }
      });

      // Sort the filtered data by trip_started_at in descending order
      this.dataSource.data = filteredData.sort((a: any, b: any) => {
        const dateA = new Date(a.trip_started_at.seconds * 1000 + a.trip_started_at.nanoseconds / 1e6);
        const dateB = new Date(b.trip_started_at.seconds * 1000 + b.trip_started_at.nanoseconds / 1e6);
        return dateB.getTime() - dateA.getTime();
      });

      this.loadingService.setLoading(false);
    }, error => {
      console.error("Error fetching data:", error);
      this.loadingService.setLoading(false);
    });
  }


  onDriverSelect($event: any) {
    this.driverName = $event.value
  }

  OnVehicleSelect($event: any) {
    this.selectedVehicle = $event.value

  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    // @ts-ignore
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
