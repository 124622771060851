// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    useEmulators:false,
    firebase: {
        apiKey: "AIzaSyBWOlzNGOIKxx3Da-_qV-tmn8Ej3Z1hteU",
        authDomain: "acceife-fbdb5.firebaseapp.com",
        databaseURL: "https://acceife-fbdb5-default-rtdb.firebaseio.com",
        projectId: "acceife-fbdb5",
        storageBucket: "acceife-fbdb5.appspot.com",
        messagingSenderId: "409067061417",
        appId: "1:409067061417:web:0761c7794887c91bd1a9b8",
        measurementId: "G-8EV8QWGFYL"
    },
    production: true,
    mode: 'dev',
    dataEnvironment: 'test',
    testMode: {
        // centres: 'ALCentres',
        centres: 'devCentre',
        documents: 'Documents',
        units: 'devUnits',
        // units: 'ALUnits',
        // child:'UploadALChild',
        child: 'devChild',
        // child: 'ALChild',
        // hospitals: 'dummy/hospitals',
        hospitals: 'testHospitals',
        reminders: 'Reminders',
        prescreening: 'testchild',
        caretakers: 'caretakers',
        counters: 'counters',
        counseling: 'counsellingTest',
        education: 'educationTesting',
        educationGroup: 'educationGroupTesting',
        assessment: 'assessmentsTest',
        employee: 'dummy/employeelist',
        users: 'users',
        volunteers: 'dummy/volunteerlist',
        reqvolunteers: 'volunteer/volunteers',
        donors: 'donors',
        fileUploads: 'testfileuploads',
        mealmatesEvents: 'meal-mate/events/',
        mealmatesCounter: 'meal-mate/meal-mate-counter/',
        mealMateDonations: 'MealmateDonations-test',
        receiptCounter: 'receiptcounter/testcounter',
        events: 'events',
      showInventory: true,
      sponsoredChildren: 'testSponsoredChildren',
      unitsponsorship: 'testUnitSponsorships',
      skuCounter: 'testCounters/sku',
      products: 'product',
        purchaseInvoices: 'purchaseInvoices',
        centreInventory: 'centreInventory',
        stockAdjustment: "stockAdjustment",
        inventoryRequests: 'inv-requests',
        transferInventory: 'transferInventory',
        IssueInventory: 'IssueInventory',
        purchaselist: 'purchase-list',
    },
  // testMode: {
  //   centres: 'ALCentres',
  //   units: 'ALUnits',
  //   hospitals: 'Hospitals',
  //   documents: 'ALDocuments',
  //   education: 'educationTest',
  //   educationGroup: 'educationGroupTest',
  //   users: 'users',
  //   child: 'ALChild',
  //   reminders: 'ALReminders',
  //   prescreening: 'ALPrescreening',
  //   caretakers: 'ALCaretakers',
  //   counters: 'ALCounters',
  //   employee: 'ALEmployee',
  //   volunteers: 'ALVolunteers',
  //   reqvolunteers: 'ALReqVolunteers',
  //   assessment: 'Assessments',
  //   counseling: 'Counselling',
  //   donors: 'ALDonors',
  //   fileUploads: 'FileUploads',
  //   mealmatesEvents: 'ALMEAL-MATE/events/',
  //   mealmatesCounter: 'ALMEAL-MATE/meal-mate-counter/',
  //   mealMateDonations: 'MealmateDonations',
  //   receiptCounter: 'receiptcounter/counter',
  //   skuCounter: 'ALCounters/sku',
  //   events: 'events',
  //   sponsoredChildren: 'sponsoredChildren',
  //   unitsponsorship: 'unitSponsorships',
  //   showInventory: true,
  //   products: 'ALProducts',
  //   purchaseInvoices: 'ALPurchases',
  //   centreInventory: 'ALInventory',
  //   stockAdjustment: "ALStockAdjustments",
  //   inventoryRequests: 'ALInventoryRequests',
  //   transferInventory: 'ALTransfers',
  //   IssueInventory: 'ALDirectIssue',
  //   purchaselist: 'ALPurchaseList'
  //
  //
  // },
    productionMode: {
        centres: 'ALCentres',
        units: 'ALUnits',
        hospitals: 'Hospitals',
        documents: 'ALDocuments',
        education: 'educationTest',
        educationGroup: 'educationGroupTest',
        users: 'users',
        child: 'ALChild',
        reminders: 'ALReminders',
        prescreening: 'ALPrescreening',
        caretakers: 'ALCaretakers',
        counters: 'ALCounters',
        employee: 'ALEmployee',
        volunteers: 'ALVolunteers',
        reqvolunteers: 'ALReqVolunteers',
        assessment: 'Assessments',
        counseling: 'Counselling',
        donors: 'ALDonors',
        fileUploads: 'FileUploads',
        mealmatesEvents: 'ALMEAL-MATE/events/',
        mealmatesCounter: 'ALMEAL-MATE/meal-mate-counter/',
        mealMateDonations: 'MealmateDonations',
        receiptCounter: 'receiptcounter/counter',
        skuCounter: 'ALCounters/sku',
        events: 'events',
        sponsoredChildren: 'sponsoredChildren',
        unitsponsorship: 'unitSponsorships',
        showInventory: true,
        products: 'ALProducts',
        purchaseInvoices: 'ALPurchases',
        centreInventory: 'ALInventory',
        stockAdjustment: "ALStockAdjustments",
        inventoryRequests: 'ALInventoryRequests',
        transferInventory: 'ALTransfers',
        IssueInventory: 'ALDirectIssue',
        purchaselist: 'ALPurchaseList'


    },
    cloudFunctions: {
        getpayments: 'https://us-central1-acceife-fbdb5.cloudfunctions.net/fetchpayments',
        fetchPaymentByDate: 'https://us-central1-acceife-fbdb5.cloudfunctions.net/fetchPaymentsByDateAPI',
        geTestpatemnts: ' https://us-central1-acceife-fbdb5.cloudfunctions.net/geTestpayments',
        fetchpayments: 'https://us-central1-acceife-fbdb5.cloudfunctions.net/fetchpayments',
        fetchRazoryPay: 'https://us-central1-acceife-fbdb5.cloudfunctions.net/fetchRazoryPay',
        fetchRazoryPaycors: 'https://us-central1-acceife-fbdb5.cloudfunctions.net/fetchRazoryPaycors'
    }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
// test accesslige key

//* var test_key_id = "rzp_test_M9aqnxvLMQo6VC";
//* var key_id = "rzp_live_n1im1rcGlUYmyU";
//* var test_key_secret = "hbAlfVs0EdoEZqy9uKz4ggQ5";
//*  test accesslife
//* var key_secret = "nuYSZGm2PvUjbnZ

