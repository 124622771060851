<section class="">
  <div class="">

    <!-- Your content goes here  -->
    <div class="row clearfix">
      <div class="col-lg-4 col-md-12">
        <div class="card">
          <div class="m-b-20">
            <div class="contact-grid">
              <div class="profile-header bg-dark">
                <div class="user-name">{{vehicleData.vehicleName}} </div>
                <div class="text-warning my-1">Vehicle Number: {{vehicleData.vehicleNumber}}</div>
                <div class="text-warning">Base center: {{vehicleData.vehicleBrand}}</div>
              </div>
              <img class="user-img" alt="">
              <p>
                <strong>Month/Year of Manufacturing : </strong><span
                class="text-muted">{{(vehicleData.manufactureDate).toDate() | date:'MMM/yyyy'}}</span>
              </p>
              <p>
                <strong>Engine No : </strong><span
                class="text-muted">{{(vehicleData.engineNumber) }}</span>
              </p>
              <p>
                <strong>Chassis Number : </strong><span
                class="text-muted">{{(vehicleData.chassisNumber) }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="card">
<!--          <mat-tab-group mat-align-tabs="start">-->
<!--            <mat-tab label="Admission Records">-->
<!--              <div class="p-4">-->

<!--                <section>-->
<!--                  <div class="w-100">-->
<!--                    <mat-table [dataSource]="datasource" matSort class="table table-bordered">-->

<!--                      <ng-container matColumnDef="dateOfAdmission">-->
<!--                        <mat-header-cell *matHeaderCellDef mat-sort-header> Date Of Admission</mat-header-cell>-->
<!--                        <mat-cell *matCellDef="let row"> {{row.admissionDate|date:'dd/MM/yyyy'}} </mat-cell>-->
<!--                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>-->

<!--                      </ng-container>-->
<!--                      <ng-container matColumnDef="dateOfDischarge">-->
<!--                        <mat-header-cell *matHeaderCellDef mat-sort-header> Date Of Discharge</mat-header-cell>-->
<!--                        <mat-cell *matCellDef="let row"> {{row.dischargeDate|date:'dd/MM/yyyy'}} </mat-cell>-->
<!--                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>-->

<!--                      </ng-container>-->
<!--                      <ng-container matColumnDef="duration">-->
<!--                        <mat-header-cell *matHeaderCellDef mat-sort-header> Duration</mat-header-cell>-->
<!--                        <mat-cell *matCellDef="let row">-->
<!--                          <ng-container *ngIf="row.treatmentDuration !== 0">{{ row.treatmentDuration }}</ng-container>-->
<!--                        </mat-cell>-->
<!--                        <mat-footer-cell *matFooterCellDef>-->
<!--                          {{ datasource.data }}-->
<!--                        </mat-footer-cell>-->
<!--                      </ng-container>-->

<!--                      &lt;!&ndash; Add other columns similarly &ndash;&gt;-->

<!--                      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>-->
<!--                      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>-->
<!--                      <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>-->

<!--                    </mat-table>-->

<!--                    <mat-paginator [pageSizeOptions]="[25, 50, 100,1000]"></mat-paginator>-->
<!--                  </div>-->
<!--                </section>-->
<!--                <hr>-->
<!--              </div>-->
<!--            </mat-tab>-->
<!--            <mat-tab label="Treatment Records">-->
<!--              <div class="p-4">-->

<!--                <table class="table table-bordered">-->
<!--                  <thead>-->
<!--                  <tr>-->
<!--                    <th>Date</th>-->
<!--                    <th>Height</th>-->
<!--                    <th>Weight</th>-->
<!--                  </tr>-->
<!--                  </thead>-->
<!--                  <tbody>-->
<!--                  <tr>-->
<!--                    <td>{{(vehicleData.dateOfAdmission).toDate() | date:'dd/MM/yyyy'}}</td>-->
<!--                    <td>{{vehicleData.height}}</td>-->
<!--                    <td>{{vehicleData.weight}}</td>-->
<!--                  </tr>-->
<!--                  </tbody>-->
<!--                </table>-->
<!--              </div>-->
<!--            </mat-tab>-->
<!--          </mat-tab-group>-->
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="card">
          <mat-tab-group>
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon msr-2">airport_shuttle</mat-icon>
                Vehicle
              </ng-template>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="project_widget">
                  <div class="header">
                    <h2><strong>Vehicle Details</strong></h2>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-md-4  ">
                        <strong>Driver Name</strong>
                        <!--                        <br>-->
                        <p
                          class="text-muted"> {{vehicleData.driver}}</p>
                      </div>

                      <div class="col-md-4  ">
                        <strong>Fuel Used</strong>
                        <p class="text-muted mb-0">
                          {{vehicleData.fuelUsed}}
                        </p>
                      </div>

                      <div class="col-md-4  ">
                        <strong>Horse Power</strong>
                        <!--                        <br>-->
                        <p class="text-muted"> {{vehicleData.horsePower}}</p>
                      </div>
                      <div class="col-md-4  ">
                        <strong>Makers name</strong>
                        <!--                        <br>-->
                        <p class="text-muted"> {{vehicleData.makersName}}</p>
                      </div>
                      <div class="col-md-4  ">
                        <strong>Number of Cylinders</strong>
                        <!--                        <br>-->
                        <p class="text-muted"> {{vehicleData.numberOfCylinders}}</p>
                      </div>

                      <div class="col-md-4  ">
                        <strong>Provider Name</strong>
                        <!--                        <br>-->
                        <p class="text-muted">{{vehicleData.providerName}}</p>
                      </div>
                      <div class="col-md-4  ">
                        <strong>Seating Capacity</strong>
                        <!--                        <br>-->
                        <p class="text-muted">{{vehicleData.seatingCapacity}}</p>
                      </div>



                      <div class="col-md-4 ">
                        <strong>Servicing Date</strong>
                        <!--                        <br>-->
                        <p class="text-muted">{{(vehicleData.servicingDate).toDate() | date:'dd/MM/yyyy' }}</p>
                      </div>

                    </div>
                    <br>
                  </div>
                </div>
              </div>
              <hr>
              <div class="text-center">
                <button mat-raised-button color="accent" (click)="onCancel()">Cancel</button>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon msr-2">airport_shuttle</mat-icon>
                Vehicle Photos
              </ng-template>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="project_widget">
                  <div class="header">
                    <h2><strong>Vehicle Photos</strong></h2>
                  </div>
                  <div class="body">
                    <div class="row row-gap-2">
                      <div  class="col-md-6 ">
                        <img src="" alt="" height="200px" class="childphoto card">
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="text-center">
                    <button mat-raised-button color="accent" (click)="onCancel()">Cancel</button>
                  </div>
                </div>
              </div>
            </mat-tab>
<!--            <mat-tab>-->
<!--              <ng-template mat-tab-label>-->
<!--                <mat-icon class="example-tab-icon msr-2">face</mat-icon>-->
<!--                Child Story-->
<!--              </ng-template>-->
<!--              <div class="col-lg-12 col-md-12 col-sm-12">-->
<!--                <div class="project_widget">-->
<!--                  <div class="header">-->
<!--                    <h2><strong>Child Story</strong></h2>-->
<!--                  </div>-->
<!--                  <div class="body">-->
<!--                    <div class="row ">-->
<!--                      <p class="text-muted">-->
<!--                        {{vehicleData.sickness}}-->
<!--                      </p>-->


<!--                    </div>-->

<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </mat-tab>-->
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</section>

