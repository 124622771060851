import {Component, OnInit, ViewChild} from '@angular/core';
import {InventoryService} from "../inventory.service";
import {MatTableDataSource} from "@angular/material/table";
import {DatePipe} from "@angular/common";
import {Reciept} from "../../../components/Donationt/DonationModel";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import * as XLSX from "xlsx";
import {ViewDonationComponent} from "../../../components/Donationt/view-donation/view-donation.component";
import {MatDialog} from "@angular/material/dialog";
import {
  ViewAttemptedDonationRecieptComponent
} from "../../../components/Donationt/view-attempted-donation-reciept/view-attempted-donation-reciept.component";
import {LogService} from "../../../services/log.service";

@Component({
  selector: 'app-attempted-donation',
  templateUrl: './attempted-donation.component.html',
  styleUrls: ['./attempted-donation.component.scss']
})
export class AttemptedDonationComponent implements OnInit {
  displayedColumns: string[] = ['donorName','donorMobile', 'email','donoraddress','pan', 'donorcity', 'date','action'];
  dataSource!: MatTableDataSource<any>;
  startDate: any;
  endDate: any;
  resultsLength = 0;
  receipts: Reciept[] = [];
  title = 'angular-export-to-excel';
  dataForExcel: any[] = [];
  isLoadingResults = true;
  isRateLimitReached = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  fileName = 'Attempted_Donation_Report.xlsx';

  constructor(private inventoryService: InventoryService,
              private  readonly mDialog:MatDialog,
              private mLogService:LogService,
              private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.inventoryService.fetchAttemptedDonation().subscribe((data: any) => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    })
  }

  formatDate(timestamp: any): string {
    const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
    return <string>this.datePipe.transform(date, 'dd/MM/yyyy');

  }

  searchbyDateRange() {
    this.inventoryService.getByRangeAttemptedDonations(this.startDate, this.endDate)
      .subscribe(value => {
        this.receipts = []
        this.isLoadingResults = false;
        this.isRateLimitReached = value === null;
        console.log('d: ' + value.length)
        this.receipts = value
        this.dataForExcel = value
        this.dataSource = new MatTableDataSource(this.receipts);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        let activity = {
          date: new Date().getTime(),
          section: 'Donation',
          action: 'Filter',
          description: `Vehicles Filter  Receipt Attempt Donation by user`,
          currentIp: localStorage.getItem('currentip')!
        }
        this.mLogService.addLog(activity)
      })

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  exportexcel() {
    /* assessment-table id is passed over here */
    let element = document.getElementById('excel-table');

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    // const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
    // const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(this.receipts)
    ws['!cols'] = [];
    ws['!cols'][19] = {hidden: true};

    /* O1 is your Column in Excel*/

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);


  }

  viewRecepit(receipt: Reciept) {
    console.log('View Receipt clicked')
    this.mDialog.open(ViewAttemptedDonationRecieptComponent, {
      data: receipt,
      width: '60%',
      height: '80%'
    })

  let activity = {
    date: new Date().getTime(),
    section: 'Donation',
    action: 'View',
    description: `Vehicles view View Receipt Attempt Donation by user`,
    currentIp: localStorage.getItem('currentip')!
  }
  this.mLogService.addLog(activity)
  }
}
