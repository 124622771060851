import {Component, isDevMode, OnInit, ViewChild} from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Observable, Subscription} from "rxjs";
import {AdmissionType} from "../../../interfaces/AdmissionType";
import {iter} from "rrule/dist/esm/src/iter";
import firebase from "firebase/compat";
import Timestamp = firebase.firestore.Timestamp;
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {Child} from "../../child/childdata";
import {ViewRegisteredChildComponent} from "../../child/view-registered-child/view-registered-child.component";
import {MatDialog} from "@angular/material/dialog";
import {
    ViewFollowUpChildDetailsComponent
} from "../view-follow-up-child-details/view-follow-up-child-details.component";
import {DatePipe} from "@angular/common";
import {EditAdmissionReminderComponent} from "../edit-admission-reminder/edit-admission-reminder.component";
import {environment} from "../../../../environments/environment";
import {UnitService} from "../../../services/unit.service";
import {MatSnackBar} from "@angular/material/snack-bar";

export interface FollowUpModel {
  key?: string,
  centreCode: string,
  childCode: string,
  firstName?:string
  lastName?:string
  followUpDate: Timestamp
  remark?: string
}

@Component({
  selector: 'app-followup-admission',
  templateUrl: './followup-admission.component.html',
  styleUrls: ['./followup-admission.component.scss']
})
export class FollowupAdmissionComponent implements OnInit {
    @ViewChild(MatPaginator) paginator1!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;

    // @ViewChild(MatSort) sort!: MatSort;
    // @ts-ignore
    // @ViewChild(MatSort, {static: true}) sort: MatSort;
  mRef: Subscription
  map!: Map<string, FollowUpModel>
  mappArray: any[]=[]
  displayedColumns: string[] = [ 'childName','centreName', 'followUpDate','remark', 'action'];
    env = isDevMode() ? environment.testMode : environment.productionMode

  dataSource = new MatTableDataSource<FollowUpModel>()
  constructor(private readonly mFirestore: AngularFirestore,
              private  datePipe:DatePipe,
              private  matdialog:MatDialog,
              private  readonly unitService:UnitService,
              readonly matSnackbar:MatSnackBar,) {
    this.mRef = this.mFirestore.collection(this.env.reminders).doc('followUpChildren').valueChanges()
      .subscribe(res => {
        // @ts-ignore
        const data = res.remindersdata

        this.map = new Map(Object.entries((data))) as Map<string, FollowUpModel>
        const arr = Object.entries(data)
        const m = new Map<string, any>()
        m.set('s', 'sfs')
        m.set('f', 'df')
        m.set('g', 'f')
        console.log('M1', this.map.entries())

        // @ts-ignore
        for (let pair of this.map) {
          var [key, value] = pair;
          // console.log(key + " = " + value.centreCode);
          const a = value
          a.key = key
          this.mappArray?.push(a)
        }
        // this.mappArray.forEach(it=>{
        //     it.followUpDate =this.datePipe.transform(it.followUpDate.toDate(), 'dd/MM/yyyy');
        // })
        this.dataSource.data=this.mappArray!
          this.dataSource.paginator =this.paginator1
          this.dataSource.sort = this.sort
        // console.log(this.mappArray)
      })
  }

  ngOnInit(): void {
    // this.mRef=  this.mFirestore.collection('Reminders').doc('followUpChildren').valueChanges({idField:'id'})
  }
    datashow(index: Child) {
        console.log('showdata :' + index)
        this.matdialog.open(ViewFollowUpChildDetailsComponent,
            {
                width: '97%', height: '95%',
                enterAnimationDuration: '100ms',
                exitAnimationDuration: '100ms',
                data: index,
                autoFocus: false
                // @ts-ignore


            }
        )
    }

    edit(element:any) {
        this.matdialog.open( EditAdmissionReminderComponent,
            {

                enterAnimationDuration: '100ms',
                exitAnimationDuration: '100ms',
                data: element,
                autoFocus: false
                // @ts-ignore


            }
        )
    }

  delete(element:any) {
    const key= element.key
    this.unitService.deleteReminder(key).then(()=>{
      this.matSnackbar.open('Deleted Successfully')._dismissAfter(3000)
    })

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
