<!--<div class="header">-->
<!--  <h2 mat-dialog-title style="flex-grow: 9">{{data.title}}</h2>-->
<!--  <button mat-icon-button [mat-dialog-close]="false" style="flex-grow: 1">-->
<!--    <mat-icon>close</mat-icon>-->
<!--  </button>-->
<!--</div>-->
<!--<div mat-dialog-content>-->
<!--  {{data.message}}-->
<!--</div>-->
<!--<div mat-dialog-actions [align]="'end'">-->
<!--  <button mat-raised-button [mat-dialog-close]="false">Cancel</button>-->
<!--  <button mat-raised-button color="primary" [mat-dialog-close]="true">Confirm</button>-->
<!--</div>-->
<!--<h2 mat-dialog-title class="text-center">{{ data.title || 'Confirm Action' }}</h2>-->
<h2 mat-dialog-title style="flex-grow: 9"  class="text-center">{{data.title || 'Confirmation'}}</h2>
<mat-dialog-content class="text-center">
  {{ data.message || 'Are you sure you want to proceed?' }}

</mat-dialog-content>
<div class="d-flex justify-content-center">
  <mat-dialog-actions>
    <button mat-raised-button color="warn" (click)="onNoClick()">No</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true">Yes</button>
  </mat-dialog-actions>
</div>
