import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {CreatecenterComponent} from "./components/centers/createcenter/createcenter.component";
import {VolunteersComponent} from "./components/volunteers/volunteers.component";
import {EventsComponent} from "./components/events/events.component";
import {UnitsComponent} from "./components/units/units.component";
import {AuthGuard} from "./auth.guard";
import {ChildlistComponent} from "./components/child/childlist/childlist.component";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {CentrelistComponent} from "./components/centers/centrelist/centrelist.component";
import {DonationtableComponent} from "./components/Donationt/donationtable/donationtable.component";
import {ViewDonationComponent} from "./components/Donationt/view-donation/view-donation.component";
import {DonorTableComponent} from "./components/donoradmin/donor-table/donor-table.component";
import {FullcontentchildComponent} from "./components/child/fullcontentchild/fullcontentchild.component";
import {HospitalsComponent} from "./components/hospitals/hospitals.component";
import {ListCaretakerComponent} from "./components/caretakers/list-caretaker/list-caretaker.component";
import {TypesComponent} from "./components/types/types.component";
import {GetreceiptComponent} from "./components/Donationt/getreciept/getreceipt.component";
import {MealsComponent} from './components/meals/meals.component';
import {MealSlotsComponent} from './components/meals/meal-slots/meal-slots.component';
import {MealsCalenderComponent} from './components/meals/meal-calender/meals-calender.component';
import {EditFullChildComponent} from "./components/child/edit-full-child/edit-full-child.component";
import {PaymentRemindersComponent} from "./components/reminders/payment-reminders/payment-reminders.component";

import {FollowupAdmissionComponent} from "./components/reminders/followup-admission/followup-admission.component";
import {MyTestComponent} from "./my-test/my-test.component";
import {ViewRegisteredChildComponent} from "./components/child/view-registered-child/view-registered-child.component";
import {ViewEventComponent} from "./components/events/view-event/view-event.component";
import {LogoutComponent} from "./logout/logout.component";
import {BulkUploadComponent} from "./bulk-upload/bulk-upload.component";
import {MealMateEventTableComponent} from "./components/meals/meal-mate-event-table/meal-mate-event-table.component";
import {RazorpayDataComponent} from "./components/razorpay-data/razorpay-data.component";
import {
  RegisteredChildExcelComponent
} from "./components/child/registered-child-excel/registered-child-excel.component";
import {RenameComponent} from "./components/rename/rename.component";
import {ActivityLogComponent} from "./components/activity-log/activity-log.component";

import {EmployeelistComponent} from "./components/employee/employeelist/employeelist.component";
import {SponsorTableComponent} from "./components/unit-sponsorship/sponsor-table/sponsor-table.component";
import {
  AllotChildSponsorshipComponent
} from "./components/unit-sponsorship/allot-sponsorship-child/allot-child-sponsorship.component";


import {MainComponent} from "./main/main.component";
import {
    ChildRegistrationTableComponent
} from "./components/child/child-registration-table/child-registration-table.component";

import {AdminChildEditComponent} from "./components/child/admin-child-edit/admin-child-edit.component";
import {GlossaryComponent} from "./components/glossary/glossary.component";
import {RazorpayTransactionComponent} from "./components/razorpay-transaction/razorpay-transaction.component";
import {
  SponsorshipDashboardComponent
} from "./components/unit-sponsorship/sponsorship-dashboard/sponsorship-dashboard.component";
import {DashboardRemindersComponent} from "./components/reminders/dashboard-reminders/dashboard-reminders.component";
import {
  UnsponsorChildrenTableComponent
} from "./components/unit-sponsorship/unsponsor-children-table/unsponsor-children-table.component";
import {AddInfoComponent} from "./components/vehicles/add-info/add-info.component";
import {AddVehiclesComponent} from "./components/vehicles/add-vehicles/add-vehicles.component";
import {VehicleTableComponent} from "./components/vehicles/vehicle-table/vehicle-table.component";
import {DriverTableComponent} from "./components/vehicles/driver-table/driver-table.component";
import {ReportCard} from "./modules/reports/education-reports/report-card/report-card.component";
import {CarLogBookComponent} from "./components/vehicles/car-log-book/car-log-book.component";
import {AllCarFuelDetailsComponent} from "./components/vehicles/all-car-fuel-details/all-car-fuel-details.component";
import {CodeComponent} from "./code/code.component";
import {QuarterReportComponent} from "./quarter-report/quarter-report.component";
import {
  AllCarFuelsDetailsNewComponent
} from "./components/vehicles/all-car-fuels-details-new/all-car-fuels-details-new.component";
//import {AllServicingDetailsNewComponent } from "./components/vehicles/app-all-car-servicing-details-new/app-all-car-servicing-details-new.component";
import {ChildJourneyComponent} from "./components/child/child-journey/child-journey.component";
import {PhotoGalleryComponent} from "./components/photo-gallery/photo-gallery.component";
import {ManageOnlineSlotsComponent} from "./components/meals/manage-online-slots/manage-online-slots.component";
import {
  OnlineDonorsTableComponent
} from "./modules/cms/online-donors-table/online-donors-table.component";
import {CountTableComponent} from "./modules/reports/ops-reports/count-table/count-table.component";
import {DynamicTableComponent} from "./modules/reports/ops-reports/dynamic-table/dynamic-table.component";
import {ChildReportComponent} from "./modules/reports/ops-reports/child-report/child-report.component";
import {CentreEfficiencyComponent} from "./components/dashboard/centre-efficiency/centre-efficiency.component";
import {DataCorrectionComponent} from "./components/data-correction/data-correction.component";
import {ManualDonationComponent} from "./components/Donationt/manual-donation/manual-donation.component";
import {AttemptedDonationComponent} from "./modules/inventory/attempted-donation/attempted-donation.component";
import {FailedDonationsComponent} from "./components/Donationt/failed-donations/failed-donations.component";
import {
  AllServicingDetailsNewComponent
} from "./components/vehicles/all-car-servicing-details-new/all-car-servicing-details-new.component";
import {
  ManualAttemptedDonationRecieptComponent
} from "./components/Donationt/manual-attempted-donation-reciept/manual-attempted-donation-reciept.component";
import {GetPlacesComponent} from "./get-places/get-places.component";
import {MisBorivaliComponent} from "./components/dashboard/mis-borivali/mis-borivali.component";


const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },


    {path: 'getreceipt/:id', component: GetreceiptComponent},
    {
        path: 'home',

        component: MainComponent,
        canActivate: [AuthGuard],
        children: [

            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full'
            },
            {path: 'code', component: CodeComponent},

            {
                path:'quarterly-report',
                component:QuarterReportComponent
            },
          {
            path:'mis-borivali',
            component:MisBorivaliComponent
          },
            {
                path: 'types',
                component: TypesComponent,
                canActivate: [AuthGuard]
            },

            {
                path: "dashboard",
                component: DashboardComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "child-list",
                component: ChildRegistrationTableComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "centre-efficiency",
                component: CentreEfficiencyComponent,
                canActivate: [AuthGuard]
            },
          {
                path: "child-journey",
                component: ChildJourneyComponent,
                canActivate: [AuthGuard]
            },
          {
            path: "dc",
            component: DataCorrectionComponent,
            canActivate: [AuthGuard]
          },
            {
                path: "manual-donation",
                component: ManualDonationComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "attempted-donations",
                component: AttemptedDonationComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "failed-donations",
                component: FailedDonationsComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "manual-donation/:id",
                component: ManualDonationComponent,
                canActivate: [AuthGuard]
            },
          {
            path: "manual-attempted-donation/:id",
            component: ManualAttemptedDonationRecieptComponent,
            canActivate: [AuthGuard]
          },
          {
                path: "photo-gallery",
                component: PhotoGalleryComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "prescreening",
                component: ChildlistComponent,
                canActivate: [AuthGuard]
            },

            {
                path: "rename",
                component: RenameComponent,
                canActivate: [AuthGuard]
            },

            {
                path: "razorpay-transaction",
                component: RazorpayTransactionComponent,
                canActivate: [AuthGuard]
            },


            {
                path: "razorpay-data",
                component: RazorpayDataComponent,
                canActivate: [AuthGuard]
            },

            {
                path: "createcenter",
                component: CreatecenterComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "allcentres",
                component: CentrelistComponent,
                canActivate: [AuthGuard],

            },
            {
                path: "users",
                component: EmployeelistComponent,
                canActivate: [AuthGuard],

            },
            {
                path: "volunteers",
                component: VolunteersComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "childdetails",
                component: ChildlistComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "child-admission",
                component: FullcontentchildComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'admission-view',
                component: ViewRegisteredChildComponent,
                canActivate: [AuthGuard]
            },

            {
                path: "edit-admission",
                component: EditFullChildComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "edit-admission/:id",
                component: EditFullChildComponent,
                canActivate: [AuthGuard]
            },

            {
                path: "Events",
                // component: UnderConstructionComponent,
                component: EventsComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "Events/:id",
                // component: UnderConstructionComponent,
                component: ViewEventComponent,
                canActivate: [AuthGuard]
            },

            {
                path: "reminders",
                // component: UnderConstructionComponent,
                component: DashboardRemindersComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "unitsponsorship",
                component: SponsorshipDashboardComponent,
                // component: DashboardRemindersComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "unitsponsorship/sponsorTable",
                component: SponsorTableComponent,
                // component: DashboardRemindersComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "unitsponsorship/sponsor",
                component: AllotChildSponsorshipComponent,
                // component: DashboardRemindersComponent,
                canActivate: [AuthGuard]
            },
          {
                path: "unitsponsorship/unsponsor",
                component: UnsponsorChildrenTableComponent,
                // component: DashboardRemindersComponent,
                canActivate: [AuthGuard]
            },
          {
                path: "unitsponsorship/count-table",
                component: CountTableComponent,
                // component: DashboardRemindersComponent,
                canActivate: [AuthGuard]
            },
          {
                path: "unitsponsorship/online-donors",
                component: OnlineDonorsTableComponent,
                // component: DashboardRemindersComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "payments",
                // component: UnderConstructionComponent,
                component: PaymentRemindersComponent,
                canActivate: [AuthGuard],
                data: {
                    role: 'admin'
                }
            },
            {
                path: "followup-admissions",
                // component: UnderConstructionComponent,
                component: FollowupAdmissionComponent,
                canActivate: [AuthGuard]
            },

            {
                path: "Donor",
                component: DonorTableComponent,
                canActivate: [AuthGuard]
            },

            {
                path: "activitylog",
                component: ActivityLogComponent,
                // component: ActivitiesComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "hospitals",
                component: HospitalsComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "Units",
                component: UnitsComponent,
                canActivate: [AuthGuard]
            },


            {
                path: "CMS",
                loadChildren: () => import('./modules/cms/cms.module').then(m => m.CmsModule)

            },
            {
                path: "education",
                loadChildren: () => import('./modules/education/education.module').then(m => m.EducationModule),
                canActivate: [AuthGuard]

            },
      {
        path: 'tasks',
        loadChildren: () => (import('./modules/task/task.module').then(m => m.TaskModule)),
        canActivate: [AuthGuard]
      },
            {
                path: "reports",
                loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)

            },
          {
                path: "questions",
                loadChildren: () => import('./modules/questions/questions.module').then(m => m.QuestionsModule)

            },
            {
                path: "Caretakers",
                // component: UnderConstructionComponent,

                component: ListCaretakerComponent,
                canActivate: [AuthGuard]
            },
            {
                path:'dynamic-table',
                component:DynamicTableComponent,
                canActivate:[AuthGuard]
            },
            {
                path:'driver-table',
                component:DriverTableComponent,
                canActivate:[AuthGuard]
            },
            {
                path: "driver",
                component: AddInfoComponent,
                // component: UnderConstructionComponent,
                canActivate: [AuthGuard]
            },
          {
                path: "Vehicles",
                component: AddVehiclesComponent,
                // component: UnderConstructionComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "add-info",
                component: AllCarFuelDetailsComponent,
                // component: UnderConstructionComponent,
                canActivate: [AuthGuard]
            },
          {
                path: "vehicle-table",
                component: VehicleTableComponent,
                // component: UnderConstructionComponent,
                canActivate: [AuthGuard]
            },
          {
                path: "car-log-book",
                component: CarLogBookComponent,
                // component: UnderConstructionComponent,
                canActivate: [AuthGuard]
            },
          {
                path: "fuels-details",
                component: AllCarFuelsDetailsNewComponent,
                // component: UnderConstructionComponent,
                canActivate: [AuthGuard]
            },
          {
            path: "servicing-details",
            component: AllServicingDetailsNewComponent,
            // component: UnderConstructionComponent,
            canActivate: [AuthGuard]
          },
            {
                path: "glossary",
                component: GlossaryComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "child-report-x",
                // component: VehiclesComponent
                component: ChildReportComponent,
                canActivate: [AuthGuard]
            },

            {
                path: 'child-excel',
                component: RegisteredChildExcelComponent
            },
            {
                path: 'child-edit-admin/:id',
                component: AdminChildEditComponent
            },
            {
                path: "donation",
                component: DonationtableComponent,
                canActivate: [AuthGuard],
                children: [
                    // {path: 'getreceipt/:id', component: GetreceiptComponent},

                ]
            },

            {
                path: "meals",
                canActivate: [AuthGuard],
                component: MealsComponent,
                children: [
                    {
                        path: "",
                        pathMatch: 'full',
                        component: MealsCalenderComponent,
                        canActivate: [AuthGuard]
                    },
                    {
                        path: "allevents",
                        pathMatch: 'full',
                        component: MealMateEventTableComponent,
                        canActivate: [AuthGuard]
                    },
                    {
                        path: "slots",
                        component: MealSlotsComponent,
                        canActivate: [AuthGuard]
                    },
                    {
                        path: 'bookings',
                        component: ManageOnlineSlotsComponent,
                        canActivate: [AuthGuard]
                    }

                ]
            },
            {
                path: "view-donation",
                component: ViewDonationComponent,
                canActivate: [AuthGuard]
            },
            // {
            //   path: "education",
            //   component: UnderConstructionComponent,
            //   canActivate: [AuthGuard]
            // },
            {
                path: 'counseling',
                loadChildren: () => import('./modules/counseling/counseling.module').then(m => m.CounselingModule)
            },
            // {path: 'getreceipt/:id', component: GetreceiptComponent},

            {
                path: 'inventory',
                loadChildren: () => import('./modules/inventory/inventory.module').then(m => m.InventoryModule)
            },

        ]
    },

    // {
    //   path: "",
    //   component: HeaderComponent
    // },

    // { path: '',   redirectTo: '/dashboard', pathMatch: 'full' }, // redirect to `first-component`

    //lazyloading

    {
        path: "login",
        component: LoginComponent
    },
    {
        path: "logout",
        component: LogoutComponent
    },
    {
        path: 'test',
        component: MyTestComponent
    },
    {
        path: 'bulk',
        component: BulkUploadComponent
    },
  {
    path: "places",
    component: GetPlacesComponent,
    canActivate: [AuthGuard]
  },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
