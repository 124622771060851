<!-- trip-view.component.html -->

<div class="container mt-4 w-100 border-1">
  <h1 class="bg-warning text-center mb-4 mt-2">Trip Details</h1>

  <table class="table w-100">
    <tbody>
    <tr>
      <th>Child Name</th>
      <td>{{ childNames || 'NA'}}</td>
    </tr>

    <tr>
      <th>Round Trip ?</th>
      <td>{{ tripData.isRoundTrip ? 'Yes' : 'No' }}</td>
    </tr>
    <tr>
      <th>Trip Date</th>
      <td>{{ displayDate2(tripData.trip_started_at) }}</td>
    </tr>

    <!-- Trip From -->
    <tr class="cursor-pointer" (click)="openGoogleMaps(tripData.pick_up_location_lat_lng.lat, tripData.pick_up_location_lat_lng.lng)">
      <th>Trip From</th>
      <td>{{ tripData.pick_up_location }}</td>
    </tr>

    <!-- Trip To -->
    <tr class="cursor-pointer" (click)="openGoogleMaps(tripData.drop_location_lat_lng.lat, tripData.drop_location_lat_lng.lng)">
      <th>Trip To</th>
      <td>{{ tripData.drop_location }}</td>
    </tr>

    <!-- Vehicle Number -->
    <tr>
      <th>Vehicle Number</th>
      <td>{{ tripData.vehicle_info.vehicleNumber }}</td>
    </tr>

    <!-- Driver Name -->
    <tr>
      <th>Driver Name</th>
      <td>{{ tripData.vehicle_info.driverName }}</td>
    </tr>

    <!-- Start Date and Time -->
    <tr>
      <th>Start Date and Time</th>
      <td>{{ displayDate(tripData.trip_started_at) }}</td>
    </tr>

    <!-- End Date and Time -->
    <tr>
      <th>End Date and Time</th>
      <td>{{displayDate(tripData.trip_ended_at) }}</td>
    </tr>

    <!-- Purpose of Trip -->
    <tr>
      <th>Purpose of Trip</th>
      <td>{{ tripData.purpose_of_trip }}</td>
    </tr>

    <tr class="cursor-pointer" (click)="openGoogleMaps(tripData.actual_pick_up_location.lat, tripData.actual_pick_up_location.lng)">
      <th>Actual Pick Up Location</th>
      <td>{{ tripData.actual_pick_up_location.lat }}, {{ tripData.actual_pick_up_location.lng }}</td>
    </tr>


    <tr class="cursor-pointer" (click)="openGoogleMaps(tripData.actual_drop_location.lat, tripData.actual_drop_location.lng)">
      <th>Actual Drop Location</th>
      <td>{{ tripData.actual_drop_location.lat }}, {{ tripData.actual_drop_location.lng }}</td>
    </tr>

    <tr>
      <th> Trip Type</th>
      <td>{{ tripData.triptype}}</td>
    </tr>
    <tr>
      <th> Pick meter Reading</th>
      <td>{{ tripData.pick_up_meter_reading}}</td>
    </tr>
    <tr>
      <th> Drop Meter Reading</th>
      <td>{{ tripData.drop_meter_reading}}</td>
    </tr>
    <tr>
      <th>Round Meter Reading</th>
      <td>{{ tripData.round_meter_reading }}</td>
    </tr>

    <tr>
      <th>Person Name</th>
      <td>{{ tripData.person_name_list}}</td>
    </tr>
    <!-- Remark -->
    <tr>
      <th>Remark</th>
      <td>{{ tripData.remark }}</td>
    </tr>

    <!-- Status -->
    <tr>
      <th>Status</th>
      <td>{{ tripData.status }}</td>
    </tr>

    <!-- Total KM -->
    <tr>
      <th>Total KM</th>
      <td>{{calculateTotalKms(tripData)}}</td>
    </tr>
    </tbody>
  </table>
  <!-- Image Row -->
  <div class="row">
    <!-- Start Trip Image -->
    <div class="col-4 text-center">
      <strong>Start Trip Image</strong>
      <div>
        <img *ngIf="tripData.startTripImage; else startTripImagePlaceholder" [src]="tripData.startTripImage" alt="Start Trip Image" class="img-fluid" style="max-height: 10rem;">
        <ng-template #startTripImagePlaceholder>
          <img src="/assets/images/dummyImage.jpg" alt="Dummy Start Trip Image" class="img-fluid" style="max-height: 10rem;">
        </ng-template>
      </div>
    </div>

    <!-- Round Trip Image -->
    <div class="col-4 text-center">
      <strong>Round Trip Image</strong>
      <div>
        <img *ngIf="tripData.roundTripImage; else roundTripImagePlaceholder" [src]="tripData.roundTripImage" alt="Round Trip Image" class="img-fluid" style="max-height: 10rem;">
        <ng-template #roundTripImagePlaceholder>
          <img src="/assets/images/dummyImage.jpg" alt="Dummy Round Trip Image" class="img-fluid" style="max-height: 10rem;">
        </ng-template>
      </div>
    </div>

    <!-- End Trip Image -->
    <div class="col-4 text-center">
      <strong>End Trip Image</strong>
      <div>
        <img *ngIf="tripData.endTripImage; else endTripImagePlaceholder" [src]="tripData.endTripImage" alt="End Trip Image" class="img-fluid" style="max-height: 10rem;">
        <ng-template #endTripImagePlaceholder>
          <img src="/assets/images/dummyImage.jpg" alt="Dummy End Trip Image" class="img-fluid" style="max-height: 10rem;">
        </ng-template>
      </div>
    </div>
  </div>

  <div class="text-center">
  <button mat-raised-button color="accent" (click)="onCancel()" class="m-4">Cancel</button>
</div>
</div>
