import {AfterContentInit, AfterViewChecked, AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {ActivatedRoute, Router} from '@angular/router';
// const fs = require("fs");
// import {fs} from fs
// const path = require("path");
// const { MailtrapClient } = require("mailtrap");


import {getDownloadURL, getStorage, ref, uploadBytesResumable, uploadString,} from '@angular/fire/storage';
import {addDoc, collection, doc, Firestore, getDoc, updateDoc} from '@angular/fire/firestore';
import {Database} from '@angular/fire/database';
import {Reciept} from "../../../models/Reciept";
import {LogService} from "../../../services/log.service";

const numWords = require('num-words');

interface ReceiptModel {
  name: string,
  address: string,
  state: string,
  pan: string
}

@Component({
  selector: 'app-reciept',
  templateUrl: './getreceipt.component.html',
  styleUrls: ['./getreceipt.component.css']
})
export class GetreceiptComponent implements OnInit {
  // public readonly downloadUrl$: Observable<string>;
logo='../src/assets/cropped-logo.png'
    // @ts-ignore
  model: Reciept;
  uid = 't';
  mName = 'x';
  mAddress = 'x';
  mCity = 'x';
  mCountry: string | undefined = 'x';
  mPan = 'x';
  mState = 'x';
  mEmail = 'x';
  mZipCode = 'x';
  amountinWords = 'x'
  mUri = '';
  // @ts-ignore
  @ViewChild('sectiontoprint', {static: true}) div: ElementRef;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private firestore: Firestore,
              private mDatabase: Database,
              private zone: NgZone,
              private mLogService:LogService,
  ) {
    const extras = this.router.getCurrentNavigation()?.extras
    // const  ext = JSON.stringify(extras?.state)
    const ext: Reciept = extras?.state as Reciept
    // this.model = extras?.state as Reciept
    // this.mName = this.model.name
    // this.mAddress = this.model.address
    // this.mCity = this.model.city
    //  this.mCountry = this.model.country
    //   console.log(`${ext.name} ${ext.address}  : this is the data`)
    console.log('amount in words' + this.amountinWords)
    //   this.savepdf(this.div);
// this.savepdf(this.div)
//  this.zone.run(()=>{
   this.fullfunction()
//  })

    // setTimeout(this.fullfunction, 2000)

  }


  exportAsPDF(divId: HTMLDivElement) {
    const data = divId
    // @ts-ignore
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png')  // 'image/jpeg' for lower quality output.
      // @ts-ignore
      const pdf = new jsPDF('p', '', 'a4');
      // Generates PDF in portrait mode
      pdf.addImage(contentDataURL, 'PNG', 0, 0, 21.0, 29.7);
      pdf.save('Filename.pdf');
    });
  }

// On Click
  public openPDF(divId: HTMLDivElement): void {
    // const DATA: any =divId
    const DATA: any = document.querySelector('#capture');

    // @ts-ignore
    html2canvas(DATA).then((canvas) => {
        const PDF = new jsPDF('p', 'cm', 'a4');
        const fileWidth = 222;
        const fileHeight = (canvas.height * fileWidth) / canvas.width;
        const pdfwidth = PDF.internal.pageSize.getWidth()
        const pfheight = (canvas.height * pdfwidth) / canvas.width;
        const FILEURI = canvas.toDataURL('image/png');
        const position = 0;
        // PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
        const d = PDF.addImage(FILEURI, 'PNG', 0, position, 21.0, 29.7, '', 'FAST');
        const filename = this.model.receipt_id
        const output = PDF.output()
        // console.log(output)
     /** To save PDF File */
        PDF.save(filename, {returnPromise: true}).then(()=>
          {
            /** To Upload Reciept Image to Database */
            // this.extracted(FILEURI, filename);
            let activity = {
                date: new Date().getTime(),
                section: 'Donor',
                action: 'Receipt',
                description: `Donation Generate Donation Report by user`,
                currentIp: localStorage.getItem('currentip')!
              }
            this.mLogService.addLog(activity)
          }
        );

      },
    )
    //   console.log(JSON.stringify(this.mUri))
//    sendEmail()


  }

  // tslint:disable-next-line:variable-name
  private uploadtoFirebase(mUri: string, receiptId: string) {
    const storageRef = ref(getStorage(getStorage().app), `testdonations/${receiptId}.png`,);
    uploadString(storageRef, mUri).then(() => {
      console.log('successfull')
    })
      .catch((reason) => {
        console.log(reason.data)
      })
  }

  private extracted(FILEURI: string, filename: string) {
    console.log('into extracted');
    const metadata = {
      contentType: 'image/png'
    };
    const file = this.dataURItoBlob(FILEURI);
    console.log(file.type)
    const storage = getStorage;
    const storageRef = ref(getStorage(getStorage().app), `donations/${filename}.png`,);
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);
    uploadTask.on('state_changed',
      snapshot => {
        console.log('into upload tsk')
        // TO GET TRANSFER STATUS PERCENTAGE
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done `);
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;

          case 'running':
            console.log(`Upload is running`);
            break;
          case 'success':
            console.log(`Upload is succeded`);
            break;
          case 'error':
            console.log(`error`)
        }
      },
      (error) => {
        switch (error.code) {
          case 'storage/unauthorised': // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;
          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        console.log('complete');
        // implementsendEmail On this now
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
          const docid = localStorage.getItem('currentreceiptid')
          const paymentRef = doc(this.firestore, `donations/${docid}`)
          this.model.doc_id = downloadURL
          return updateDoc(paymentRef, {doc_id: downloadURL}).then(() => {
            console.log('Document Updated')
            // window.location.replace('http://www.accesslife.org');

          }).catch((err) => {
            console.log('error uploding: ' + err);
          })

        });
      })

    const handle = uploadTask.on('state_changed');
    const unsubscribe = handle((snapshot) => {
      const percent = snapshot.bytesTransferred / snapshot.totalBytes * 100;
      console.log(percent + '% done');
      // Stop after receiving one update.
      // @ts-ignore
      unsubscribe();
    });
  }

  dataURItoBlob(dataURI: string) {
// convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);
// separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
// write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type: 'application/pdf'});
  }

// Automated
  public savepdf(div: ElementRef<HTMLElement>): void {
    // const DATA: HTMLElement = div.nativeElement.
    // console.log(DATA)
    // const DATA: any = document.querySelector('#capture');
    const DATA: any = document.querySelector('#capture');
    //
    // html2canvas(document.querySelector("#capture")).then((canvas) => {
    html2canvas(DATA).then((canvas) => {
        const PDF = new jsPDF('p', 'cm', 'a4');
        const fileWidth = 222;
        const fileHeight = (canvas.height * fileWidth) / canvas.width;
        const pdfwidth = PDF.internal.pageSize.getWidth()
        const pfheight = (canvas.height * pdfwidth) / canvas.width;
        const FILEURI = canvas.toDataURL('image/png');
        this.mUri = canvas.toDataURL('image/png');
        const position = 0;
        // PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);

        const d = PDF.addImage(FILEURI, 'PNG', 0, position, 21.0, 29.7, '', 'FAST');
        console.log(JSON.stringify(this.model))
        const filename = this.model.receipt_id
        this.extracted(FILEURI, filename);
        this.mUri = canvas.toDataURL('image/png');
        this.uploadtoFirebase(this.mUri, this.model.receipt_id)
        PDF.save(filename, {returnPromise: true}).then(() => {
          this.extracted(FILEURI, filename);
          this.mUri = canvas.toDataURL('image/png');
       //   this.uploadtoFirebase(this.mUri, this.model.receipt_id)
        });

      },
    )
    //   console.log(JSON.stringify(this.mUri))
//    sendEmail()


  }

  // ngAfterViewInit() {
  // console.log(`${this.div.nativeElement}`)
// setTimeout(this.savepdf,1000)

  // }

  ngOnInit(): void {

  }

  fullfunction() {
    this.route.params.subscribe(data => {
      console.log('ID is: ' + JSON.stringify(data));
      console.log('ID is: ' + data.id);
      localStorage.setItem('currentreceiptid', data.id)
      const docref = doc(this.firestore, `donations/${data.id}`)
      const rec = getDoc(docref)
      const docSnap = rec.then(async (snap) => {
        console.log(snap.data())
        this.model = await snap.data() as Reciept
        console.log(this.model.receipt_id)
        this.amountinWords = numWords(this.model?.totalAmountWithoutTax)
        this.uid = snap.id
        const DATA: any = await document.querySelector('#capture');
        //
        // html2canvas(document.querySelector("#capture")).then((canvas) => {
        // element(window.document.body).ready(function () {
        //
        //   // Your function that runs after all DOM is loaded
        //
        // });

        /** Full Function execution*/
       /*setTimeout(()=>{
         html2canvas(DATA).then(async (canvas) => {
           const PDF = new jsPDF('p', 'cm', 'a4');
           const fileWidth = 222;
           const fileHeight = await (canvas.height * fileWidth) / canvas.width;
           const pdfwidth = await PDF.internal.pageSize.getWidth()
           const pfheight = (canvas.height * pdfwidth) / canvas.width;
           const FILEURI = await canvas.toDataURL('image/png');
           this.mUri = await canvas.toDataURL('image/png');
           const position = 0;
           // PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);

           const d = PDF.addImage(FILEURI, 'PNG', 0, position, 21.0, 29.7, '', 'FAST');
           console.log(JSON.stringify(this.model))
           const filename =await this.model.receipt_id
           this.mUri =await canvas.toDataURL('image/png');
           //     this.uploadtoFirebase(this.mUri, this.model.receipt_id)
           await PDF.save(filename, {returnPromise: true}).then(() => {
             this.extracted(FILEURI, filename);
             this.mUri = canvas.toDataURL('image/png');
             //  this.uploadtoFirebase(this.mUri, this.model.receipt_id)
             this.extracted(FILEURI, filename);

           });

         },)
       },1000);*/
        // window.setTimeout(this.savepdf, 1000);
        //
      })

    })
  }

  // ngAfterViewChecked(): void {
  //   this.savepdf(this.div);
  //
  // }

}
