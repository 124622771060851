import {Component, isDevMode, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, Validators} from "@angular/forms";
import {MAT_DATE_FORMATS} from "@angular/material/core";
import {MY_FORMATS} from "../../../utils/formats";
import {MatDialog} from "@angular/material/dialog";
import {AddHospitalComponent} from "../../../modules/hospitals/components/add-hospital/add-hospital.component";
import {Observable} from "rxjs";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {Centre} from "../../../interfaces/Centres";
import {MatSelectChange} from "@angular/material/select";
import {CentreService} from "../../../services/centre.service";
import {Units} from "../../../interfaces/Units";
import {ChildService} from "../child.service";
import {UnitService} from "../../../services/unit.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Database, increment, ref, set} from "@angular/fire/database";
import {Router} from "@angular/router";
import {FullChild} from "../../../interfaces/FullChild";
import firebase from "firebase/compat/app";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Firestore} from "@angular/fire/firestore";
import {increment as fireincrement} from "@angular/fire/firestore";
import {AddFieldComponent} from "../../types/add-field/add-field.component";
import {ListType} from "../../types/types.component";
import {Hospitals} from "../../../interfaces/Hospitals";
import {environment} from "../../../../environments/environment";
import {needConfirmation} from "../../../confirm-dialog/confirm-dialog.decorator";
import {TitleCasePipe} from '@angular/common';
import {IndianPostalCodesService} from "indian-postal-codes";
import {LogService} from "../../../services/log.service";
import {takeUntil} from "rxjs/internal/operators/takeUntil";
import {Subject} from "rxjs/internal/Subject";
import {map} from "rxjs/operators";
import {TasksService} from "../../../modules/task/tasks.service";
import {Task} from "../../../modules/task/models/task.model";

@Component({
  selector: 'app-fullcontentchild',
  templateUrl: './fullcontentchild.component.html',
  styleUrls: ['./fullcontentchild.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})

//Only positive numbers valideation pattern="^[1-9]+[0-9]*$"

export class FullcontentchildComponent implements OnInit, OnDestroy {
  // FAQ PANEL STATES
  pinCode: any;
  result: any;
  displayField: boolean = false;
  postOffice: any;

  selectedCenter: Centre | undefined | null

  centers$: Observable<Centre[]>
  childForm: any;
  submitted = false;
  myControl = new FormControl('');
  options: string[] = ['Bandra Center', 'Chembur Center', 'Pune Center'];
  bloodGroupsArray = ['A+ve', 'A-ve', 'B+ve', 'B-ve', 'AB+ve', 'AB-ve', 'O+ve', 'O-ve', 'NA']
  // diagnosticsArray = Array.from({length: 10}).map((_, i) => `Diagnosis ${i}`);
//  allergiesArrray = Array.from({length: 10}).map((_, i) => `Allergy  ${i}`);
  allCentres: Centre[] = []
  currentYear = new Date().getFullYear();
  minAdmissionDate = new Date(this.currentYear, 0, -3);
  maxAdmissionDate = new Date(this.currentYear, 0, +1);
  minDate = new Date(this.currentYear - 14, 0, 1);
  maxDate = new Date(this.currentYear, 11, 31);
  maxDateLimit = new Date
  isSelectedCentre = false
  /**  @param(tempID) : key for prescreening child doc key*/
  tempId: string | undefined;
  currentChildCode: string | undefined
  // @ts-ignore
  units$: Observable<Units[]>
  fb: FormBuilder
  routerData: FullChild
  _destroyed$ = new Subject()
  _diagnosis$: Observable<ListType[]>
  _allergies$: Observable<String[]>
  _skills$: Observable<String[]>
  _occupation$: Observable<String[]>
  _relation$: Observable<String[]>
//Documents
  titleAadhar = 'Adhaar Card'
  env = isDevMode() ? environment.testMode : environment.productionMode
  mHospitals: Hospitals[] = []
  //To get current Year
  userData = JSON.parse(localStorage.getItem('userData')!)
  specialCase: boolean = false

  constructor(private fbApi: FormBuilder,
              private mDatabase: AngularFireDatabase,
              private mCenterService: CentreService,
              private childService: ChildService,
              private unitService: UnitService,
              private taskService: TasksService,
              private mDatabaseRef: Database,
              private mFirestore: Firestore,
              private firestore: AngularFirestore,
              private postalCodesService: IndianPostalCodesService,
              private router: Router,
              private _snackBar: MatSnackBar,
              private matDialog: MatDialog,
              private readonly mLogService: LogService,
              private titlecasePipe: TitleCasePipe
  ) {

    this.fetchHopitals();
    this.centers$ = this.mCenterService.getAllCentres()
    this.fb = fbApi
    this.routerData = this.router.getCurrentNavigation()?.extras.state as FullChild
    console.table(this.routerData)
    this._diagnosis$ = this.mDatabase.list<ListType>('types/Diagnosis').valueChanges().pipe(
      map(diagnosis => diagnosis.sort()), // Sort the array alphabetically
    );
    this._allergies$ = this.mDatabase.list<String>('types/Allergy').valueChanges().pipe(
      map(relations => relations.sort()), // Sort the array alphabetically
    );
    this._skills$ = this.mDatabase.list<String>('types/Skills').valueChanges().pipe(
      map(skills => skills.sort()), // Sort the array alphabetically
    );
    this._occupation$ = this.mDatabase.list<String>('types/Occupation').valueChanges().pipe(
      map(occupation => occupation.sort()), // Sort the array alphabetically
    );
    this._relation$ = this.mDatabase.list<String>('types/Relation').valueChanges().pipe(
      map(relations => relations.sort()), // Sort the array alphabetically
    );
  }

  private fetchHopitals() {
    this.mDatabase.list<Hospitals>(this.env.hospitals).valueChanges().pipe(takeUntil(this._destroyed$)).subscribe(res => {
      this.mHospitals = res
      if (this.userData.role == 'Centre') {
        this.mHospitals = this.mHospitals.filter((x) => x.city == this.userData.location)
        this._snackBar.open(this.mHospitals.length.toString())

      }
    })
  }

  ngOnInit(): void {
    // this.childForm = this.fb.group()

    this.childForm = this.fb.group({
      //Child Info
      /** childCode for latest child counter to genereate child code from database. */
      childCode: new FormControl('', [Validators.required]),
      // "centreCode": new FormControl('', [Validators.required]),
      firstName: new FormControl(this.routerData?.firstName, [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      middleName: new FormControl(this.routerData?.middleName, [Validators.pattern('[a-z A-Z ]*')]),
      lastName: new FormControl(this.routerData?.lastName, [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      mothername: new FormControl(this.routerData?.mothername, [Validators.required, Validators.pattern('[a-zA-Z ]*')]),

      bloodGroup: new FormControl('', [Validators.required]),
      dob: new FormControl(null, [Validators.required]),
      age: new FormControl(''),
      gender: new FormControl(this.routerData?.gender, [Validators.required]),
      mobile: new FormControl(this.routerData?.mobile, [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(10)]),
      address: new FormControl('', Validators.required),
      city: new FormControl(this.routerData?.city, [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      district: new FormControl(this.routerData?.city, [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      country: new FormControl(this.routerData?.country, [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      state: new FormControl(this.routerData?.state, [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      pincode: new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(6)]),
      childAadhaar: new FormControl('', [Validators.pattern('[0-9]*'), Validators.maxLength(15)]),
      hobbies: new FormControl(this.routerData?.hobbies, [Validators.required]),
      allergies: new FormControl(this.routerData?.allergies, [Validators.required]),
      aspirations: new FormControl(this.routerData?.aspirations, [Validators.required]),
      // ?Emergency Contact Name
      emergencyContactName1: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      emergencyContactRelation1: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      emergencyContactNumber1: new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(6)]),
      emergencyContactName2: new FormControl('', Validators.required),
      emergencyContactRelation2: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      emergencyContactNumber2: new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(6)]),
      // Registration Info

      selectCentre: new FormControl(''),
      stayedBefore: new FormControl('', [Validators.required]),
      selectUnit: new FormControl('', [Validators.required]),
      bag: new FormControl('', [Validators.required]),
      guardianStayStatus: new FormControl('', [Validators.required]),
      dateOfAdmission: new FormControl('', [Validators.required]),
      //Basic Info End
      // Doc Section
      // "documents": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      // "drivingLicense": new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      // "rationCard": new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      // "electionId": new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      // Doc Section End
//Guardian Info
      siblings: this.fb.array([]),
      guardians: this.fb.array([]),
      // "aadhaarnumber": new FormControl('', [Validators.required, Validators.pattern('[0-9]{12}$'), Validators.maxLength(12), Validators.minLength(12)]),
      // "relation": new FormControl('', [Validators.required]),
      // "occupation": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      // "contactno": new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(10), Validators.minLength(10)]),
      // "altcontactno": new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(10), Validators.minLength(10)]),
      // "skill": new FormControl('', [Validators.required]),
      //
      doctorName: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      fileNo: new FormControl('', [Validators.required]),
      socialName: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      height: new FormControl('', [Validators.required, Validators.pattern('[.0-9]*'), Validators.maxLength(5)]),
      weight: new FormControl('', [Validators.required, Validators.pattern('[0-9.]*'), Validators.maxLength(5)]),
      prescribed: new FormControl('', [Validators.required]),
      sickness: new FormControl('', [Validators.required]),
      // "treatment": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      treatment: new FormControl(''),
      medical: new FormControl('', [Validators.required]),
      hospital: new FormControl('', [Validators.required]),
      centreName: new FormControl('', [Validators.required]),
      centreCode: new FormControl('', [Validators.required]),
      baseCentreName: new FormControl('', [Validators.required]),
      baseCentreCode: new FormControl('', [Validators.required]),
      allergiesSelected: new FormControl('', [Validators.required]),
      //  diagnosis: new FormControl(this.routerData?.diagnosis, [Validators.required]),
      diagnosisSelected: new FormControl('', [Validators.required]),
      months: new FormControl('', [Validators.pattern('[0-9]*'), Validators.maxLength(3)]),
      days: new FormControl('', [Validators.pattern('[0-9]*'), Validators.maxLength(3)]),
      // timestamp: new FormControl(ServerValue.TIMESTAMP)
      sponsored: new FormControl(false, Validators.required)
    })
    this.addGuardianControl()
    this.addGuardianControl()
    // this.addSiblingControl()
    this.tempId = this.routerData?.id

    this.mCenterService.getCentreListSnapshot().pipe(takeUntil(this._destroyed$)).subscribe(centres => {
      centres.forEach(centre => {
          const $key = centre.key
          const data = centre.payload.toJSON()
          const c = {$key, ...data}
          this.allCentres.push(c as Centre)
        }
      )
    })
    this.childService.getCurrentChildCounter().pipe(takeUntil(this._destroyed$)).subscribe((snapshot: any) => {
      let snapshots = snapshot + ''
      console.log('currentchildcounter', snapshots.length)
      let count = ''
      if (snapshots.length == 4) count = '0'
      else if (snapshots.length == 3) count = '00'
      else if (snapshots.length == 2) count = '000'
      else if (snapshots.length == 1) count = '0000'
      console.log('generatedId', `AL-${this.currentYear}-${count}${snapshot}`)
      this.childForm.get('childCode').setValue('AL-' + this.currentYear + '-' + count + snapshot)
      console.log('currentChildCode:count-' + snapshot.length, this.childForm.get('childCode').value)
    })
  }

  relation: string[] = [
    'Father',
    'Mother',
    'Brother',
    'Sister',
    'Paternal Uncle',
    'Paternal Aunt',
    'Maternal Aunt',
    'Paternal Grandfather',
    'Paternal Grandmother',
    'Maternal Grandfather',
    'Maternal Grandmother'
  ];
  states: string[] = [
    'None',
    'Yes',
    'No',
  ]
  skill: string[] = [
    'None',
    'Driving',
    'Teaching',
    'Carpenting',
    'Photography',
    'Mobile Reparing',
    'AC reparing',
    'Farming'
  ];
  hospital: string[] = [
    'None',
    'Yes',
    'No',
  ];

  allergies: string[] = [
    'None',
    'Yes',
    'No',
  ];
  diagnosis: string[] = [
    'None',
    'Yes',
    'No',
  ];
  months: string[] = [
    'None',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // days = [  'o'  ,'1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'];

  // 'Tuesday',
  // 'Wednesday',
  // 'Thursday',
  // 'Friday',
  // 'Saturday',
  // 'Sunday',
  @needConfirmation()
  SubmitData() {

    try {
      this.childForm.get('age').setValue(this.getCalculatedAge(this.childForm.get('dob').value))
      console.log(this.childForm.value)
    } catch (e: any) {
      console.warn(e)
    }
//first sumbit data
    this.submitted = true;
    //Ideal Code Should be like this
    this.CentreName.setValue(this.selectedCenter?.centreName)
    this.CentreCode.setValue(this.selectedCenter?.centreCode)
    this.BaseCentreName.setValue(this.selectedCenter?.centreName)
    this.BaseCentreCode.setValue(this.selectedCenter?.centreCode)
    if (this.childForm.valid) {
      const unitselected = this.childForm.get('selectUnit').value
      const childCode = this.childForm.get('childCode').value
      const childName = this.childForm.get('firstName').value + ' ' + this.childForm.get('lastName').value
      const centre = this.childForm.get('selectCentre').value.centreCode
      console.log('Unit Selected', unitselected)
      console.log('center on Sumbmit', centre)
      // this.childService.addTempChild(this.childForm.value)
      const g = this.childForm.get('guardians').value
      console.log('Guardian ', g)
      console.log(this.childForm.value);
      console.table(this.childForm.value);
      this.childForm.removeControl('selectCentre')
      this.childForm.addControl('isAlloted', new FormControl(true));
      this.childForm.addControl('createdAt', new FormControl(new Date()));



      this.childService.enrollChild(childCode, this.childForm).then(() => {
        if (this.routerData !== undefined) {
          const id = this.routerData.id
          this.firestore.doc(`${this.env.prescreening}/${id}`).update({
            alloted: true,
            reasonOfClousure: 'Admitted to AL',
            status: 'Closed'
          });
        }
        if(this.routerData===undefined) {
       //add try catch
          try {
            this.addToPrescreening(this.childForm.value)
          } catch (e) {
            console.log(e)
          }

        }

        // this.mDatabase.object(`dummy/centrelist/${id}`).update()
        const documentRef = ref(this.mDatabaseRef, `${this.env.counters}/childCounter`)
        set(documentRef, increment(1))
        // console.log('child added')
        this.unitService.allotUnit(childCode, centre, unitselected, childName).then(() => {

          this.firestore.doc(`${this.env.centres}/${centre}`).update({
            availableUnits: fireincrement(-1),
            occupiedUnits: fireincrement(1)
          }).then(() => {
            // console.log('Centre Updated!')
          })

          let activity = {
            date: new Date().getTime(),
            section: 'Admission',
            action: 'Submit',
            description: `Child ${childCode} registered  by  `,
            currentIp: localStorage.getItem('currentip')!
          }
          this.mLogService.addLog(activity)
          let taskDate = new Date()
          taskDate.setDate(taskDate.getDate() + 4)
            //* ADD TASK TO CONDUCT RAPPORT SESSION TO DWANI GUJAL ON NEW ADMISSION if(child Age is above 4)
          const mEducationTask: Partial<Task> = {
            title: `Conduct Rapport session `,
            done: false,
            priority: 'medium',
            taskFor: ['Dwani Gujral'], // Example user assignment
            due_date:taskDate, // Set a default due date
            note: `Conduct Rapport session of child ${childName} of ${this.CentreName.value}`,
          };
          this.taskService.addAutomatedTask(mEducationTask)

          //* ADD TASK TO CONDUCT RAPPORT SESSION New Child Admitted to _ Centre_, Please schedule Counseling Sessions for _ChildRen to krisha

          const mCounsellingTask: Partial<Task> = {
            title: `Schedule Counselling Session for ${childName}  `,
            done: false,
            priority: 'medium',
            taskFor: ['Krisha Shah'], // Example user assignment
            due_date: taskDate, // Set a default due date
            note: `Schedule Counselling session of child ${childName} of ${this.CentreName.value}`,
          };
          this.taskService.addAutomatedTask(mCounsellingTask)

          this._snackBar.open(`Child Alloted to ${unitselected}`, '', {duration: 3000})
          this.router.navigateByUrl('/home/childdetails')
        })
        // /** After adding child */


        //Child Enroll End
      }).catch(reason => {
        console.log('failed due to', reason)
      })

    } else {
      this._snackBar.open('Please fill the form properly', '', {duration: 3000})
      // this.findInvalidControls()
      console.log(this.childForm.state)

      console.log(this.findInvalidControls())
    }
//then close dialog
//     this.dialogRef.close()

  }

  addToPrescreening(child: any) {
    this.firestore.collection(`${this.env.prescreening}`).add({
      firstName:child.firstName,
      middleName:child.middleName,
      lastName:child.lastName,
      city:child.state,
      district:child.district,
      country:child.country,
      state:child.state,
      pincode:child.pincode,
      diagnosis:child.diagnosisSelected,
      gender:child.gender,
      dateOfClosure:new Date(),
      hospitalname:child.hospital,

      alloted: true,
      reasonOfClosure: 'Admitted to AL',
      status: 'Closed'
    }).then(res=>{
      console.log('Added to Prescreening')
    }).catch(reason => {
      console.log('failed due to', reason)
    });

  }

  TestForm() {
    console.log(this.childForm.value)
  }

  get doctorname() {
    return this.childForm.get('doctorName');
  }

  get CentreName() {
    return this.childForm.get('centreName')
  }

  get CentreCode() {
    return this.childForm.get('centreCode')
  }

  get BaseCentreName() {
    return this.childForm.get('baseCentreName')
  }

  get BaseCentreCode() {
    return this.childForm.get('baseCentreCode')
  }

  get fileno() {
    return this.childForm.get('fileNo');
  }

  get lastname() {
    return this.childForm.get('lastName');
  }

  get mothername() {
    return this.childForm.get('mothername');
  }

  get socialname() {
    return this.childForm.get('socialName');
  }

  get height() {
    return this.childForm.get('height');
  }


  get weight() {
    return this.childForm.get('weight');
  }

  get getdiagnosis() {
    return this.childForm.get('diagnosis');
  }

  get diagnosisSelected() {
    return this.childForm.get('diagnosisSelected');
  }

  get prescribed() {
    return this.childForm.get('prescribed');
  }


  get sickness() {
    return this.childForm.get('sickness');
  }

  get treatment() {
    return this.childForm.get('treatment');
  }

  get Months() {
    return this.childForm.get('months');
  }

  get Days() {
    return this.childForm.get('days');
  }

  get medical() {
    return this.childForm.get('medical');
  }

  get documents() {
    return this.childForm.get('documents');
  }

  get altcontactno() {
    return this.childForm.get('altcontactNo');
  }

  get firstname() {
    return this.childForm.get('firstName');
  }

  gfirstName(i: any): FormControl {

    const formArray = this.childForm.get('guardians') as FormArray;

    return <FormControl<any>>formArray.at(i).get('firstName')
  }

  gAnnualIncome(i: any): FormControl {

    const formArray = this.childForm.get('guardians') as FormArray;

    return <FormControl<any>>formArray.at(i).get('annualIncome')
  }

  gmiddleName(i: number): FormControl {
    const formArray = this.childForm.get('guardians') as FormArray;

    return <FormControl<any>>formArray.at(i).get('middleName')
  }

  // get gfirstName() {
  //   const g = this.childForm.get('guardians') as FormArray
  //   return g?.get('gfirstName')
  // }

  // get gmiddleName() {
  //   const g = this.childForm.get('guardians') as FormArray
  //   return g?.get('gmiddleName')
  // }


  glastname(i: number): FormControl {
    const formArray = this.childForm.get('guardians') as FormArray;

    return <FormControl<any>>formArray.at(i).get('lastName')
  }

  gmothername(i: number): FormControl {
    const formArray = this.childForm.get('guardians') as FormArray;

    return <FormControl<any>>formArray.at(i).get('mothername')
  }

  gaadhaarnumber(i: number): FormControl {
    const formArray = this.childForm.get('guardians') as FormArray;

    return <FormControl<any>>formArray.at(i).get('aadharNumber')
  }

  gRelation(i: number): FormControl {
    const formArray = this.childForm.get('guardians') as FormArray;
    return <FormControl<any>>formArray.at(i).get('relation')

  }

  guardianOccupation(i: number): FormControl {
    const formArray = this.childForm.get('guardians') as FormArray;
    return <FormControl<any>>formArray.at(i).get('occupation')
  }

  gContactNo(i: number): FormControl {
    const formArray = this.childForm.get('guardians') as FormArray;
    return <FormControl<any>>formArray.at(i).get('contactNo')
  }

  gAlteranate(i: number): FormControl {
    const formArray = this.childForm.get('guardians') as FormArray;
    return <FormControl<any>>formArray.at(i).get('alternateContactNo')
  }

  gElectionId(i: number): FormControl {
    const formArray = this.childForm.get('guardians') as FormArray;
    return <FormControl<any>>formArray.at(i).get('electionId')
  }

  gRationCard(i: number): FormControl {
    const formArray = this.childForm.get('guardians') as FormArray;
    return <FormControl<any>>formArray.at(i).get('rationCard')
  }

  gDrivingLicense(i: number): FormControl {
    const formArray = this.childForm.get('guardians') as FormArray;
    return <FormControl<any>>formArray.at(i).get('drivingLicense')
  }

  gOtherDocuments(i: number): FormControl {
    const formArray = this.childForm.get('guardians') as FormArray;
    return <FormControl<any>>formArray.at(i).get('otherDocuments')
  }

  gSkills(i: number): FormControl {
    const formArray = this.childForm.get('guardians') as FormArray;
    return <FormControl<any>>formArray.at(i).get('skills')
  }

  sFirstName(i: number): FormControl {
    const formArray = this.childForm.get('siblings') as FormArray;
    return <FormControl<any>>formArray.at(i).get('firstName')
  }

  sGender(i: number): FormControl {
    const formArray = this.childForm.get('siblings') as FormArray;
    return <FormControl<any>>formArray.at(i).get('gender')
  }

  sContactNumber(i: number): FormControl {
    const formArray = this.childForm.get('siblings') as FormArray;
    return <FormControl<any>>formArray.at(i).get('mobile')
  }

  sAge(i: number): FormControl {
    const formArray = this.childForm.get('siblings') as FormArray;
    return <FormControl<any>>formArray.at(i).get('age')
  }

  get rationcard() {
    return this.childForm.get('rationCard');
  }

  get middlename() {
    return this.childForm.get('middleName');
  }


  get occupation() {
    return this.childForm.get('occupation');
  }


  get contactno() {
    return this.childForm.get('contactNo');
  }

  get childAadhaar() {
    return this.childForm.get('childAadhaar');
  }


  public findInvalidControls() {
    const invalid = [];
    const controls = this.childForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  cancel() {
    // this.dialogRef.close()
    this.submitted = false;
    console.log(this.childForm.reset())
    this.router.navigateByUrl('a/childdetails')
  }

  get Pincode() {
    return this.childForm.get('pincode');
  }

  get mobile() {
    return this.childForm.get('mobile');
  }

  get city() {
    return this.childForm.get('city');
  }

  get District() {
    return this.childForm.get('district');
  }

  get country() {
    return this.childForm.get('country');
  }

  get state() {
    return this.childForm.get('state');
  }

  get pincode() {
    return this.childForm.get('pincode');
  }

  get Address() {
    return this.childForm.get('address');
  }

  get childAadhar() {
    return this.childForm.get('childAadhaar')
  }

  onBlur(formControl: any) {
    if (formControl.value !== null) {
      formControl.setValue(this.titlecasePipe.transform(formControl.value.toString().trim().toUpperCase()))
    }
  }

  findPinCode(pinCode: any) {
    if (pinCode.length == 6) {
      this.postalCodesService.findByPinCode(pinCode).pipe(takeUntil(this._destroyed$)).subscribe((data) => {
        // console.log(data)
        this.result = data[0].PostOffice[0]
        if (data[0].Status === 'Success') {
          this.result = data[0].PostOffice[0];
          this.childForm.get('state').setValue(this.result.State)
          this.childForm.get('city').setValue(this.result.Block)
          this.childForm.get('country').setValue(this.result.Country)
          this.childForm.get('district').setValue(this.result.District)
          console.log(this.result)
        } else if (data[0].Status === 'Error') {
          alert(data[0].Message);
          this.result = [];
        } else if (data[0].Status === '404') {
          alert(data[0].Message);
          this.result = [];

        }
        // console.log(data)
      });
    }
  }


  get Hobbies() {
    return this.childForm.get('hobbies');
  }

  get Allergies() {
    return this.childForm.get('allergies');
  }

  get AllergiesSelected() {
    return this.childForm.get('allergiesSelected');
  }

  get Aspirations() {
    return this.childForm.get('aspirations');
  }

  get getCentre() {
    return this.childForm.get('selectCentre');
  }

  get emergencyContactName1() {
    return this.childForm.get('emergencyContactName1')
  }

  get emergencyContactRelation1() {
    return this.childForm.get('emergencyContactRelation1')
  }

  get emergencyContactRelation2() {
    return this.childForm.get('emergencyContactRelation2')
  }

  get emergencyContactNumber1() {
    return this.childForm.get('emergencyContactNumber1')
  }

  get emergencyContactName2() {
    return this.childForm.get('emergencyContactName2')
  }

  get emergencyContactNumber2() {
    return this.childForm.get('emergencyContactNumber2')
  }


  get guardiansFormArray(): FormArray {
    return this.childForm.get('guardians') as FormArray;
  }

  get siblingFormArray(): FormArray {
    return this.childForm.get('siblings') as FormArray;
  }

  guardian() {
    return this.fb.group({
      childCode: this.childForm.get('childCode', Validators.required),
      firstName: this.fb.control('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      middleName: this.fb.control('', [Validators.pattern('[a-zA-Z ]*')]),
      lastName: this.fb.control('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      // aadharNumber: this.fb.control('', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(12), Validators.minLength(12)]),
      relation: this.fb.control('', Validators.required),
      occupation: this.fb.control('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      contactNo: this.fb.control('', [Validators.required, Validators.pattern('[0-9]{10}$'), Validators.maxLength(10), Validators.minLength(10)]),
      // alternateContactNo: this.fb.control('', [Validators.required, Validators.maxLength(10), Validators.pattern('[0-9]{10}$'), Validators.minLength(10)]),
      // electionId: this.fb.control('', [Validators.required,Validators.pattern('[0-9]*')]),
      // rationCard: this.fb.control('', [Validators.required,Validators.pattern('[0-9]*')]),
      annualIncome: this.fb.control('', [Validators.required]),
      // drivingLicense: this.fb.control('', [Validators.required,Validators.pattern('[0-9]*')]),
      otherDocuments: this.fb.control('', Validators.required),
      skills: this.fb.control('', Validators.required),
    })
  }

  sibling() {
    return this.fb.group({
      firstName: this.fb.control('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      age: this.fb.control('', [Validators.required, Validators.maxLength(2), Validators.pattern('[0-9]*')]),
      gender: this.fb.control('', Validators.required),
      mobile: this.fb.control('', [Validators.min(10), Validators.pattern('[0-9]*')]),
    })
  }

  addGuardianControl() {
    this.guardiansFormArray.push(this.guardian());
  }

  addSiblingControl() {
    this.siblingFormArray.push(this.sibling());
    this.siblingFormArray.length
    console.log(this.siblingFormArray.length)
    this.siblingFormArray.controls.forEach(it => {
      console.log(it)
    })
  }

  removeSiblingControl() {
    // this.siblingFormArray.push(this.sibling());
    const limit = this.siblingFormArray.length
    this.siblingFormArray.removeAt(limit - 1)
    console.log(this.siblingFormArray.length)
    // this.siblingFormArray.controls.forEach(it => {
    //   console.log(it)
    // })
  }

  removeGuardainControl() {
    // this.siblingFormArray.push(this.sibling());
    const limit = this.guardiansFormArray.length
    if (limit > 1) this.guardiansFormArray.removeAt(limit - 1)
    console.log(this.guardiansFormArray.length)
    // this.siblingFormArray.controls.forEach(it => {
    //   console.log(it)
    // })
  }

  get getFormData() {
    return this.childForm.value.toJSON
  }

  openHospital() {
    this.matDialog.open(AddHospitalComponent,
      {
        width: '70%', height: '600px',
        enterAnimationDuration: '100ms',
        exitAnimationDuration: '200ms',
      }
    )
  }

  fetchUnits($event: MatSelectChange) {
    this.isSelectedCentre = true
    console.log('fetchUnits', $event.value)
    // this.selectedCenter  = this.allCentres.at()
    this.selectedCenter = $event.value
    if(this.selectedCenter?.centreCode =='ALBorivali'){
      this.minDate = new Date(this.currentYear - 18, 0, 1);

    }else {
this.minDate = new Date(this.currentYear - 14, 0, 1);

    }
    this.units$ = this.mDatabase.list<Units>(`${this.env.units}/${this.selectedCenter?.centreCode}`, ref => ref.orderByChild('order')).valueChanges()
    console.log('selected', $event.value)
// this.childForm.setControl()
    this.childForm.addControl('centreCode', new FormControl(this.selectedCenter?.centreCode), Validators.required)
    this.childForm.addControl('centreName', new FormControl(this.selectedCenter?.centreName), Validators.required)
    // this.childForm.setControl( 'centreCode', this.selectedCenter?.centreCode)

  }


  addDiagnosis() {
    this.matDialog.open(AddFieldComponent, {
      data: 'Diagnosis'
    })
  }

  addAllergy() {
    this.matDialog.open(AddFieldComponent, {
      data: "Allergy"
    })
  }

  HospitalChange($event: Hospitals) {
    console.log($event)
    const h = $event as Hospitals
    this.doctorname.setValue(h.doctorsName)
    this.socialname.setValue(h.socialWorkerName)

  }

  getCalculatedAge(child: Date) {
    let timeDiff = Math.abs(Date.now() - (child).getTime());
    let age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
    // * UPDATING AGE IN FIRESTORE ALCHILD
    console.log(age)
    return age
  }

  ngOnDestroy(): void {
    this._destroyed$.next('')
    this._destroyed$.complete()
  }


  isSpecial(checked: boolean) {
    this.specialCase = checked
    if (checked) {
      this.minDate = new Date(this.currentYear - 30, 0, 1)

    } else this.minDate = new Date(this.currentYear - 14, 0, 1);
  }
}
