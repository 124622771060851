<table mat-table [dataSource]="dataSource" class=" text-center">

  <!-- Display Column -->
  <ng-container matColumnDef="vehicleNumber">
    <th mat-header-cell *matHeaderCellDef> Date </th>
    <td mat-cell *matCellDef="let element">{{element?.vehicleNumber}} </td>
  </ng-container>

  <ng-container matColumnDef="entryDateTime">
    <th mat-header-cell *matHeaderCellDef> Date </th>
    <td mat-cell *matCellDef="let element">{{element?.entryDateTime}} </td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef> Date </th>
    <td mat-cell *matCellDef="let element">{{element?.date}} </td>
  </ng-container>
  <!-- Location Column -->
  <ng-container matColumnDef="totalQuantityField">
    <th mat-header-cell *matHeaderCellDef> Fuel(Diesel) </th>
    <td mat-cell *matCellDef="let element"> {{element?.totalQuantityField}} </td>
  </ng-container>

  <!-- Location Column -->
  <ng-container matColumnDef="driverName">
    <th mat-header-cell *matHeaderCellDef> Fuel(Diesel) </th>
    <td mat-cell *matCellDef="let element"> {{element?.driverName}} </td>
  </ng-container>
  <!-- Location Column -->
  <ng-container matColumnDef="meterReading">
    <th mat-header-cell *matHeaderCellDef> Fuel(Diesel) </th>
    <td mat-cell *matCellDef="let element"> {{element?.meterReading}} </td>
  </ng-container>


  <!-- Add more columns as needed -->

  <!-- Table Rows -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
