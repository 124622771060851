
<div class="row gap-2">
<mat-form-field appearance="outline" class="col-md-3">
  <mat-label>Driver Name:</mat-label>
  <mat-select  [(ngModel)]="driver_name" (selectionChange)="onDriverSelect($event)">
    <mat-option *ngFor="let model of drivers" [value]="model.firstName+' '+model.lastName">
      {{ model.firstName }} {{ model.lastName }}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-form-field appearance="outline" class="col-md-3">
  <mat-label>Vehicle Number</mat-label>
  <mat-select placeholder="Select Number"   [(ngModel)]="vehicles_no" (selectionChange)="OnVehicleSelect($event)">
    <mat-option *ngFor="let vehicle of vehicles" [value]="vehicle.vehicleNumber">{{ vehicle.vehicleNumber }}</mat-option>
  </mat-select>
</mat-form-field>

   <button mat-mini-fab (click)="filterAllCarfuleData()" ><mat-icon>search</mat-icon></button>
  <button mat-mini-fab (click)="clearAll()" color="accent"><mat-icon>clear</mat-icon></button>
</div>

<div class="card p-2">
  <div class="ngxTableHeader">
    <ul class="header-buttons-left ms-0 mb-0">
      <li class="dropdown">
        <h2>Fuels Filling Logs</h2>
      </li>
      <li class="dropdown m-l-20">
        <label for="search-input"><i class="material-icons search-icon">search</i></label>
        <input placeholder="Search" id="search-input" type="text" class="browser-default search-field"
               (keyup)='applyFilter($event)' aria-label="Search box" #input>
      </li>
    </ul>
    <ul class="header-buttons mr-2">
      <li>
        <button   mat-mini-fab color="primary" (click)="OpenDialog()">
          <mat-icon class="col-white" >add</mat-icon>
        </button>
      </li>
      <!--      <li>-->
      <!--        <div class="export-button m-l-10" matTooltip="XLSX">-->
      <!--          <img src="assets/images/icons/xlsx.png" alt=""/>-->
      <!--        </div>-->
      <!--      </li>-->
    </ul>
  </div>

  <table mat-table [dataSource]="dataSource">
<!--    <ng-container matColumnDef="entryDateTime">-->
<!--      <th mat-header-cell *matHeaderCellDef> Entry Date </th>-->
<!--      <td mat-cell *matCellDef="let element"> {{element.entryDateTime?.toDate() | date:'dd/MM/yyyy'}} </td>-->
<!--    </ng-container>-->
    <ng-container matColumnDef="entryDateTime">
      <th mat-header-cell *matHeaderCellDef> Date of Filling </th>
      <td mat-cell *matCellDef="let element"> {{element.date | date:'dd/MM/yyyy'}} </td>
    </ng-container>
    <ng-container matColumnDef="createdDateTime">
      <th mat-header-cell *matHeaderCellDef> Date of Entry </th>
      <td mat-cell *matCellDef="let element">{{ displayDate(element.createdAt) }}</td>
    </ng-container>

    <!-- Location Column -->
    <ng-container matColumnDef="vehicleNumber">
      <th mat-header-cell *matHeaderCellDef> Vehicle Number </th>
      <td mat-cell *matCellDef="let element"> {{element.vehicleNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="driverName">
      <th mat-header-cell *matHeaderCellDef> Driver Name </th>
      <td mat-cell *matCellDef="let element"> {{element.driverName}} </td>
    </ng-container>


    <ng-container matColumnDef="totalQuantityField">
      <th mat-header-cell *matHeaderCellDef> Fuel(Qty) </th>
      <td mat-cell *matCellDef="let element"> {{element.totalQuantityField}} </td>
    </ng-container>


    <ng-container matColumnDef="meterReading">
      <th mat-header-cell *matHeaderCellDef> KM(Reading) </th>
      <td mat-cell *matCellDef="let element"> {{element.meterReading}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button matTooltip="Edit" color="primary" (click)="editInfo(element)"> <mat-icon>edit</mat-icon></button>
          <button mat-icon-button matTooltip="View" color="accent" (click)="openView(element)"> <mat-icon>visibility</mat-icon></button>
        <button mat-icon-button matTooltip="delete" color=warn (click)="deleteInfo(element)"> <mat-icon>delete</mat-icon></button>
      </td>
    </ng-container>


    <!-- Add more columns as needed -->

    <!-- Table Rows -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [length]="200" [pageSizeOptions]="[25, 50, 100, 200, 500]" aria-label="Select page">
  </mat-paginator>
</div>
