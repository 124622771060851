import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {Timestamp} from "@angular/fire/firestore";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-view-car-fuels-details',
  templateUrl: './view-car-fuels-details.component.html',
  styleUrls: ['./view-car-fuels-details.component.scss']
})
export class ViewCarFuelsDetailsComponent implements OnInit {
  petrolfill :any;
  petrolmeter :any;
  constructor( @Inject(MAT_DIALOG_DATA) public Data:any,private datePipe: DatePipe, private mDialog:MatDialog){
    console.log(Data);
  }

  ngOnInit(): void {
    console.log(this.Data);  // Check if the Data object contains petrolfill and petrolmeter
    this.petrolmeter = this.Data.petrolmeter || ''; // Default to empty string if not available
    this.petrolfill = this.Data.petrolfill || '';   // Default to empty string if not available
    console.log("petrolfill:", this.petrolfill);
    console.log("petrolmeter:", this.petrolmeter);
  }

  displayDate(timestamp: Timestamp): string {
    // Check if timestamp exists and is a Firestore Timestamp
    if (timestamp && timestamp.toDate) {
      const date = timestamp.toDate(); // Convert to JavaScript Date
      return this.datePipe.transform(date, 'dd/MM/yyyy') || '';
    }
    return '';
  }

  displayDate2(dateObject: { nanoseconds: number; seconds: number }): string {

    const date = new Date(dateObject.seconds * 1000 + dateObject.nanoseconds / 1e6);

    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear().toString().slice(-2);

    return `${day}/${month}/${year}`;
  }

}
