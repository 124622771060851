import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {catchError, map} from "rxjs/operators";
export interface PlaceDetails {
  formatted_address: string;
  international_phone_number: string;
  name: string;
}
@Injectable({
  providedIn: 'root'
})


export class GooglePlacesService {
  private apiKey = 'AIzaSyBAxd83XNzBCFiq9DzTzn69qnNJIhJ1xvA'; // Replace with your actual API key

  constructor(private http: HttpClient) {}

  getPlacesByZipCode(zipCode: string): Observable<PlaceDetails[]> {
    const url = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${zipCode}&type=postal_code&key=${this.apiKey}`;

    return this.http.get<any>(url).pipe(
      map(response => {
        if (response.status === 'OK') {
          // @ts-ignore
          return response.results.map(result => {
            return ({
              formatted_address: result.formatted_address,
              international_phone_number: result.international_phone_number,
              name: result.name
            });
          });
        } else {
          throw new Error('Error fetching places: ' + response.status);
        }
      }),
      catchError(error => {
        console.error('Error fetching places:', error);
        return of([]); // Return an empty array on error
      })
    );
  }
}
